const t = {
    icon: 'fa-rectangle-ad',
    menus: {
        main: {section: 'ads', priority: 30},
    },
    new: {
        attributes: [
            'name', 'title', 'subtitle', 'overline', 'presentation', 'longDescription', 'description', 'tenant', 'advertiser', 'affiliater', 'externalTrackerId',
            'commission', 'url', 'startAt', 'endAt',  'script', 'scriptUrl', 'banner2Image', 'bannerImage', 'mobileBannerImage', 'tabletBannerImage', 'largeLeaderboardBanner2Image', 'largeLeaderboardBannerImage',
            'largeLeaderboardMainImage', 'largeLeaderboardThumbnailImage', 'largeRectangleBanner2Image', 'largeRectangleBannerImage', 'largeRectangleMainImage',
            'largeRectangleThumbnailImage', 'leaderboardBanner2Image', 'leaderboardBannerImage', 'leaderboardMainImage', 'leaderboardThumbnailImage', 'logoAltImage',
            'logoImage', 'mainImage', 'mediumRectangleBanner2Image', 'mediumRectangleBannerImage', 'mediumRectangleMainImage', 'mediumRectangleThumbnailImage',
            'mobileLeaderboardBanner2Image', 'mobileLeaderboardBannerImage', 'mobileLeaderboardMainImage', 'mobileLeaderboardThumbnailImage', 'skyscraperBanner2Image',
            'skyscraperBannerImage', 'skyscraperMainImage', 'skyscraperThumbnailImage', 'smallSquareBanner2Image', 'smallSquareBannerImage', 'smallSquareMainImage',
            'smallSquareThumbnailImage', 'squareBanner2Image', 'squareBannerImage', 'squareMainImage', 'squareThumbnailImage', 'thumbnailImage', 'wideSkyscraperBanner2Image',
            'wideSkyscraperBannerImage', 'wideSkyscraperMainImage', 'wideSkyscraperThumbnailImage', 'banner2ImageUrl', 'bannerImageUrl', 'largeLeaderboardBanner2ImageUrl',
            'largeLeaderboardBannerImageUrl', 'largeLeaderboardMainImageUrl', 'largeLeaderboardThumbnailImageUrl', 'largeRectangleBanner2ImageUrl', 'largeRectangleBannerImageUrl',
            'largeRectangleMainImageUrl', 'largeRectangleThumbnailImageUrl', 'leaderboardBanner2ImageUrl', 'leaderboardBannerImageUrl', 'leaderboardMainImageUrl',
            'leaderboardThumbnailImageUrl', 'logoAltImageUrl', 'logoImageUrl', 'mainImageUrl', 'mediumRectangleBanner2ImageUrl', 'mediumRectangleBannerImageUrl',
            'mediumRectangleMainImageUrl', 'mediumRectangleThumbnailImageUrl', 'mobileLeaderboardBanner2ImageUrl', 'mobileLeaderboardBannerImageUrl', 'mobileLeaderboardMainImageUrl',
            'mobileLeaderboardThumbnailImageUrl', 'skyscraperBanner2ImageUrl', 'skyscraperBannerImageUrl', 'skyscraperMainImageUrl', 'skyscraperThumbnailImageUrl',
            'smallSquareBanner2ImageUrl', 'smallSquareBannerImageUrl', 'smallSquareMainImageUrl', 'smallSquareThumbnailImageUrl', 'squareBanner2ImageUrl', 'squareBannerImageUrl',
            'squareMainImageUrl', 'squareThumbnailImageUrl', 'thumbnailImageUrl', 'wideSkyscraperBanner2ImageUrl', 'wideSkyscraperBannerImageUrl', 'wideSkyscraperMainImageUrl',
            'wideSkyscraperThumbnailImageUrl', 'tags',
        ],
        content: [
            ['row', [
                ['tabs', [
                    ['edit_ad_form_general_title', [
                        ['row', [
                            ['block', [
                                '!$name!', '$url!', '$title', '$subtitle', '$overline', '$presentation', '$description', '$longDescription',
                            ]],
                            ['block', [
                                '$tenant!', '$advertiser', '$affiliater', '$adTags', '$externalTrackerId', '$commission', '$startAt', '$endAt',
                            ]],
                        ]],
                    ]],
                    ['edit_ad_form_images_title', [
                        ['tabs', [
                            ['edit_ad_form_images_general_title', [
                                ['row', [
                                    ['block', [
                                        '$logoImage', '$mainImage', '$bannerImage',
                                    ]],
                                    ['block', [
                                        '$logoAltImage', '$thumbnailImage', '$banner2Image',
                                    ]],
                                ]],
                            ]],
                            ['edit_ad_form_images_squares_title', [
                                ['row', [
                                    ['block', [
                                        '$smallSquareBanner2Image', '$smallSquareBannerImage', '$smallSquareMainImage', '$smallSquareThumbnailImage',
                                    ]],
                                    ['block', [
                                        '$squareBanner2Image', '$squareBannerImage', '$squareMainImage', '$squareThumbnailImage',
                                    ]],
                                ]],
                            ]],
                            ['edit_ad_form_images_rectangles_title', [
                                ['row', [
                                    ['block', [
                                        '$mediumRectangleBanner2Image', '$mediumRectangleBannerImage', '$mediumRectangleMainImage', '$mediumRectangleThumbnailImage',
                                    ]],
                                    ['block', [
                                        '$largeRectangleBanner2Image', '$largeRectangleBannerImage', '$largeRectangleMainImage', '$largeRectangleThumbnailImage',
                                    ]],
                                ]],
                            ]],
                            ['edit_ad_form_images_leaderboards_title', [
                                ['row', [
                                    ['block', [
                                        '$mobileLeaderboardBanner2Image', '$mobileLeaderboardBannerImage', '$mobileLeaderboardMainImage', '$mobileLeaderboardThumbnailImage',
                                    ]],
                                    ['block', [
                                        '$leaderboardBanner2Image', '$leaderboardBannerImage', '$leaderboardMainImage', '$leaderboardThumbnailImage',
                                    ]],
                                    ['block', [
                                        '$largeLeaderboardBanner2Image', '$largeLeaderboardBannerImage', '$largeLeaderboardMainImage', '$largeLeaderboardThumbnailImage',
                                    ]],
                                ]],
                            ]],
                            ['edit_ad_form_images_skyscrapers_title', [
                                ['row', [
                                    ['block', [
                                        '$skyscraperBanner2Image', '$skyscraperBannerImage', '$skyscraperMainImage', '$skyscraperThumbnailImage',
                                    ]],
                                    ['block', [
                                        '$wideSkyscraperBanner2Image', '$wideSkyscraperBannerImage', '$wideSkyscraperMainImage', '$wideSkyscraperThumbnailImage',
                                    ]],
                                ]],
                            ]],
                        ]],
                    ]],
                    ['edit_ad_form_images_url_title', [
                        ['tabs', [
                            ['edit_ad_form_images_url_general_title', [
                                ['row', [
                                    ['block', [
                                        '$logoImageUrl', '$mainImageUrl', '$bannerImageUrl',
                                    ]],
                                    ['block', [
                                        '$logoAltImageUrl', '$thumbnailImageUrl', '$banner2ImageUrl',
                                    ]],
                                ]],
                            ]],
                            ['edit_ad_form_images_url_squares_title', [
                                ['row', [
                                    ['block', [
                                        '$smallSquareBanner2ImageUrl', '$smallSquareBannerImageUrl', '$smallSquareMainImageUrl', '$smallSquareThumbnailImageUrl',
                                    ]],
                                    ['block', [
                                        '$squareBanner2ImageUrl', '$squareBannerImageUrl', '$squareMainImageUrl', '$squareThumbnailImageUrl',
                                    ]],
                                ]],
                            ]],
                            ['edit_ad_form_images_url_rectangles_title', [
                                ['row', [
                                    ['block', [
                                        '$mediumRectangleBanner2ImageUrl', '$mediumRectangleBannerImageUrl', '$mediumRectangleMainImageUrl', '$mediumRectangleThumbnailImageUrl',
                                    ]],
                                    ['block', [
                                        '$largeRectangleBanner2ImageUrl', '$largeRectangleBannerImageUrl', '$largeRectangleMainImageUrl', '$largeRectangleThumbnailImageUrl',
                                    ]],
                                ]],
                            ]],
                            ['edit_ad_form_images_url_leaderboards_title', [
                                ['row', [
                                    ['block', [
                                        '$mobileLeaderboardBanner2ImageUrl', '$mobileLeaderboardBannerImageUrl', '$mobileLeaderboardMainImageUrl', '$mobileLeaderboardThumbnailImageUrl',
                                    ]],
                                    ['block', [
                                        '$leaderboardBanner2ImageUrl', '$leaderboardBannerImageUrl', '$leaderboardMainImageUrl', '$leaderboardThumbnailImageUrl',
                                    ]],
                                    ['block', [
                                        '$largeLeaderboardBanner2ImageUrl', '$largeLeaderboardBannerImageUrl', '$largeLeaderboardMainImageUrl', '$largeLeaderboardThumbnailImageUrl',
                                    ]],
                                ]],
                            ]],
                            ['edit_ad_form_images_url_skyscrapers_title', [
                                ['row', [
                                    ['block', [
                                        '$skyscraperBanner2ImageUrl', '$skyscraperBannerImageUrl', '$skyscraperMainImageUrl', '$skyscraperThumbnailImageUrl',
                                    ]],
                                    ['block', [
                                        '$wideSkyscraperBanner2ImageUrl', '$wideSkyscraperBannerImageUrl', '$wideSkyscraperMainImageUrl', '$wideSkyscraperThumbnailImageUrl',
                                    ]],
                                ]],
                            ]],
                        ]],
                    ]],
                    ['edit_ad_form_scripts_title', [
                        ['row', [
                            ['block', [
                                '$script',
                            ]],
                            ['block', [
                                '$scriptUrl',
                            ]],
                        ]],
                    ]],
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'title', 'subtitle', 'overline', 'presentation', 'longDescription', 'description', 'tenant', 'advertiser', 'affiliater', 'externalTrackerId',
            'commission', 'url', 'startAt', 'endAt',  'script', 'scriptUrl', 'banner2Image', 'bannerImage', 'largeLeaderboardBanner2Image', 'largeLeaderboardBannerImage',
            'largeLeaderboardMainImage', 'largeLeaderboardThumbnailImage', 'largeRectangleBanner2Image', 'largeRectangleBannerImage', 'largeRectangleMainImage',
            'largeRectangleThumbnailImage', 'leaderboardBanner2Image', 'leaderboardBannerImage', 'leaderboardMainImage', 'leaderboardThumbnailImage', 'logoAltImage',
            'logoImage', 'mainImage', 'mediumRectangleBanner2Image', 'mediumRectangleBannerImage', 'mediumRectangleMainImage', 'mediumRectangleThumbnailImage',
            'mobileLeaderboardBanner2Image', 'mobileLeaderboardBannerImage', 'mobileLeaderboardMainImage', 'mobileLeaderboardThumbnailImage', 'skyscraperBanner2Image',
            'skyscraperBannerImage', 'skyscraperMainImage', 'skyscraperThumbnailImage', 'smallSquareBanner2Image', 'smallSquareBannerImage', 'smallSquareMainImage',
            'smallSquareThumbnailImage', 'squareBanner2Image', 'squareBannerImage', 'squareMainImage', 'squareThumbnailImage', 'thumbnailImage', 'wideSkyscraperBanner2Image',
            'wideSkyscraperBannerImage', 'wideSkyscraperMainImage', 'wideSkyscraperThumbnailImage', 'banner2ImageUrl', 'bannerImageUrl', 'largeLeaderboardBanner2ImageUrl',
            'largeLeaderboardBannerImageUrl', 'largeLeaderboardMainImageUrl', 'largeLeaderboardThumbnailImageUrl', 'largeRectangleBanner2ImageUrl', 'largeRectangleBannerImageUrl',
            'largeRectangleMainImageUrl', 'largeRectangleThumbnailImageUrl', 'leaderboardBanner2ImageUrl', 'leaderboardBannerImageUrl', 'leaderboardMainImageUrl',
            'leaderboardThumbnailImageUrl', 'logoAltImageUrl', 'logoImageUrl', 'mainImageUrl', 'mediumRectangleBanner2ImageUrl', 'mediumRectangleBannerImageUrl',
            'mediumRectangleMainImageUrl', 'mediumRectangleThumbnailImageUrl', 'mobileLeaderboardBanner2ImageUrl', 'mobileLeaderboardBannerImageUrl', 'mobileLeaderboardMainImageUrl',
            'mobileLeaderboardThumbnailImageUrl', 'skyscraperBanner2ImageUrl', 'skyscraperBannerImageUrl', 'skyscraperMainImageUrl', 'skyscraperThumbnailImageUrl',
            'smallSquareBanner2ImageUrl', 'smallSquareBannerImageUrl', 'smallSquareMainImageUrl', 'smallSquareThumbnailImageUrl', 'squareBanner2ImageUrl', 'squareBannerImageUrl',
            'squareMainImageUrl', 'squareThumbnailImageUrl', 'thumbnailImageUrl', 'wideSkyscraperBanner2ImageUrl', 'wideSkyscraperBannerImageUrl', 'wideSkyscraperMainImageUrl',
            'wideSkyscraperThumbnailImageUrl', 'tags',
        ],
        content: [
            ['row', [
                ['tabs', [
                    ['edit_ad_form_general_title', [
                        ['row', [
                            ['block', [
                                '!$name!', '$url!', '$title', '$subtitle', '$overline', '$presentation', '$description', '$longDescription',
                            ]],
                            ['block', [
                                '$tenant:readonly!', '$advertiser', '$affiliater', '$adTags', '$externalTrackerId', '$commission', '$startAt', '$endAt',
                            ]],
                        ]],
                    ]],
                    ['edit_ad_form_images_title', [
                        ['tabs', [
                            ['edit_ad_form_images_general_title', [
                                ['row', [
                                    ['block', [
                                        '$logoImage', '$mainImage', '$bannerImage',
                                    ]],
                                    ['block', [
                                        '$logoAltImage', '$thumbnailImage', '$banner2Image',
                                    ]],
                                ]],
                            ]],
                            ['edit_ad_form_images_squares_title', [
                                ['row', [
                                    ['block', [
                                        '$smallSquareBanner2Image', '$smallSquareBannerImage', '$smallSquareMainImage', '$smallSquareThumbnailImage',
                                    ]],
                                    ['block', [
                                        '$squareBanner2Image', '$squareBannerImage', '$squareMainImage', '$squareThumbnailImage',
                                    ]],
                                ]],
                            ]],
                            ['edit_ad_form_images_rectangles_title', [
                                ['row', [
                                    ['block', [
                                        '$mediumRectangleBanner2Image', '$mediumRectangleBannerImage', '$mediumRectangleMainImage', '$mediumRectangleThumbnailImage',
                                    ]],
                                    ['block', [
                                        '$largeRectangleBanner2Image', '$largeRectangleBannerImage', '$largeRectangleMainImage', '$largeRectangleThumbnailImage',
                                    ]],
                                ]],
                            ]],
                            ['edit_ad_form_images_leaderboards_title', [
                                ['row', [
                                    ['block', [
                                        '$mobileLeaderboardBanner2Image', '$mobileLeaderboardBannerImage', '$mobileLeaderboardMainImage', '$mobileLeaderboardThumbnailImage',
                                    ]],
                                    ['block', [
                                        '$leaderboardBanner2Image', '$leaderboardBannerImage', '$leaderboardMainImage', '$leaderboardThumbnailImage',
                                    ]],
                                    ['block', [
                                        '$largeLeaderboardBanner2Image', '$largeLeaderboardBannerImage', '$largeLeaderboardMainImage', '$largeLeaderboardThumbnailImage',
                                    ]],
                                ]],
                            ]],
                            ['edit_ad_form_images_skyscrapers_title', [
                                ['row', [
                                    ['block', [
                                        '$skyscraperBanner2Image', '$skyscraperBannerImage', '$skyscraperMainImage', '$skyscraperThumbnailImage',
                                    ]],
                                    ['block', [
                                        '$wideSkyscraperBanner2Image', '$wideSkyscraperBannerImage', '$wideSkyscraperMainImage', '$wideSkyscraperThumbnailImage',
                                    ]],
                                ]],
                            ]],
                        ]],
                    ]],
                    ['edit_ad_form_images_url_title', [
                        ['tabs', [
                            ['edit_ad_form_images_url_general_title', [
                                ['row', [
                                    ['block', [
                                        '$logoImageUrl', '$mainImageUrl', '$bannerImageUrl',
                                    ]],
                                    ['block', [
                                        '$logoAltImageUrl', '$thumbnailImageUrl', '$banner2ImageUrl',
                                    ]],
                                ]],
                            ]],
                            ['edit_ad_form_images_url_squares_title', [
                                ['row', [
                                    ['block', [
                                        '$smallSquareBanner2ImageUrl', '$smallSquareBannerImageUrl', '$smallSquareMainImageUrl', '$smallSquareThumbnailImageUrl',
                                    ]],
                                    ['block', [
                                        '$squareBanner2ImageUrl', '$squareBannerImageUrl', '$squareMainImageUrl', '$squareThumbnailImageUrl',
                                    ]],
                                ]],
                            ]],
                            ['edit_ad_form_images_url_rectangles_title', [
                                ['row', [
                                    ['block', [
                                        '$mediumRectangleBanner2ImageUrl', '$mediumRectangleBannerImageUrl', '$mediumRectangleMainImageUrl', '$mediumRectangleThumbnailImageUrl',
                                    ]],
                                    ['block', [
                                        '$largeRectangleBanner2ImageUrl', '$largeRectangleBannerImageUrl', '$largeRectangleMainImageUrl', '$largeRectangleThumbnailImageUrl',
                                    ]],
                                ]],
                            ]],
                            ['edit_ad_form_images_url_leaderboards_title', [
                                ['row', [
                                    ['block', [
                                        '$mobileLeaderboardBanner2ImageUrl', '$mobileLeaderboardBannerImageUrl', '$mobileLeaderboardMainImageUrl', '$mobileLeaderboardThumbnailImageUrl',
                                    ]],
                                    ['block', [
                                        '$leaderboardBanner2ImageUrl', '$leaderboardBannerImageUrl', '$leaderboardMainImageUrl', '$leaderboardThumbnailImageUrl',
                                    ]],
                                    ['block', [
                                        '$largeLeaderboardBanner2ImageUrl', '$largeLeaderboardBannerImageUrl', '$largeLeaderboardMainImageUrl', '$largeLeaderboardThumbnailImageUrl',
                                    ]],
                                ]],
                            ]],
                            ['edit_ad_form_images_url_skyscrapers_title', [
                                ['row', [
                                    ['block', [
                                        '$skyscraperBanner2ImageUrl', '$skyscraperBannerImageUrl', '$skyscraperMainImageUrl', '$skyscraperThumbnailImageUrl',
                                    ]],
                                    ['block', [
                                        '$wideSkyscraperBanner2ImageUrl', '$wideSkyscraperBannerImageUrl', '$wideSkyscraperMainImageUrl', '$wideSkyscraperThumbnailImageUrl',
                                    ]],
                                ]],
                            ]],
                        ]],
                    ]],
                    ['edit_ad_form_scripts_title', [
                        ['row', [
                            ['block', [
                                '$script',
                            ]],
                            ['block', [
                                '$scriptUrl',
                            ]],
                        ]],
                    ]],
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        body: true,
        properties: [
            'id', 'name', 'code', 'tags', 'advertiser', 'advertiserCode', 'advertiserName', 'affiliater', 'affiliaterCode', 'affiliaterName', 'tenant', 'tenantCode', 'tenantName',
            'commission', 'createdAt', 'description', 'endAt', 'externalTrackerId', 'longDescription', 'overline', 'presentation', 'scriptUrl', 'startAt', 'subtitle', 'title', 'url',
        ],
    },
    list: {
        columns: [
            {id: 'bannerImage', label: 'column_bannerimage_label', component: 'adImage'} as any,
            {id: 'createdAt', label: 'column_createdat_label', component: 'date'},
            {id: 'name', label: 'column_name_label', flex: 1} as any,
            {id: 'code', label: 'column_code_label', flex: 1} as any,
            {id: 'affiliater', label: 'column_affiliater_label', flex: 1, component: 'affiliater'} as any,
            {id: 'advertiser', label: 'column_advertiser_label', flex: 1, component: 'advertiser'} as any,
            {id: 'status', label: 'column_status_label', flex: 1} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
        filters: {
            published: {},
            all: {},
        }
    },
};

export default t;