export const GET_PRESULT = (gql: any) => gql`
    query($id: ID!) {
        getPresult(id: $id) {
            id
            token
            createdAt
            updatedAt
            tenant
            tenantCode
            tenantName
            partner
            partnerCode
            partnerName
            organization
            organizationCode
            organizationName
            game
            gameCode
            gameName
            draw
            firstName
            lastName
            email
            phone
            publicPageUrl
        }
    }
`;
export const FIND_PRESULTS = (gql: any) => gql`
    query findPresults($offset: String, $limit: Int) {
        findPresults(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                token
                createdAt
                updatedAt
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                draw
                firstName
                lastName
                email
                phone
            }
        }
    }
`;