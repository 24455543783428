const t =  {
    menus: {
        main: {section: 'customers', priority: 32, icon: 'fa-users'},
    },
    new: {
        attributes: [
            'provider', 'tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!',
                ]],
                ['block', [
                    '$provider!',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'provider', 'tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!',
                ]],
                ['block', [
                    '$provider!',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'createdAt', 'id', 'provider', 'status', 'tags', 'tenant', 'tenantCode', 'tenantName', 'token', 'updatedAt',
        ],
    },
    list: {
        columns: [
            {id: 'createdAt', label: 'column_createdat_label', component: 'date', flex: 1, width: 100} as any,
            {id: 'id', label: 'column_id_label', flex: 1, width: 100} as any,
            {id: 'provider', label: 'column_provider_label', flex: 1, width: 100} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;