export const CREATE_PAYMENTMETHOD = (gql: any) => gql`
    mutation($data: CreatePaymentmethodInput!) {
        createPaymentmethod(data: $data) {
            id
        }
    }
`;
export const UPDATE_PAYMENTMETHOD = (gql: any) => gql`
    mutation($id: ID!, $data: UpdatePaymentmethodInput!) {
        updatePaymentmethod(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_PAYMENTMETHOD = (gql: any) => gql`
    mutation($id: ID!) {
        deletePaymentmethod(id: $id) {
            id
        }
    }
`;
export const GET_PAYMENTMETHOD = (gql: any) => gql`
    query($id: ID!) {
        getPaymentmethod(id: $id) {
            id
            paymentProvider
            paymentProviderCustomer
            paymentProviderPaymentMethod
            status
            tenant
            tenantCode
            tenantName
        }
    }
`;
export const FIND_PAYMENTMETHODS = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findPaymentmethods(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                paymentProvider
                paymentProviderCustomer
                paymentProviderPaymentMethod
                status
                tenant
                tenantCode
                tenantName
            }
        }
    }
`;
export const SEARCH_PAYMENTMETHODS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPaymentmethods(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                paymentProvider
                paymentProviderCustomer
                paymentProviderPaymentMethod
                status
                tenant
                tenantCode
                tenantName
            }
        }
    }
`;