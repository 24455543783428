export const CREATE_GACHIEVEMENT = (gql: any) => gql`
    mutation($data: CreateGachievementInput!) {
        createGachievement(data: $data) {
            id
        }
    }
`;
export const GET_GACHIEVEMENT = (gql: any) => gql`
    query($id: ID!) {
        getGachievement(id: $id) {
            id
            game
            gameName
            gameCode
            value
            type
        }
    }
`;
export const FIND_GACHIEVEMENTS = (gql: any) => gql`
    query findGachievements($offset: String, $limit: Int) {
        findGachievements(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                game
                gameName
                gameCode
                value
                type
            }
        }
    }
`;