const t = {
    icon: 'fa-image',
    menus: {
        main: {section: 'medias', priority: 30},
    },
    new: {
        attributes: [
            'partner', 'tags', 'mainImage', 'desktopImage', 'tabletImage', 'mobileImage', 'tvImage', 'emailImage',
            'title', 'description',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$title!', '$description', '$partner', '$imageTags',
                ]],
                ['block', [
                    '$mainImage', '$desktopImage', '$tabletImage', '$mobileImage', '$tvImage', '$emailImage',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'partner', 'tags', 'mainImage', 'desktopImage', 'tabletImage', 'mobileImage', 'tvImage', 'emailImage',
            'title', 'description',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$title!', '$description', '$partner', '$imageTags',
                ]],
                ['block', [
                    '$mainImage', '$desktopImage', '$tabletImage', '$mobileImage', '$tvImage', '$emailImage',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'partnerName', 'status'
        ],
    },
    list: {
        columns: [
            {id: 'status', label: 'column_status_label'},
            {id: 'partner', label: 'column_partner_label', flex: 1, width: 120, component: 'partner'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;