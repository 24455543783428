export const CREATE_SMSSENDER = (gql: any) => gql`
    mutation($data: CreateSmssenderInput!) {
        createSmssender(data: $data) {
            id
            phone
        }
    }
`;
export const GET_SMSSENDER = (gql: any) => gql`
    query($id: ID!) {
        getSmssender(id: $id) {
            id
            phone
            name
            provider
            tenant
            tenantCode
            features
        }
    }
`;
export const FIND_SMSSENDERS = (gql: any) => gql`
    query findSmssenders($offset: String, $limit: Int) {
        findSmssenders(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                phone
                name
                provider
                tenant
                tenantCode
                features
            }
        }
    }
`;