export const CREATE_GIMAGE = (gql: any) => gql`
    mutation($data: CreateGimageInput!) {
        createGimage(data: $data) {
            id
        }
    }
`;
export const GET_GIMAGE = (gql: any) => gql`
    query($id: ID!) {
        getGimage(id: $id) {
            id
            status
            tags
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            title
            description
            mainImage {
                available
                url
            }
            desktopImage {
                available
                url
            }
            tabletImage {
                available
                url
            }
            mobileImage {
                available
                url
            }
            tvImage {
                available
                url
            }
            emailImage {
                available
                url
            }
        }
    }
`;
export const FIND_GIMAGES = (gql: any) => gql`
    query findGimages($offset: String, $limit: Int) {
        findGimages(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                title
                token
                mainImage {
                    available
                    url
                }
                desktopImage {
                    available
                    url
                }
                tabletImage {
                    available
                    url
                }
                mobileImage {
                    available
                    url
                }
                tvImage {
                    available
                    url
                }
                emailImage {
                    available
                    url
                }
            }
        }
    }
`;