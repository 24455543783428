export const CREATE_ACHIEVABLESET = (gql: any) => gql`
    mutation($data: CreateAchievablesetInput!) {
        createAchievableset(data: $data) {
            id
        }
    }
`;
export const GET_ACHIEVABLESET = (gql: any) => gql`
    query($id: ID!) {
        getAchievableset(id: $id) {
            id
            code
            name
            tenant
            tenantCode
        }
    }
`;
export const FIND_ACHIEVABLESETS = (gql: any) => gql`
    query findAchievablesets($offset: String, $limit: Int) {
        findAchievablesets(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                tenant
                tenantCode
            }
        }
    }
`;
export const SEARCH_ACHIEVABLESETS = (gql: any) => gql`
    query searchAchievablesets($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAchievablesets(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                tenant
                tenantCode
            }
        }
    }
`;
export const UPDATE_ACHIEVABLESET = (gql: any) => gql`
    mutation updateAchievableset($id: ID!, $data: UpdateAchievablesetInput!) {
        updateAchievableset(id: $id, data: $data) {
            id
        }
    }
`;

export const DELETE_ACHIEVABLESET = (gql: any) => gql`
    mutation deleteAchievableset($id: ID!) {
        deleteAchievableset(id: $id) {
            id
        }
    }
`;
