const t = {
    icon: 'fa-palette',
    menus: {
        main: {section: 'settings2', priority: 70},
    },
    new: {
        attributes: [
            'tenant', 'data', 'mainImage',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$tenant!', '$data!', '$mainImage!',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'tenant', 'data', 'mainImage',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$tenant!', '$data!', '$mainImage!',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'data', 'mainImage', 'tenant', 'tenantCode', 'tenantName',
        ],
    },
    list: {
        columns: [
            {id: 'mainImage', label: 'column_main_image_label', render: 'image', flex: 0, width: 200},
            {id: 'data', label: 'column_data_label', flex: 0, width: 200},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;