export const CREATE_DATAQUERY = (gql: any) => gql`
    mutation($data: CreateDataqueryInput!) {
        createDataquery(data: $data) {
            id
        }
    }
`;
export const DELETE_DATAQUERY = (gql: any) => gql`
    mutation($id: ID!) {
        deleteDataquery(id: $id) {
            id
        }
    }
`;
export const FIND_DATAQUERIES = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findDataqueries(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                code
                createdAt
                definition
                description
                id
                inlinePreset
                name
                parameters
                presets
                sql
                status
                tenant
                tenantCode
                tenantName
                token
                updatedAt
            }
        }
    }
`;
export const GET_DATAQUERY = (gql: any) => gql`
    query($id: ID!) {
        getDataquery(id: $id) {
            code
            createdAt
            definition
            description
            id
            inlinePreset
            name
            parameters
            presets
            sql
            status
            tenant
            tenantCode
            tenantName
            token
            updatedAt
        }
    }
`;
export const SEARCH_DATAQUERIES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDataqueries(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                code
                createdAt
                definition
                description
                id
                inlinePreset
                name
                parameters
                presets
                sql
                status
                tenant
                tenantCode
                tenantName
                token
                updatedAt
            }
        }
    }
`;
export const UPDATE_DATAQUERY = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateDataqueryInput!) {
        updateDataquery(id: $id, data: $data) {
            id
        }
    }
`;
