export const CREATE_OFFER = (gql: any) => gql`
    mutation($data: CreateOfferInput!) {
        createOffer(data: $data) {
            id
            code
        }
    }
`;
export const GET_OFFER = (gql: any) => gql`
    query($id: ID!) {
        getOffer(id: $id) {
            id
            code
            name
            createdAt
            updatedAt
            status
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            type
            features
            params {
                name
                type
                value
                intValue
                floatValue
                booleanValue
                jsonValue
            }
        }
    }
`;
export const FIND_OFFERS = (gql: any) => gql`
    query findOffers($offset: String, $limit: Int) {
        findOffers(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                createdAt
                updatedAt
                status
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                type
                features
                params {
                    name
                    type
                    value
                    intValue
                    floatValue
                    booleanValue
                    jsonValue
                }
            }
        }
    }
`;