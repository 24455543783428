export const CREATE_PIMAGE = (gql: any) => gql`
    mutation($data: CreatePimageInput!) {
        createPimage(data: $data) {
            id
        }
    }
`;
export const GET_PIMAGE = (gql: any) => gql`
    query($id: ID!) {
        getPimage(id: $id) {
            id
            status
            tags
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            mainImage {
                available
                url
            }
            desktopImage {
                available
                url
            }
            tabletImage {
                available
                url
            }
            mobileImage {
                available
                url
            }
            tvImage {
                available
                url
            }
            emailImage {
                available
                url
            }
        }
    }
`;
export const FIND_PIMAGES = (gql: any) => gql`
    query findPimages($offset: String, $limit: Int) {
        findPimages(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                mainImage {
                    available
                    url
                }
                desktopImage {
                    available
                    url
                }
                tabletImage {
                    available
                    url
                }
                mobileImage {
                    available
                    url
                }
                tvImage {
                    available
                    url
                }
                emailImage {
                    available
                    url
                }
            }
        }
    }
`;