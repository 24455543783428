export const CREATE_HOST = (gql: any) => gql`
    mutation($data: CreateHostInput!) {
        createHost(data: $data) {
            id
            code
        }
    }
`;
export const GET_HOST = (gql: any) => gql`
    query($id: ID!) {
        getHost(id: $id) {
            id
            code
            dns
            url
            name
            tenant
            tenantCode
            game
            gameCode
            organization
            organizationCode
            campaign
            campaignCode
            partner
            partnerCode
            mode
            main
            key
            options
            pool
            cname
            redirectTarget
        }
    }
`;
export const FIND_HOSTS = (gql: any) => gql`
    query findHosts($offset: String, $limit: Int) {
        findHosts(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                dns
                url
                name
                game
                gameCode
                organization
                organizationCode
                campaign
                campaignCode
                partner
                partnerCode
                tenant
                tenantCode
                mode
                main
                key
                options
                pool
                cname
                redirectTarget
            }
        }
    }
`;