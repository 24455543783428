export const CREATE_BOOK = (gql: any) => gql`
    mutation($data: CreateBookInput!) {
        createBook(data: $data) {
            id
        }
    }
`;
export const GET_BOOK = (gql: any) => gql`
    query($id: ID!) {
        getBook(id: $id) {
            id
            attributionStatus
            code
            currency
            customCode
            email
            firstName
            game
            gameName
            gameCode
            organization
            organizationName
            organizationCode
            lastName
            paymentStatus
            phone
            presets
            salesInfo
            seller
            sellerLastName
            sellerFirstName
            sellerCode
            sellergroup
            type
            limitedPrivatePageUrl
            publicToken
            publicPageUrl
        }
    }
`;
export const GET_BOOK_BY_CODE = (gql: any) => gql`
    query($code: String!) {
        getBookByCode(code: $code) {
            id
            code
        }
    }
`;
export const SEARCH_BOOKS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchBooks(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                attributionStatus
                code
                currency
                customCode
                email
                firstName
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                lastName
                paymentStatus
                phone
                presets
                salesInfo
                seller
                sellergroup
                sellergroupCode
                sellergroupName
                sellerFirstName
                sellerLastName
                sellerCode
                type
                limitedPrivatePageUrl
                publicToken
                publicPageUrl
            }
        }
    }
`;
export const SEARCH_PAID_BOOKS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPaidBooks: searchBooks(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "paymentStatus", type: "string", operator: "wildcard", value: "PAID"}
            ]
        }) {
            cursor
            count
            items {
                id
                attributionStatus
                code
                currency
                customCode
                email
                firstName
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                lastName
                paymentStatus
                phone
                presets
                salesInfo
                seller
                sellergroup
                sellergroupCode
                sellergroupName
                sellerFirstName
                sellerLastName
                sellerCode
                type
                limitedPrivatePageUrl
                publicToken
                publicPageUrl
            }
        }
    }
`;
export const SEARCH_ATTRIBUTED_BOOKS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "attributedAt:desc") {
        searchAttributedBooks: searchBooks(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "attributionStatus", type: "string", operator: "wildcard", value: "ATTRIBUTED"}
            ]
        }) {
            cursor
            count
            items {
                id
                attributionStatus
                code
                currency
                customCode
                email
                firstName
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                lastName
                paymentStatus
                phone
                presets
                salesInfo
                seller
                sellergroup
                sellergroupCode
                sellergroupName
                sellerFirstName
                sellerLastName
                sellerCode
                type
                limitedPrivatePageUrl
                publicToken
                publicPageUrl
            }
        }
    }
`;
export const SEARCH_PARTIALLYPAID_BOOKS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPartiallyPaidBooks: searchBooks(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "paymentStatus", type: "string", operator: "wildcard", value: "PARTIALLY_PAID"}
            ]
        }) {
            cursor
            count
            items {
                id
                attributionStatus
                code
                currency
                customCode
                email
                firstName
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                lastName
                paymentStatus
                phone
                presets
                salesInfo
                seller
                sellergroup
                sellergroupCode
                sellergroupName
                sellerFirstName
                sellerLastName
                sellerCode
                type
                limitedPrivatePageUrl
                publicToken
                publicPageUrl
            }
        }
    }
`;
export const SEARCH_VERIFIED_BOOKS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchVerifiedBooks: searchBooks(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "verificationStatus", type: "string", operator: "wildcard", value: "VERIFIED"}
            ]
        }) {
            cursor
            count
            items {
                id
                attributionStatus
                code
                currency
                customCode
                email
                firstName
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                lastName
                paymentStatus
                phone
                presets
                salesInfo
                seller
                sellergroup
                sellergroupCode
                sellergroupName
                sellerFirstName
                sellerLastName
                sellerCode
                type
                limitedPrivatePageUrl
                publicToken
                publicPageUrl
            }
        }
    }
`;
export const SEARCH_ALL_BOOKS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAllBooks: searchBooks(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                attributionStatus
                code
                currency
                customCode
                email
                firstName
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                lastName
                paymentStatus
                phone
                presets
                salesInfo
                seller
                sellergroup
                sellergroupCode
                sellergroupName
                sellerFirstName
                sellerLastName
                sellerCode
                type
                limitedPrivatePageUrl
                publicToken
                publicPageUrl
            }
        }
    }
`;
export const FIND_BOOKS = (gql: any) => gql`
    query findBooks($offset: String, $limit: Int) {
        findBooks(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                attributionStatus
                code
                currency
                customCode
                email
                firstName
                lastName
                paymentStatus
                phone
                presets
                salesInfo
                seller
                sellerFirstName
                sellerLastName
                sellerCode
                sellergroup
                sellergroupName
                sellergroupCode
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                type
                limitedPrivatePageUrl
                publicToken
                publicPageUrl
            }
        }
    }
`;