export const CREATE_GATTRIBUTABLE = (gql: any) => gql`
    mutation($data: CreateGattributableInput!) {
        createGattributable(data: $data) {
            id
        }
    }
`;
export const GET_GATTRIBUTABLE = (gql: any) => gql`
    query($id: ID!) {
        getGattributable(id: $id) {
            id
            type
            mode
            subtype
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            createdAt
            updatedAt
            name
            color
            overline
            description
            code
            mainVideoUrl
            mainAudioUrl
            nickName
            firstName
            lastName
            email
            phone
            websiteUrl
            birthDate
            nationality
            mainImage {
                available
                url
            }
            logoImage {
                available
                url
            }
        }
    }
`;
export const FIND_GATTRIBUTABLES = (gql: any) => gql`
    query findGattributables($offset: String, $limit: Int) {
        findGattributables(offset: $offset, limit: $limit) {
            cursor
            count
            items {
            	id
                type
                mode
                subtype
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                name
                color
                overline
                description
                code
                mainVideoUrl
                mainAudioUrl
                nickName
                firstName
                lastName
                email
                phone
                websiteUrl
                birthDate
                nationality
                mainImage {
                    available
                    url
                }
               logoImage {
                    available
                    url
                }
            }
        }
    }
`;