export const CREATE_GREPORT = (gql: any) => gql`
    mutation($data: CreateGreportInput!) {
        createGreport(data: $data) {
            id
        }
    }
`;
export const GET_GREPORT = (gql: any) => gql`
    query($id: ID!) {
        getGreport(id: $id) {
            id
            createdAt
            updatedAt
            startDate
            type
            typeParentPeriod
            period
            year
            semester
            month
            week
            monthWeek
            day
            hour
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
        }
    }
`;
export const FIND_GREPORTS = (gql: any) => gql`
    query findGreports($offset: String, $limit: Int) {
        findGreports(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                paidSales
                paidTickets
                paymentAmount
                promisedSales
                paymentAmountForPaidBookpayments
            }
        }
    }
`;

export const SEARCH_GREPORTS = (gql: any) => gql`
    query searchGreports($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchGreports(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                paidSales
                paidTickets
                paymentAmount
                promisedSales
                paymentAmountForPaidBookpayments
            }
        }
    }
`;
export const SEARCH_ALL_GREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchAllGreports: searchGreports(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                paidSales
                paidTickets
                paymentAmount
                promisedSales
                paymentAmountForPaidBookpayments
            }
        }
    }
`;
export const SEARCH_YEAR_GREPORTS = (gql: any) => gql`
    query ($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchYearGreports: searchGreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "YEAR", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                paidSales
                paidTickets
                paymentAmount
                promisedSales
                paymentAmountForPaidBookpayments
            }
        }
    }
`;
export const SEARCH_MONTH_GREPORTS = (gql: any) => gql`
    query ($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchMonthGreports: searchGreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "MONTH", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                paidSales
                paidTickets
                paymentAmount
                promisedSales
                paymentAmountForPaidBookpayments
            }
        }
    }
`;
export const SEARCH_WEEK_GREPORTS = (gql: any) => gql`
    query ($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchWeekGreports: searchGreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "WEEK", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                paidSales
                paidTickets
                paymentAmount
                promisedSales
                paymentAmountForPaidBookpayments
            }
        }
    }
`;
export const SEARCH_DAY_GREPORTS = (gql: any) => gql`
    query ($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchDayGreports: searchGreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "DAY", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                paidSales
                paidTickets
                paymentAmount
                promisedSales
                paymentAmountForPaidBookpayments
            }
        }
    }
`;
export const SEARCH_HOUR_GREPORTS = (gql: any) => gql`
    query ($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchHourGreports: searchGreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "HOUR", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                paidSales
                paidTickets
                paymentAmount
                promisedSales
                paymentAmountForPaidBookpayments
            }
        }
    }
`;
