const t =  {
    menus: {
        main: {section: 'settings', priority: 40, icon: 'fa-copyright'},
    },
    new: {
        attributes: [
            'name', 'code', 'tenant', 'presentation', 'description',
            'logoImage', 'logoAltImage', 'bannerImage', 'mobileBannerImage', 'tabletBannerImage', 'banner2Image',
            'mainImage', 'faviconImage', 'appleTouchImage',
            'websiteUrl', 'twitterUrl', 'facebookUrl', 'youtubeUrl', 'instagramUrl',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$code!', '$name!', '$presentation', '$description',
                    '$websiteUrl', '$twitterUrl', '$facebookUrl', '$youtubeUrl', '$instagramUrl',
                ]],
                ['block', [
                    '$logoImage', '$logoAltImage', '$bannerImage', '$mobileBannerImage', '$tabletBannerImage', '$banner2Image',
                    '$mainImage', '$faviconImage', '$appleTouchImage',
                ]]
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'presentation', 'description',
            'logoImage', 'logoAltImage', 'bannerImage', 'mobileBannerImage', 'tabletBannerImage', 'banner2Image',
            'mainImage', 'faviconImage', 'appleTouchImage',
            'websiteUrl', 'twitterUrl', 'facebookUrl', 'youtubeUrl', 'instagramUrl',
        ],
        content: [
            ['row', [
                ['block', [
                    '$code:readonly', '$name!', '$presentation', '$description',
                    '$websiteUrl', '$twitterUrl', '$facebookUrl', '$youtubeUrl', '$instagramUrl',
                ]],
                ['block', [
                    '$logoImage', '$logoAltImage', '$bannerImage', '$mobileBannerImage', '$tabletBannerImage', '$banner2Image',
                    '$mainImage', '$faviconImage', '$appleTouchImage',
                ]]
            ]],
            '$submit',
        ],
    },
    display: {
        properties: ['name', 'code', 'presentation', 'description',
            'logoImage', 'logoAltImage', 'bannerImage', 'mobileBannerImage', 'tabletBannerImage', 'banner2Image',
            'mainImage', 'faviconImage', 'appleTouchImage',
            'websiteUrl', 'twitterUrl', 'facebookUrl', 'youtubeUrl', 'instagramUrl'
        ],
    },
    list: {
        columns: [
            {id: 'logoImage', label: 'column_logoimage_label', flex: 0, width: 110, render: 'image'} as any,
            {id: 'code', label: 'column_code_label', flex: 0, width: 100} as any,
            {id: 'name', label: 'column_name_label', flex: 0, width: 120} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;