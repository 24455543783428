export const CREATE_OVERSION = (gql: any) => gql`
    mutation($data: CreateOversionInput!) {
        createOversion(data: $data) {
            id
        }
    }
`;
export const GET_OVERSION = (gql: any) => gql`
    query($id: ID!) {
        getOversion(id: $id) {
            id
            name
            type
            code
            organization
            organizationName
            organizationCode
            fullName
            shortName
            legalName
            nin
            createdAt
            updatedAt
            status
            campaign
            campaignCode
            campaignName
            partner
            partnerCode
            partnerName
            code
            locale
            country
            presentation
            description
            externalReference
            publicPageShortUrl
            city
            street
            zipCode
            publicEmail
            publicPhone
            overline
            complement
            state
            owner
            ownerEmail
            ownerFirstName
            ownerLastName
            tenant
            faviconImage {
                available
                url
            }
            appleTouchImage {
                available
                url
            }
            logoImage {
                available
                url
            }
            logoAltImage {
                available
                url
            }
            bannerImage {
                available
                url
            }
            mobileBannerImage {
                available
                url
            }
            tabletBannerImage {
                available
                url
            }
            mainImage {
                available
                url
            }
            banner2Image {
                available
                url
            }
            backgroundImage {
                available
                url
            }
            facebookShareImage {
                available
                url
            }
            twitterShareImage {
                available
                url
            }
            flyerImage {
                available
                url
            }
            websiteUrl
            twitterUrl
            facebookUrl
            instagramUrl
            linkedinUrl
            youtubeUrl
            pinterestUrl
            tumblrUrl
            flickrUrl
            redditUrl
            tiktokUrl
            vimeoUrl
            snapchatUrl
            backgroundVideoUrl
            bannerVideoUrl
            banner2VideoUrl
            flyerVideoUrl
            mainVideoUrl
            mainShortVideoUrl
            mainLongVideoUrl
            domain
            mainTheme
            varColorNavPrimary
            varColorNavSecondary
            varColorPrimary
            varColorSecondary
            varColorTextPrimary
            varColorTextSecondary
            varColorBorderPrimary
            varColorBorderSecondary
            varColorPanelPrimary
            varColorPanelSecondary
            varColorLogo
            varColorTitle
            varColorChoicePrimary
            varColorChoiceSecondary
            varColorFooterPrimary
            varColorFooterSecondary
            varColorHeading
        }
    }
`;
export const FIND_OVERSIONS = (gql: any) => gql`
    query findOversions($offset: String, $limit: Int) {
        findOversions(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                type
                code
                partner
                tenant
                tenantCode
                createdAt
                updatedAt
                status
                publicPageShortUrl
                domain
                ownerEmail
                logoImage {
                    url
                    available
                }
            }
        }
    }
`;