export const CREATE_PLAN = (gql: any) => gql`
    mutation($data: CreatePlanInput!) {
        createPlan(data: $data) {
            id
        }
    }
`;
export const DELETE_PLAN = (gql: any) => gql`
    mutation($id: ID!) {
        deletePlan(id: $id) {
            id
        }
    }
`;
export const FIND_PLANS = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findPlans(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                maxPaymentAmount
                maxSales
                maxTickets
                tenant
                tenantCode
                tenantName
            }
        }
    }
`;
export const GET_PLAN = (gql: any) => gql`
    query($id: ID!) {
        getPlan(id: $id) {
            id
            name
            code
            maxPaymentAmount
            maxSales
            maxTickets
            tenant
            tenantCode
            tenantName
        }
    }
`;
export const SEARCH_PLANS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPlans(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                name
                code
                maxPaymentAmount
                maxSales
                maxTickets
                tenant
                tenantCode
                tenantName
            }
        }
    }
`;
export const UPDATE_PLAN = (gql: any) => gql`
    mutation($id: ID!, $data: UpdatePlanInput!) {
        updatePlan(id: $id, data: $data) {
            id
        }
    }
`;
