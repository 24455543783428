export const CREATE_CREDIT = (gql: any) => gql`
    mutation($data: CreateCreditInput!) {
        createCredit(data: $data) {
            id
        }
    }
`;
export const GET_CREDIT = (gql: any) => gql`
    query($id: ID!) {
        getCredit(id: $id) {
            createdAt
            id
            privateToken
            publicToken
            status
            tags
            tenant
            tenantCode
            tenantName
            token
            type
            level
            updatedAt
            quantity
            product
            productCode
            productName
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            partner
            partnerCode
            partnerName
            campaign
            campaignCode
            campaignName
            user
            userCode
            sellergroup
            sellergroupCode
            sellergroupName
            seller
            sellerCode
        }
    }
`;
export const FIND_CREDITS = (gql: any) => gql`
    query findCredits($offset: String, $limit: Int) {
        findCredits(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                createdAt
                id
                privateToken
                publicToken
                status
                tags
                tenant
                tenantCode
                tenantName
                token
                type
                level
                updatedAt
                quantity
                product
                productCode
                productName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                campaign
                campaignCode
                campaignName
                user
                userCode
                sellergroup
                sellergroupCode
                sellergroupName
                seller
                sellerCode
            }
        }
    }
`;
export const SEARCH_CREDITS = (gql: any) => gql`
    query searchCredits($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchCredits(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                createdAt
                id
                privateToken
                publicToken
                status
                tags
                quantity
                tenant
                tenantCode
                tenantName
                token
                type
                level
                updatedAt
                product
                productCode
                productName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                campaign
                campaignCode
                campaignName
                user
                userCode
                sellergroup
                sellergroupCode
                sellergroupName
                seller
                sellerCode
            }
        }
    }
`;
export const DELETE_CREDIT = (gql: any) => gql`
    mutation deleteCredit($id: ID!) {
        deleteCredit(id: $id) {
            id
        }
    }
`;
export const UPDATE_CREDIT = (gql: any) => gql`
    mutation updateCredit($id: ID!, $data: UpdateCreditInput!) {
        updateCredit(id: $id, data: $data) {
            id
        }
    }
`;
