export const CREATE_GIMPORT = (gql: any) => gql`
    mutation($data: CreateGimportInput!) {
        createGimport(data: $data) {
            id
        }
    }
`;
export const GET_GIMPORT = (gql: any) => gql`
    query($id: ID!) {
        getGimport(id: $id) {
            id
            type
            status
            token
            createdAt
            updatedAt
            tenant
            tenantCode
            tenantName
            partner
            partnerCode
            partnerName
            organization
            organizationCode
            organizationName
            game
            gameCode
            gameName
            country
            locale
            params
            dataFile {
                available
                url
            }
            job
            jobStatus
        }
    }
`;
export const FIND_GIMPORTS = (gql: any) => gql`
    query findGimports($offset: String, $limit: Int) {
        findGimports(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                status
                type
                token
                createdAt
                updatedAt
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
            }
        }
    }
`;