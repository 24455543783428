export const CREATE_DASHBOARD = (gql: any) => gql`
    mutation($data: CreateDashboardInput!) {
        createDashboard(data: $data) {
            id
        }
    }
`;
export const GET_DASHBOARD = (gql: any) => gql`
    query($id: ID!) {
        getDashboard(id: $id) {
            id
            name
            level
            rank
            definition
            tenant
            tenantName
            tenantCode
            game
            gameName
            gameCode
            organization
            organizationName
            organizationCode
            seller
            sellerCode
            sellergroup
            sellergroupName
            sellergroupCode
            grouping
            groupingName
            groupingCode
            campaign
            campaignName
            campaignCode
            partner
            partnerName
            partnerCode
            shareMode
            privatePageUrl
        }
    }
`;
export const FIND_DASHBOARDS = (gql: any) => gql`
    query findDashboards($offset: String, $limit: Int) {
        findDashboards(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                level
                rank
                definition
                tenant
                tenantName
                tenantCode
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                seller
                sellerCode
                sellergroup
                sellergroupName
                sellergroupCode
                grouping
                groupingName
                groupingCode
                campaign
                campaignName
                campaignCode
                partner
                partnerName
                partnerCode
                shareMode
                privatePageUrl
            }
        }
    }
`;
export const FIND_DASHBOARDS_OF_LEVEL_TENANT_BY_TENANT = (gql: any) => gql`
    query findDashboardsOfLevelTenantByTenant($tenant: String!, $offset: String, $limit: Int) {
        findDashboardsOfLevelTenantByTenant(tenant: $tenant, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                level
                rank
                definition
                tenant
                tenantName
                tenantCode
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                seller
                sellerCode
                sellergroup
                sellergroupName
                sellergroupCode
                grouping
                groupingName
                groupingCode
                campaign
                campaignName
                campaignCode
                partner
                partnerName
                partnerCode
                shareMode
                privatePageUrl
            }
        }
    }
`;
export const FIND_DASHBOARDS_OF_LEVEL_CAMPAIGN_BY_CAMPAIGN = (gql: any) => gql`
    query findDashboardsOfLevelCampaignByCampaign($campaign: String!, $offset: String, $limit: Int) {
        findDashboardsOfLevelCampaignByCampaign(campaign: $campaign, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                level
                rank
                definition
                tenant
                tenantName
                tenantCode
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                seller
                sellerCode
                sellergroup
                sellergroupName
                sellergroupCode
                grouping
                groupingName
                groupingCode
                campaign
                campaignName
                campaignCode
                partner
                partnerName
                partnerCode
                shareMode
                privatePageUrl
            }
        }
    }
`;
export const FIND_DASHBOARDS_OF_LEVEL_PARTNER_BY_PARTNER = (gql: any) => gql`
    query findDashboardsOfLevelPartnerByPartner($partner: String!, $offset: String, $limit: Int) {
        findDashboardsOfLevelPartnerByPartner(partner: $partner, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                level
                rank
                definition
                tenant
                tenantName
                tenantCode
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                seller
                sellerCode
                sellergroup
                sellergroupName
                sellergroupCode
                grouping
                groupingName
                groupingCode
                campaign
                campaignName
                campaignCode
                partner
                partnerName
                partnerCode
                shareMode
                privatePageUrl
            }
        }
    }
`;
export const FIND_DASHBOARDS_OF_LEVEL_ORGANIZATION_BY_ORGANIZATION = (gql: any) => gql`
    query findDashboardsOfLevelOrganizationByOrganization($organization: String!, $offset: String, $limit: Int) {
        findDashboardsOfLevelOrganizationByOrganization(organization: $tenant, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                level
                rank
                definition
                tenant
                tenantName
                tenantCode
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                seller
                sellerCode
                sellergroup
                sellergroupName
                sellergroupCode
                grouping
                groupingName
                groupingCode
                campaign
                campaignName
                campaignCode
                partner
                partnerName
                partnerCode
                shareMode
                privatePageUrl
            }
        }
    }
`;
export const FIND_DASHBOARDS_OF_LEVEL_GAME_BY_GAME = (gql: any) => gql`
    query findDashboardsOfLevelGameByGame($game: String!, $offset: String, $limit: Int) {
        findDashboardsOfLevelGameByGame(game: $game, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                level
                rank
                definition
                tenant
                tenantName
                tenantCode
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                seller
                sellerCode
                sellergroup
                sellergroupName
                sellergroupCode
                grouping
                groupingName
                groupingCode
                campaign
                campaignName
                campaignCode
                partner
                partnerName
                partnerCode
                shareMode
                privatePageUrl
            }
        }
    }
`;
export const FIND_DASHBOARDS_OF_LEVEL_GROUPING_BY_GROUPING = (gql: any) => gql`
    query findDashboardsOfLevelGroupingByGrouping($grouping: String!, $offset: String, $limit: Int) {
        findDashboardsOfLevelGroupingByGrouping(grouping: $grouping, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                level
                rank
                definition
                tenant
                tenantName
                tenantCode
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                seller
                sellerCode
                sellergroup
                sellergroupName
                sellergroupCode
                grouping
                groupingName
                groupingCode
                campaign
                campaignName
                campaignCode
                partner
                partnerName
                partnerCode
                shareMode
                privatePageUrl
            }
        }
    }
`;
export const FIND_DASHBOARDS_OF_LEVEL_SELLERGROUP_BY_SELLERGROUP = (gql: any) => gql`
    query findDashboardsOfLevelSellergroupBySellergroup($sellergroup: String!, $offset: String, $limit: Int) {
        findDashboardsOfLevelSellergroupBySellergroup(sellergroup: $sellergroup, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                level
                rank
                definition
                tenant
                tenantName
                tenantCode
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                seller
                sellerCode
                sellergroup
                sellergroupName
                sellergroupCode
                grouping
                groupingName
                groupingCode
                campaign
                campaignName
                campaignCode
                partner
                partnerName
                partnerCode
                shareMode
                privatePageUrl
            }
        }
    }
`;
export const FIND_DASHBOARDS_OF_LEVEL_SELLER_BY_SELLER = (gql: any) => gql`
    query findDashboardsOfLevelSellerBySeller($seller: String!, $offset: String, $limit: Int) {
        findDashboardsOfLevelSellerBySeller(seller: $seller, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                level
                rank
                definition
                tenant
                tenantName
                tenantCode
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                seller
                sellerCode
                sellergroup
                sellergroupName
                sellergroupCode
                grouping
                groupingName
                groupingCode
                campaign
                campaignName
                campaignCode
                partner
                partnerName
                partnerCode
                shareMode
                privatePageUrl
            }
        }
    }
`;