const t =  {
    menus: {
        main: {section: 'settings3', priority: 95, icon: 'fa-file-code'},
    },
    new: {
        attributes: [
            'name', 'code', 'tenant', 'definition', 'params', 'frequency',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$code!', '$name!', '$automationDefinition', '$params', '$frequency',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'code', 'tenant', 'definition', 'params', 'frequency',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$code!', '$name!', '$automationDefinition', '$params', '$frequency',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: ['name', 'code', 'tenantCode', 'definition', 'params', 'frequency', 'lastExecutionDate', 'lastExecutionStatus', 'lastExecutionDetails', 'type', 'plannedExecutionDate', 'game', 'gameCode', 'gameName'],
    },
    list: {
        columns: [
            {id: 'createdAt', label: 'column_createdat_label', flex: 1, width: 100, component: 'date'} as any,
            {id: 'code', label: 'column_code_label', flex: 1, width: 100} as any,
            {id: 'type', label: 'column_type_label', flex: 1, width: 120} as any,
            {id: 'status', label: 'column_status_label', flex: 1, width: 120} as any,
            {id: 'frequency', label: 'column_frequency_label', flex: 1, width: 120} as any,
            {id: 'plannedExecutionDate', label: 'column_plannedexecutiondate_label', flex: 1, width: 100, component: 'date'} as any,
            {id: 'game', label: 'column_game_label', flex: 1, width: 120, component: 'game'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
        filters: {
            running: {},
            created: {},
            paused: {},
            archived: {},
            all: {},
        },
    },
    types: {
        run: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    {id: 'createdAt', label: 'column_createdat_label', flex: 1, width: 100, component: 'date'} as any,
                    {id: 'code', label: 'column_code_label', flex: 1, width: 100} as any,
                    {id: 'type', label: 'column_type_label', flex: 1, width: 120} as any,
                    {id: 'status', label: 'column_status_label', flex: 1, width: 120} as any,
                    {id: 'frequency', label: 'column_frequency_label', flex: 1, width: 120} as any,
                    {id: 'plannedExecutionDate', label: 'column_plannedexecutiondate_label', flex: 1, width: 100, component: 'date'} as any,
                ],
                globalActions: ['add'],
                actions: ['display', 'edit', 'delete'],
            },
        }
    }
};

export default t;