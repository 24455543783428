const t = {
    icon: 'fa-at',
    menus: {
        main: {section: 'settings3', priority: 90},
    },
    new: {
        attributes: [
            'tenant', 'code', 'organization', 'game', 'partner', 'campaign', 'key', 'mode', 'content',
        ],
        content: [
            ['row', [
                ['block', [
                    '$code!',
                    '$tenant!',
                    '$game',
                    '$organization',
                    '$campaign',
                    '$partner',
                    '$key',
                    '$mode',
                    '$content',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'tenant', 'code', 'organization', 'game', 'partner', 'campaign', 'key', 'mode', 'content',
        ],
        content: [
            ['row', [
                ['block', [
                    '$code!',
                    '$tenant!',
                    '$game',
                    '$organization',
                    '$campaign',
                    '$partner',
                    '$key',
                    '$mode',
                    '$content',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'code', 'gameCode', 'organizationCode', 'campaignCode', 'partnerCode', 'tenantCode',
            'key', 'content', 'mode',
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 0, width: 200} as any,
            {id: 'gameCode', label: 'column_game_label'} as any,
            {id: 'organizationCode', label: 'column_organization_label'} as any,
            {id: 'partnerCode', label: 'column_partner_label'} as any,
            {id: 'key', label: 'column_key_label', flex: 0, width: 240} as any,
            {id: 'mode', label: 'column_mode_label'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete']
    },
};

export default t;