export const CREATE_PRESET = (gql: any) => gql`
    mutation($data: CreatePresetInput!) {
        createPreset(data: $data) {
            id
        }
    }
`;
export const GET_PRESET = (gql: any) => gql`
    query($id: ID!) {
        getPreset(id: $id) {
            id
            tenant
            tenantCode
            tenantName
            name
            code
            type
            definition
            status
        }
    }
`;
export const FIND_PRESETS = (gql: any) => gql`
    query findPresets($offset: String, $limit: Int) {
        findPresets(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                tenant
                tenantCode
                tenantName
                name
                code
                type
                definition
                status
            }
        }
    }
`;
export const FIND_PRESETS_OF_TYPE_GAME = (gql: any) => gql`
    query findPresetsOfTypeGame($offset: String, $limit: Int) {
        findPresetsOfTypeGame(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                type
            }
        }
    }
`;
export const FIND_PRESETS_OF_TYPE_ORGANIZATION = (gql: any) => gql`
    query findPresetsOfTypeOrganization($offset: String, $limit: Int) {
        findPresetsOfTypeOrganization(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                type
            }
        }
    }
`;
export const FIND_PRESETS_OF_TYPE_ODATAITEM = (gql: any) => gql`
    query findPresetsOfTypeOdataitem($offset: String, $limit: Int) {
        findPresetsOfTypeOdataitem(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                type
            }
        }
    }
`;
export const FIND_PRESETS_OF_TYPE_BDATAITEM = (gql: any) => gql`
    query findPresetsOfTypeBdataitem($offset: String, $limit: Int) {
        findPresetsOfTypeBdataitem(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                type
            }
        }
    }
`;
export const FIND_PRESETS_OF_TYPE_GACHIEVEMENT = (gql: any) => gql`
    query findPresetsOfTypeGachievement($offset: String, $limit: Int) {
        findPresetsOfTypeGachievement(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                type
            }
        }
    }
`;
export const FIND_PRESETS_OF_TYPE_INVOICE = (gql: any) => gql`
    query findPresetsOfTypeInvoice($offset: String, $limit: Int) {
        findPresetsOfTypeInvoice(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                type
            }
        }
    }
`;
export const FIND_PRESETS_OF_TYPE_GROUPING = (gql: any) => gql`
    query findPresetsOfTypeGrouping($offset: String, $limit: Int) {
        findPresetsOfTypeGrouping(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                type
            }
        }
    }
`;
export const FIND_PRESETS_OF_TYPE_GDATAITEM = (gql: any) => gql`
    query findPresetsOfTypeGdataitem($offset: String, $limit: Int) {
        findPresetsOfTypeGdataitem(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                type
            }
        }
    }
`;
export const FIND_PRESETS_OF_TYPE_DASHBOARD = (gql: any) => gql`
    query findPresetsOfTypeDashboard($offset: String, $limit: Int) {
        findPresetsOfTypeDashboard(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                type
            }
        }
    }
`;
export const FIND_PRESETS_OF_TYPE_DATAQUERY = (gql: any) => gql`
    query findPresetsOfTypeDataquery($offset: String, $limit: Int) {
        findPresetsOfTypeDataquery(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                type
            }
        }
    }
`;
export const FIND_PRESETS_OF_TYPE_PLAN = (gql: any) => gql`
    query findPresetsOfTypePlan($offset: String, $limit: Int) {
        findPresetsOfTypePlan(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
            }
        }
    }
`;
export const FIND_PRESETS_OF_TYPE_SALEITEM = (gql: any) => gql`
    query findPresetsOfTypeSaleitem($offset: String, $limit: Int) {
        findPresetsOfTypeSaleitem(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
            }
        }
    }
`;
export const FIND_PRESETS_OF_TYPE_INVITATION = (gql: any) => gql`
    query findPresetsOfTypeInvitation($offset: String, $limit: Int) {
        findPresetsOfTypeInvitation(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
            }
        }
    }
`;
export const FIND_PRESETS_OF_TYPE_BOOKSET = (gql: any) => gql`
    query findPresetsOfTypeBookset($offset: String, $limit: Int) {
        findPresetsOfTypeBookset(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                type
            }
        }
    }
`;
export const FIND_PRESETS_OF_TYPE_GLABEL = (gql: any) => gql`
    query findPresetsOfTypeGlabel($offset: String, $limit: Int) {
        findPresetsOfTypeGlabel(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                type
            }
        }
    }
`;
export const FIND_PRESETS_OF_TYPE_OLABEL = (gql: any) => gql`
    query findPresetsOfTypeOlabel($offset: String, $limit: Int) {
        findPresetsOfTypeOlabel(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                type
            }
        }
    }
`;
export const FIND_PRESETS_OF_TYPE_GKEYFIGURE = (gql: any) => gql`
    query findPresetsOfTypeGkeyfigure($offset: String, $limit: Int) {
        findPresetsOfTypeGkeyfigure(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                type
            }
        }
    }
`;
export const FIND_PRESETS_OF_TYPE_OKEYFIGURE = (gql: any) => gql`
    query findPresetsOfTypeOkeyfigure($offset: String, $limit: Int) {
        findPresetsOfTypeOkeyfigure(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                code
                type
            }
        }
    }
`;