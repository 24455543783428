export const CREATE_EMAILSENDER = (gql: any) => gql`
    mutation($data: CreateEmailsenderInput!) {
        createEmailsender(data: $data) {
            id
            email
        }
    }
`;
export const GET_EMAILSENDER = (gql: any) => gql`
    query($id: ID!) {
        getEmailsender(id: $id) {
            id
            email
            name
            identityArn
            tenant
            tenantCode
            features
        }
    }
`;
export const FIND_EMAILSENDERS = (gql: any) => gql`
    query findEmailsenders($offset: String, $limit: Int) {
        findEmailsenders(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                email
                name
                identityArn
                tenant
                tenantCode
                features
            }
        }
    }
`;