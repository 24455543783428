const t = {
    icon: 'fa-at',
    menus: {
        main: {section: 'settings2', priority: 90},
    },
    new: {
        attributes: [
            'tenant', 'code', 'name', 'organization', 'game', 'partner', 'campaign', 'features',
            'mode', 'main', 'key', 'options', 'pool', 'redirectTarget',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$code!',
                    '$name',
                    '$tenant!',
                    '$game',
                    '$organization',
                    '$campaign',
                    '$partner',
                    '$features',
                ]],
                ['block', [
                    '$hostMode',
                    '$main',
                    '$key',
                    '$options',
                    '$pool',
                    '$redirectTarget',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'tenant', 'code', 'name', 'organization', 'game', 'partner', 'campaign', 'features',
            'mode', 'main', 'key', 'options', 'pool', 'redirectTarget',
        ],
        content: [
            ['row', [
                ['block', [
                    '$code!',
                    '$name',
                    '$tenant!',
                    '$game',
                    '$organization',
                    '$campaign',
                    '$partner',
                    '$features',
                ]],
                ['block', [
                    '$hostMode',
                    '$main',
                    '$key',
                    '$options',
                    '$pool',
                    '$redirectTarget',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'dns', 'code', 'name', 'gameCode', 'organizationCode', 'campaignCode', 'partnerCode', 'tenantCode',
            'cname', 'mode', 'main', 'key', 'options', 'pool', 'redirectTarget',

        ],
    },
    list: {
        columns: [
            {id: 'dns', label: 'column_dns_label', flex: 0, width: 200} as any,
            {id: 'game', label: 'column_game_label', component: 'game'} as any,
            {id: 'partner', label: 'column_partner_label', component: 'partner'} as any,
            {id: 'cname', label: 'column_cname_label', flex: 0, width: 240} as any,
            {id: 'mode', label: 'column_mode_label'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete']
    },
};

export default t;