const t =  {
    plural: 'dataqueries',
    menus: {
        main: {section: 'settings3', priority: 99, icon: 'fa-file-code'},
    },
    new: {
        attributes: [
            'code', 'definition', 'description', 'inlinePreset', 'name', 'parameters', 'presets', 'sql', 'tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$code!', '$name!', '$description',
                ]],
                ['block', [
                    '$sql!', '$parameters', '$definition', '$inlinePreset', '$dataqueriesPresets',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'code', 'definition', 'description', 'name', 'parameters', 'sql', 'tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$code!', '$name!', '$description',
                ]],
                ['block', [
                    '$sql!', '$parameters', '$definition',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        body: true,
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 1} as any,
            {id: 'name', label: 'column_name_label', flex: 1} as any,
            {id: 'sql', label: 'column_sql_label', flex: 1} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;