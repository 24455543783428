const t = {
    icon: 'fa-users',
    menus: {
        main: { section: 'settings', priority: 50 },
    },
    new: {
        attributes: [
            'game', 'name', 'email', 'phone', 'country', 'locale', 'features', 'rank', 'customCode', 'logoImage',
            'qrcodeLogoImage', 'presentation', 'overline', 'description', 'type', 'main', 'grouping', 'stripeCustomer',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$game!', '$grouping', '$customCode', '$name', '$rank', '$email', '$phone', '$sellergroupType', '$main',
                ]],
                ['block', [
                    '$locale', '$country', '$features', '$logoImage', '$qrcodeLogoImage', '$presentation', '$overline', '$description', '$stripeCustomer',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'game', 'name', 'email', 'phone', 'country', 'locale', 'features', 'rank', 'customCode', 'logoImage',
            'qrcodeLogoImage', 'presentation', 'overline', 'description', 'type', 'main', 'grouping', 'stripeCustomer',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$game!', '$grouping', '$customCode', '$name', '$rank', '$email', '$phone', '$sellergroupType', '$main',
                ]],
                ['block', [
                    '$locale', '$country', '$features', '$logoImage', '$qrcodeLogoImage', '$presentation', '$overline', '$description', '$stripeCustomer',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: [
            'id', 'gameName', 'gameCode', 'name', 'email', 'phone', 'type', 'main', 'groupingCode', 'groupingEmail', 'groupingName', 'stripeCustomer',
        ],
    },
    list: {
        columns: [
            { id: 'game', label: 'column_game_code_label', flex: 0, width: 350, component: 'game' },
            { id: 'customCode', label: 'column_sellergroup_customcode_label', flex: 0, width: 150 },
            { id: 'name', label: 'column_name_label' },
            { id: 'groupingCode', label: 'column_grouping_label', component: 'grouping' },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'go_public_page', 'delete']
    },
    types: {
        book: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    { id: 'code', label: 'column_code_label', flex: 0, width: 100 } as any,
                    { id: 'game', label: 'column_game_label', flex: 0, width: 300, component: 'game' } as any,
                    { id: 'paymentStatus', label: 'column_payment_status_label' },
                    { id: 'sellergroup', label: 'column_sellergroup_code_label' },
                    { id: 'customCode', label: 'column_customcode_label' },
                    { id: 'attributionStatus', label: 'column_attribution_status_label' },
                ],
                globalActions: ['add'],
                actions: ['display', 'edit', 'go_public_page', 'delete'],
            },
        }
    }
};

export default t;