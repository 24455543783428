const t = {
    menus: {
        main: {section: 'settings3', priority: 100, icon: 'fa-chart-column'},
    },
    display: {
        properties: [
            'startedAt', 'endedAt', 'type', 'intDiff', 'floatDiff', 'hasIntDiff', 'hasFloatDiff', 'app', 'view',
            'tenantCode', 'partnerCode', 'organizationCode', 'gameCode', 'campaignCode', 'sellerCode', 'sellergroupCode', 'groupingCode',
        ],
    },
    list: {
        search: false,
        searchMode: 'default',
        searchSwitch: false,
        columns: [
            {id: 'type', label: 'column_type_label'},
            {id: 'view', label: 'column_view_label'},
            {id: 'startedAt', label: 'column_startedat_label', component: 'date'},
            {id: 'endedAt', label: 'column_endedat_label', component: 'date'},
            {id: 'game', label: 'column_game_label', component: 'game'},
            {id: 'organization', label: 'column_organization_label', component: 'organization'},
            {id: 'partner', label: 'column_partner_label', component: 'partner'},
            {id: 'grouping', label: 'column_grouping_label', component: 'grouping'},
        ],
        globalActions: ['add'],
        actions: ['display'],
        filters: {
            processableEnded: {},
            processable: {},
            all: {},
        }
    },
};

export default t;