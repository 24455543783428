const t = {
    icon: 'fa-chart-column',
    menus: {
        main: {section: 'settings2', priority: 90},
    },
    display: {
        properties: [
            'id', 'type', 'game', 'gameName', 'gameCode', 'tenant', 'tenantName', 'tenantCode',
            'organization', 'organizationName', 'organizationCode', 'data', 'startTime', 'endTime',
        ],
    },
    list: {
        columns: [
            {id: 'type', label: 'column_type_label'},
            {id: 'game', label: 'column_game_label', component: 'game'},
            {id: 'startTime', label: 'column_starttime_label', component: 'date'},
            {id: 'endTime', label: 'column_endtime_label', component: 'date'},
        ],
        globalActions: ['add'],
        actions: ['display', 'delete'],
    },
};

export default t;