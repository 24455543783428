export const CREATE_SESSION = (gql: any) => gql`
    mutation($data: CreateSessionInput!) {
        createSession(data: $data) {
            id
        }
    }
`;
export const DELETE_SESSION = (gql: any) => gql`
    mutation($id: ID!) {
        deleteSession(id: $id) {
            id
        }
    }
`;
export const FIND_SESSIONS = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findSessions(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                activeGroup
                closedAt
                closedBy
                country
                createdAt
                externalReference
                game
                gameCode
                gameName
                id
                locale
                notOpenedAt
                notOpenedBy
                objectId
                openedAt
                openedBy
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                status
                tenant
                tenantCode
                tenantName
                type
                user
                userCode
                userEmail
                userFirstName
                userLastName
                userPhone
            }
        }
    }
`;
export const GET_SESSION = (gql: any) => gql`
    query($id: ID!) {
        getSession(id: $id) {
            activeGroup
            closedAt
            closedBy
            country
            createdAt
            externalReference
            game
            gameCode
            gameName
            id
            inlinePreset
            locale
            notOpenedAt
            notOpenedBy
            objectId
            openedAt
            openedBy
            organization
            organizationCode
            organizationName
            partner
            partnerCode
            partnerName
            presets
            privateToken
            publicToken
            seller
            sellerCode
            sellerEmail
            sellerFirstName
            sellerLastName
            sellerPhone
            sellergroup
            sellergroupCode
            sellergroupEmail
            sellergroupGameSellergroupType
            sellergroupName
            sellergroupPhone
            sellergroupType
            status
            tags
            tenant
            tenantCode
            tenantName
            type
            updatedAt
            user
            userCode
            userEmail
            userFirstName
            userLastName
            userPhone
        }
    }
`;
export const SEARCH_SESSIONS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchSessions(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                activeGroup
                closedAt
                closedBy
                country
                createdAt
                externalReference
                game
                gameCode
                gameName
                id
                locale
                notOpenedAt
                notOpenedBy
                objectId
                openedAt
                openedBy
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                status
                tenant
                tenantCode
                tenantName
                type
                user
                userCode
                userEmail
                userFirstName
                userLastName
                userPhone
            }
        }
    }
`;
export const UPDATE_SESSION = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateSessionInput!) {
        updateSession(id: $id, data: $data) {
            id
        }
    }
`;
