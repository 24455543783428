export const CREATE_PRODUCT = (gql: any) => gql`
    mutation($data: CreateProductInput!) {
        createProduct(data: $data) {
            id
        }
    }
`;
export const GET_PRODUCT = (gql: any) => gql`
    query($id: ID!) {
        getProduct(id: $id) {
            id
            code
            name
            description
            tenant
            organization
            game
            amount
            taxAmount
            currency
            processorType
            status
        }
    }
`;
export const FIND_PRODUCTS = (gql: any) => gql`
    query findProducts($offset: String, $limit: Int) {
        findProducts(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                description
                tenant
                organization
                game
                amount
                taxAmount
                currency
                processorType
                status
            }
        }
    }
`;