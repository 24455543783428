export const CREATE_ORDER = (gql: any) => gql`
    mutation($data: CreateOrderInput!) {
        createOrder(data: $data) {
            id
        }
    }
`;
export const GET_ORDER = (gql: any) => gql`
    query($id: ID!) {
        getOrder(id: $id) {
            id
            owner
            status
            processorStatus
            email
            firstName
            lastName
            game
            gameName
            gameCode
            organization
            organizationCode
            organizationName
            paymentAmount
            orderitems {
                items {
                    productCode
                    amount
                    currency
                }
            }
        }
    }
`;
export const FIND_ORDERS = (gql: any) => gql`
    query findOrders($offset: String, $limit: Int) {
        findOrders(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                owner
                status
                processorStatus
                email
                firstName
                lastName
                game
                gameName
                gameCode
                organization
                organizationCode
                organizationName
                paymentAmount
                orderitems {
                    items {
                        productCode
                        amount
                        currency
                    }
                }
            }
        }
    }
`;
export const SEARCH_ORDERS = (gql: any) => gql`
    query searchOrders($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOrders(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                owner
                status
                processorStatus
                email
                firstName
                lastName
                game
                gameName
                gameCode
                organization
                organizationCode
                organizationName
                paymentAmount
                orderitems {
                    items {
                        productCode
                       amount
                       currency
                    }
                }
            }
        }
    }
`;
export const UPDATE_ORDER = (gql: any) => gql`
    mutation updateOrder($id: ID!, $data: UpdateOrderInput!) {
        updateOrder(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_ORDER = (gql: any) => gql`
    mutation deleteOrder($id: ID!) {
        deleteOrder(id: $id) {
            id
        }
    }
`;
