const t =  {
    plural: 'plans',
    menus: {
        main: {section: 'settings3', priority: 58, icon: 'fa-cart-arrow-down'},
    },
    new: {
        attributes: [
            'code', 'maxPaymentAmount', 'maxSales', 'maxTickets', 'name', 'tenant', 'inlinePreset', 'presets',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$code!', '$name!', '$inlinePreset', '$plansPresets',
                ]],
                ['block', [
                    '$maxPaymentAmount', '$maxSales', '$maxTickets',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'code', 'maxPaymentAmount', 'maxSales', 'maxTickets', 'name', 'tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$code!', '$name!',
                ]],
                ['block', [
                    '$maxPaymentAmount', '$maxSales', '$maxTickets',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'code', 'name', 'maxPaymentAmount', 'maxSales', 'maxTickets',
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 1} as any,
            {id: 'name', label: 'column_name_label', flex: 1} as any,
            {id: 'maxTickets', label: 'column_maxtickets_label', flex: 1} as any,
            {id: 'maxSales', label: 'column_maxsales_label', flex: 1} as any,
            {id: 'maxPaymentAmount', label: 'column_maxpaymentamount_label', flex: 1} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;