export const CREATE_SPONSOR = (gql: any) => gql`
    mutation($data: CreateSponsorInput!) {
        createSponsor(data: $data) {
            id
        }
    }
`;
export const GET_SPONSOR = (gql: any) => gql`
    query($id: ID!) {
        getSponsor(id: $id) {
            id
            code
            name
            type
            lastName
            firstName
            email
            phone
            game
            gameCode
            gameName
            tenant
            tenantCode
            tenantName
            brand
            brandCode
            brandName
            brandWebsiteUrl
            brandFacebookUrl
            brandTwitterUrl
            brandYoutubeUrl
            brandInstagramUrl
            logoImage  {
                available
                size
                url
            }
            logoAltImage  {
                available
                size
                url
            }
            bannerImage  {
                available
                size
                url
            }
            mobileBannerImage  {
                available
                size
                url
            }
            tabletBannerImage  {
                available
                size
                url
            }
            banner2Image  {
                available
                size
                url
            }
            mainImage  {
                available
                size
                url
            }
            appleTouchImage  {
                available
                size
                url
            }
            faviconImage  {
                available
                size
                url
            }
            presentation
            description
            websiteUrl
            facebookUrl
            twitterUrl
            youtubeUrl
            instagramUrl
            privateToken
            publicToken
            token
        }
    }
`;
export const FIND_SPONSORS = (gql: any) => gql`
    query findSponsors($offset: String, $limit: Int) {
        findSponsors(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                type
                lastName
                firstName
                email
                phone
                game
                gameCode
                gameName
                tenant
                tenantCode
                tenantName
                brand
                brandCode
                brandName
                logoImage  {
                    available
                    size
                    url
                }
                logoAltImage  {
                    available
                    size
                    url
                }
                bannerImage  {
                    available
                    size
                    url
                }
                mobileBannerImage  {
                    available
                    size
                    url
                }
                tabletBannerImage  {
                    available
                    size
                    url
                }
                mainImage  {
                    available
                    size
                    url
                }
                websiteUrl
            }
        }
    }
`;
export const DELETE_SPONSOR = (gql: any) => gql`
    mutation deleteSponsor($id: ID!) {
        deleteSponsor(id: $id) {
            id
        }
    }
`;
export const SEARCH_SPONSORS = (gql: any) => gql`
    query searchSponsors($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchSponsors(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                type
                lastName
                firstName
                email
                game
                gameCode
                gameName
                phone
                tenant
                tenantCode
                tenantName
                brand
                brandCode
                brandName
                logoImage  {
                    available
                    size
                    url
                }
                logoAltImage  {
                    available
                    size
                    url
                }
                bannerImage  {
                    available
                    size
                    url
                }
                mobileBannerImage  {
                    available
                    size
                    url
                }
                tabletBannerImage  {
                    available
                    size
                    url
                }
                mainImage  {
                    available
                    size
                    url
                }
                websiteUrl
            }
        }
    }
`;
export const UPDATE_SPONSOR = (gql: any) => gql`
    mutation updateSponsor($id: ID!, $data: UpdateSponsorInput!) {
        updateSponsor(id: $id, data: $data) {
            id
        }
    }
`;
