export const CREATE_TCOUNTRY = (gql: any) => gql`
    mutation($data: CreateTcountryInput!) {
        createTcountry(data: $data) {
            id
        }
    }
`;
export const GET_TCOUNTRY = (gql: any) => gql`
    query($id: ID!) {
        getTcountry(id: $id) {
            code
            createdAt
            id
            mainImage {
                url
                available
            }
            name
            privateToken
            publicToken
            status
            tags
            tenant
            tenantCode
            tenantName
            token
            updatedAt
            rank
        }
    }
`;
export const FIND_TCOUNTRIES = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findTcountries(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                code
                createdAt
                id
                mainImage {
                    url
                    available
                }
                name
                privateToken
                publicToken
                status
                rank
            }
        }
    }
`;
export const FIND_TENANT_TCOUNTRIES = (gql: any) => gql`
    query($id: String!, $offset: String, $limit: Int) {
        findTenantTcountries(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                code
                createdAt
                id
                mainImage {
                    url
                    available
                }
                name
                privateToken
                publicToken
                status
                rank
            }
        }
    }
`;
export const SEARCH_TCOUNTRIES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchTcountries(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                code
                createdAt
                id
                mainImage {
                    url
                    available
                }
                name
                privateToken
                publicToken
                status
                rank
            }
        }
    }
`;
export const UPDATE_TCOUNTRY = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateTcountryInput!) {
        updateTcountry(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_TCOUNTRY = (gql: any) => gql`
    mutation($id: ID!) {
        deleteTcountry(id: $id) {
            id
        }
    }
`;