import {ReactElement, useCallback, useMemo} from 'react';
import {BaseCell} from "@gotombola/react-as/lib/types";
import {Image} from "@genstackio/react-admin-ui/lib/atoms/Image";
import stopPrevent from "@genstackio/react-admin-ui/lib/utils/stopPrevent";

export function AdImageCell({row: { bannerImage, bannerImageUrl, mediumRectangleBannerImage, mediumRectangleBannerImageUrl, leaderboardBannerImage, leaderboardBannerImageUrl }}: AdImageCellProps): ReactElement {
    const url = useMemo(() => [bannerImage?.url, bannerImageUrl, mediumRectangleBannerImage?.url, mediumRectangleBannerImageUrl, leaderboardBannerImage?.url, leaderboardBannerImageUrl].find(x => !!x), [bannerImage?.url, bannerImageUrl, mediumRectangleBannerImage?.url, mediumRectangleBannerImageUrl, leaderboardBannerImage?.url, leaderboardBannerImageUrl]);
    const goUrl = useCallback((e: any) => {
        stopPrevent(e);
        !!url && 'undefined' !== typeof window && window.open(url);
    }, [url]);

    return (
        <div onClick={goUrl} className={'cursor-pointer group flex items-center justify-center w-full'}>
            <div className={'h-10 items-center flex justify-center p-x'}>
                <Image url={url} className={'border-transparent border-2 group-hover:border-danger box-border'} />
            </div>
        </div>
    );
}

export type AdImageCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default AdImageCell;