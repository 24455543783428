export const CREATE_DFONTFAMILY = (gql: any) => gql`
    mutation($data: CreateDfontfamilyInput!) {
        createDfontfamily(data: $data) {
            id
        }
    }
`;
export const GET_DFONTFAMILY = (gql: any) => gql`
    query($id: ID!) {
        getDfontfamily(id: $id) {
            id
            tenant
            code
            name
            value
            tags
            publicCatalogStatus
            linkUrl
        }
    }
`;
export const FIND_DFONTFAMILIES = (gql: any) => gql`
    query findDfontfamilies($offset: String, $limit: Int) {
        findDfontfamilies(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                value
                tags
                publicCatalogStatus
                linkUrl
            }
        }
    }
`;
export const SEARCH_DFONTFAMILIES = (gql: any) => gql`
    query searchDfontfamilies($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDfontfamilies(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                value
                tags
                publicCatalogStatus
                linkUrl
            }
        }
    }
`;
export const UPDATE_DFONTFAMILY = (gql: any) => gql`
    mutation updateDfontfamily($id: ID!, $data: UpdateDfontfamilyInput!) {
        updateDfontfamily(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_DFONTFAMILY = (gql: any) => gql`
    mutation deleteDfontfamily($id: ID!) {
        deleteDfontfamily(id: $id) {
            id
        }
    }
`;
