export const CREATE_USER = (gql: any) => gql`
    mutation($data: CreateUserInput!) {
        createUser(data: $data) {
            id
        }
    }
`;
export const GET_CURRENT_USER = (gql: any) => gql`
    query {
        getCurrentUser {
            id
            email
            firstName
            lastName
            createdAt
            updatedAt
            status
            role
        }
    }
`;
export const GET_USER = (gql: any) => gql`
    query($id: ID!) {
        getUser(id: $id) {
            id
            code
            email
            firstName
            lastName
            createdAt
            updatedAt
            status
            role
            tenant
            tenantCode
            tenantName
            stripeCustomer
        }
    }
`;
export const FIND_USERS = (gql: any) => gql`
    query findUsers($offset: String, $limit: Int) {
        findUsers(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                lastName
                firstName
                email
                createdAt
                updatedAt
                status
                publicPageShortUrl
                role
            }
        }
    }
`;