const t = {
    icon: 'fa-file-import',
    menus: {
        main: {section: 'settings2', priority: 20},
    },
    new: {
        attributes: [
            'game', 'type', 'dataFile', 'params',
        ],
        content: [
            ['row', [
                ['block', [
                    '$type!', '$game!',
                ]],
                ['block', [
                    '$params', '$dataFile',
                ]]
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'status', 'params',
        ],
        content: [
            ['row', [
                ['block', [
                    '$status:readonly!', '$type!', '$game!',
                ]],
                ['block', [
                    '$params', '$dataFile',
                ]]
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: [
            'id', 'status', 'type', 'gameCode', 'gameName',
        ],
    },
    list: {
        columns: [
            {id: 'createdAt', label: 'column_createdat_label', component: 'date'},
            {id: 'status', label: 'column_status_label', flex: 0, width: 100},
            {id: 'type', label: 'column_type_label'},
            {id: 'gameName', label: 'column_game_label'},
            {id: 'organizationName', label: 'column_organization_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;