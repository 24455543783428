export const CREATE_REMINDER = (gql: any) => gql`
    mutation($data: CreateReminderInput!) {
        createReminder(data: $data) {
            id
        }
    }
`;
export const GET_REMINDER = (gql: any) => gql`
    query($id: ID!) {
        getReminder(id: $id) {
            id
            tenant
            tenantCode
            tenantName
            partner
            partnerCode
            partnerName
            organization
            organizationCode
            organizationName
            game
            gameCode
            gameName
            country
            locale
            status
            type
            kind
            sale
            promise
            promiseCode
            greminder
            oreminder
            preminder
            treminder
            notifications {
                items {
                    template
                    recipient
                    sender
                    variables
                    type
                    locale
                    country
                    subject
                    attachments
                }
            }
        }
    }
`;
export const FIND_REMINDERS = (gql: any) => gql`
    query findReminders($offset: String, $limit: Int) {
        findReminders(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                status
                kind
                type
                greminder
                oreminder
                preminder
                treminder
            }
        }
    }
`;