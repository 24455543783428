const t = {
    icon: 'fa-users',
    menus: {
        main: {section: 'settings', priority: 51},
    },
    new: {
        attributes: [
            'agenda', 'backgroundAudioUrl', 'backgroundVideoUrl', 'banner2VideoUrl', 'bannerVideoUrl', 'code', 'country', 'customCode',
            'customPublicPageUrl', 'description', 'email', 'emailFrom', 'emailFromArn', 'emailFromEmail', 'facebookUrl', 'features',
            'flickrUrl', 'flyerVideoUrl', 'fullName', 'game', 'infos', 'inlinePreset', 'instagramUrl', 'legalName', 'linkedinUrl', 'locale',
            'logoImage', 'longDescription', 'main', 'mainAudioUrl', 'mainCss', 'mainJs', 'mainLongAudioUrl', 'mainLongVideoUrl',
            'mainShortAudioUrl', 'mainShortVideoUrl', 'mainVideoUrl', 'mission', 'name', 'nin', 'notifyEmail', 'notifyPhone', 'overline',
            'phone', 'pinterestUrl', 'presentation', 'presets', 'publicEmail', 'publicPageBackgroundColor', 'publicPageBackgroundCustom',
            'publicPageBackgroundStyle', 'publicPageBackgroundTexture', 'publicPageBackgroundVideoUrl', 'publicPageEffects', 'publicPageModules',
            'publicPageModulesCustomRaw', 'publicPageModulesData', 'publicPageModulesDiff', 'publicPhone', 'rank', 'redditUrl', 'seoAbstract',
            'seoAuthor', 'seoDescription', 'seoKeywords', 'seoLang', 'seoSubject', 'seoTitle', 'shortName', 'snapchatUrl', 'tiktokUrl', 'tumblrUrl',
            'twitterUrl', 'type', 'varColorBorderPrimary', 'varColorBorderSecondary', 'varColorChoicePrimary', 'varColorChoiceSecondary',
            'varColorFooterPrimary', 'varColorFooterSecondary', 'varColorHeading', 'varColorLogo', 'varColorNavPrimary', 'varColorNavSecondary',
            'varColorPanelPrimary', 'varColorPanelSecondary', 'varColorPrimary', 'varColorSecondary', 'varColorTextPrimary', 'varColorTextSecondary',
            'varColorTitle', 'varsCss', 'vimeoUrl', 'warning', 'websiteUrl', 'youtubeUrl', 'grouping'
        ],
        content: [
            ['row', [
                ['tabs', [
                    ['edit_grouping_form_general_title', [
                        ['row', [
                            ['block', [
                                '$game!', '$name!', '$code', '$grouping', '$customCode', '$fullName', '$shortName', '$legalName', '$locale', '$country', '$presentation', '$description',
                                '$agenda',  '$infos', '$longDescription', '$main', '$mission', '$nin',  '$overline', '$rank', '$type', '$warning', '$inlinePreset', '$groupingPresets',
                            ]],
                            ['block', [
                                '$logoImage', '$email', '$emailFrom', '$emailFromArn', '$emailFromEmail', '$publicEmail', '$notifyEmail', '$notifyPhone', '$publicPhone',
                                '$phone', '$features', '$seoAbstract', '$seoAuthor', '$seoDescription', '$seoKeywords', '$seoLang', '$seoSubject', '$seoTitle',
                            ]],
                        ]],
                    ]],
                    ['edit_grouping_form_url_title', [
                        ['row', [
                            ['block', [
                                '$websiteUrl', '$customPublicPageUrl', '$backgroundVideoUrl', '$banner2VideoUrl', '$bannerVideoUrl', '$flyerVideoUrl', '$mainShortVideoUrl',
                                '$mainVideoUrl', '$backgroundAudioUrl', '$mainAudioUrl', '$mainLongAudioUrl', '$mainShortAudioUrl',
                            ]],
                            ['block', [
                                '$facebookUrl', '$flickrUrl', '$instagramUrl', '$linkedinUrl','$pinterestUrl', '$redditUrl', '$snapchatUrl', '$tiktokUrl', '$tumblrUrl',
                                '$twitterUrl', '$vimeoUrl', '$youtubeUrl',
                            ]],
                        ]],
                    ]],
                    ['edit_grouping_form_colors_title', [
                        ['column', [
                            '$varColorBorderPrimary', '$varColorBorderSecondary', '$varColorChoicePrimary', '$varColorChoiceSecondary',
                            '$varColorFooterPrimary', '$varColorFooterSecondary', '$varColorHeading', '$varColorLogo', '$varColorNavPrimary', '$varColorNavSecondary',
                            '$varColorPanelPrimary', '$varColorPanelSecondary', '$varColorPrimary', '$varColorSecondary', '$varColorTextPrimary', '$varColorTextSecondary',
                            '$varColorTitle',
                        ]],
                    ]],
                    ['edit_grouping_form_page_title', [
                        '$publicPageBackgroundColor', '$publicPageBackgroundCustom', '$publicPageBackgroundStyle', '$publicPageBackgroundTexture', '$publicPageBackgroundVideoUrl',
                        '$publicPageEffects', '$publicPageModules', '$publicPageModulesCustomRaw', '$publicPageModulesData', '$publicPageModulesDiff',
                    ]],
                    ['edit_grouping_form_code_title', [
                        ['row', [
                            ['block', [
                                '$mainJs',
                            ]],
                            ['block', [
                                '$mainCss',  '$varsCss',
                            ]],
                        ]],
                    ]],
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'agenda', 'backgroundAudioUrl', 'backgroundVideoUrl', 'banner2VideoUrl', 'bannerVideoUrl', 'code', 'country', 'customCode', 'customPublicPageUrl', 'description', 'email',
            'emailFrom', 'emailFromArn', 'emailFromEmail', 'facebookUrl', 'features', 'flickrUrl', 'flyerVideoUrl', 'fullName', 'game', 'infos', 'instagramUrl', 'legalName',
            'linkedinUrl', 'locale', 'logoImage', 'longDescription', 'main', 'mainAudioUrl', 'mainCss', 'mainJs', 'mainLongAudioUrl', 'mainLongVideoUrl', 'mainShortAudioUrl',
            'mainShortVideoUrl', 'mainVideoUrl', 'mission', 'name', 'nin', 'notifyEmail', 'notifyPhone', 'overline', 'phone', 'pinterestUrl', 'presentation', 'publicEmail',
            'publicPageBackgroundColor', 'publicPageBackgroundCustom', 'publicPageBackgroundStyle', 'publicPageBackgroundTexture', 'publicPageBackgroundVideoUrl', 'publicPageEffects',
            'publicPageModules', 'publicPageModulesCustomRaw', 'publicPageModulesData', 'publicPageModulesDiff', 'publicPhone', 'rank', 'redditUrl', 'seoAbstract', 'seoAuthor',
            'seoDescription', 'seoKeywords', 'seoLang', 'seoSubject', 'seoTitle', 'shortName', 'snapchatUrl', 'status', 'tiktokUrl', 'tumblrUrl', 'twitterUrl', 'type',
            'varColorBorderPrimary', 'varColorBorderSecondary', 'varColorChoicePrimary', 'varColorChoiceSecondary', 'varColorFooterPrimary', 'varColorFooterSecondary',
            'varColorHeading', 'varColorLogo', 'varColorNavPrimary', 'varColorNavSecondary', 'varColorPanelPrimary', 'varColorPanelSecondary', 'varColorPrimary', 'varColorSecondary',
            'varColorTextPrimary', 'varColorTextSecondary', 'varColorTitle', 'varsCss', 'vimeoUrl', 'warning', 'websiteUrl', 'youtubeUrl', 'grouping'
        ],
        content: [
            ['row', [
                ['tabs', [
                    ['edit_grouping_form_general_title', [
                        ['row', [
                            ['block', [
                                '$game!', '$name!', '$code:readonly!', '$grouping', '$customCode', '$fullName', '$shortName', '$legalName', '$locale', '$country', '$presentation', '$description',
                                '$agenda',  '$infos', '$longDescription', '$main', '$mission', '$nin',  '$overline', '$rank', '$type', '$warning',
                            ]],
                            ['block', [
                                '$logoImage', '$email', '$emailFrom', '$emailFromArn', '$emailFromEmail', '$publicEmail', '$notifyEmail', '$notifyPhone', '$publicPhone',
                                '$phone', '$features', '$seoAbstract', '$seoAuthor', '$seoDescription', '$seoKeywords', '$seoLang', '$seoSubject', '$seoTitle',
                            ]],
                        ]],
                    ]],
                    ['edit_grouping_form_url_title', [
                        ['row', [
                            ['block', [
                                '$websiteUrl', '$customPublicPageUrl', '$backgroundVideoUrl', '$banner2VideoUrl', '$bannerVideoUrl', '$flyerVideoUrl', '$mainShortVideoUrl',
                                '$mainVideoUrl', '$backgroundAudioUrl', '$mainAudioUrl', '$mainLongAudioUrl', '$mainShortAudioUrl',
                            ]],
                            ['block', [
                                '$facebookUrl', '$flickrUrl', '$instagramUrl', '$linkedinUrl','$pinterestUrl', '$redditUrl', '$snapchatUrl', '$tiktokUrl', '$tumblrUrl',
                                '$twitterUrl', '$vimeoUrl', '$youtubeUrl',
                            ]],
                        ]],
                    ]],
                    ['edit_grouping_form_colors_title', [
                        ['column', [
                            '$varColorBorderPrimary', '$varColorBorderSecondary', '$varColorChoicePrimary', '$varColorChoiceSecondary',
                            '$varColorFooterPrimary', '$varColorFooterSecondary', '$varColorHeading', '$varColorLogo', '$varColorNavPrimary', '$varColorNavSecondary',
                            '$varColorPanelPrimary', '$varColorPanelSecondary', '$varColorPrimary', '$varColorSecondary', '$varColorTextPrimary', '$varColorTextSecondary',
                            '$varColorTitle',
                        ]],
                    ]],
                    ['edit_grouping_form_page_title', [
                        '$publicPageBackgroundColor', '$publicPageBackgroundCustom', '$publicPageBackgroundStyle', '$publicPageBackgroundTexture', '$publicPageBackgroundVideoUrl',
                        '$publicPageEffects', '$publicPageModules', '$publicPageModulesCustomRaw', '$publicPageModulesData', '$publicPageModulesDiff',
                    ]],
                    ['edit_grouping_form_code_title', [
                        ['row', [
                            ['block', [
                                '$mainJs',
                            ]],
                            ['block', [
                                '$mainCss',  '$varsCss',
                            ]],
                        ]],
                    ]],
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: [
            'id', 'gameName', 'gameCode', 'name', 'email', 'phone',
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 0, width: 120},
            {id: 'game', label: 'column_game_code_label', component: 'game'},
            {id: 'email', label: 'column_email_label'},
            {id: 'name', label: 'column_name_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete']
    },
};

export default t;
