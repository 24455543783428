const t = {
    icon: 'fa-globe',
    menus: {
        main: {section: 'settings', priority: 2},
    },
    new: {
        attributes: [
            'code', 'mainImage', 'name', 'tags', 'tenant', 'rank',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$code!', '$mainImage',
                ]],
                ['block', [
                    '$tenant!', '$rank!', '$tags'
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'code', 'mainImage', 'name', 'tags', 'tenant', 'rank',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$code!', '$mainImage',
                ]],
                ['block', [
                    '$tenant!', '$rank!', '$tags'
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'rank', 'code', 'createdAt', 'id', 'mainImage', 'name', 'privateToken', 'publicToken', 'status', 'tags',
            'tenant', 'tenantCode', 'tenantName', 'token', 'updatedAt',
        ],
    },
    list: {
        columns: [
            { id: 'mainImage', label: 'column_main_image_label', render: 'image', flex: 0, width: 200 },
            { id: 'code', label: 'column_code_label' },
            { id: 'name', label: 'column_name_label' },
            { id: 'rank', label: 'column_rank_label' },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;