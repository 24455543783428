export const CREATE_DDOMAIN = (gql: any) => gql`
    mutation($data: CreateDdomainInput!) {
        createDdomain(data: $data) {
            id
        }
    }
`;
export const GET_DDOMAIN = (gql: any) => gql`
    query($id: ID!) {
        getDdomain(id: $id) {
            id
            tenant
            code
            name
            tags
        }
    }
`;
export const FIND_DDOMAINS = (gql: any) => gql`
    query findDdomains($offset: String, $limit: Int) {
        findDdomains(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                tags
            }
        }
    }
`;