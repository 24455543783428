const t = {
    icon: 'fa-chart-column',
    menus: {
        main: {section: 'settings2', priority: 93},
    },
    new: {
        attributes: [
            'campaign', 'customerCollectedAmount', 'customerCollectedAmountFlex', 'endDate', 'inlinePreset', 'marketPlaceFeeAmount', 'marketPlaceFeeAmountFlex',
            'notificationStatus', 'paidAmount', 'paidAmountFlex', 'paidDonationCount', 'paidDonationCountFlex', 'paidDonations', 'paidDonationsAmount', 'paidDonationsAmountFlex',
            'paidDonationsFlex', 'paidSales', 'paidSalesFlex', 'paidTickets', 'paidTicketsAmount', 'paidTicketsAmountFlex', 'paidTicketsFlex', 'paymentAmount',
            'paymentProviderFeeAmount', 'paymentProviderFeeAmountFlex', 'presets', 'sales', 'startDate', 'tags', 'totalFeeAmount', 'totalFeeAmountFlex', 'transactionFeeAmount',
            'transactionFeeAmountFlex', 'game',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$game!', '$campaign',
                ]],
                ['block', [
                    '$startDate', '$endDate',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'campaign', 'customerCollectedAmount', 'customerCollectedAmountFlex', 'endDate', 'marketPlaceFeeAmount', 'marketPlaceFeeAmountFlex',
            'notificationStatus', 'paidAmount', 'paidAmountFlex', 'paidDonationCount', 'paidDonationCountFlex', 'paidDonations', 'paidDonationsAmount', 'paidDonationsAmountFlex',
            'paidDonationsFlex', 'paidSales', 'paidSalesFlex', 'paidTickets', 'paidTicketsAmount', 'paidTicketsAmountFlex', 'paidTicketsFlex', 'paymentAmount',
            'paymentProviderFeeAmount', 'paymentProviderFeeAmountFlex', 'sales', 'startDate', 'tags', 'totalFeeAmount', 'totalFeeAmountFlex', 'transactionFeeAmount',
            'transactionFeeAmountFlex', 'game',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$game!', '$campaign',
                ]],
                ['block', [
                    '$startDate', '$endDate',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'campaign', 'campaignCode', 'campaignName', 'createdAt', 'customerCollectedAmount', 'customerCollectedAmountFlex', 'endDate', 'game', 'gameCode', 'gameName',
            'id', 'inlinePreset', 'marketPlaceFeeAmount', 'marketPlaceFeeAmountFlex', 'notificationNotifiedAt', 'notificationNotifiedBy', 'notificationStatus',
            'notificationUnnotifiedAt', 'notificationUnnotifiedBy', 'organization', 'organizationCode', 'organizationName', 'paidAmount', 'paidAmountFlex', 'paidDonationCount',
            'paidDonationCountFlex', 'paidDonations', 'paidDonationsAmount', 'paidDonationsAmountFlex', 'paidDonationsFlex', 'paidSales', 'paidSalesFlex', 'paidTickets',
            'paidTicketsAmount', 'paidTicketsAmountFlex', 'paidTicketsFlex', 'partner', 'partnerCode', 'partnerName', 'paymentAmount', 'paymentProviderFeeAmount',
            'paymentProviderFeeAmountFlex', 'presets', 'privateToken', 'publicToken', 'sales', 'startDate', 'tags', 'tenant', 'tenantCode', 'tenantName',
            'token', 'totalFeeAmount', 'totalFeeAmountFlex', 'transactionFeeAmount', 'transactionFeeAmountFlex', 'updatedAt',
        ],
    },
    list: {
        columns: [
            { id: 'createdAt', label: 'column_createdat_label', component: 'date' },
            { id: 'game', label: 'column_game_code_label', flex: 0, width: 350, component: 'game' },
            { id: 'organization', label: 'column_organization_code_label', flex: 0, width: 350, component: 'organization' },
            { id: 'paymentAmount', label: 'column_paymentamount_label' },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;