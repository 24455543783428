export const CREATE_ADVERTISER = (gql: any) => gql`
    mutation($data: CreateAdvertiserInput!) {
        createAdvertiser(data: $data) {
            id
        }
    }
`;
export const GET_ADVERTISER = (gql: any) => gql`
    query($id: ID!) {
        getAdvertiser(id: $id) {
            affiliater
            affiliaterCode
            affiliaterName
            code
            createdAt
            id
            name
            status
            tenant
            tenantCode
            tenantName
            updatedAt
            appleTouchImage {
                available
                url
            }
            banner2Image {
                available
                url
            }
            bannerImage {
                available
                url
            }
            mobileBannerImage {
                available
                url
            }
            tabletBannerImage {
                available
                url
            }
            faviconImage {
                available
                url
            }
            logoAltImage {
                available
                url
            }
            logoImage {
                available
                url
            }
            mainImage {
                available
                url
            }
            privateToken
            publicToken
            websiteUrl
        }
    }
`;
export const FIND_ADVERTISERS = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findAdvertisers(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                affiliater
                affiliaterCode
                affiliaterName
                code
                createdAt
                id
                name
                status
                tenant
                tenantCode
                tenantName
                websiteUrl
            }
        }
    }
`;
export const SEARCH_ADVERTISERS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAdvertisers(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                affiliater
                affiliaterCode
                affiliaterName
                code
                createdAt
                id
                name
                status
                tenant
                tenantCode
                tenantName
                websiteUrl
            }
        }
    }
`;
export const UPDATE_ADVERTISER = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateAdvertiserInput!) {
        updateAdvertiser(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_ADVERTISER = (gql: any) => gql`
    mutation($id: ID!) {
        deleteAdvertiser(id: $id) {
            id
        }
    }
`;