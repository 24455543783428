export const CREATE_STATEVENT = (gql: any) => gql`
    mutation($data: CreateStateventInput!) {
        createStatevent(data: $data) {
            id
        }
    }
`;
export const GET_STATEVENT = (gql: any) => gql`
    query($id: ID!) {
        getStatevent(id: $id) {
            id
            type
            game
            gameName
            gameCode
            tenant
            tenantName
            tenantCode
            organization
            organizationName
            organizationCode
            data
            startTime
            endTime
        }
    }
`;
export const FIND_STATEVENTS = (gql: any) => gql`
    query findStatevents($offset: String, $limit: Int) {
        findStatevents(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                type
                gameName
                gameCode
                tenantName
                tenantCode
                organizationName
                organizationCode
                startTime
                endTime
            }
        }
    }
`;