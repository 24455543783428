import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {BaseCell} from "@gotombola/react-as/lib/types";

export function AdvertiserCell({row: { advertiser, advertiserCode, advertiserName }}: AdvertiserCellProps): ReactElement {
    const history = useHistory();
    const advertiserUri = `/advertisers/${advertiser}`;

    const onAdvertiserClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(advertiserUri);
        },
        [history, advertiserUri],
    );

    return (
        <>
            <a href={advertiserUri} onClick={onAdvertiserClick} className={'hover:underline'}>
                <b>{advertiserCode}</b> - {advertiserName}
            </a>
        </>
    );
}

export type AdvertiserCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default AdvertiserCell;