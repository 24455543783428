export const CREATE_DSTUBSIZE = (gql: any) => gql`
    mutation($data: CreateDstubsizeInput!) {
        createDstubsize(data: $data) {
            id
        }
    }
`;
export const GET_DSTUBSIZE = (gql: any) => gql`
    query($id: ID!) {
        getDstubsize(id: $id) {
            id
            tenant
            code
            name
            definition
            tags
        }
    }
`;
export const FIND_DSTUBSIZES = (gql: any) => gql`
    query findDstubsizes($offset: String, $limit: Int) {
        findDstubsizes(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                definition
                tags
            }
        }
    }
`;