const t =  {
    icon: 'fa-trophy',
    menus: {
        main: {section: 'settings', priority: 124},
    },
    new: {
        attributes: [
            'code', 'name', 'tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$code!', '$name!',
                ]],
                ['block', [
                    '$tenant!',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'code', 'name',
            'tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$code!', '$name!',
                ]],
                ['block', [
                    '$tenant!',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [ 'code', 'name', 'tenantCode' ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 1, width: 120} as any,
            {id: 'name', label: 'column_name_label', flex: 1, width: 120} as any,
            {id: 'tenant', label: 'column_tenant_label', flex: 1, width: 120, component: 'tenant'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;