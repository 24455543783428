const t = {
    icon: 'fa-building',
    menus: {
        main: {section: 'customers', priority: 20},
    },
    new: {
        attributes: [
            'name', 'campaign', 'partner', 'locale', 'externalReference', 'zipCode', 'complement', 'state', 'street',
            'city',
            'country', 'presentation', 'description',
            'logoImage', 'qrcodeLogoImage', 'logoAltImage', 'mainImage', 'bannerImage', 'mobileBannerImage', 'tabletBannerImage', 'banner2Image', 'backgroundImage',
            'flyerImage', 'twitterShareImage', 'facebookShareImage',
            'websiteUrl', 'twitterUrl', 'facebookUrl', 'instagramUrl', 'linkedinUrl', 'youtubeUrl', 'pinterestUrl',
            'tumblrUrl', 'flickrUrl', 'redditUrl', 'tiktokUrl', 'vimeoUrl', 'snapchatUrl',
            'backgroundVideoUrl', 'bannerVideoUrl', 'banner2VideoUrl', 'flyerVideoUrl', 'mainVideoUrl',
            'mainShortVideoUrl',  'mainLongVideoUrl', 'faviconImage', 'appleTouchImage',
            'overline', 'publicEmail', 'publicPhone', 'nin', 'fullName', 'shortName', 'legalName', 'domain', 'mainTheme', 'mainDfontfamily',
            'owner', 'tenant', 'presets',
            'varColorNavPrimary', 'varColorNavSecondary', 'varColorPrimary', 'varColorSecondary', 'varColorPanelPrimary', 'varColorPanelSecondary',
            'varColorTextPrimary', 'varColorTextSecondary', 'varColorBorderPrimary', 'varColorBorderSecondary', 'varColorLogo', 'varColorTitle',
            'varColorChoicePrimary', 'varColorChoiceSecondary', 'varColorFooterPrimary', 'varColorFooterSecondary', 'varColorHeading',
            'stripeCustomer',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$owner!', '$tenant!', '$organizationPresets', '$stripeCustomer',
                ]],
                ['block', [
                    ['column', [
                        '$varColorPrimary',
                        '$varColorSecondary',
                        '$varColorNavPrimary',
                        '$varColorNavSecondary',
                        '$varColorTextPrimary',
                        '$varColorTextSecondary',
                        '$varColorBorderPrimary',
                        '$varColorBorderSecondary',
                        '$varColorPanelPrimary',
                        '$varColorPanelSecondary',
                        '$varColorChoicePrimary',
                        '$varColorChoiceSecondary',
                        '$varColorFooterPrimary',
                        '$varColorFooterSecondary',
                        '$varColorTitle',
                        '$varColorHeading',
                        '$varColorLogo',
                    ]],
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'campaign', 'partner', 'locale', 'externalReference', 'zipCode', 'complement', 'state', 'street',
            'city',
            'country', 'presentation', 'description',
            'logoImage', 'qrcodeLogoImage', 'logoAltImage', 'mainImage', 'bannerImage', 'mobileBannerImage', 'tabletBannerImage', 'banner2Image', 'backgroundImage',
            'flyerImage', 'twitterShareImage', 'facebookShareImage',
            'websiteUrl', 'twitterUrl', 'facebookUrl', 'instagramUrl', 'linkedinUrl', 'youtubeUrl', 'pinterestUrl',
            'tumblrUrl', 'flickrUrl', 'redditUrl', 'tiktokUrl', 'vimeoUrl', 'snapchatUrl',
            'backgroundVideoUrl', 'bannerVideoUrl', 'banner2VideoUrl', 'flyerVideoUrl', 'mainVideoUrl',
            'mainShortVideoUrl',  'mainLongVideoUrl', 'faviconImage', 'appleTouchImage',
            'overline', 'publicEmail', 'publicPhone', 'nin', 'fullName', 'shortName', 'legalName', 'domain', 'mainTheme', 'mainDfontfamily',
            'varColorNavPrimary', 'varColorNavSecondary', 'varColorPrimary', 'varColorSecondary', 'varColorPanelPrimary', 'varColorPanelSecondary',
            'varColorTextPrimary', 'varColorTextSecondary', 'varColorBorderPrimary', 'varColorBorderSecondary', 'varColorLogo', 'varColorTitle',
            'varColorChoicePrimary', 'varColorChoiceSecondary', 'varColorFooterPrimary', 'varColorFooterSecondary', 'varColorHeading',
            'stripeCustomer',
        ],
        content: [
            ['row', [
                ['tabs', [
                    ['edit_organization_form_presentation_title', [
                        '!$name!',
                        '$code:readonly!',
                        '$overline',
                        '$presentation',
                        '$description',
                        '$street',
                        '$complement',
                        '$zipCode',
                        '$city',
                        '$state',
                        '$country',
                        '$locale',
                        '$publicEmail',
                        '$publicPhone',
                        '$stripeCustomer',
                    ]],
                    ['edit_organization_form_images_title', [
                        '$logoImage',
                        '$qrcodeLogoImage',
                        '$logoAltImage',
                        '$bannerImage',
                        '$mobileBannerImage',
                        '$tabletBannerImage',
                        '$faviconImage',
                        '$appleTouchImage',
                        '$banner2Image',
                        '$mainImage',
                        '$backgroundImage',
                        '$flyerImage',
                        '$twitterShareImage',
                        '$facebookShareImage',
                    ]],
                    ['edit_organization_form_videos_title', [
                        '$backgroundVideoUrl',
                        '$bannerVideoUrl',
                        '$banner2VideoUrl',
                        '$flyerVideoUrl',
                        '$mainVideoUrl',
                        '$mainShortVideoUrl',
                        '$mainLongVideoUrl',
                    ]],
                ]],
                ['tabs', [
                    ['edit_organization_form_parameters_title', [
                        '$nin',
                        '$fullName',
                        '$shortName',
                        '$legalName',
                        '$owner',
                        '$externalReference',
                        '$tenant',
                        '$partner',
                        '$campaign',
                        '$domain',
                        '$mainTheme',
                        '$mainDfontfamily',
                    ]],
                    ['edit_organization_form_socials_title', [
                        '$websiteUrl',
                        '$facebookUrl',
                        '$twitterUrl',
                        '$youtubeUrl',
                        '$instagramUrl',
                        '$linkedinUrl',
                        '$pinterestUrl',
                        '$flickrUrl',
                        '$vimeoUrl',
                        '$tumblrUrl',
                        '$tiktokUrl',
                        '$snapchatUrl',
                        '$redditUrl',
                    ]],
                    ['edit_organization_form_colors_title', [
                        ['column', [
                            '$varColorPrimary',
                            '$varColorSecondary',
                            '$varColorNavPrimary',
                            '$varColorNavSecondary',
                            '$varColorTextPrimary',
                            '$varColorTextSecondary',
                            '$varColorBorderPrimary',
                            '$varColorBorderSecondary',
                            '$varColorPanelPrimary',
                            '$varColorPanelSecondary',
                            '$varColorChoicePrimary',
                            '$varColorChoiceSecondary',
                            '$varColorFooterPrimary',
                            '$varColorFooterSecondary',
                            '$varColorTitle',
                            '$varColorHeading',
                            '$varColorLogo',
                        ]],
                    ]],
                ]],
            ]],
            '$submit',
        ]
    },
    display: {
        toolbar: true,
        tabs: [
            {name: 'infos'},
            {name: 'users'},
            {name: 'games'},
            {name: 'integrations'},
            {name: 'sales'},
            {name: 'tickets'},
            {name: 'versions'},
        ]
    },
    list: {
        columns: [
            {id: 'logoImage', label: 'column_logoimage_label', flex: 0, width: 110, render: 'image'} as any,
            {id: 'createdAt', 'label': 'column_createdat_label', flex: 0, width: 160, component: 'date'},
            {id: 'code', label: 'column_code_label', flex: 0, width: 100} as any,
            {id: 'name', label: 'column_name_label'},
            {id: 'owner', label: 'column_owner_label', component:'organization_owner'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'go_public_page', 'delete'],
    },
    types: {
        oversion: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    {id: 'logoImage', label: 'column_logoimage_label', flex: 0, width: 110, render: 'image'} as any,
                    {id: 'createdAt', 'label': 'column_createdat_label', flex: 0, width: 150, component: 'date'},
                    {id: 'code', label: 'column_code_label', flex: 0, width: 100} as any,
                    {id: 'type', label: 'column_type_label', flex: 0, width: 100} as any,
                    {id: 'name', label: 'column_name_label'},
                    {id: 'ownerEmail', label: 'column_owner_label'},
                ],
                globalActions: ['add'],
                actions: ['display', 'edit', 'delete'],
            },
        },
    },
};

export default t;