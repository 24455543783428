const t = {
    icon: 'fa-user-tie',
    menus: {
        main: {section: 'customers', priority: 30},
    },
    new: {
        attributes: [
            'tenant', 'email', 'lastName', 'firstName', 'password', 'role', 'stripeCustomer',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$email!', '$lastName', '$firstName', '$tenant!', '$password!', '$role!', '$stripeCustomer',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'email', 'lastName', 'firstName', 'role', 'stripeCustomer',
        ],
        content: [
            ['row', [
                ['block', [
                    '$code:readonly!', '!$email!', '$lastName', '$firstName', '$role', '$tenant:readonly!', '$stripeCustomer',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'code', 'firstName', 'lastName', 'email', 'role', 'tenantCode', 'stripeCustomer',
        ],
    },
    list: {
        icon: 'fa-users',
        columns: [
            {id: 'code', label: 'column_code_label'},
            {id: 'email', label: 'column_email_label'},
            {id: 'lastName', label: 'column_lastname_label'},
            {id: 'firstName', label: 'column_firstname_label'},
            {id: 'role', label: 'column_role_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'go_public_page', 'delete'],
    },
};

export default t;