import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {BaseCell} from "@gotombola/react-as/lib/types";

export function RegistrationCell({row: { registration }}: RegistrationCellProps): ReactElement {
    const history = useHistory();
    const registrationUri = `/registrations/${registration}`;

    const onRegistrationClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(registrationUri);
        },
        [history, registrationUri],
    );

    return (
        <>
            <a href={registrationUri} onClick={onRegistrationClick} className={'hover:underline'}>
                {registration}
            </a>
        </>
    );
}

export type RegistrationCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default RegistrationCell;