const t = {
    icon: 'fa-users-rays',
    menus: {
        main: {section: 'ads', priority: 10},
    },
    new: {
        attributes: [
            'name', 'code', 'tenant', 'appleTouchImage', 'banner2Image', 'bannerImage', 'mobileBannerImage', 'tabletBannerImage', 'faviconImage', 'logoAltImage', 'logoImage', 'mainImage', 'websiteUrl',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$tenant!', '$websiteUrl',
                ]],
                ['block', [
                    '$appleTouchImage', '$banner2Image', '$bannerImage', '$mobileBannerImage', '$tabletBannerImage', '$faviconImage', '$logoAltImage', '$logoImage', '$mainImage',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'code', 'tenant', 'appleTouchImage', 'banner2Image', 'bannerImage', 'mobileBannerImage', 'tabletBannerImage', 'faviconImage', 'logoAltImage', 'logoImage', 'mainImage', 'websiteUrl',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$tenant!', '$websiteUrl',
                ]],
                ['block', [
                    '$appleTouchImage', '$banner2Image', '$bannerImage', '$mobileBannerImage', '$tabletBannerImage', '$faviconImage', '$logoAltImage', '$logoImage', '$mainImage',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'name', 'id', 'code', 'tenant', 'tenantCode', 'tenantName', 'websiteUrl',
        ],
    },
    list: {
        columns: [
            {id: 'createdAt', label: 'column_createdat_label', component: 'date'},
            {id: 'name', label: 'column_name_label', flex: 1} as any,
            {id: 'code', label: 'column_code_label', flex: 1} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;