const t = {
    icon: 'fa-file-code',
    menus: {
        main: {section: 'settings2', priority: 55},
    },
    new: {
        attributes: [
            'tenant', 'partner', 'organization', 'game', 'user', 'type', 'key', 'data',
            'autoStart', 'autoDelete', 'startCallbackUrl', 'completeCallbackUrl', 'errorCallbackUrl', 'abortCallbackUrl'
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$jobType!', '$data', '$key', '$autoStart', '$autoDelete', '$startCallbackUrl',
                    '$completeCallbackUrl', '$errorCallbackUrl', '$abortCallbackUrl',
                ]],
                ['block', [
                    '$partner', '$organization', '$game', '$user',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'status', 'startCallbackUrl', 'completeCallbackUrl', 'errorCallbackUrl', 'abortCallbackUrl', 'data', 'report',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant:readonly!', '$jobType:readonly!', '$data', '$key:readonly', '$autoStart:readonly', '$autoDelete:readonly', '$startCallbackUrl',
                    '$completeCallbackUrl', '$errorCallbackUrl', '$abortCallbackUrl',
                ]],
                ['block', [
                    '$report',
                    '$partner:readonly', '$organization:readonly', '$game:readonly', '$user:readonly',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        body: true,
        properties: [
            'id', 'status', 'type', ['progress', 'ratio'],
            'itemsCount', 'toCreateItemsCount', 'toUpdateItemsCount', 'toDeleteItemsCount', 'toNochangeItemsCount',
            'toIgnoreItemsCount', 'processedItemsCount', 'processedToCreateItemsCount', 'processedToUpdateItemsCount',
            'processedToDeleteItemsCount', 'processedToNochangeItemsCount', 'processedToIgnoreItemsCount',
            ['toCreateItemsRatio', 'ratio'], ['toUpdateItemsRatio', 'ratio'], ['toDeleteItemsRatio', 'ratio'], ['toNochangeItemsRatio', 'ratio'], ['toIgnoreItemsRatio', 'ratio'],
            ['processedItemsProgress', 'ratio'], ['processedToCreateItemsProgress', 'ratio'],
            ['processedToUpdateItemsProgress', 'ratio'], ['processedToDeleteItemsProgress', 'ratio'],
            ['processedToNochangeItemsProgress', 'ratio'], ['processedToIgnoreItemsProgress', 'ratio'],
        ],
    },
    list: {
        columns: [
            {id: 'status', label: 'column_status_label'},
            {id: 'type', label: 'column_type_label'},
            {id: 'progress', type: 'number', label: 'column_progress_label', flex: 0, width: 90, format: (v: any) => v ? `${Math.round(v * 10000) / 100}%` : undefined},
            {id: 'gameCode', label: 'column_game_code_label'},
            {id: 'gameName', label: 'column_game_label'},
            {id: 'organizationCode', label: 'column_organization_code_label'},
            {id: 'organizationName', label: 'column_organization_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;