export const CREATE_CAMPAIGN = (gql: any) => gql`
    mutation($data: CreateCampaignInput!) {
        createCampaign(data: $data) {
            id
            code
        }
    }
`;
export const FIND_CAMPAIGNS = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findCampaigns(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                slug
                status
                createdAt
                updatedAt
                offer
                offerName
                offerCode
                partnerName
                publicPageShortUrl
            }
        }
    }
`;
export const GET_CAMPAIGN = (gql: any) => gql`
    query($id: ID!) {
        getCampaign(id: $id) {
            id
            code
            name
            tenant
            tenantCode
            slug
            type
            offer
            offerName
            offerCode
            partner
            partnerCode
            partnerName
            template
            createdAt
            updatedAt
            publicPageShortUrl
            mainTheme
            mainDfontfamily
            offerParams {
                name
                type
                value
                intValue
                floatValue
                booleanValue
            }
            stripeCustomer
        }
    }
`;
export const SEARCH_CAMPAIGNS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchCampaigns(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                slug
                status
                createdAt
                updatedAt
                offer
                offerName
                offerCode
                partnerName
                partner
                partnerCode
                publicPageShortUrl
            }
        }
    }
`;