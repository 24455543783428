export const CREATE_WCONFIGURATION = (gql: any) => gql`
    mutation($data: CreateWconfigurationInput!) {
        createWconfiguration(data: $data) {
            id
        }
    }
`;
export const GET_WCONFIGURATION = (gql: any) => gql`
    query($id: ID!) {
        getWconfiguration(id: $id) {
            id
            status
            name
            description
            wdefinition
            game
            gameCode
            key
            params
            type
            startAt
            endAt
        }
    }
`;
export const FIND_WCONFIGURATIONS = (gql: any) => gql`
    query findWconfigurations($offset: String, $limit: Int) {
        findWconfigurations(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                description
                status
                key
                game
                gameCode
                type
                startAt
                endAt
            }
        }
    }
`;