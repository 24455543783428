export const CREATE_DPAPERCOLOR = (gql: any) => gql`
    mutation($data: CreateDpapercolorInput!) {
        createDpapercolor(data: $data) {
            id
        }
    }
`;
export const GET_DPAPERCOLOR = (gql: any) => gql`
    query($id: ID!) {
        getDpapercolor(id: $id) {
            id
            tenant
            code
            name
            value
            tags
        }
    }
`;
export const FIND_DPAPERCOLORS = (gql: any) => gql`
    query findDpapercolors($offset: String, $limit: Int) {
        findDpapercolors(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                value
                tags
            }
        }
    }
`;