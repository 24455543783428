export const CREATE_SPONSORING = (gql: any) => gql`
    mutation($data: CreateSponsoringInput!) {
        createSponsoring(data: $data) {
            id
        }
    }
`;
export const GET_SPONSORING = (gql: any) => gql`
    query($id: ID!) {
        getSponsoring(id: $id) {
            id
            code
            name
            game
            gameCode
            gameName
            partner
            partnerCode
            partnerName
            organization
            organizationCode
            organizationName
            locale
            country
            level
            mainImage {
                url
                available
            }
            thumbnailImage {
                url
                available
            }
            logoImage {
                url
                available
            }
            image1Image {
                url
                available
            }
            image2Image {
                url
                available
            }
            image3Image {
                url
                available
            }
            image4Image {
                url
                available
            }
            image5Image {
                url
                available
            }
            image6Image {
                url
                available
            }
            image7Image {
                url
                available
            }
            image8Image {
                url
                available
            }
            image9Image {
                url
                available
            }
            description
            websiteUrl
        }
    }
`;
export const FIND_SPONSORINGS = (gql: any) => gql`
    query findSponsorings($offset: String, $limit: Int) {
        findSponsorings(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                publicToken
                name
                game
                gameCode
                gameName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                description
                websiteUrl
                mainImage {
                    url
                    available
                }
                image1Image {
                    url
                    available
                }
                image2Image {
                    url
                    available
                }
                image3Image {
                    url
                    available
                }
                image4Image {
                    url
                    available
                }
                image5Image {
                    url
                    available
                }
                image6Image {
                    url
                    available
                }
                image7Image {
                    url
                    available
                }
                image8Image {
                    url
                    available
                }
                image9Image {
                    url
                    available
                }
            }
        }
    }
`;
export const SEARCH_SPONSORINGS = (gql: any) => gql`
    query searchSponsorings($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchSponsorings(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                game
                gameCode
                gameName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                description
                websiteUrl
                mainImage {
                    url
                    available
                }
                image1Image {
                    url
                    available
                }
                image2Image {
                    url
                    available
                }
                image3Image {
                    url
                    available
                }
                image4Image {
                    url
                    available
                }
                image5Image {
                    url
                    available
                }
                image6Image {
                    url
                    available
                }
                image7Image {
                    url
                    available
                }
                image8Image {
                    url
                    available
                }
                image9Image {
                    url
                    available
                }
            }
        }
    }
`;
export const SEARCH_GAME_SPONSORINGS = (gql: any) => gql`
    query searchGameSponsorings($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchSponsorings(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                code
                name
                gameCode
                gameName
                organizationCode
                organizationName
                rank
                description
                websiteUrl
                image1Image {
                    url
                    available
                }
                image2Image {
                    url
                    available
                }
                image3Image {
                    url
                    available
                }
                image4Image {
                    url
                    available
                }
                image5Image {
                    url
                    available
                }
                image6Image {
                    url
                    available
                }
                image7Image {
                    url
                    available
                }
                image8Image {
                    url
                    available
                }
                image9Image {
                    url
                    available
                }
            }
        }
    }
`;
export const FIND_GAME_SPONSORINGS = (gql: any) => gql`
    query findGameSponsorings($id: String!, $offset: String, $limit: Int) {
        findGameSponsorings(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                gameCode
                gameName
                organizationCode
                organizationName
                rank
                description
                websiteUrl
                image1Image {
                    url
                    available
                }
                image2Image {
                    url
                    available
                }
                image3Image {
                    url
                    available
                }
                image4Image {
                    url
                    available
                }
                image5Image {
                    url
                    available
                }
                image6Image {
                    url
                    available
                }
                image7Image {
                    url
                    available
                }
                image8Image {
                    url
                    available
                }
                image9Image {
                    url
                    available
                }
            }
        }
    }
`;
export const MOVE_GAME_SPONSORING = (gql: any) => gql`
    mutation moveGameSponsoring($parentId: String!, $id: ID!, $direction: String) {
        moveGameSponsoring(parentId: $parentId, id: $id, direction: $direction) {
            id
        }
    }
`;
export const UPDATE_SPONSORING = (gql: any) => gql`
    mutation updateSponsoring($id: ID!, $data: UpdateSponsoringInput!) {
        updateSponsoring(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_SPONSORING = (gql: any) => gql`
    mutation deleteSponsoring($id: ID!) {
        deleteSponsoring(id: $id) {
            id
        }
    }
`;
