const t = {
    icon: 'fa-image',
    menus: {
        main: {section: 'medias', priority: 20},
    },
    new: {
        attributes: [
            'game', 'tags', 'mainImage', 'desktopImage', 'tabletImage', 'mobileImage', 'tvImage', 'emailImage',
            'title', 'description',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$title!', '$description', '$game!', '$imageTags',
                ]],
                ['block', [
                    '$mainImage', '$desktopImage', '$tabletImage', '$mobileImage', '$tvImage', '$emailImage',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'game', 'tags', 'mainImage', 'desktopImage', 'tabletImage', 'mobileImage', 'tvImage', 'emailImage',
            'title', 'description',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$title!', '$description', '$game!', '$imageTags',
                ]],
                ['block', [
                    '$mainImage', '$desktopImage', '$tabletImage', '$mobileImage', '$tvImage', '$emailImage',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'gameName', 'organizationName', 'status', 'title', 'description', 'tags',
        ],
    },
    list: {
        columns: [
            {id: 'mainImage', label: 'column_main_image_label', render: 'image', flex: 0, width: 200},
            {id: 'game', label: 'column_game_label', flex: 1, width: 120, component: 'game'} as any,
            {id: 'title', label: 'column_overline_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;