const t =  {
    menus: {
        main: {section: 'settings3', priority: 98, icon: 'fa-file-code'},
    },
    new: {
        attributes: [
            'tenant', 'definition', 'params', 'details', 'automation',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$runDefinition', '$params', '$details', '$automation',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'tenant', 'definition', 'params', 'details', 'automation',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$runDefinition', '$params', '$details', '$automation',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: ['status', 'tenant', 'definition', 'params', 'details', 'processedItems', 'erroredItems', 'succeededItems', 'automation', 'result'],
    },
    list: {
        columns: [
            {id: 'createdAt', label: 'column_createdat_label', component: 'date'},
            {id: 'status', label: 'column_status_label', flex: 1, width: 120} as any,
            {id: 'game', label: 'column_game_label', flex: 1, width: 120, component: 'game'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;