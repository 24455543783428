const t = {
    icon: 'fa-user-tie',
    menus: {
        main: {section: 'activity', priority: 30},
    },
    new: {
        attributes: [
            'code', 'name', 'stripeCustomer',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$code!', '$name!', '$stripeCustomer',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'code', 'name', 'stripeCustomer',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$code!', '$name!', '$stripeCustomer',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: [
            'id', 'code', 'name', 'stripeCustomer',
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 0, width: 150} as any,
            {id: 'name', label: 'column_name_label'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'go_public_page', 'delete']
    },
    types: {
        tcountry: {
            plural: 'tcountries',
            new: {},
            list: {
                columns: [
                    { id: 'mainImage', label: 'column_main_image_label', render: 'image', flex: 0, width: 200 },
                    { id: 'code', label: 'column_code_label' },
                    { id: 'name', label: 'column_name_label' },
                    { id: 'rank', label: 'column_rank_label' },
                ],
                globalActions: ['add'],
                actions: ['up', 'down', 'display', 'edit', 'delete'],
            },
        },
        tlocale: {
            new: {},
            list: {
                columns: [
                    { id: 'mainImage', label: 'column_main_image_label', render: 'image', flex: 0, width: 200 },
                    { id: 'code', label: 'column_code_label' },
                    { id: 'name', label: 'column_name_label' },
                    { id: 'rank', label: 'column_rank_label' },
                ],
                globalActions: ['add'],
                actions: ['up', 'down', 'display', 'edit', 'delete'],
            },
        },
    }
};

export default t;