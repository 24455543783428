export const CREATE_AFFILIATER = (gql: any) => gql`
    mutation($data: CreateAffiliaterInput!) {
        createAffiliater(data: $data) {
            id
        }
    }
`;
export const GET_AFFILIATER = (gql: any) => gql`
    query($id: ID!) {
        getAffiliater(id: $id) {
            code
            createdAt
            id
            name
            status
            tenant
            tenantCode
            tenantName
            updatedAt
            appleTouchImage {
                available
                url
            }
            banner2Image {
                available
                url
            }
            bannerImage {
                available
                url
            }
            mobileBannerImage {
                available
                url
            }
            tabletBannerImage {
                available
                url
            }
            faviconImage {
                available
                url
            }
            logoAltImage {
                available
                url
            }
            logoImage {
                available
                url
            }
            mainImage {
                available
                url
            }
            privateToken
            publicToken
            websiteUrl
        }
    }
`;
export const FIND_AFFILIATERS = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findAffiliaters(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                code
                createdAt
                id
                name
                status
                tenant
                tenantCode
                tenantName
                websiteUrl
            }
        }
    }
`;
export const SEARCH_AFFILIATERS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAffiliaters(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                code
                createdAt
                id
                name
                status
                tenant
                tenantCode
                tenantName
                websiteUrl
            }
        }
    }
`;
export const UPDATE_AFFILIATER = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateAffiliaterInput!) {
        updateAffiliater(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_AFFILIATER = (gql: any) => gql`
    mutation($id: ID!) {
        deleteAffiliater(id: $id) {
            id
        }
    }
`;