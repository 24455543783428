const t = {
    icon: 'fa-user-tag',
    menus: {
        main: { section: 'settings', priority: 45 },
    },
    new: {
        attributes: [
            'game', 'firstName', 'lastName', 'sellergroup', 'email', 'phone', 'country', 'locale', 'features', 'stripeCustomer',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$game!', '$firstName!', '$lastName!', '$email', '$phone',
                ]],
                ['block', [
                    '$sellergroup', '$locale', '$country', '$features', '$stripeCustomer',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'game', 'firstName', 'lastName', 'sellergroup', 'email', 'phone', 'country', 'locale', 'features', 'stripeCustomer',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$game!', '$firstName!', '$lastName!', '$email', '$phone',
                ]],
                ['block', [
                    '$sellergroup', '$locale', '$country', '$features', '$stripeCustomer',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: [
            'id', 'gameCode', 'gameName', 'sellergroup', 'sellergroupCode', 'sellergroupName', 'lastName', 'firstName', 'email', 'phone', 'stripeCustomer',
        ],
    },
    list: {
        columns: [
            { id: 'game', label: 'column_game_code_label', flex: 0, width: 350, component: 'game' },
            { id: 'sellergroup', label: 'column_sellergroup_code_label', component: 'seller_sellergroup' },
            { id: 'email', label: 'column_email_label' },
            { id: 'lastName', label: 'column_lastname_label' },
            { id: 'firstName', label: 'column_firstname_label' },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'go_public_page', 'delete']
    },
    types: {
        book: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    { id: 'code', label: 'column_code_label', flex: 0, width: 100 } as any,
                    { id: 'game', label: 'column_game_label', flex: 0, width: 300 } as any,
                    { id: 'paymentStatus', label: 'column_payment_status_label' },
                    { id: 'sellergroup', label: 'column_sellergroup_code_label' },
                    { id: 'customCode', label: 'column_customcode_label' },
                    { id: 'attributionStatus', label: 'column_attribution_status_label' },
                ],
                globalActions: ['add'],
                actions: ['display', 'edit', 'go_public_page', 'delete'],
            },
        }
    }
};

export default t;