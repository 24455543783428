const t = {
    menus: {
        main: {section: 'settings', priority: 75, icon: 'fa-dice'},
    },
    new: {
        attributes: [
            'name', 'game', 'type', 'code', 'bookTickets', 'ticketPriceAmount', 'ticketPriceCurrency',
            'presentation', 'description',
            'plannedDrawnAt', 'plannedStartedAt', 'plannedClosedAt',
            'logoImage', 'mainCss', 'mainJs', 'mainImage', 'bannerImage', 'mobileBannerImage', 'tabletBannerImage',
            'websiteUrl', 'twitterUrl', 'facebookUrl', 'youtubeUrl',
            'mainVideoUrl',
            'faviconImage', 'appleTouchImage',
            'publicEmail', 'publicPhone', 'domain',
            'customPacks', 'customBonuses', 'customOwnerForm', 'notifyEmail', 'mainTheme',
            'agenda', 'infos', 'warning', 'locale', 'country',
            'varColorNavPrimary', 'varColorNavSecondary', 'varColorPrimary', 'varColorSecondary', 'varColorPanelPrimary', 'varColorPanelSecondary',
            'varColorTextPrimary', 'varColorTextSecondary', 'varColorBorderPrimary', 'varColorBorderSecondary', 'varColorLogo', 'varColorTitle',
            'varColorChoicePrimary', 'varColorChoiceSecondary', 'varColorFooterPrimary', 'varColorFooterSecondary', 'varColorHeading',
            'publicPageModules', 'publicPageModulesData', 'publicPageModulesCustomRaw', 'publicPageModulesDiff', 'bonusMessage',
            'publicPageEffects', 'publicPageBackgroundStyle', 'publicPageBackgroundCustom', 'publicPageBackgroundColor', 'publicPageBackgroundImage',
            'publicPageBackgroundTexture', 'publicPageBackgroundVideoUrl',
            'seoLang', 'seoKeywords', 'seoTitle', 'seoDescription', 'seoSubject', 'seoAbstract', 'seoAuthor',
        ],
        content: [
            ['tabs', [
                ['edit_game_form_general_title', [
                    ['row', [
                        ['tabs', [
                            ['edit_game_form_presentation_title', [
                                '$game!', '$code!', '$type!', '$presentation', '$description',
                                '$locale', '$country',
                                '$publicEmail', '$publicPhone',
                            ]],
                            ['edit_game_form_images_title', [
                                '$logoImage', '$bannerImage', '$mobileBannerImage', '$tabletBannerImage', '$faviconImage', '$appleTouchImage', '$publicPageBackgroundImage',
                            ]],
                            ['edit_game_form_videos_title', [
                                '$mainVideoUrl',
                                '$backgroundVideoUrl',
                                '$bannerVideoUrl',
                                '$banner2VideoUrl',
                                '$flyerVideoUrl',
                                '$mainVideoUrl',
                                '$mainQuoteVideoUrl',
                                '$mainShortVideoUrl',
                                '$mainLongVideoUrl',
                                '$salePaidVideoUrl',
                                '$salePromisedVideoUrl',
                                '$winnerVideoUrl',
                                '$looserVideoUrl',
                                '$participantVideoUrl',
                                '$promiseReminderVideoUrl',
                                '$promiseLastReminderVideoUrl',
                            ]],
                            ['edit_game_form_seo_title', [
                                '$seoLang', '$seoKeywords', '$seoTitle', '$seoDescription', '$seoSubject', '$seoAbstract', '$seoAuthor',
                            ]],
                        ]],
                        ['tabs', [
                            ['edit_game_form_fundraising_title', [
                                '$gameType', '$bookTickets', '$ticketPriceAmount', '$plannedTickets',
                                '$ticketPriceCurrency', '$domain', '$notifyEmail', '$mainTheme',
                            ]],
                            ['edit_game_form_advanced_title', [
                                '$nin', '$fullName', '$shortName', '$legalName',
                                '$plannedDrawnAt', '$plannedStartedAt', '$plannedClosedAt',
                                '$externalReference',
                                '$saleMandatoryOptInMessage',
                                '$mainQuote', '$mainQuoteTitle', '$mainQuoteImageTitle', '$mainQuoteImageSubtitle',
                                '$customPacks', '$customBonuses', '$customOwnerForm',
                                '$agenda', '$infos', '$warning',
                                '$publicPageModules', '$publicPageModulesData', '$publicPageModulesCustomRaw', '$publicPageModulesDiff', '$bonusMessage',
                                '$publicPageEffects', '$publicPageBackgroundStyle', '$publicPageBackgroundCustom', '$publicPageBackgroundColor',
                                '$publicPageBackgroundTexture', '$publicPageBackgroundVideoUrl',
                            ]],
                            ['edit_organization_form_socials_title', [
                                '$websiteUrl', '$facebookUrl', '$twitterUrl', '$youtubeUrl',
                                '$instagramUrl', '$linkedinUrl', '$pinterestUrl', '$flickrUrl', '$vimeoUrl', '$tumblrUrl',
                                '$tiktokUrl', '$snapchatUrl', '$redditUrl',
                            ]],
                        ]]
                    ]]
                ]],
                ['edit_game_form_style_title', [
                    ['row', [
                        ['tabs', [
                            ['edit_game_form_css_title', [
                                '$mainCss',
                            ]],
                            ['edit_game_form_colors_title', [
                                ['column', [
                                    '$varColorPrimary',
                                    '$varColorSecondary',
                                    '$varColorNavPrimary',
                                    '$varColorNavSecondary',
                                    '$varColorTextPrimary',
                                    '$varColorTextSecondary',
                                    '$varColorBorderPrimary',
                                    '$varColorBorderSecondary',
                                    '$varColorPanelPrimary',
                                    '$varColorPanelSecondary',
                                    '$varColorChoicePrimary',
                                    '$varColorChoiceSecondary',
                                    '$varColorFooterPrimary',
                                    '$varColorFooterSecondary',
                                    '$varColorTitle',
                                    '$varColorHeading',
                                    '$varColorLogo',
                                ]],
                            ]],
                        ]],
                    ]]
                ]],
                ['edit_game_form_code_title', [
                    ['row', [
                        '$mainJs',
                    ]]
                ]],
                ['edit_game_form_location_title', [
                    ['column', [
                        '$locationName',
                        '$locationPresentation',
                        '$locationDescription',
                        '$locationStreet',
                        '$locationComplement',
                        '$locationCity',
                        '$locationZipCode',
                        '$locationState',
                        '$locationCountry',
                        '$locationWebsiteUrl',
                        '$locationFacebookUrl',
                        '$locationPhone',
                        '$locationEmail',
                        '$locationLogoImage',
                        '$locationMainImage',
                        '$locationMainVideoUrl',
                        '$locationMainAudioUrl',
                        '$locationLatitude',
                        '$locationLongitude',
                        '$locationTwitterUrl',
                        '$locationYoutubeUrl',
                    ]]
                ]],
                ['edit_game_form_godfather_title', [
                    ['row', [
                        ['column', [
                            '$godfatherImage',
                            '$godfatherVideoUrl',
                            '$godfatherTitle',
                            '$godfatherSubtitle',
                        ]],
                        ['column', [
                            '$godfather2Image',
                            '$godfather2VideoUrl',
                            '$godfather2Title',
                            '$godfather2Subtitle',
                        ]],
                        ['column', [
                            '$godfather3Image',
                            '$godfather3VideoUrl',
                            '$godfather3Title',
                            '$godfather3Subtitle',
                        ]],
                    ]]
                ]]
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'type', 'game', 'offer', 'bookTickets', 'ticketPriceAmount', 'ticketPriceCurrency',
            'plannedTickets', 'suspended', 'locale',
            'country', 'public', 'private', 'permanent', 'presentation', 'description',
            'features', 'plannedDrawnAt', 'plannedStartedAt', 'plannedClosedAt',
            'saleMandatoryOptInMessage',
            'logoImage', 'logoAltImage', 'mainCss', 'mainJs', 'mainImage', 'bannerImage', 'mobileBannerImage', 'tabletBannerImage', 'banner2Image', 'backgroundImage',
            'flyerImage', 'twitterShareImage', 'facebookShareImage',
            'websiteUrl', 'twitterUrl', 'facebookUrl', 'instagramUrl', 'linkedinUrl', 'youtubeUrl', 'pinterestUrl',
            'tumblrUrl', 'flickrUrl', 'redditUrl', 'tiktokUrl', 'vimeoUrl', 'snapchatUrl',
            'backgroundVideoUrl', 'bannerVideoUrl', 'banner2VideoUrl', 'flyerVideoUrl', 'mainVideoUrl',
            'mainShortVideoUrl',  'mainLongVideoUrl', 'salePaidVideoUrl', 'salePromisedVideoUrl',
            'winnerVideoUrl',  'looserVideoUrl', 'participantVideoUrl', 'promiseReminderVideoUrl', 'promiseLastReminderVideoUrl',
            'mainQuote', 'mainQuoteImage', 'mainQuoteTitle', 'faviconImage', 'appleTouchImage',
            'overline', 'publicEmail', 'publicPhone', 'nin', 'fullName', 'shortName', 'legalName', 'domain',
            'termsPdfFile', 'customPacks', 'customBonuses', 'customOwnerForm', 'mainQuoteImageTitle', 'mainQuoteImageSubtitle', 'notifyEmail', 'mainTheme',
            'godfatherImage', 'godfatherVideoUrl', 'godfatherTitle', 'godfatherSubtitle', 'godfather2Image',
            'godfather2VideoUrl', 'godfather2Title', 'godfather2Subtitle', 'godfather3Image', 'godfather3VideoUrl',
            'godfather3Title', 'godfather3Subtitle', 'agenda', 'warning', 'locationMainImage', 'locationMainVideoUrl',
            'locationMainAudioUrl', 'locationLatitude', 'locationLongitude', 'locationName', 'locationCountry', 'locationZipCode',
            'locationStreet', 'locationComplement', 'locationCity', 'locationState', 'locationPhone', 'locationEmail',
            'locationWebsiteUrl', 'locationFacebookUrl', 'locationTwitterUrl', 'locationYoutubeUrl', 'mainQuoteVideoUrl',
            'locationPresentation', 'locationDescription', 'locationLogoImage', 'infos',
            'varColorNavPrimary', 'varColorNavSecondary', 'varColorPrimary', 'varColorSecondary', 'varColorPanelPrimary', 'varColorPanelSecondary',
            'varColorTextPrimary', 'varColorTextSecondary', 'varColorBorderPrimary', 'varColorBorderSecondary', 'varColorLogo', 'varColorTitle',
            'varColorChoicePrimary', 'varColorChoiceSecondary', 'varColorFooterPrimary', 'varColorFooterSecondary', 'varColorHeading',
            'publicPageModules', 'publicPageModulesData', 'publicPageModulesCustomRaw', 'publicPageModulesDiff', 'bonusMessage',
            'publicPageEffects', 'publicPageBackgroundStyle', 'publicPageBackgroundCustom', 'publicPageBackgroundColor', 'publicPageBackgroundImage',
            'publicPageBackgroundTexture', 'publicPageBackgroundVideoUrl',
            'seoLang', 'seoKeywords', 'seoTitle', 'seoDescription', 'seoSubject', 'seoAbstract', 'seoAuthor',
        ],
        content: [
            ['tabs', [
                ['edit_game_form_general_title', [
                    ['row', [
                        ['tabs', [
                            ['edit_game_form_presentation_title', [
                                '$name', '$code:readonly!', '$organization:readonly!', '$game:readonly!', '$overline', '$presentation', '$description', '$offer',
                                '$type!', '$locale', '$country', '$publicEmail', '$publicPhone', '$termsPdfFile',
                            ]],
                            ['edit_game_form_images_title', [
                                '$logoImage', '$logoAltImage', '$bannerImage', '$mobileBannerImage', '$tabletBannerImage', '$banner2Image', '$faviconImage', '$appleTouchImage',
                                '$mainImage', '$backgroundImage', '$flyerImage', '$twitterShareImage', '$facebookShareImage',
                                '$mainQuoteImage', '$publicPageBackgroundImage',
                            ]],
                            ['edit_game_form_videos_title', [
                                '$mainVideoUrl'
                            ]],
                            ['edit_game_form_seo_title', [
                                '$seoLang', '$seoKeywords', '$seoTitle', '$seoDescription', '$seoSubject', '$seoAbstract', '$seoAuthor',
                            ]],
                        ]],
                        ['tabs', [
                            ['edit_game_form_fundraising_title', [
                                '$gameType', '$permanent', '$bookTickets', '$ticketPriceAmount', '$plannedTickets',
                                '$ticketPriceCurrency', '$domain', '$notifyEmail', '$mainTheme',
                            ]],
                            ['edit_game_form_advanced_title', [
                                '$plannedDrawnAt', '$plannedStartedAt', '$plannedClosedAt',
                                '$customPacks', '$customBonuses', '$customOwnerForm',
                                '$agenda', '$infos', '$warning',
                                '$publicPageModules', '$publicPageModulesData', '$publicPageModulesCustomRaw', '$publicPageModulesDiff', '$bonusMessage',
                                '$publicPageEffects', '$publicPageBackgroundStyle', '$publicPageBackgroundCustom', '$publicPageBackgroundColor',
                                '$publicPageBackgroundTexture', '$publicPageBackgroundVideoUrl',

                            ]],
                            ['edit_organization_form_socials_title', [
                                '$websiteUrl', '$facebookUrl', '$twitterUrl', '$youtubeUrl',
                            ]],
                        ]]
                    ]]
                ]],
                ['edit_game_form_style_title', [
                    ['row', [
                        ['tabs', [
                            ['edit_game_form_css_title', [
                                '$mainCss',
                            ]],
                            ['edit_game_form_colors_title', [
                                ['column', [
                                    '$varColorPrimary',
                                    '$varColorSecondary',
                                    '$varColorNavPrimary',
                                    '$varColorNavSecondary',
                                    '$varColorTextPrimary',
                                    '$varColorTextSecondary',
                                    '$varColorBorderPrimary',
                                    '$varColorBorderSecondary',
                                    '$varColorPanelPrimary',
                                    '$varColorPanelSecondary',
                                    '$varColorChoicePrimary',
                                    '$varColorChoiceSecondary',
                                    '$varColorFooterPrimary',
                                    '$varColorFooterSecondary',
                                    '$varColorTitle',
                                    '$varColorHeading',
                                    '$varColorLogo',
                                ]],
                            ]],
                        ]],
                    ]]
                ]],
                ['edit_game_form_code_title', [
                    ['row', [
                        '$mainJs',
                    ]]
                ]]
            ]],
            '$submit',
        ],
    },
    display: {
        properties: ['code', 'name', 'gameName', 'type', 'locale'],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 0, width: 200} as any,
            {id: 'type', label: 'column_type_label', flex: 0, width: 100} as any,
            {id: 'game', label: 'column_name_label',  component: 'game'} as any,
            {id: 'ticketPriceAmount', type: 'number', label: 'column_ticket_price_label', flex: 0, width: 160, component: 'ticket_price_amount'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'go_public_page', 'delete'],
    },
};

export default t;