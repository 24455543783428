const t = {
    icon: 'fa-bullhorn',
    menus: {
        main: { section: 'activity', priority: 45 },
    },
    new: {
        attributes: [
            'name', 'slug', 'tenant', 'description', 'type', 'template', 'offer', 'partner', 'locale', 'country', 'stripeCustomer',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$slug!', '$tenant!', '$type!',
                ]],
                ['block', [
                    '$description', '$offer', '$partner', '$template', '$locale', '$country', '$stripeCustomer',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'slug', 'description', 'type', 'template', 'offer', 'partner', 'locale', 'country', 'stripeCustomer',
        ],
        content: [
            ['row', [
                ['block', [
                    '$code!', '!$name!', '$slug!', '$tenant!', '$type!',
                ]],
                ['block', [
                    '$description', '$offer', '$partner', '$template', '$locale', '$country', '$stripeCustomer',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        body: true,
        properties: [
            'code', 'name', 'slug', 'publicPageUrl', 'offerCode', 'offerName', 'offer', 'partnerCode', 'partner', 'partnerName', 'stripeCustomer',
        ],
    },
    list: {
        columns: [
            { id: 'code', label: 'column_code_label', flex: 0, width: 100 } as any,
            { id: 'slug', label: 'column_slug_label', flex: 0, width: 300 } as any,
            { id: 'name', label: 'column_name_label' },
            { id: 'offer', label: 'column_offer_label', component: 'offer' },
            { id: 'partner', label: 'column_partner_label', flex: 0, width: 120, component: 'partner' },

        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'go_public_page', 'delete'],
    }
};

export default t;