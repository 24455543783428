const t = {
    icon: 'fa-key',
    menus: {
        main: {section: 'customers', priority: 55},
    },
    new: {
        attributes: [
            'code', 'name', 'platform',
        ],
        content: [
            ['row', [
                ['block', [
                    '$code!', '$name!',
                ]],
                ['block', [
                    '$platform',
                ]],
            ]],
            '$submit',
        ]
    },
    edit: {
        attributes: [
            'code', 'name', 'platform', 'status',
        ],
        content: [
            ['row', [
                ['block', [
                    '$code', '$name',
                ]],
                ['block', [
                    '$status', '$platform',
                ]],
            ]],
            '$submit',
        ]
    },
    display: {
        properties: [
            'id', 'code', 'name', 'token', 'secretKey', 'platformEmail', 'platformName', 'platformCode', 'partnerName', 'partnerCode', 'organizationName', 'organizationCode',
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 1} as any,
            {id: 'status', label: 'column_status_label', flex: 1} as any,
            {id: 'name', label: 'column_name_label', flex: 1} as any,
            {id: 'platform', label: 'column_platform_label', flex: 1, component: 'platform'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;