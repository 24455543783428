const t = {
    icon: 'fa-hands-bound',
    menus: {
        main: {section: 'settings', priority: 100},
    },
    new: {
        attributes: [
            'type', 'mode', 'subtype', 'game', 'name', 'color', 'overline', 'description', 'code', 'mainVideoUrl', 'mainAudioUrl',
            'nickName', 'firstName', 'lastName', 'email', 'phone', 'websiteUrl', 'birthDate', 'nationality', 'mainImage', 'logoImage',
        ],
        content: [
            ['row', [
                ['block', [
                    '$game!', '$code!', '$gattributableMode!', '$gattributableType!', '$gattributableSubtype', '$name', '$email', '$phone', '$overline', '$description', '$color',
                ]],
                ['block', [
                    '$mainVideoUrl', '$mainAudioUrl', '$nickName', '$firstName', '$lastName', '$websiteUrl', '$birthDate', '$nationality', '$mainImage', '$logoImage',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'type', 'mode', 'subtype', 'game', 'name', 'color', 'overline', 'description', 'code', 'mainVideoUrl', 'mainAudioUrl',
            'nickName', 'firstName', 'lastName', 'email', 'phone', 'websiteUrl', 'birthDate', 'nationality', 'mainImage', 'logoImage',
        ],
        content: [
            ['row', [
                ['block', [
                    '$game', '$code', '$gattributableMode', '$gattributableType', '$gattributableSubtype', '$name', '$email', '$phone', '$overline', '$description', '$color',
                ]],
                ['block', [
                    '$mainVideoUrl', '$mainAudioUrl', '$nickName', '$firstName', '$lastName', '$websiteUrl', '$birthDate', '$nationality', '$mainImage', '$logoImage',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'gameName', 'gameCode', 'type', 'mode', 'subtype', 'game', 'name', 'color', 'overline', 'description', 'code',
            'nickName', 'firstName', 'lastName', 'email', 'phone', 'websiteUrl', 'birthDate', 'nationality',
        ],
    },
    list: {
        columns: [
            {id: 'game', label: 'column_game_label', flex: 1, component: 'game'} as any,
            {id: 'code', label: 'column_code_label'},
            {id: 'type', label: 'column_type_label'},
            {id: 'mode', label: 'column_mode_label'},
            {id: 'name', label: 'column_name_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;
