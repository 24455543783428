const t =  {
    icon: 'fa-trophy',
    menus: {
        main: {section: 'settings', priority: 125},
    },
    new: {
        attributes: [
            'event', 'type', 'scope', 'code', 'name', 'description',
            'achievableset',
            'tenant', 'campaign', 'partner', 'organization', 'game', 'sellergroup', 'seller',
            'godfather', 'project', 'pack', 'beneficiary', 'sale', 'rule',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$achievableEvent!', '$type!', '$achievableScope!', '$code!', '$name!', '$description', '$achievableRule!',
                ]],
                ['block', [
                    '$achievableset',
                    '$tenant!', '$campaign', '$partner', '$organization', '$game', '$sellergroup', '$seller',
                    '$godfather', '$project', '$pack', '$beneficiary', '$sale',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'event', 'type', 'scope', 'code', 'name', 'description',
            'achievableset',
            'tenant', 'campaign', 'partner', 'organization', 'game', 'sellergroup', 'seller',
            'godfather', 'project', 'pack', 'beneficiary', 'sale', 'rule',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$achievableEvent!', '$type!', '$achievableScope!', '$code!', '$name!', '$description', '$achievableRule!',
                ]],
                ['block', [
                    '$achievableset',
                    '$tenant!', '$campaign', '$partner', '$organization', '$game', '$sellergroup', '$seller',
                    '$godfather', '$project', '$pack', '$beneficiary', '$sale',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [ 'achievablesetCode', 'achievablesetName', 'event', 'type', 'scope', 'code', 'name', 'rule', 'tenantCode', 'campaignCode', 'partnerCode', 'organizationCode', 'gameCode', 'sellergroupCode', 'sellerCode', 'godfatherCode', 'projectCode', 'packCode', 'beneficiaryCode', 'sale'],
    },
    list: {
        columns: [
            {id: 'achievablesetCode', label: 'column_achievableset_label', flex: 1, width: 120} as any,
            {id: 'event', label: 'column_event_label', flex: 1, width: 120} as any,
            {id: 'type', label: 'column_type_label', flex: 1, width: 120} as any,
            {id: 'scope', label: 'column_scope_label', flex: 1, width: 120} as any,
            {id: 'code', label: 'column_code_label', flex: 1, width: 120} as any,
            {id: 'name', label: 'column_name_label', flex: 1, width: 120} as any,
            {id: 'game', label: 'column_game_label', flex: 1, component: 'game'} as any,
            {id: 'partner', label: 'column_partner_label', flex: 1, component: 'partner'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;