const t = {
    icon: 'fa-arrow-rotate-right',
    menus: {
        main: {section: 'settings2', priority: 30},
    },
    new: {
        attributes: [
            'code', 'url', ['ttl', 'number'], 'tenant', 'status',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$code!', '$url!', '$tenant!',
                ]],
                ['block', [
                    '$ttl',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'code', 'url', ['ttl', 'number'], 'tenant', 'status',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$code!', '$url!', '$tenant!',
                ]],
                ['block', [
                    '$ttl',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'code', 'url',
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 0, width: 100} as any,
            {id: 'publicShortUrl', label: 'column_redirect_public_url_label', render: (_: any, p: any) => `${process.env.REACT_APP_REDIRECT_PREFIX}/r/${p.getValue(p.id, 'code')}`},
            {id: 'url', label: 'column_redirect_url_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', {type: 'go_public_page', map: (doc: any) => ({...doc, row: {...doc.row, publicPageUrl: `${process.env.REACT_APP_REDIRECT_PREFIX}/r/${doc?.row.code}`}})}, {type: 'go_public_page_qrcode', map: (doc: any) => ({...doc, row: {...doc.row, publicPageShortUrl: `${process.env.REACT_APP_REDIRECT_PREFIX}/r/${doc?.row.code}`}})}, 'delete', ],
    },
};

export default t;