const t = {
    icon: 'fa-folder-plus',
    menus: {
        main: {section: 'settings3', priority: 30},
    },
    new: {
        attributes: [
            'name', 'game', 'locale', 'country',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$game!',
                ]],
                ['block', [
                    '$locale', '$country',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'game', 'locale', 'country',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$game!',
                ]],
                ['block', [
                    '$locale', '$country',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'name', 'game', 'gameName', 'organization', 'organizationName',
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 0, width: 200} as any,
            {id: 'name', label: 'column_name_label'} as any,
            {id: 'game', label: 'column_game_label', flex: 1, width: 120, component: 'game'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;