export const CREATE_AD = (gql: any) => gql`
    mutation($data: CreateAdInput!) {
        createAd(data: $data) {
            id
        }
    }
`;
export const GET_AD = (gql: any) => gql`
    query($id: ID!) {
        getAd(id: $id) {
            advertiser
            advertiserCode
            advertiserName
            affiliater
            affiliaterCode
            affiliaterName
            banner2Image {
                url
                available
            }
            banner2ImageUrl
            bannerImage {
                url
                available
            }
            mobileBannerImage {
                url
                available
            }
            tabletBannerImage {
                url
                available
            }
            bannerImageUrl
            mobileBannerImageUrl
            tabletBannerImageUrl
            code
            commission
            createdAt
            description
            endAt
            externalTrackerId
            id
            largeLeaderboardBanner2Image {
                url
                available
            }
            largeLeaderboardBanner2ImageUrl
            largeLeaderboardBannerImage {
                url
                available
            }
            largeLeaderboardBannerImageUrl
            largeLeaderboardMainImage {
                url
                available
            }
            largeLeaderboardMainImageUrl
            largeLeaderboardThumbnailImage {
                url
                available
            }
            largeLeaderboardThumbnailImageUrl
            largeRectangleBanner2Image {
                url
                available
            }
            largeRectangleBanner2ImageUrl
            largeRectangleBannerImage {
                url
                available
            }
            largeRectangleBannerImageUrl
            largeRectangleMainImage {
                url
                available
            }
            largeRectangleMainImageUrl
            largeRectangleThumbnailImage {
                url
                available
            }
            largeRectangleThumbnailImageUrl
            leaderboardBanner2Image {
                url
                available
            }
            leaderboardBanner2ImageUrl
            leaderboardBannerImage {
                url
                available
            }
            leaderboardBannerImageUrl
            leaderboardMainImage {
                url
                available
            }
            leaderboardMainImageUrl
            leaderboardThumbnailImage {
                url
                available
            }
            leaderboardThumbnailImageUrl
            logoAltImage {
                url
                available
            }
            logoAltImageUrl
            logoImage {
                url
                available
            }
            logoImageUrl
            longDescription
            mainImage {
                url
                available
            }
            mainImageUrl
            mediumRectangleBanner2Image {
                url
                available
            }
            mediumRectangleBanner2ImageUrl
            mediumRectangleBannerImage {
                url
                available
            }
            mediumRectangleBannerImageUrl
            mediumRectangleMainImage {
                url
                available
            }
            mediumRectangleMainImageUrl
            mediumRectangleThumbnailImage {
                url
                available
            }
            mediumRectangleThumbnailImageUrl
            mobileLeaderboardBanner2Image {
                url
                available
            }
            mobileLeaderboardBanner2ImageUrl
            mobileLeaderboardBannerImage {
                url
                available
            }
            mobileLeaderboardBannerImageUrl
            mobileLeaderboardMainImage {
                url
                available
            }
            mobileLeaderboardMainImageUrl
            mobileLeaderboardThumbnailImage {
                url
                available
            }
            mobileLeaderboardThumbnailImageUrl
            name
            overline
            presentation
            privateToken
            publicToken
            script
            scriptUrl
            skyscraperBanner2Image {
                url
                available
            }
            skyscraperBanner2ImageUrl
            skyscraperBannerImage {
                url
                available
            }
            skyscraperBannerImageUrl
            skyscraperMainImage {
                url
                available
            }
            skyscraperMainImageUrl
            skyscraperThumbnailImage {
                url
                available
            }
            skyscraperThumbnailImageUrl
            smallSquareBanner2Image {
                url
                available
            }
            smallSquareBanner2ImageUrl
            smallSquareBannerImage {
                url
                available
            }
            smallSquareBannerImageUrl
            smallSquareMainImage {
                url
                available
            }
            smallSquareMainImageUrl
            smallSquareThumbnailImage {
                url
                available
            }
            smallSquareThumbnailImageUrl
            squareBanner2Image {
                url
                available
            }
            squareBanner2ImageUrl
            squareBannerImage {
                url
                available
            }
            squareBannerImageUrl
            squareMainImage {
                url
                available
            }
            squareMainImageUrl
            squareThumbnailImage {
                url
                available
            }
            squareThumbnailImageUrl
            startAt
            status
            subtitle
            tenant
            tenantCode
            tenantName
            thumbnailImage {
                url
                available
            }
            thumbnailImageUrl
            title
            updatedAt
            url
            wideSkyscraperBanner2Image {
                url
                available
            }
            wideSkyscraperBanner2ImageUrl
            wideSkyscraperBannerImage {
                url
                available
            }
            wideSkyscraperBannerImageUrl
            wideSkyscraperMainImage {
                url
                available
            }
            wideSkyscraperMainImageUrl
            wideSkyscraperThumbnailImage {
                url
                available
            }
            wideSkyscraperThumbnailImageUrl
            tags
        }
    }
`;
export const FIND_ADS = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findAds(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                advertiser
                advertiserCode
                advertiserName
                affiliater
                affiliaterCode
                affiliaterName
                code
                createdAt
                id
                mainImage {
                    url
                    available
                }
                name
                status
                tenant
                tenantCode
                tenantName
                url
                bannerImage {
                    url
                    available
                }
                bannerImageUrl
                mediumRectangleBannerImage {
                    url
                    available
                }
                mediumRectangleBannerImageUrl
                leaderboardBannerImage {
                    url
                    available
                }
                leaderboardBannerImageUrl
            }
        }
    }
`;
export const SEARCH_ADS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAds(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                advertiser
                advertiserCode
                advertiserName
                affiliater
                affiliaterCode
                affiliaterName
                code
                createdAt
                id
                mainImage {
                    url
                    available
                }
                name
                status
                tenant
                tenantCode
                tenantName
                url
                bannerImage {
                    url
                    available
                }
                bannerImageUrl
                mediumRectangleBannerImage {
                    url
                    available
                }
                mediumRectangleBannerImageUrl
                leaderboardBannerImage {
                    url
                    available
                }
                leaderboardBannerImageUrl
            }
        }
    }
`;
export const SEARCH_PUBLISHED_ADS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPublishedAds: searchAds(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "PUBLISHED"},
            ]
        }) {
            cursor
            count
            items {
                advertiser
                advertiserCode
                advertiserName
                affiliater
                affiliaterCode
                affiliaterName
                code
                createdAt
                id
                mainImage {
                    url
                    available
                }
                name
                status
                tenant
                tenantCode
                tenantName
                url
                bannerImage {
                    url
                    available
                }
                bannerImageUrl
                mediumRectangleBannerImage {
                    url
                    available
                }
                mediumRectangleBannerImageUrl
                leaderboardBannerImage {
                    url
                    available
                }
                leaderboardBannerImageUrl
            }
        }
    }
`;
export const SEARCH_ALL_ADS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAllAds: searchAds(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                advertiser
                advertiserCode
                advertiserName
                affiliater
                affiliaterCode
                affiliaterName
                code
                createdAt
                id
                mainImage {
                    url
                    available
                }
                name
                status
                tenant
                tenantCode
                tenantName
                url
                bannerImage {
                    url
                    available
                }
                bannerImageUrl
                mediumRectangleBannerImage {
                    url
                    available
                }
                mediumRectangleBannerImageUrl
                leaderboardBannerImage {
                    url
                    available
                }
                leaderboardBannerImageUrl
            }
        }
    }
`;
export const UPDATE_AD = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateAdInput!) {
        updateAd(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_AD = (gql: any) => gql`
    mutation($id: ID!) {
        deleteAd(id: $id) {
            id
        }
    }
`;