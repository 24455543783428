const t = {
    icon: 'fa-euro-sign',
    menus: {
        main: { section: 'customers', priority: 60 },
    },
    new: {
        attributes: [
            'type', 'level', 'quantity', 'game', 'organization', 'user', 'partner', 'campaign', 'sellergroup', 'seller', 'tags', 'tenant', 'product',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$type!', '$creditLevel!', '$quantity', '$creditTags', '$product',
                ]],
                ['block', [
                    '$tenant!', '$campaign', '$partner', '$organization', '$game', '$sellergroup', '$seller', '$user',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'type', 'level', 'quantity', 'game', 'organization', 'user', 'partner', 'campaign', 'sellergroup', 'seller', 'tags', 'tenant', 'product',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$type!', '$creditLevel!', '$quantity', '$creditTags', '$product',
                ]],
                ['block', [
                    '$tenant!', '$campaign', '$partner', '$organization', '$game', '$sellergroup', '$seller', '$user',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'type', 'level', 'quantity', 'gameCode', 'organizationCode', 'userCode', 'partnerCode', 'campaignCode', 'sellergroupCode', 'seller', 'tags', 'tenantCode', 'productCode',
        ],
    },
    list: {
        columns: [
            { id: 'type', label: 'column_type_label', flex: 0, width: 200 },
            { id: 'level', label: 'column_level_label', flex: 0, width: 200 },
            { id: 'product', label: 'column_product_label', flex: 0, width: 200 },
            { id: 'quantity', label: 'column_quantity_label', flex: 0, width: 200 },
            {id: 'organizationCode', label: 'column_organization_code_label', component: 'organization'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;