export const CREATE_PREMINDER = (gql: any) => gql`
    mutation($data: CreatePreminderInput!) {
        createPreminder(data: $data) {
            id
        }
    }
`;
export const GET_PREMINDER = (gql: any) => gql`
    query($id: ID!) {
        getPreminder(id: $id) {
            id
            tenant
            tenantCode
            tenantName
            partner
            partnerCode
            partnerName
            country
            locale
            status
            type
            kind
            treminder
        }
    }
`;
export const FIND_PREMINDERS = (gql: any) => gql`
    query findPreminders($offset: String, $limit: Int) {
        findPreminders(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                status
                kind
                type
                treminder
            }
        }
    }
`;