const t = {
    icon: 'fa-clock',
    menus: {
        main: {section: 'settings4', priority: 20},
    },
    new: {
        attributes: [
            'sale', 'promise', 'game', 'organization', 'partner', 'tenant', 'type', 'kind',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$tenant!', '$partner', '$organization', '$game', '$promise', '$sale',
                ]],
                ['block', [
                    '$type!', '$kind',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'status',
        ],
        content: [
            ['row', [
                ['block', [
                    '$status:readonly!', '!$tenant!', '$partner', '$organization', '$game', '$promise', '$sale',
                ]],
                ['block', [
                    '$type!', '$kind',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: [
            'id', 'organizationCode', 'organizationName', 'gameCode', 'tenantName', 'promise', 'promiseCode', 'kind', 'greminder', 'oreminder', 'preminder', 'treminder',
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 0, width: 100} as any,
            {id: 'type', label: 'column_type_label'},
            {id: 'kind', label: 'column_kind_label'},
            {id: 'status', label: 'column_status_label'},
            {id: 'jobStatus', label: 'column_jobstatus_label'},
            {id: 'game', label: 'column_game_label', flex: 1, width: 120, component: 'game'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;