const t = {
    icon: 'fa-money-bill-wave',
    menus: {
        main: {section: 'activity', priority: 26},
    },
    new: {
        attributes: [
            'inlinePreset', 'pack', 'presets', 'quantity', 'sale', 'tags', 'type',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$sale!', '$pack', '$tags', 'saleitemsPreset',
                ]],
                ['block', [
                    '$type!', '$quantity', 'inlinePreset',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'pack', 'quantity', 'sale', 'status', 'tags', 'type',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$sale', '$pack', '$tags',
                ]],
                ['block', [
                    '$type', '$quantity',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'campaign', 'campaignCode', 'campaignName', 'createdAt', 'game', 'gameCode', 'gameName', 'id', 'organization', 'organizationCode',
            'organizationName', 'pack', 'packCode', 'packDonation', 'packName', 'packPrice', 'packTickets', 'partner', 'partnerCode', 'partnerName',
            'quantity', 'sale', 'seller', 'sellerCode', 'sellerEmail', 'sellerFirstName', 'sellerLastName', 'sellerName', 'sellerPhone', 'sellergroup',
            'sellergroupCode', 'sellergroupEmail', 'sellergroupGameSellergroupType', 'sellergroupName', 'sellergroupPhone', 'sellergroupType', 'tags',
            'tenant', 'tenantCode', 'tenantName', 'type',
        ],
    },
    list: {
        columns: [
            {id: 'createdAt', label: 'column_createdat_label', component: 'date', flex: 0, width: 160},
            {id: 'status', label: 'column_status_label', flex: 0, width: 100, component: 'sale_status'},
            {id: 'gameCode', label: 'column_game_code_label', component: 'game'},
            {id: 'type', label: 'column_type_label', flex: 0, width: 110},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;