const t =  {
    icon: 'fa-database',
    menus: {
        main: {section: 'settings', priority: 95},
    },
    new: {
        attributes: [
            'name', 'domain', 'subdomain', 'sport', 'country', 'locale', 'tenant', 'presets',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$name!', '$domain!',
                ]],
                ['block', [
                    '$subdomain', '$sport', '$odataitemPresets',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'domain', 'subdomain', 'sport', 'country', 'locale',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$domain!',
                ]],
                ['block', [
                    '$subdomain', '$sport',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: ['name', 'domain', 'subdomain', 'sport', 'country', 'locale', 'tenant', 'presets'],
    },
    list: {
        columns: [
            {id: 'name', label: 'column_name_label', flex: 1, width: 120} as any,
            {id: 'domain', label: 'column_domain_label', flex: 1, width: 120} as any,
            {id: 'subdomain', label: 'column_subdomain_label', flex: 1, width: 120} as any,
            {id: 'sport', label: 'column_sport_label', flex: 1, width: 120} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;