export * from './achievable';
export * from './achievableset';
export * from './asset';
export * from './automation';
export * from './bdataitem';
export * from './beneficiary';
export * from './book';
export * from './bookpayment';
export * from './brand';
export * from './bunch';
export * from './campaign';
export * from './chunkcounter';
export * from './credit';
export * from './creport';
export * from './customer';
export * from './dataquery';
export * from './dashboard';
export * from './dbackground';
export * from './ddomain';
export * from './design';
export * from './dfontfamily';
export * from './dimage';
export * from './document';
export * from './donee';
export * from './dpapercolor';
export * from './dpapersize';
export * from './dprintcolor';
export * from './draw';
export * from './dshape';
export * from './dstub';
export * from './dstubsize';
export * from './dstyling';
export * from './dtemplate';
export * from './dtext';
export * from './dtheme';
export * from './dynaudio';
export * from './opt';
export * from './dynimage';
export * from './dynvideo';
export * from './emailsender';
export * from './feature';
export * from './exportrule';
export * from './fixture';
export * from './gachievement';
export * from './game';
export * from './gattributable';
export * from './gaudio';
export * from './gdataitem';
export * from './gimage';
export * from './gimport';
export * from './godfather';
export * from './greminder';
export * from './greport';
export * from './grouping';
export * from './gversion';
export * from './gvideo';
export * from './host';
export * from './input';
export * from './integration';
export * from './invoice';
export * from './job';
export * from './keyword';
export * from './externalevent';
export * from './lead';
export * from './misc';
export * from './module';
export * from './notification';
export * from './oaudio';
export * from './odataitem';
export * from './offer';
export * from './oimage';
export * from './order';
export * from './oreminder';
export * from './oreport';
export * from './organization';
export * from './oversion';
export * from './ovideo';
export * from './partner';
export * from './pack';
export * from './page';
export * from './paudio';
export * from './pimage';
export * from './plan';
export * from './platform';
export * from './preminder';
export * from './preport';
export * from './preset';
export * from './presult';
export * from './printer';
export * from './product';
export * from './project';
export * from './promise';
export * from './pvideo';
export * from './redirect';
export * from './registration';
export * from './reminder';
export * from './run';
export * from './sale';
export * from './segment';
export * from './seller';
export * from './sellergroup';
export * from './sereport';
export * from './sgreport';
export * from './smssender';
export * from './sponsor';
export * from './sponsoring';
export * from './statevent';
export * from './svgobject';
export * from './tag';
export * from './template';
export * from './tenant';
export * from './theme';
export * from './ticket';
export * from './token';
export * from './translation';
export * from './treminder';
export * from './treport';
export * from './user';
export * from './winner';
export * from './wconfiguration';
export * from './wdefinition';
export * from './invitation';
export * from './recipient';
export * from './paymentmethod';
export * from './bookset';
export * from './ad';
export * from './advertiser';
export * from './affiliater';
export * from './preorder';
export * from './registrationstep';
export * from './gadrule';
export * from './tcountry';
export * from './tlocale';
export * from './glabel';
export * from './olabel';
export * from './gkeyfigure';
export * from './okeyfigure';
export * from './saleitem';
export * from './goreport';
export * from './goperiod';
export * from './gperiod';
export * from './identity';
export * from './ridp';
export * from './session';

export const FIND_WDEFINITION_WCONFIGURATIONS = (gql: any) => gql`
    query findWdefinitionWconfigurations($id: String!, $offset: String, $limit: Int) {
        findWdefinitionWconfigurations(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                description
                status
                key
            }
        }
    }
`;
export const FIND_GAME_BOOKS = (gql: any) => gql`
    query findBooks($offset: String, $limit: Int) {
        findBooks(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                customCode
                game
                gameCode
                publicToken
            }
        }
    }
`;
export const FIND_GAME_GROUPINGS = (gql: any) => gql`
    query findGameGroupings($id: String!, $offset: String, $limit: Int) {
        findGameGroupings(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id 
                token
                code
                name
                email
                phone
                sellergroups {
                    items {
                        fullName
                        email
                        code
                    }
                }
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                tenant
                tenantCode
                tenantName
                country
                locale
                type
            }
        }
    }
`;
export const FIND_GAME_SALES = (gql: any) => gql`
    query findGameSales($id: String!, $offset: String, $limit: Int) {
        findGameSales(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                status
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                books
                pack
                packCode
                packName
                beneficiary
                beneficiaryCode
                beneficiaryName
                project
                projectCode
                projectName
                paymentAmount
                paymentCurrency
                tickets
                donation
                game
                gameCode
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
                owner
            }
        }
    }
`;
export const FIND_GAME_BOOKPAYMENTS = (gql: any) => gql`
    query findGameBookpayments($id: String!, $offset: String, $limit: Int) {
        findGameBookpayments(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                status
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                paymentAmount
                paymentCurrency
                tickets
                game
                gameCode
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
                book
                bookCode
                bookCustomCode
            }
        }
    }
`;
export const FIND_GAME_TICKETS = (gql: any) => gql`
    query findGameTickets($id: String!, $offset: String, $limit: Int) {
        findGameTickets(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                customCode
                status
                createdAt
                updatedAt
                owner
                price
                currency
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                tenant
                tenantCode
                sale
                publicPageShortUrl
            }
        }
    }
`;
export const FIND_GAME_PROJECTS = (gql: any) => gql`
    query findGameProjects($id: String!, $offset: String, $limit: Int) {
        findGameProjects(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                gameCode
                gameName
                organizationCode
                organizationName
                rank
            }
        }
    }
`;
export const FIND_GAME_PROMISES = (gql: any) => gql`
    query findGamePromises($id: String!, $offset: String, $limit: Int) {
        findGamePromises(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                sale
                createdAt
                updatedAt
                status
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                tickets
                donation
                game
                gameCode
                owner
            }
        }
    }
`;
export const FIND_GAME_GODFATHERS = (gql: any) => gql`
    query findGameGodfathers($id: String!, $offset: String, $limit: Int) {
        findGameGodfathers(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                sale
                email
                phone
                gameName
                gameCode
                publicPageShortUrl
                status
            }
        }
    }
`;
export const FIND_GAME_BENEFICIARIES = (gql: any) => gql`
    query findGameBeneficiaries($id: String!, $offset: String, $limit: Int) {
        findGameBeneficiaries(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                gameCode
                gameName
                organizationCode
                organizationName
                logoImage {
                    available
                    size
                    url
                }
                mainImage {
                    available
                    size
                    url
                }
                thumbnailImage {
                    available
                    size
                    url
                }
                plannedDonationRatio
                description
                presentation
                websiteUrl
                donationUrl
                rank
            }
        }
    }
`;
export const FIND_GAME_SELLERGROUPS = (gql: any) => gql`
    query findGameSellergroups($id: String!, $offset: String, $limit: Int) {
        findGameSellergroups(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id 
                token
                code
                name
                email
                phone
                grouping
                groupingCode
                groupingName
                sellers {
                    items {
                        firstName
                        lastName
                        email
                        code
                    }
                }
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                tenant
                tenantCode
                tenantName
                type
                country
                locale
                rank
            }
        }
    }
`;
export const FIND_GAME_SELLERS = (gql: any) => gql`
    query findGameSellers($id: String!, $offset: String, $limit: Int) {
        findGameSellers(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                 id 
                token
                code
                firstName
                lastName
                email
                phone
                sellergroup
                sellergroupCode
                sellergroupName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                tenant
                tenantCode
                tenantName
                country
                locale
            }
        }
    }
`;
export const FIND_GAME_INPUTS = (gql: any) => gql`
    query findGameInputs($id: String!, $offset: String, $limit: Int) {
        findGameInputs(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                 id
                code
                type
                defaultValue
                name
                gameCode
                gameName
                organizationCode
                organizationName
                rank
            }
        }
    }
`;
export const FIND_GAME_PACKS = (gql: any) => gql`
    query findGamePacks($id: String!, $offset: String, $limit: Int) {
        findGamePacks(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                tickets
                ticketBooks
                donation
                currency
                name
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                locale
                country
                overline
                rank
            }
        }
    }
`;
export const FIND_GAME_INTEGRATIONS = (gql: any) => gql`
    query findGameIntegrations($id: String!, $offset: String, $limit: Int) {
        findGameIntegrations(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                organization
                organizationName
                name
                createdAt
                updatedAt
                status
            }
        }
    }
`;
export const FIND_GAME_KEYWORDS = (gql: any) => gql`
    query findGameKeywords($id: String!, $offset: String, $limit: Int) {
        findGameKeywords(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                gameCode
                gameName
                organizationCode
                organizationName
                game
                name
                status
                features
            }
        }
    }
`;
export const FIND_GAME_DRAWS = (gql: any) => gql`
    query findGameDraws($id: String!, $offset: String, $limit: Int) {
        findGameDraws(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                key
                token
                createdAt
                updatedAt
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                strategy
                draft
                country
                locale
               winners {
                    cursor
                    count
                    items {
                        id
                        code
                        rank
                        bunchRank
                        token
                        createdAt
                        updatedAt
                        bunch
                        bunchName
                        ticket
                        ticketCode
                        firstName
                        lastName
                        email
                        phone
                        sale
                    }
                }
            }
        }
    }
`;
export const FIND_GAME_GIMPORTS = (gql: any) => gql`
    query findGameGimports($id: String!, $offset: String, $limit: Int) {
        findGameGimports(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                type
                token
                createdAt
                updatedAt
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                country
                locale
           }
        }
    }
`;
export const FIND_SELLER_SEREPORTS = (gql: any) => gql`
    query findSellerSereports($id: String!, $offset: String, $limit: Int) {
        findSellerSereports(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
                paidSales
                paidTickets
                paymentAmount
                promisedSales
           }
        }
    }
`;
export const FIND_SELLERGROUP_SGREPORTS = (gql: any) => gql`
    query findSellergroupSgreports($id: String!, $offset: String, $limit: Int) {
        findSellergroupSgreports(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                paidSales
                paidTickets
                paymentAmount
                promisedSales
           }
        }
    }
`;
export const FIND_GAME_GREPORTS = (gql: any) => gql`
    query findGameGreports($id: String!, $offset: String, $limit: Int) {
        findGameGreports(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                paidSales
                paidTickets
                paymentAmount
                promisedSales
           }
        }
    }
`;
export const FIND_ORGANIZATION_OREPORTS = (gql: any) => gql`
    query findOrganizationOreports($id: String!, $offset: String, $limit: Int) {
        findOrganizationOreports(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                paidSales
                paidTickets
                paymentAmount
                promisedSales
           }
        }
    }
`;
export const FIND_PARTNER_PREPORTS = (gql: any) => gql`
    query findPartnerPreports($id: String!, $offset: String, $limit: Int) {
        findPartnerPreports(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                paidSales
                paidTickets
                paymentAmount
                promisedSales
           }
        }
    }
`;
export const FIND_CAMPAIGN_CREPORTS = (gql: any) => gql`
    query findCampaignCreports($id: String!, $offset: String, $limit: Int) {
        findCampaignCreports(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                paidSales
                paidTickets
                paymentAmount
                promisedSales
           }
        }
    }
`;
export const FIND_TENANT_TREPORTS = (gql: any) => gql`
    query findTenantTreports($id: String!, $offset: String, $limit: Int) {
        findTenantTreports(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                paidSales
                paidTickets
                paymentAmount
                promisedSales
           }
        }
    }
`;
export const FIND_GAME_GVERSIONS = (gql: any) => gql`
    query findGameGversions($id: String!, $offset: String, $limit: Int) {
        findGameGversions(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                type
                locale
                game
                gameName
                gameCode
                publicToken
                organization
                organizationCode
                organizationName
                bookTickets
                ticketPriceAmount
                ticketPriceCurrency
                plannedTickets
                createdAt
                updatedAt
                saleMandatoryOptInMessage
                mainCss {
                    available
                }
                mainJs {
                    available
                }
                domain
                bannerImage {
                    url
                    available
                }
                mobileBannerImage {
                    url
                    available
                }
                tabletBannerImage {
                    url
                    available
                }
           }
        }
    }
`;
export const FIND_GAME_WINNERS = (gql: any) => gql`
    query findGameWinners($id: String!, $offset: String, $limit: Int) {
        findGameWinners(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                rank
                bunchRank
                token
                createdAt
                updatedAt
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                draw
                bunch
                bunchName
                ticket
                ticketCode
                country
                locale
                firstName
                lastName
                email
                phone
                sale
            }
        }
    }
`;
export const FIND_GAME_NOTIFICATIONS = (gql: any) => gql`
    query findGameNotifications($id: String!, $offset: String, $limit: Int) {
        findGameNotifications(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                token
                status
                type
                subject
                createdAt
                updatedAt
                tenant
                tenantCode
                tenantName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                draw
                country
                locale
                recipient
                sender
                sale
            }
        }
    }
`;
export const FIND_AUTOMATION_RUNS = (gql: any) => gql`
     query findAutomationRuns($id: String!, $offset: String, $limit: Int) {
        findAutomationRuns(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
            	id
            	createdAt
                updatedAt
                status
                token
                privateToken
                publicToken
                tenant
                tenantCode
                definition
                params
                details
                processedItems
                erroredItems
                succeededItems
                automation
            }
        }
    }
`;
export const FIND_ORGANIZATION_OVERSIONS = (gql: any) => gql`
    query findOrganizationOversions($id: String!, $offset: String, $limit: Int) {
        findOrganizationOversions(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                partner
                tenant
                tenantCode
                createdAt
                updatedAt
                status
                publicPageShortUrl
                domain
                ownerEmail
                logoImage {
                    url
                    available
                }
            }
        }
    }
`;
export const FIND_SELLER_BOOKS = (gql: any) => gql`
    query findSellerBooks($id: String!, $offset: String, $limit: Int) {
        findSellerBooks(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                attributionStatus
                code
                currency
                customCode
                email
                firstName
                game
                lastName
                paymentStatus
                phone
                presets
                salesInfo
                seller
                sellergroup
                type
            }
        }
    }
`;
export const FIND_SELLERGROUP_BOOKS = (gql: any) => gql`
    query findSellergroupBooks($id: String!, $offset: String, $limit: Int) {
        findSellergroupBooks(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                attributionStatus
                code
                currency
                customCode
                email
                firstName
                game
                lastName
                paymentStatus
                phone
                presets
                salesInfo
                seller
                sellergroup
                type
                limitedPrivatePageUrl
                publicToken
            }
        }
    }
`;
export const SEARCH_WCONFIGURATIONS = (gql: any) => gql`
    query searchWconfigurations($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchWconfigurations(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                name
                description
                status
                key
                game
                gameCode
                type
                startAt
                endAt
            }
        }
    }
`;
export const SEARCH_CUSTOMERS = (gql: any) => gql`
    query searchCustomers($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchCustomers(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                firstName
                lastName
                email
                status
                tenant
                tenantCode
                tenantName
            }
        }
    }
`;
export const SEARCH_WDEFINITIONS = (gql: any) => gql`
    query searchWdefinitions($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchWdefinitions(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                name
                description
                status
                key
            }
        }
    }
`;
export const SEARCH_CHUNKCOUNTERS = (gql: any) => gql`
    query searchChunkcounters($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchChunkcounters(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                startedAt
                endedAt
                type
                intDiff
                floatDiff
                hasIntDiff
                hasFloatDiff
                app
                view
                tenant
                tenantName
                tenantCode
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                seller
                sellerCode
                sellergroup
                sellergroupName
                sellergroupCode
                grouping
                groupingName
                groupingCode
                campaign
                campaignName
                campaignCode
                partner
                partnerName
                partnerCode
            }
        }
    }
`;
export const SEARCH_DASHBOARDS = (gql: any) => gql`
    query searchDashboards($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDashboards(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                name
                level
                rank
                definition
                tenant
                tenantName
                tenantCode
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                seller
                sellerCode
                sellergroup
                sellergroupName
                sellergroupCode
                grouping
                groupingName
                groupingCode
                campaign
                campaignName
                campaignCode
                partner
                partnerName
                partnerCode
                privatePageUrl
            }
        }
    }
`;
export const SEARCH_DBACKGROUNDS = (gql: any) => gql`
    query searchDbackgrounds($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDbackgrounds(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                description
                type
                svgFile {
                    available
                    url
                }
                mainImage {
                    available
                    url
                }
                tags
            }
        }
    }
`;
export const SEARCH_DDOMAINS = (gql: any) => gql`
    query searchDdomains($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDdomains(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                tags
            }
        }
    }
`;
export const SEARCH_DIMAGES = (gql: any) => gql`
    query searchDimages($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDimages(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                description
                type
                svgFile {
                    available
                    url
                }
                mainImage {
                    available
                    url
                }
                tags
            }
        }
    }
`;
export const SEARCH_DPAPERCOLORS = (gql: any) => gql`
    query searchDpapercolors($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDpapercolors(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                value
                tags
            }
        }
    }
`;
export const SEARCH_DPAPERSIZES = (gql: any) => gql`
    query searchDpapersizes($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDpapersizes(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                value
                tags
            }
        }
    }
`;
export const SEARCH_DPRINTCOLORS = (gql: any) => gql`
    query searchDprintcolors($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDprintcolors(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                definition
                tags
            }
        }
    }
`;
export const SEARCH_DSHAPES = (gql: any) => gql`
    query searchDshapes($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDshapes(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                definition
                tags
            }
        }
    }
`;
export const SEARCH_DSTUBS = (gql: any) => gql`
    query searchDstubs($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDstubs(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                definition
                tags
            }
        }
    }
`;
export const SEARCH_DSTUBSIZES = (gql: any) => gql`
    query searchDstubsizes($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDstubsizes(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                definition
                tags
            }
        }
    }
`;
export const SEARCH_DSTYLINGS = (gql: any) => gql`
    query searchDstylings($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDstylings(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                definition
                tags
            }
        }
    }
`;
export const SEARCH_DTEMPLATES = (gql: any) => gql`
    query searchDtemplates($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDtemplates(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                definition
                tags
            }
        }
    }
`;
export const SEARCH_DTEXTS = (gql: any) => gql`
    query searchDtexts($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDtexts(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                definition
                tags
            }
        }
    }
`;
export const SEARCH_DTHEMES = (gql: any) => gql`
    query searchDthemes($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDthemes(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                definition
                tags
            }
        }
    }
`;
export const SEARCH_DESIGNS = (gql: any) => gql`
    query searchDesigns($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDesigns(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                data
                mainImage {
                    available
                    url
                }
                createdAt
                updatedAt
                tenant
                tenantCode
                tenantName
            }
        }
    }
`;
export const SEARCH_DOCUMENTS = (gql: any) => gql`
    query searchDocuments($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDocuments(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                type
                token
                status
                game
                gameCode
                gameName
                organization
                organizationName
                organizationCode
                partner
                partnerCode
                partnerName
                tenant
                tenantName
                tenantCode
                locale
                country
                title
                description
                job
                jobStatus
                draw
                drawCode
                sale
                promise
                promiseCode
            }
        }
    }
`;
export const SEARCH_FEATURES = (gql: any) => gql`
    query searchFeatures($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchFeatures(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                tenant
                tenantCode
                tenantName
            }
        }
    }
`;
export const SEARCH_DRAWS = (gql: any) => gql`
    query searchDraws($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDraws(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                key
                token
                createdAt
                updatedAt
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                strategy
                draft
                country
                locale
                processedTickets
                processedPrizes
                processedBunches
                winnersCount
                winners {
                    cursor
                    count
                    items {
                        id
                        code
                        rank
                        bunchRank
                        token
                        createdAt
                        updatedAt
                        bunch
                        bunchName
                        ticket
                        ticketCode
                        firstName
                        lastName
                        email
                        phone
                        sale
                    }
                }
            }
        }
    }
`;
export const SEARCH_DYNAUDIOS = (gql: any) => gql`
    query searchDynaudios($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDynaudios(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                title
                token
                job
                type
            }
        }
    }
`;
export const SEARCH_DYNIMAGES = (gql: any) => gql`
    query searchDynimages($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDynimages(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                mainImage {
                    url
                }
                title
                token
                job
                type
            }
        }
    }
`;
export const SEARCH_DYNVIDEOS = (gql: any) => gql`
    query searchDynvideos($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDynvideos(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                title
                token
                job
                type
            }
        }
    }
`;
export const SEARCH_PRESETS = (gql: any) => gql`
    query searchPresets($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPresets(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                tenant
                tenantCode
                tenantName
                name
                code
                type
                definition
                status
                createdAt
            }
        }
    }
`;
export const SEARCH_GVERSIONS = (gql: any) => gql`
    query searchGversions($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGversions(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                type
                locale
                game
                gameName
                gameCode
                publicToken
                organization
                organizationCode
                organizationName
                bookTickets
                ticketPriceAmount
                ticketPriceCurrency
                plannedTickets
                createdAt
                updatedAt
                saleMandatoryOptInMessage
                mainCss {
                    available
                }
                mainJs {
                    available
                }
                domain
                bannerImage {
                    url
                    available
                }
                mobileBannerImage {
                    url
                    available
                }
                tabletBannerImage {
                    url
                    available
                }
            }
        }
    }
`;
export const SEARCH_GIMPORTS = (gql: any) => gql`
    query searchGimports($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGimports(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                type
                token
                createdAt
                updatedAt
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                country
                locale
                status
            }
        }
    }
`;
export const SEARCH_GAUDIOS = (gql: any) => gql`
    query searchGaudios($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGaudios(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                mainAudioUrl
                desktopAudioUrl
                tabletAudioUrl
                mobileAudioUrl
                tvAudioUrl
                emailAudioUrl
            }
        }
    }
`;
export const SEARCH_GATTRIBUTABLES = (gql: any) => gql`
    query searchGattributables($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGattributables(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
            	id
                type
                mode
                subtype
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                name
                color
                overline
                description
                code
                mainVideoUrl
                mainAudioUrl
                nickName
                firstName
                lastName
                email
                phone
                websiteUrl
                birthDate
                nationality
                mainImage {
                    available
                    url
                }
               logoImage {
                    available
                    url
                }
            }
        }
    }
`;
export const SEARCH_GIMAGES = (gql: any) => gql`
    query searchGimages($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGimages(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                title
                token
                mainImage {
                    available
                    url
                }
                desktopImage {
                    available
                    url
                }
                tabletImage {
                    available
                    url
                }
                mobileImage {
                    available
                    url
                }
                tvImage {
                    available
                    url
                }
                emailImage {
                    available
                    url
                }
            }
        }
    }
`;
export const SEARCH_GODFATHERS = (gql: any) => gql`
    query searchGodfathers($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGodfathers(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                sale
                email
                phone
                gameName
                gameCode
                publicPageShortUrl
                status
            }
        }
    }
`;
export const SEARCH_GVIDEOS = (gql: any) => gql`
    query searchGvideos($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGvideos(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                mainVideoUrl
                desktopVideoUrl
                tabletVideoUrl
                mobileVideoUrl
                tvVideoUrl
                emailVideoUrl
            }
        }
    }
`;
export const SEARCH_JOBS = (gql: any) => gql`
    query searchJobs($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchJobs(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                token
                statusToken
                progressToken
                type
                status
                data
                report
                startCallbackUrl
                completeCallbackUrl
                errorCallbackUrl
                abortCallbackUrl
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                progress
                itemsCount
                processedItemsCount
                processedItemsProgress
            }
        }
    }
`;
export const SEARCH_KEYWORDS = (gql: any) => gql`
    query searchKeywords($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchKeywords(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                gameCode
                gameName
                organizationCode
                organizationName
                game
                name
                status
                features
            }
        }
    }
`;
export const SEARCH_BENEFICIARIES = (gql: any) => gql`
    query searchBeneficiaries($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchBeneficiaries(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                gameCode
                gameName
                organizationCode
                organizationName
                logoImage {
                    available
                    size
                    url
                }
                mainImage {
                    available
                    size
                    url
                }
                thumbnailImage {
                    available
                    size
                    url
                }
                plannedDonationRatio
                description
                presentation
                websiteUrl
                donationUrl
            }
        }
    }
`;
export const SEARCH_BRANDS = (gql: any) => gql`
    query searchBrands($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchBrands(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                tenant
                tenantCode
                tenantName
                logoImage  {
                    available
                    size
                    url
                }
                logoAltImage  {
                    available
                    size
                    url
                }
                bannerImage  {
                    available
                    size
                    url
                }
                mobileBannerImage  {
                    available
                    size
                    url
                }
                tabletBannerImage  {
                    available
                    size
                    url
                }
                mainImage  {
                    available
                    size
                    url
                }
                websiteUrl
            }
        }
    }
`;
export const SEARCH_PROJECTS = (gql: any) => gql`
    query searchProjects($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchProjects(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                gameCode
                gameName
                organizationCode
                organizationName
            }
        }
    }
`;
export const SEARCH_PLATFORMS = (gql: any) => gql`
    query searchPlatforms($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPlatforms(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                organizationName
                organizationCode
                organization
                status
                partnerName
                partnerCode
                partner
                tenantName
                tenantCode
                tenant
                email
            }
        }
    }
`;
export const SEARCH_TOKENS = (gql: any) => gql`
    query searchTokens($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchTokens(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                platformName
                platformCode
                platform
                partnerName
                partnerCode
                partner
                status
            }
        }
    }
`;
export const SEARCH_GAME_SALES = (gql: any) => gql`
    query searchGameSales($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchSales(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                status
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                books
                pack
                packCode
                packName
                beneficiary
                beneficiaryCode
                beneficiaryName
                project
                projectCode
                projectName
                paymentAmount
                paymentCurrency
                tickets
                donation
                game
                gameCode
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
            }
        }
    }
`;
export const SEARCH_GAME_BOOKPAYMENTS = (gql: any) => gql`
    query searchGameBookpayments($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchBookpayments(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                status
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                paymentAmount
                paymentCurrency
                tickets
                game
                gameCode
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
                book
                bookCode
                bookCustomCode
            }
        }
    }
`;
export const SEARCH_BOOK_BOOKPAYMENTS = (gql: any) => gql`
    query searchBookBookpayments($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchBookpayments(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "book", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                status
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                paymentAmount
                paymentCurrency
                tickets
                game
                gameCode
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
                book
                bookCode
                bookCustomCode
            }
        }
    }
`;
export const SEARCH_GAME_TICKETS = (gql: any) => gql`
    query searchGameTickets($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchTickets(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                code
                status
                createdAt
                updatedAt
                owner
                price
                currency
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                tenant
                tenantCode
                sale
                publicPageShortUrl
            }
        }
    }
`;
export const SEARCH_GAME_PROJECTS = (gql: any) => gql`
    query searchGameProjects($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchProjects(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                code
                name
                gameCode
                gameName
                organizationCode
                organizationName
                rank
            }
        }
    }
`;
export const SEARCH_GAME_PROMISES = (gql: any) => gql`
    query searchGamePromises($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPromises(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                sale
                createdAt
                updatedAt
                status
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                tickets
                donation
                game
                gameCode
            }
        }
    }
`;
export const SEARCH_GAME_GODFATHERS = (gql: any) => gql`
    query searchGameGodfathers($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGodfathers(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                code
                sale
                email
                phone
                gameName
                gameCode
                publicPageShortUrl
                status
            }
        }
    }
`;
export const SEARCH_GAME_BENEFICIARIES = (gql: any) => gql`
    query searchGameBeneficiaries($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchBeneficiaries(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                code
                name
                gameCode
                gameName
                organizationCode
                organizationName
                logoImage {
                    available
                    size
                    url
                }
                mainImage {
                    available
                    size
                    url
                }
                thumbnailImage {
                    available
                    size
                    url
                }
                plannedDonationRatio
                description
                presentation
                websiteUrl
                donationUrl
                rank
            }
        }
    }
`;
export const SEARCH_GAME_SELLERGROUPS = (gql: any) => gql`
    query searchGameSellergroups($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchSellergroups(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id 
                token
                code
                name
                email
                phone
                sellers {
                    items {
                        firstName
                        lastName
                        email
                        code
                    }
                }
                grouping
                groupingCode
                groupingName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                tenant
                tenantCode
                tenantName
                type
                country
                locale
                rank
            }
        }
    }
`;
export const SEARCH_GAME_SELLERS = (gql: any) => gql`
    query searchGameSellers($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchSellers(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                 id 
                token
                code
                firstName
                lastName
                email
                phone
                sellergroup
                sellergroupCode
                sellergroupName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                tenant
                tenantCode
                tenantName
                country
                locale
            }
        }
    }
`;
export const SEARCH_GAME_INPUTS = (gql: any) => gql`
    query searchGameInputs($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchInputs(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                code
                type
                defaultValue
                name
                gameCode
                gameName
                organizationCode
                organizationName
                rank
            }
        }
    }
`;
export const SEARCH_GAME_PACKS = (gql: any) => gql`
    query searchGamePacks($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPacks(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                code
                tickets
                ticketBooks
                donation
                currency
                name
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                locale
                country
                overline
                rank
            }
        }
    }
`;
export const SEARCH_GAME_KEYWORDS = (gql: any) => gql`
    query searchGameKeywords($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchKeywords(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                code
                gameCode
                gameName
                organizationCode
                organizationName
                game
                name
                status
                features
            }
        }
    }
`;
export const SEARCH_GAME_DRAWS = (gql: any) => gql`
    query searchGameDraws($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDraws(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                key
                token
                createdAt
                updatedAt
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                strategy
                draft
                country
                locale
                winners {
                    cursor
                    count
                    items {
                        id
                        code
                        rank
                        bunchRank
                        token
                        createdAt
                        updatedAt
                        bunch
                        bunchName
                        ticket
                        ticketCode
                        firstName
                        lastName
                        email
                        phone
                        sale
                    }
                }
            }
        }
    }
`;
export const SEARCH_GAME_GIMPORTS = (gql: any) => gql`
    query searchGameGimports($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGimports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                type
                token
                createdAt
                updatedAt
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                country
                locale
            }
        }
    }
`;
export const SEARCH_SELLER_SEREPORTS = (gql: any) => gql`
    query searchSellerSereports($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchSereports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "seller", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
                seller
            }
        }
    }
`;
export const SEARCH_SELLERGROUP_SGREPORTS = (gql: any) => gql`
    query searchSellergroupSgreports($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchSgreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "sellergroup", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
            }
        }
    }
`;
export const SEARCH_GAME_GREPORTS = (gql: any) => gql`
    query searchGameGreports($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
            }
        }
    }
`;
export const SEARCH_ORGANIZATION_OREPORTS = (gql: any) => gql`
    query searchOrganizationOreports($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "organization", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                organization
                organizationCode
                organizationName
            }
        }
    }
`;
export const SEARCH_PARTNER_PREPORTS = (gql: any) => gql`
    query searchPartnerPreports($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "partner", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                partner
                partnerCode
                partnerName
            }
        }
    }
`;
export const SEARCH_CAMPAIGN_CREPORTS = (gql: any) => gql`
    query searchCampaignCreports($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchCreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "campaign", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                campaign
                campaignCode
                campaignName
            }
        }
    }
`;
export const SEARCH_TENANT_TREPORTS = (gql: any) => gql`
    query searchTenantTreports($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchTreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "tenant", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                tenant
                tenantCode
                tenantName
            }
        }
    }
`;
export const SEARCH_GAME_WINNERS = (gql: any) => gql`
    query searchGameWinners($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchWinners(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                code
                rank
                bunchRank
                token
                createdAt
                updatedAt
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                draw
                bunch
                bunchName
                ticket
                ticketCode
                country
                locale
                firstName
                lastName
                email
                phone
                sale
            }
        }
    }
`;
export const SEARCH_GAME_NOTIFICATIONS = (gql: any) => gql`
    query searchGameNotifications($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchNotifications(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                token
                status
                createdAt
                updatedAt
                tenant
                tenantCode
                tenantName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                draw
                country
                locale
                recipient
                sender
                sale
            }
        }
    }
`;
export const SEARCH_INPUTS = (gql: any) => gql`
    query searchInputs($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchInputs(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                type
                defaultValue
                name
                gameCode
                gameName
                organizationCode
                organizationName
            }
        }
    }
`;
export const SEARCH_PACKS = (gql: any) => gql`
    query searchPacks($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPacks(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                tickets
                ticketBooks
                donation
                currency
                name
                gameCode
                gameName
                organizationCode
                organizationName
                overline
            }
        }
    }
`;
export const SEARCH_AUTOMATIONS = (gql: any) => gql`
    query searchAutomations($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAutomations(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
            	id
                code
                token
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                name
                definition
                params
                frequency
                status
                lastExecutionDate
                lastExecutionStatus
                lastExecutionDetails
            }
        }
    }
`;
export const SEARCH_SEGMENTS = (gql: any) => gql`
    query searchSegments($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchSegments(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                token
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                name
                definition
                params
                index
                typename
            }
        }
    }
`;
export const SEARCH_RUNS = (gql: any) => gql`
    query searchRuns($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchRuns(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
            	id
            	createdAt
                updatedAt
                status
                token
                privateToken
                publicToken
                tenant
                tenantCode
                definition
                params
                details
                processedItems
                erroredItems
                succeededItems
                automation
                result
            }
        }
    }
`;
export const SEARCH_PAGES = (gql: any) => gql`
    query searchPages($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPages(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                game
                gameCode
                organization
                organizationCode
                campaign
                campaignCode
                partner
                partnerCode
                tenant
                tenantCode
                key
                mode
                content
            }
        }
    }
`;
export const SEARCH_LEADS = (gql: any) => gql`
    query searchLeads($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchLeads(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                partner
                tenant
                tenantCode
                name
                createdAt
                updatedAt
                status
                domain
            }
        }
    }
`;
export const SEARCH_NOTIFICATIONS = (gql: any) => gql`
    query searchNotifications($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchNotifications(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                createdAt
                status
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                draw
                drawCode
                promise
                sale
                template
                recipient
                sender
                variables
                type
                locale
                country
                subject
                attachments
                bodyFile {
                    available
                    url
                }
                bodyTextFile {
                    available
                    url
                }
            }
        }
    }
`;
export const SEARCH_ALL_NOTIFICATIONS = (gql: any) => gql`
    query searchAllNotifications($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAllNotifications: searchNotifications(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                createdAt
                status
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                draw
                drawCode
                promise
                sale
                template
                recipient
                sender
                variables
                type
                locale
                country
                subject
                attachments
                bodyFile {
                    available
                    url
                }
                bodyTextFile {
                    available
                    url
                }
            }
        }
    }
`;
export const SEARCH_SENDFAILED_NOTIFICATIONS = (gql: any) => gql`
    query searchSendFailedNotifications($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchSendFailedNotifications: searchNotifications(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "SEND_FAILED"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                status
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                draw
                drawCode
                promise
                sale
                template
                recipient
                sender
                variables
                type
                locale
                country
                subject
                attachments
                bodyFile {
                    available
                    url
                }
                bodyTextFile {
                    available
                    url
                }
            }
        }
    }
`;
export const SEARCH_DELIVERED_NOTIFICATIONS = (gql: any) => gql`
    query searchDeliveredNotifications($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDeliveredNotifications: searchNotifications(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "DELIVERED"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                status
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                draw
                drawCode
                promise
                sale
                template
                recipient
                sender
                variables
                type
                locale
                country
                subject
                attachments
                bodyFile {
                    available
                    url
                }
                bodyTextFile {
                    available
                    url
                }
            }
        }
    }
`;
export const SEARCH_CREATED_NOTIFICATIONS = (gql: any) => gql`
    query searchCreatedNotifications($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchCreatedNotifications: searchNotifications(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "CREATED"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                status
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                draw
                drawCode
                promise
                sale
                template
                recipient
                sender
                variables
                type
                locale
                country
                subject
                attachments
                bodyFile {
                    available
                    url
                }
                bodyTextFile {
                    available
                    url
                }
            }
        }
    }
`;
export const SEARCH_MODULES = (gql: any) => gql`
    query searchModules($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchModules(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                params {
                    name
                }
            }
        }
    }
`;
export const SEARCH_ODATAITEMS = (gql: any) => gql`
    query searchOdataitems($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOdataitems(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                name
                domain
                subdomain
                sport
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                country
                locale
                presets
            }
        }
    }
`;
export const SEARCH_BDATAITEMS = (gql: any) => gql`
    query searchBdataitems($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchBdataitems(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                name
                category
                subcategory
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                createdAt
                updatedAt
                country
                locale
                presets
            }
        }
    }
`;
export const SEARCH_GACHIEVEMENTS = (gql: any) => gql`
    query searchGachievements($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGachievements(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                game
                gameName
                gameCode
                value
                type
            }
        }
    }
`;
export const SEARCH_GDATAITEMS = (gql: any) => gql`
    query searchGdataitems($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGdataitems(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                name
                category
                subcategory
                tenant
                tenantCode
                tenantName
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                createdAt
                updatedAt
                country
                locale
                presets
            }
        }
    }
`;
export const SEARCH_OFFERS = (gql: any) => gql`
    query searchOffers($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOffers(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                createdAt
                updatedAt
                status
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                type
                features
                params {
                    name
                    type
                    value
                    intValue
                    floatValue
                    booleanValue
                    jsonValue
                }
            }
        }
    }
`;
export const SEARCH_OAUDIOS = (gql: any) => gql`
    query searchOaudios($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOaudios(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                organization
                organizationCode
                organizationName
                mainAudioUrl
                desktopAudioUrl
                tabletAudioUrl
                mobileAudioUrl
                tvAudioUrl
                emailAudioUrl
            }
        }
    }
`;
export const SEARCH_PAUDIOS = (gql: any) => gql`
    query searchPaudios($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPaudios(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                mainAudioUrl
                desktopAudioUrl
                tabletAudioUrl
                mobileAudioUrl
                tvAudioUrl
                emailAudioUrl
            }
        }
    }
`;
export const SEARCH_OIMAGES = (gql: any) => gql`
    query searchOimages($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOimages(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                organization
                organizationCode
                organizationName
                mainImage {
                    available
                    url
                }
                desktopImage {
                    available
                    url
                }
                tabletImage {
                    available
                    url
                }
                mobileImage {
                    available
                    url
                }
                tvImage {
                    available
                    url
                }
                emailImage {
                    available
                    url
                }
            }
        }
    }
`;
export const SEARCH_PIMAGES = (gql: any) => gql`
    query searchPimages($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPimages(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                mainImage {
                    available
                    url
                }
                desktopImage {
                    available
                    url
                }
                tabletImage {
                    available
                    url
                }
                mobileImage {
                    available
                    url
                }
                tvImage {
                    available
                    url
                }
                emailImage {
                    available
                    url
                }
            }
        }
    }
`;
export const SEARCH_ORGANIZATIONS = (gql: any) => gql`
    query searchOrganizations($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOrganizations(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                partner
                tenant
                tenantCode
                name
                owner
                createdAt
                updatedAt
                status
                publicPageShortUrl
                domain
                ownerEmail
                logoImage {
                    url
                    available
                }
            }
        }
    }
`;
export const SEARCH_OVERSIONS = (gql: any) => gql`
    query searchOversions($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOversions(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                type
                code
                organization
                organizationName
                organizationCode
                organization
                organizationName
                organizationCode
                partner
                tenant
                tenantCode
                createdAt
                updatedAt
                status
                publicPageShortUrl
                domain
                ownerEmail
                logoImage {
                    url
                    available
                }
            }
        }
    }
`;
export const SEARCH_OVIDEOS = (gql: any) => gql`
    query searchOvideos($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOvideos(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                organization
                organizationCode
                organizationName
                mainVideoUrl
                desktopVideoUrl
                tabletVideoUrl
                mobileVideoUrl
                tvVideoUrl
                emailVideoUrl
            }
        }
    }
`;
export const SEARCH_PVIDEOS = (gql: any) => gql`
    query searchPvideos($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPvideos(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                mainVideoUrl
                desktopVideoUrl
                tabletVideoUrl
                mobileVideoUrl
                tvVideoUrl
                emailVideoUrl
            }
        }
    }
`;
export const SEARCH_PRINTERS = (gql: any) => gql`
    query searchPrinters($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPrinters(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                tenant
            }
        }
    }
`;
export const SEARCH_PRODUCTS = (gql: any) => gql`
    query searchProducts($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchProducts(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                description
                tenant
                organization
                game
                amount
                taxAmount
                currency
                processorType
                status
            }
        }
    }
`;
export const SEARCH_REDIRECTS = (gql: any) => gql`
    query searchRedirects($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchRedirects(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                url
                ttl
                createdAt
                updatedAt
                status
                tenant
                tenantCode
                tenantName
            }
        }
    }
`;
export const SEARCH_REMINDERS = (gql: any) => gql`
    query searchReminders($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchReminders(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                status
                kind
                type
                greminder
                oreminder
                preminder
                treminder
            }
        }
    }
`;
export const SEARCH_GREMINDERS = (gql: any) => gql`
    query searchGreminders($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGreminders(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                status
                kind
                type
                oreminder
                preminder
                treminder
            }
        }
    }
`;
export const SEARCH_OREMINDERS = (gql: any) => gql`
    query searchOreminders($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOreminders(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                status
                kind
                type
                preminder
                treminder
            }
        }
    }
`;
export const SEARCH_PREMINDERS = (gql: any) => gql`
    query searchPreminders($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPreminders(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                status
                kind
                type
                treminder
            }
        }
    }
`;
export const SEARCH_TREMINDERS = (gql: any) => gql`
    query searchTreminders($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchTreminders(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                tenant
                tenantCode
                tenantName
                status
                kind
                type
            }
        }
    }
`;
export const SEARCH_BOOKPAYMENTS = (gql: any) => gql`
    query searchBookpayments($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchBookpayments(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                status
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                paymentAmount
                paymentCurrency
                tickets
                game
                gameCode
                gameName
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
                book
                bookCode
                bookCustomCode
            }
        }
    }
`;
export const SEARCH_SELLERS = (gql: any) => gql`
    query searchSellers($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchSellers(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id 
                token
                code
                firstName
                lastName
                email
                phone
                sellergroup
                sellergroupCode
                sellergroupName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                tenant
                tenantCode
                tenantName
                country
                locale
            }
        }
    }
`;
export const SEARCH_SELLERGROUPS = (gql: any) => gql`
    query searchSellergroups($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchSellergroups(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
               id 
                token
                code
                name
                email
                phone
                sellers {
                    items {
                        firstName
                        lastName
                        email
                        code
                    }
                }
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                tenant
                tenantCode
                tenantName
                type
                country
                locale
                customCode
                grouping
                groupingCode
                groupingName
            }
        }
    }
`;
export const SEARCH_GROUPINGS = (gql: any) => gql`
    query searchGroupings($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGroupings(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id 
                token
                code
                name
                email
                phone
                sellergroups {
                    items {
                        fullName
                        email
                        code
                    }
                }
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                tenant
                tenantCode
                tenantName
                country
                locale
                type
                grouping
            }
        }
    }
`;
export const SEARCH_STATEVENTS = (gql: any) => gql`
    query searchStatevents($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchStatevents(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                type
                gameName
                gameCode
                tenantName
                tenantCode
                organizationName
                organizationCode
                startTime
                endTime
            }
        }
    }
`;
export const SEARCH_SVGOBJECTS = (gql: any) => gql`
    query searchSvgobjects($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchSvgobjects(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                tenant
                tenantCode
                tenantName
                description
                tags
                createdAt
                updatedAt
                token
                svgFile {
                    available
                    url
                }
            }
        }
    }
`;
export const SEARCH_TENANTS = (gql: any) => gql`
    query searchTenants($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchTenants(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                publicPageShortUrl
            }
        }
    }
`;
export const SEARCH_THEMES = (gql: any) => gql`
    query searchThemes($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchThemes(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                mainCss {
                    available
                    url
                }
                mainJs {
                    available
                    url
                }
                varColorNavPrimary
                varColorNavSecondary
                varColorPrimary
                varColorSecondary
                varColorTextPrimary
                varColorTextSecondary
                varColorBorderPrimary
                varColorBorderSecondary
                varColorPanelPrimary
                varColorPanelSecondary
                varColorLogo
                varColorTitle
                varColorChoicePrimary
                varColorChoiceSecondary
                varColorFooterPrimary
                varColorFooterSecondary
                varColorHeading
            }
        }
    }
`;
export const SEARCH_HOSTS = (gql: any) => gql`
    query searchHosts($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchHosts(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                dns
                url
                name
                game
                gameCode
                organization
                organizationCode
                campaign
                campaignCode
                partner
                partnerCode
                tenant
                tenantCode
                mode
                main
                key
                options
                pool
                cname
            }
        }
    }
`;
export const SEARCH_SMSSENDERS = (gql: any) => gql`
    query searchSmssenders($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchSmssenders(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                phone
                name
                provider
                tenant
                tenantCode
                features
            }
        }
    }
`;
export const SEARCH_EMAILSENDERS = (gql: any) => gql`
    query searchEmailsenders($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchEmailsenders(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                email
                name
                identityArn
                tenant
                tenantCode
                features
            }
        }
    }
`;
export const SEARCH_TICKETS = (gql: any) => gql`
    query searchTickets($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchTickets(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                status
                createdAt
                updatedAt
                owner
                price
                currency
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                tenant
                tenantCode
                sale
                publicPageShortUrl
                customCode
            }
        }
    }
`;
export const SEARCH_USERS = (gql: any) => gql`
    query searchUsers($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchUsers(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                lastName
                firstName
                email
                createdAt
                updatedAt
                status
                publicPageShortUrl
                role
            }
        }
    }
`;
export const SEARCH_WINNERS = (gql: any) => gql`
    query searchWinners($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchWinners(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                rank
                bunchRank
                token
                createdAt
                updatedAt
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                draw
                bunch
                bunchCode
                bunchName
                ticket
                ticketCode
                country
                locale
                firstName
                lastName
                email
                phone
                sale
            }
        }
    }
`;
export const SEARCH_PRESULTS = (gql: any) => gql`
    query searchPresults($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPresults(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                token
                createdAt
                updatedAt
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                draw
                firstName
                lastName
                email
                phone
            }
        }
    }
`;
export const UPDATE_CAMPAIGN = (gql: any) => gql`
    mutation updateCampaign($id: ID!, $data: UpdateCampaignInput!) {
        updateCampaign(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_WCONFIGURATION = (gql: any) => gql`
    mutation updateWconfiguration($id: ID!, $data: UpdateWconfigurationInput!) {
        updateWconfiguration(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_CUSTOMER = (gql: any) => gql`
    mutation updateCustomer($id: ID!, $data: UpdateCustomerInput!) {
        updateCustomer(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_WDEFINITION = (gql: any) => gql`
    mutation updateWdefinition($id: ID!, $data: UpdateWdefinitionInput!) {
        updateWdefinition(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_CHUNKCOUNTER = (gql: any) => gql`
    mutation updateChunkcounter($id: ID!, $data: UpdateChunkcounterInput!) {
        updateChunkcounter(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DASHBOARD = (gql: any) => gql`
    mutation updateDashboard($id: ID!, $data: UpdateDashboardInput!) {
        updateDashboard(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DBACKGROUND = (gql: any) => gql`
    mutation updateDbackground($id: ID!, $data: UpdateDbackgroundInput!) {
        updateDbackground(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DDOMAIN = (gql: any) => gql`
    mutation updateDdomain($id: ID!, $data: UpdateDdomainInput!) {
        updateDdomain(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DIMAGE = (gql: any) => gql`
    mutation updateDimage($id: ID!, $data: UpdateDimageInput!) {
        updateDimage(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DPAPERCOLOR = (gql: any) => gql`
    mutation updateDpapercolor($id: ID!, $data: UpdateDpapercolorInput!) {
        updateDpapercolor(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DPAPERSIZE = (gql: any) => gql`
    mutation updateDpapersize($id: ID!, $data: UpdateDpapersizeInput!) {
        updateDpapersize(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DPRINTCOLOR = (gql: any) => gql`
    mutation updateDprintcolor($id: ID!, $data: UpdateDprintcolorInput!) {
        updateDprintcolor(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DSHAPE = (gql: any) => gql`
    mutation updateDshape($id: ID!, $data: UpdateDshapeInput!) {
        updateDshape(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DSTUB = (gql: any) => gql`
    mutation updateDstub($id: ID!, $data: UpdateDstubInput!) {
        updateDstub(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DSTUBSIZE = (gql: any) => gql`
    mutation updateDstubsize($id: ID!, $data: UpdateDstubsizeInput!) {
        updateDstubsize(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DSTYLING = (gql: any) => gql`
    mutation updateDstyling($id: ID!, $data: UpdateDstylingInput!) {
        updateDstyling(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DTEMPLATE = (gql: any) => gql`
    mutation updateDtemplate($id: ID!, $data: UpdateDtemplateInput!) {
        updateDtemplate(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DTEXT = (gql: any) => gql`
    mutation updateDtext($id: ID!, $data: UpdateDtextInput!) {
        updateDtext(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DTHEME = (gql: any) => gql`
    mutation updateDtheme($id: ID!, $data: UpdateDthemeInput!) {
        updateDtheme(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DESIGN = (gql: any) => gql`
    mutation updateDesign($id: ID!, $data: UpdateDesignInput!) {
        updateDesign(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DOCUMENT = (gql: any) => gql`
    mutation updateDocument($id: ID!, $data: UpdateDocumentInput!) {
        updateDocument(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_FEATURE = (gql: any) => gql`
    mutation updateFeature($id: ID!, $data: UpdateFeatureInput!) {
        updateFeature(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DRAW = (gql: any) => gql`
    mutation updateDraw($id: ID!, $data: UpdateDrawInput!) {
        updateDraw(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_GAME = (gql: any) => gql`
    mutation updateGame($id: ID!, $data: UpdateGameInput!) {
        updateGame(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PRESET = (gql: any) => gql`
    mutation updatePreset($id: ID!, $data: UpdatePresetInput!) {
        updatePreset(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_GVERSION = (gql: any) => gql`
    mutation updateGversion($id: ID!, $data: UpdateGversionInput!) {
        updateGversion(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_GIMPORT = (gql: any) => gql`
    mutation updateGimport($id: ID!, $data: UpdateGimportInput!) {
        updateGimport(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_SEREPORT = (gql: any) => gql`
    mutation updateSereport($id: ID!, $data: UpdateSereportInput!) {
        updateSereport(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_SGREPORT = (gql: any) => gql`
    mutation updateSgreport($id: ID!, $data: UpdateSgreportInput!) {
        updateSgreport(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_GREPORT = (gql: any) => gql`
    mutation updateGreport($id: ID!, $data: UpdateGreportInput!) {
        updateGreport(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_OREPORT = (gql: any) => gql`
    mutation updateOreport($id: ID!, $data: UpdateOreportInput!) {
        updateOreport(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PREPORT = (gql: any) => gql`
    mutation updatePreport($id: ID!, $data: UpdatePreportInput!) {
        updatePreport(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_CREPORT = (gql: any) => gql`
    mutation updateCreport($id: ID!, $data: UpdateCreportInput!) {
        updateCreport(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_TREPORT = (gql: any) => gql`
    mutation updateTreport($id: ID!, $data: UpdateTreportInput!) {
        updateTreport(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_GODFATHER = (gql: any) => gql`
    mutation updateGodfather($id: ID!, $data: UpdateGodfatherInput!) {
        updateGodfather(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_INTEGRATION = (gql: any) => gql`
    mutation updateIntegration($id: ID!, $data: UpdateIntegrationInput!) {
        updateIntegration(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_KEYWORD = (gql: any) => gql`
    mutation updateKeyword($id: ID!, $data: UpdateKeywordInput!) {
        updateKeyword(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_BRAND = (gql: any) => gql`
    mutation updateBrand($id: ID!, $data: UpdateBrandInput!) {
        updateBrand(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PROJECT = (gql: any) => gql`
    mutation updateProject($id: ID!, $data: UpdateProjectInput!) {
        updateProject(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_TOKEN = (gql: any) => gql`
    mutation updateToken($id: ID!, $data: UpdateTokenInput!) {
        updateToken(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PLATFORM = (gql: any) => gql`
    mutation updatePlatform($id: ID!, $data: UpdatePlatformInput!) {
        updatePlatform(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_BUNCH = (gql: any) => gql`
    mutation updateBunch($id: ID!, $data: UpdateBunchInput!) {
        updateBunch(id: $id, data: $data) {
            id
        }
    }
`;
export const MOVE_GAME_BUNCH = (gql: any) => gql`
    mutation moveGameBunch($parentId: String!, $id: ID!, $direction: String) {
        moveGameBunch(parentId: $parentId, id: $id, direction: $direction) {
            id
        }
    }
`;
export const MOVE_GAME_PROJECT = (gql: any) => gql`
    mutation moveGameProject($parentId: String!, $id: ID!, $direction: String) {
        moveGameProject(parentId: $parentId, id: $id, direction: $direction) {
            id
        }
    }
`;
export const MOVE_GAME_SELLERGROUP = (gql: any) => gql`
    mutation moveGameSellergroup($parentId: String!, $id: ID!, $direction: String) {
        moveGameSellergroup(parentId: $parentId, id: $id, direction: $direction) {
            id
        }
    }
`;
export const MOVE_GAME_INPUT = (gql: any) => gql`
    mutation moveGameInput($parentId: String!, $id: ID!, $direction: String) {
        moveGameInput(parentId: $parentId, id: $id, direction: $direction) {
            id
        }
    }
`;
export const MOVE_GAME_PACK = (gql: any) => gql`
    mutation moveGamePack($parentId: String!, $id: ID!, $direction: String) {
        moveGamePack(parentId: $parentId, id: $id, direction: $direction) {
            id
        }
    }
`;
export const UPDATE_INPUT = (gql: any) => gql`
    mutation updateInput($id: ID!, $data: UpdateInputInput!) {
        updateInput(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DYNAUDIO = (gql: any) => gql`
    mutation updateDynaudio($id: ID!, $data: UpdateDynaudioInput!) {
        updateDynaudio(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DYNIMAGE = (gql: any) => gql`
    mutation updateDynimage($id: ID!, $data: UpdateDynimageInput!) {
        updateDynimage(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_DYNVIDEO = (gql: any) => gql`
    mutation updateDynvideo($id: ID!, $data: UpdateDynvideoInput!) {
        updateDynvideo(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_GAUDIO = (gql: any) => gql`
    mutation updateGaudio($id: ID!, $data: UpdateGaudioInput!) {
        updateGaudio(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_GATTRIBUTABLE = (gql: any) => gql`
    mutation updateGattributable($id: ID!, $data: UpdateGattributableInput!) {
        updateGattributable(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_GIMAGE = (gql: any) => gql`
    mutation updateGimage($id: ID!, $data: UpdateGimageInput!) {
        updateGimage(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_GVIDEO = (gql: any) => gql`
    mutation updateGvideo($id: ID!, $data: UpdateGvideoInput!) {
        updateGvideo(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_JOB = (gql: any) => gql`
    mutation updateJob($id: ID!, $data: UpdateJobInput!) {
        updateJob(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PACK = (gql: any) => gql`
    mutation updatePack($id: ID!, $data: UpdatePackInput!) {
        updatePack(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_TRANSLATION = (gql: any) => gql`
    mutation updateTranslation($id: ID!, $data: UpdateTranslationInput!) {
        updateTranslation(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_RUN = (gql: any) => gql`
    mutation updateRun($id: ID!, $data: UpdateRunInput!) {
        updateRun(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PAGE = (gql: any) => gql`
    mutation updatePage($id: ID!, $data: UpdatePageInput!) {
        updatePage(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_AUTOMATION = (gql: any) => gql`
    mutation updateAutomation($id: ID!, $data: UpdateAutomationInput!) {
        updateAutomation(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_SEGMENT = (gql: any) => gql`
    mutation updateSegment($id: ID!, $data: UpdateSegmentInput!) {
        updateSegment(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_LEAD = (gql: any) => gql`
    mutation updateLead($id: ID!, $data: UpdateLeadInput!) {
        updateLead(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_NOTIFICATION = (gql: any) => gql`
    mutation updateNotification($id: ID!, $data: UpdateNotificationInput!) {
        updateNotification(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_MODULE = (gql: any) => gql`
    mutation updateModule($id: ID!, $data: UpdateModuleInput!) {
        updateModule(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_OAUDIO = (gql: any) => gql`
    mutation updateOaudio($id: ID!, $data: UpdateOaudioInput!) {
        updateOaudio(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PAUDIO = (gql: any) => gql`
    mutation updatePaudio($id: ID!, $data: UpdatePaudioInput!) {
        updatePaudio(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_ODATAITEM = (gql: any) => gql`
    mutation updateOdataitem($id: ID!, $data: UpdateOdataitemInput!) {
        updateOdataitem(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_BDATAITEM = (gql: any) => gql`
    mutation updateBdataitem($id: ID!, $data: UpdateBdataitemInput!) {
        updateBdataitem(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_GACHIEVEMENT = (gql: any) => gql`
    mutation updateGachievement($id: ID!, $data: UpdateGachievementInput!) {
        updateGachievement(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_GDATAITEM = (gql: any) => gql`
    mutation updateGdataitem($id: ID!, $data: UpdateGdataitemInput!) {
        updateGdataitem(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_OFFER = (gql: any) => gql`
    mutation updateOffer($id: ID!, $data: UpdateOfferInput!) {
        updateOffer(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_OIMAGE = (gql: any) => gql`
    mutation updateOimage($id: ID!, $data: UpdateOimageInput!) {
        updateOimage(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PIMAGE = (gql: any) => gql`
    mutation updatePimage($id: ID!, $data: UpdatePimageInput!) {
        updatePimage(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_ORGANIZATION = (gql: any) => gql`
    mutation updateOrganization($id: ID!, $data: UpdateOrganizationInput!) {
        updateOrganization(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_OVERSION = (gql: any) => gql`
    mutation updateOversion($id: ID!, $data: UpdateOversionInput!) {
        updateOversion(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_OVIDEO = (gql: any) => gql`
    mutation updateOvideo($id: ID!, $data: UpdateOvideoInput!) {
        updateOvideo(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PVIDEO = (gql: any) => gql`
    mutation updatePvideo($id: ID!, $data: UpdatePvideoInput!) {
        updatePvideo(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PARTNER = (gql: any) => gql`
    mutation updatePartner($id: ID!, $data: UpdatePartnerInput!) {
        updatePartner(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PRINTER = (gql: any) => gql`
    mutation updatePrinter($id: ID!, $data: UpdatePrinterInput!) {
        updatePrinter(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PRODUCT = (gql: any) => gql`
    mutation updateProduct($id: ID!, $data: UpdateProductInput!) {
        updateProduct(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_REDIRECT = (gql: any) => gql`
    mutation updateRedirect($id: ID!, $data: UpdateRedirectInput!) {
        updateRedirect(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_REMINDER = (gql: any) => gql`
    mutation updateReminder($id: ID!, $data: UpdateReminderInput!) {
        updateReminder(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_GREMINDER = (gql: any) => gql`
    mutation updateGreminder($id: ID!, $data: UpdateGreminderInput!) {
        updateGreminder(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_OREMINDER = (gql: any) => gql`
    mutation updateOreminder($id: ID!, $data: UpdateOreminderInput!) {
        updateOreminder(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PREMINDER = (gql: any) => gql`
    mutation updatePreminder($id: ID!, $data: UpdatePreminderInput!) {
        updatePreminder(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_TREMINDER = (gql: any) => gql`
    mutation updateTreminder($id: ID!, $data: UpdateTreminderInput!) {
        updateTreminder(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_SALE = (gql: any) => gql`
    mutation updateSale($id: ID!, $data: UpdateSaleInput!) {
        updateSale(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_BOOKPAYMENT = (gql: any) => gql`
    mutation updateBookpayment($id: ID!, $data: UpdateBookpaymentInput!) {
        updateBookpayment(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_SELLER = (gql: any) => gql`
    mutation updateSeller($id: ID!, $data: UpdateSellerInput!) {
        updateSeller(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_SELLERGROUP = (gql: any) => gql`
    mutation updateSellergroup($id: ID!, $data: UpdateSellergroupInput!) {
        updateSellergroup(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_GROUPING = (gql: any) => gql`
    mutation updateGrouping($id: ID!, $data: UpdateGroupingInput!) {
        updateGrouping(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_STATEVENT = (gql: any) => gql`
    mutation updateStatevent($id: ID!, $data: UpdateStateventInput!) {
        updateStatevent(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_SVGOBJECT = (gql: any) => gql`
    mutation updateSvgobject($id: ID!, $data: UpdateSvgobjectInput!) {
        updateSvgobject(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_TENANT = (gql: any) => gql`
    mutation updateTenant($id: ID!, $data: UpdateTenantInput!) {
        updateTenant(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_THEME = (gql: any) => gql`
    mutation updateTheme($id: ID!, $data: UpdateThemeInput!) {
        updateTheme(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_HOST = (gql: any) => gql`
    mutation updateHost($id: ID!, $data: UpdateHostInput!) {
        updateHost(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_SMSSENDER = (gql: any) => gql`
    mutation updateSmssender($id: ID!, $data: UpdateSmssenderInput!) {
        updateSmssender(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_EMAILSENDER = (gql: any) => gql`
    mutation updateEmailsender($id: ID!, $data: UpdateEmailsenderInput!) {
        updateEmailsender(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_TICKET = (gql: any) => gql`
    mutation updateTicket($id: ID!, $data: UpdateTicketInput!) {
        updateTicket(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_USER = (gql: any) => gql`
    mutation updateUser($id: ID!, $data: UpdateUserInput!) {
        updateUser(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_WINNER = (gql: any) => gql`
    mutation updateWinner($id: ID!, $data: UpdateWinnerInput!) {
        updateWinner(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PRESULT = (gql: any) => gql`
    mutation updatePresult($id: ID!, $data: UpdatePresultInput!) {
        updatePresult(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_BOOK = (gql: any) => gql`
    mutation updateBook($id: ID!, $data: UpdateBookInput!) {
        updateBook(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_CAMPAIGN = (gql: any) => gql`
    mutation deleteCampaign($id: ID!) {
        deleteCampaign(id: $id) {
            id
        }
    }
`;
export const DELETE_WCONFIGURATION = (gql: any) => gql`
    mutation deleteWconfiguration($id: ID!) {
        deleteWconfiguration(id: $id) {
            id
        }
    }
`;
export const DELETE_CUSTOMER = (gql: any) => gql`
    mutation deleteCustomer($id: ID!) {
        deleteCustomer(id: $id) {
            id
        }
    }
`;
export const DELETE_WDEFINITION = (gql: any) => gql`
    mutation deleteWdefinition($id: ID!) {
        deleteWdefinition(id: $id) {
            id
        }
    }
`;
export const DELETE_CHUNKCOUNTER = (gql: any) => gql`
    mutation deleteChunkcounter($id: ID!) {
        deleteChunkcounter(id: $id) {
            id
        }
    }
`;
export const DELETE_DASHBOARD = (gql: any) => gql`
    mutation deleteDashboard($id: ID!) {
        deleteDashboard(id: $id) {
            id
        }
    }
`;
export const DELETE_DBACKGROUND = (gql: any) => gql`
    mutation deleteDbackground($id: ID!) {
        deleteDbackground(id: $id) {
            id
        }
    }
`;
export const DELETE_DDOMAIN = (gql: any) => gql`
    mutation deleteDdomain($id: ID!) {
        deleteDdomain(id: $id) {
            id
        }
    }
`;
export const DELETE_DIMAGE = (gql: any) => gql`
    mutation deleteDimage($id: ID!) {
        deleteDimage(id: $id) {
            id
        }
    }
`;
export const DELETE_DPAPERCOLOR = (gql: any) => gql`
    mutation deleteDpapercolor($id: ID!) {
        deleteDpapercolor(id: $id) {
            id
        }
    }
`;
export const DELETE_DPAPERSIZE = (gql: any) => gql`
    mutation deleteDpapersize($id: ID!) {
        deleteDpapersize(id: $id) {
            id
        }
    }
`;
export const DELETE_DPRINTCOLOR = (gql: any) => gql`
    mutation deleteDprintcolor($id: ID!) {
        deleteDprintcolor(id: $id) {
            id
        }
    }
`;
export const DELETE_DSHAPE = (gql: any) => gql`
    mutation deleteDshape($id: ID!) {
        deleteDshape(id: $id) {
            id
        }
    }
`;
export const DELETE_DSTUB = (gql: any) => gql`
    mutation deleteDstub($id: ID!) {
        deleteDstub(id: $id) {
            id
        }
    }
`;
export const DELETE_DSTUBSIZE = (gql: any) => gql`
    mutation deleteDstubsize($id: ID!) {
        deleteDstubsize(id: $id) {
            id
        }
    }
`;
export const DELETE_DSTYLING = (gql: any) => gql`
    mutation deleteDstyling($id: ID!) {
        deleteDstyling(id: $id) {
            id
        }
    }
`;
export const DELETE_DTEMPLATE = (gql: any) => gql`
    mutation deleteDtemplate($id: ID!) {
        deleteDtemplate(id: $id) {
            id
        }
    }
`;
export const DELETE_DTEXT = (gql: any) => gql`
    mutation deleteDtext($id: ID!) {
        deleteDtext(id: $id) {
            id
        }
    }
`;
export const DELETE_DTHEME = (gql: any) => gql`
    mutation deleteDtheme($id: ID!) {
        deleteDtheme(id: $id) {
            id
        }
    }
`;
export const DELETE_DESIGN = (gql: any) => gql`
    mutation deleteDesign($id: ID!) {
        deleteDesign(id: $id) {
            id
        }
    }
`;
export const DELETE_DOCUMENT = (gql: any) => gql`
    mutation deleteDocument($id: ID!) {
        deleteDocument(id: $id) {
            id
        }
    }
`;
export const DELETE_FEATURE = (gql: any) => gql`
    mutation deleteFeature($id: ID!) {
        deleteFeature(id: $id) {
            id
        }
    }
`;
export const DELETE_DRAW = (gql: any) => gql`
    mutation deleteDraw($id: ID!) {
        deleteDraw(id: $id) {
            id
        }
    }
`;
export const DELETE_GAME = (gql: any) => gql`
    mutation deleteGame($id: ID!) {
        deleteGame(id: $id) {
            id
        }
    }
`;
export const DELETE_PRESET = (gql: any) => gql`
    mutation deletePreset($id: ID!) {
        deletePreset(id: $id) {
            id
        }
    }
`;
export const DELETE_GVERSION = (gql: any) => gql`
    mutation deleteGversion($id: ID!) {
        deleteGversion(id: $id) {
            id
        }
    }
`;
export const DELETE_GIMPORT = (gql: any) => gql`
    mutation deleteGimport($id: ID!) {
        deleteGimport(id: $id) {
            id
        }
    }
`;
export const DELETE_SEREPORT = (gql: any) => gql`
    mutation deleteSereport($id: ID!) {
        deleteSereport(id: $id) {
            id
        }
    }
`;
export const DELETE_SGREPORT = (gql: any) => gql`
    mutation deleteSgreport($id: ID!) {
        deleteSgreport(id: $id) {
            id
        }
    }
`;
export const DELETE_GREPORT = (gql: any) => gql`
    mutation deleteGreport($id: ID!) {
        deleteGreport(id: $id) {
            id
        }
    }
`;
export const DELETE_OREPORT = (gql: any) => gql`
    mutation deleteOreport($id: ID!) {
        deleteOreport(id: $id) {
            id
        }
    }
`;
export const DELETE_PREPORT = (gql: any) => gql`
    mutation deletePreport($id: ID!) {
        deletePreport(id: $id) {
            id
        }
    }
`;
export const DELETE_CREPORT = (gql: any) => gql`
    mutation deleteCreport($id: ID!) {
        deleteCreport(id: $id) {
            id
        }
    }
`;
export const DELETE_TREPORT = (gql: any) => gql`
    mutation deleteTreport($id: ID!) {
        deleteTreport(id: $id) {
            id
        }
    }
`;
export const DELETE_GODFATHER = (gql: any) => gql`
    mutation deleteGodfather($id: ID!) {
        deleteGodfather(id: $id) {
            id
        }
    }
`;
export const DELETE_INTEGRATION = (gql: any) => gql`
    mutation deleteIntegration($id: ID!) {
        deleteIntegration(id: $id) {
            id
        }
    }
`;
export const DELETE_KEYWORD = (gql: any) => gql`
    mutation deleteKeyword($id: ID!) {
        deleteKeyword(id: $id) {
            id
        }
    }
`;
export const DELETE_BRAND = (gql: any) => gql`
    mutation deleteBrand($id: ID!) {
        deleteBrand(id: $id) {
            id
        }
    }
`;
export const DELETE_PROJECT = (gql: any) => gql`
    mutation deleteProject($id: ID!) {
        deleteProject(id: $id) {
            id
        }
    }
`;
export const DELETE_PLATFORM = (gql: any) => gql`
    mutation deletePlatform($id: ID!) {
        deletePlatform(id: $id) {
            id
        }
    }
`;
export const DELETE_TOKEN = (gql: any) => gql`
    mutation deleteToken($id: ID!) {
        deleteToken(id: $id) {
            id
        }
    }
`;
export const DELETE_BUNCH = (gql: any) => gql`
    mutation deleteBunch($id: ID!) {
        deleteBunch(id: $id) {
            id
        }
    }
`;
export const DELETE_INPUT = (gql: any) => gql`
    mutation deleteInput($id: ID!) {
        deleteInput(id: $id) {
            id
        }
    }
`;
export const DELETE_DYNAUDIO = (gql: any) => gql`
    mutation deleteDynaudio($id: ID!) {
        deleteDynaudio(id: $id) {
            id
        }
    }
`;
export const DELETE_DYNIMAGE = (gql: any) => gql`
    mutation deleteDynimage($id: ID!) {
        deleteDynimage(id: $id) {
            id
        }
    }
`;
export const DELETE_DYNVIDEO = (gql: any) => gql`
    mutation deleteDynvideo($id: ID!) {
        deleteDynvideo(id: $id) {
            id
        }
    }
`;
export const DELETE_GAUDIO = (gql: any) => gql`
    mutation deleteGaudio($id: ID!) {
        deleteGaudio(id: $id) {
            id
        }
    }
`;
export const DELETE_GATTRIBUTABLE = (gql: any) => gql`
    mutation deleteGattributable($id: ID!) {
        deleteGattributable(id: $id) {
            id
        }
    }
`;
export const DELETE_GIMAGE = (gql: any) => gql`
    mutation deleteGimage($id: ID!) {
        deleteGimage(id: $id) {
            id
        }
    }
`;
export const DELETE_GVIDEO = (gql: any) => gql`
    mutation deleteGvideo($id: ID!) {
        deleteGvideo(id: $id) {
            id
        }
    }
`;
export const DELETE_JOB = (gql: any) => gql`
    mutation deleteJob($id: ID!) {
        deleteJob(id: $id) {
            id
        }
    }
`;
export const DELETE_PACK = (gql: any) => gql`
    mutation deletePack($id: ID!) {
        deletePack(id: $id) {
            id
        }
    }
`;
export const DELETE_AUTOMATION = (gql: any) => gql`
    mutation deleteAutomation($id: ID!) {
        deleteAutomation(id: $id) {
            id
        }
    }
`;
export const DELETE_SEGMENT = (gql: any) => gql`
    mutation deleteSegment($id: ID!) {
        deleteSegment(id: $id) {
            id
        }
    }
`;
export const DELETE_TRANSLATION = (gql: any) => gql`
    mutation deleteTranslation($id: ID!) {
        deleteTranslation(id: $id) {
            id
        }
    }
`;
export const DELETE_RUN = (gql: any) => gql`
    mutation deleteRun($id: ID!) {
        deleteRun(id: $id) {
            id
        }
    }
`;
export const DELETE_PAGE = (gql: any) => gql`
    mutation deletePage($id: ID!) {
        deletePage(id: $id) {
            id
        }
    }
`;
export const DELETE_LEAD = (gql: any) => gql`
    mutation deleteLead($id: ID!) {
        deleteLead(id: $id) {
            id
        }
    }
`;
export const DELETE_NOTIFICATION = (gql: any) => gql`
    mutation deleteNotification($id: ID!) {
        deleteNotification(id: $id) {
            id
        }
    }
`;
export const DELETE_MODULE = (gql: any) => gql`
    mutation deleteModule($id: ID!) {
        deleteModule(id: $id) {
            id
        }
    }
`;
export const DELETE_OAUDIO = (gql: any) => gql`
    mutation deleteOaudio($id: ID!) {
        deleteOaudio(id: $id) {
            id
        }
    }
`;
export const DELETE_PAUDIO = (gql: any) => gql`
    mutation deletePaudio($id: ID!) {
        deletePaudio(id: $id) {
            id
        }
    }
`;
export const DELETE_ODATAITEM = (gql: any) => gql`
    mutation deleteOdataitem($id: ID!) {
        deleteOdataitem(id: $id) {
            id
        }
    }
`;
export const DELETE_BDATAITEM = (gql: any) => gql`
    mutation deleteBdataitem($id: ID!) {
        deleteBdataitem(id: $id) {
            id
        }
    }
`;
export const DELETE_GACHIEVEMENT = (gql: any) => gql`
    mutation deleteGachievement($id: ID!) {
        deleteGachievement(id: $id) {
            id
        }
    }
`;

export const DELETE_GDATAITEM = (gql: any) => gql`
    mutation deleteGdataitem($id: ID!) {
        deleteGdataitem(id: $id) {
            id
        }
    }
`;
export const DELETE_OFFER = (gql: any) => gql`
    mutation deleteOffer($id: ID!) {
        deleteOffer(id: $id) {
            id
        }
    }
`;
export const DELETE_OIMAGE = (gql: any) => gql`
    mutation deleteOimage($id: ID!) {
        deleteOimage(id: $id) {
            id
        }
    }
`;
export const DELETE_PIMAGE = (gql: any) => gql`
    mutation deletePimage($id: ID!) {
        deletePimage(id: $id) {
            id
        }
    }
`;
export const DELETE_ORGANIZATION = (gql: any) => gql`
    mutation deleteOrganization($id: ID!) {
        deleteOrganization(id: $id) {
            id
        }
    }
`;
export const DELETE_OVERSION = (gql: any) => gql`
    mutation deleteOversion($id: ID!) {
        deleteOversion(id: $id) {
            id
        }
    }
`;
export const DELETE_OVIDEO = (gql: any) => gql`
    mutation deleteOvideo($id: ID!) {
        deleteOvideo(id: $id) {
            id
        }
    }
`;
export const DELETE_PVIDEO = (gql: any) => gql`
    mutation deletePvideo($id: ID!) {
        deletePvideo(id: $id) {
            id
        }
    }
`;
export const DELETE_PARTNER = (gql: any) => gql`
    mutation deletePartner($id: ID!) {
        deletePartner(id: $id) {
            id
        }
    }
`;
export const DELETE_PRINTER = (gql: any) => gql`
    mutation deletePrinter($id: ID!) {
        deletePrinter(id: $id) {
            id
        }
    }
`;
export const DELETE_PRODUCT = (gql: any) => gql`
    mutation deleteProduct($id: ID!) {
        deleteProduct(id: $id) {
            id
        }
    }
`;
export const DELETE_REDIRECT = (gql: any) => gql`
    mutation deleteRedirect($id: ID!) {
        deleteRedirect(id: $id) {
            id
        }
    }
`;
export const DELETE_REMINDER = (gql: any) => gql`
    mutation deleteReminder($id: ID!) {
        deleteReminder(id: $id) {
            id
        }
    }
`;
export const DELETE_GREMINDER = (gql: any) => gql`
    mutation deleteGreminder($id: ID!) {
        deleteGreminder(id: $id) {
            id
        }
    }
`;
export const DELETE_OREMINDER = (gql: any) => gql`
    mutation deleteOreminder($id: ID!) {
        deleteOreminder(id: $id) {
            id
        }
    }
`;
export const DELETE_PREMINDER = (gql: any) => gql`
    mutation deletePreminder($id: ID!) {
        deletePreminder(id: $id) {
            id
        }
    }
`;
export const DELETE_TREMINDER = (gql: any) => gql`
    mutation deleteTreminder($id: ID!) {
        deleteTreminder(id: $id) {
            id
        }
    }
`;
export const DELETE_SALE = (gql: any) => gql`
    mutation deleteSale($id: ID!) {
        deleteSale(id: $id) {
            id
        }
    }
`;
export const DELETE_BOOKPAYMENT = (gql: any) => gql`
    mutation deleteBookpayment($id: ID!) {
        deleteBookpayment(id: $id) {
            id
        }
    }
`;
export const DELETE_SELLER = (gql: any) => gql`
    mutation deleteSeller($id: ID!) {
        deleteSeller(id: $id) {
            id
        }
    }
`;
export const DELETE_SELLERGROUP = (gql: any) => gql`
    mutation deleteSellergroup($id: ID!) {
        deleteSellergroup(id: $id) {
            id
        }
    }
`;
export const DELETE_GROUPING = (gql: any) => gql`
    mutation deleteGrouping($id: ID!) {
        deleteGrouping(id: $id) {
            id
        }
    }
`;
export const DELETE_STATEVENT = (gql: any) => gql`
mutation deleteStatevent($id: ID!) {
    deleteStatevent(id: $id) {
        id
    }
}
`;
export const DELETE_SVGOBJECT = (gql: any) => gql`
    mutation deleteSvgobject($id: ID!) {
        deleteSvgobject(id: $id) {
            id
        }
    }
`;
export const DELETE_TENANT = (gql: any) => gql`
    mutation deleteTenant($id: ID!) {
        deleteTenant(id: $id) {
            id
        }
    }
`;
export const DELETE_THEME = (gql: any) => gql`
    mutation deleteTheme($id: ID!) {
        deleteTheme(id: $id) {
            id
        }
    }
`;
export const DELETE_HOST = (gql: any) => gql`
    mutation deleteHost($id: ID!) {
        deleteHost(id: $id) {
            id
        }
    }
`;
export const DELETE_SMSSENDER = (gql: any) => gql`
    mutation deleteSmssender($id: ID!) {
        deleteSmssender(id: $id) {
            id
        }
    }
`;
export const DELETE_EMAILSENDER = (gql: any) => gql`
    mutation deleteEmailsender($id: ID!) {
        deleteEmailsender(id: $id) {
            id
        }
    }
`;
export const DELETE_TICKET = (gql: any) => gql`
    mutation deleteTicket($id: ID!) {
        deleteTicket(id: $id) {
            id
        }
    }
`;
export const DELETE_USER = (gql: any) => gql`
    mutation deleteUser($id: ID!) {
        deleteUser(id: $id) {
            id
        }
    }
`;
export const DELETE_WINNER = (gql: any) => gql`
    mutation deleteWinner($id: ID!) {
        deleteWinner(id: $id) {
            id
        }
    }
`;
export const DELETE_PRESULT = (gql: any) => gql`
    mutation deletePresult($id: ID!) {
        deletePresult(id: $id) {
            id
        }
    }
`;
export const DELETE_BOOK = (gql: any) => gql`
    mutation deleteBook($id: ID!) {
        deleteBook(id: $id) {
            id
        }
    }
`;
export const QUERY_SEGMENT = (gql: any) => gql`
    query querySegment($id: ID!) {
        querySegment(id: $id) {
            cursor
            count
            items {
                ...on User {
                    id
                    email
                }
                ...on Lead {
                    id
                    publicEmail
                }
                ...on Game {
                    id
                    code
                    name
                    status
                }
                ...on Organization {
                    id
                    code
                    name
                    status
                }
            }
        }
    }
`;
export const SQL_SEARCH = (gql: any) => gql`
    query sqlSearch($sql: String!) {
        sqlSearch(sql: $sql) {
            items
            count
        }
    }
`;