const t = {
    icon: 'fa-box',
    menus: {
        main: {section: 'settings', priority: 55},
    },
    new: {
        attributes: [
            'name', 'game', 'locale', 'country', 'tickets', 'ticketBooks', 'donation', 'currency', 'overline', 'price',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$game!', '$tickets', '$price',
                ]],
                ['block', [
                    '$locale', '$country', '$overline'
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'game', 'locale', 'country', 'tickets', 'ticketBooks', 'donation', 'currency', 'overline', 'price',
        ],
        content: [
            ['row', [
                ['block', [
                    '$code:readonly!', '!$name!', '$game!', '$tickets', '$price',
                ]],
                ['block', [
                    '$locale', '$country', '$overline'
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'name', 'game', 'gameName', 'organization', 'organizationName', 'overline', 'price', 'tickets',
        ],
    },
    list: {
        columns: [
            {id: 'game', label: 'column_game_code_label', flex: 0, width: 350, component: 'game'},
            {id: 'code', label: 'column_code_label'},
            {id: 'name', label: 'column_name_label'},
            {id: 'price', label: 'column_price_label'},
            {id: 'tickets', label: 'column_tickets_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;