export const CREATE_REDIRECT = (gql: any) => gql`
    mutation($data: CreateRedirectInput!) {
        createRedirect(data: $data) {
            id
            code
        }
    }
`;
export const GET_REDIRECT = (gql: any) => gql`
    query($id: ID!) {
        getRedirect(id: $id) {
            id
            code
            url
            ttl
            createdAt
            updatedAt
            status
            tenant
            tenantCode
            tenantName
        }
    }
`;
export const FIND_REDIRECTS = (gql: any) => gql`
    query findRedirects($offset: String, $limit: Int) {
        findRedirects(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                url
                ttl
                createdAt
                updatedAt
                status
                tenant
                tenantCode
                tenantName
            }
        }
    }
`;