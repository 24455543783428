const item = `
    id
    code
    tenant
    tenantCode
    tenantName
    partner
    partnerCode
    partnerName
    organization
    organizationCode
    organizationName
    game
    gameCode
    gameName
    sellergroup
    sellergroupCode
    sellergroupName
    seller
    sellerCode
    sellerFirstName
    sellerLastName
    createdAt
    updatedAt
    locale
    text
    type
    domain
    main
    lock
`
const page = `cursor count items { ${item} }`;

// noinspection JSUnusedGlobalSymbols
export const CREATE_TRANSLATION = (gql: any) => gql`
    mutation($data: CreateTranslationInput!) {
        createTranslation(data: $data) { id }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const GET_TRANSLATION = (gql: any) => gql`
    query($id: ID!) {
        getTranslation(id: $id) { ${item} }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const FIND_TRANSLATIONS = (gql: any) => gql`
    query findTranslations($offset: String, $limit: Int) {
        findTranslations(offset: $offset, limit: $limit) { ${page} }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_GAME_TRANSLATIONS = (gql: any) => gql`
    query($id: String!, $offset: String, $limit: Int) {
        findGameTranslations(id: $id, offset: $offset, limit: $limit) { ${page} }
    }
`;
export const SEARCH_TRANSLATIONS = (gql: any) => gql`
    query searchTranslations($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchTranslations(offset: $offset, limit: $limit, sort: $sort) { ${page} }
    }
`;
