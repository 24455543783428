export const CREATE_WDEFINITION = (gql: any) => gql`
    mutation($data: CreateWdefinitionInput!) {
        createWdefinition(data: $data) {
            id
        }
    }
`;
export const GET_WDEFINITION = (gql: any) => gql`
    query($id: ID!) {
        getWdefinition(id: $id) {
            id
            status
            name
            description
            key
        }
    }
`;
export const FIND_WDEFINITIONS = (gql: any) => gql`
    query findWdefinitions($offset: String, $limit: Int) {
        findWdefinitions(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                description
                status
                key
            }
        }
    }
`;