const t = {
    icon: 'fa-ticket',
    menus: {
        main: { section: 'settings', priority: 45 },
    },
    new: {
        attributes: [
            'code',
            'currency',
            'customCode',
            'email',
            'firstName',
            'game',
            'lastName',
            'phone',
            'salesInfo',
            'seller',
            'sellergroup',
            'type',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$game', '$firstName', '$lastName', '$email', '$phone', '$salesInfo',
                ]],
                ['block', [
                    '$code', '$currency', '$customCode', '$sellergroup', '$seller',  '$type'
                ]]
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'code',
            'currency',
            'customCode',
            'email',
            'firstName',
            'game',
            'lastName',
            'phone',
            'salesInfo',
            'seller',
            'sellergroup',
            'type',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$game', '$firstName', '$lastName', '$email', '$phone', '$salesInfo'
                ]],
                ['block', [
                    '$code', '$currency', '$customCode', '$seller', '$sellergroup', '$type'
                ]]
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: [
            'code', 'game', 'gameCode', 'gameName', 'organization','organizationName', 'organizationCode', 'paymentStatus', 'sellergroup', 'customCode', 'attributionStatus', 'sellergroup', 'sellergroupName', 'sellergroupCode', 'seller', 'sellerLastName', 'sellerFirstName'
        ],
    },
    list: {
        columns: [
            { id: 'gameCode', label: 'column_game_code_label', component: 'game', flex: 0, width: 300 },
            { id: 'customCode', label: 'column_customcode_label', flex: 0, width: 80 },
            { id: 'code', label: 'column_code_label', flex: 0, width: 100 } as any,
            { id: 'attributionStatus', label: 'column_attribution_status_label', component:'book_attribution_status', flex: 0, width: 160 },
            { id: 'paymentStatus', label: 'column_payment_status_label',component: 'book_payment_status', flex: 1 },
            { id: 'sellergroup', label: 'column_sellergroup_code_label', component: 'seller_sellergroup', flex: 0, width: 300 },
            { id: 'seller', label: 'column_seller_label', component: 'book_seller', flex: 0, width: 300 },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
        filters: {
            attributed: {},
            paid: {},
            verified: {},
            partiallyPaid: {},
            all: {},
        }
    }
};

export default t;