const t = {
    icon: 'fa-key',
    menus: {
        main: {section: 'settings2', priority: 25},
    },
    new: {
        attributes: [
            'code', 'game', 'name', 'features', 'status', 'type',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$code!', '$game!',
                ]],
                ['block', [
                    '$name', '$features',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'code', 'game', 'name', 'features', 'status', 'type',
        ],
        content: [
            ['row', [
                ['block', [
                    '$status:readonly!', '!$code!', '$game!',
                ]],
                ['block', [
                    '$name', '$features',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'gameName', 'code', 'organizationName'
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 0, width: 200} as any,
            {id: 'gameCode', label: 'column_game_code_label', flex: 0, width: 120},
            {id: 'gameName', label: 'column_game_label'},
            {id: 'organizationCode', label: 'column_organization_code_label', flex: 0, width: 150},
            {id: 'organizationName', label: 'column_organization_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;