const t = {
    plural: 'beneficiaries',
    icon: 'fa-gift',
    menus: {
        main: {section: 'settings3', priority: 25},
    },
    new: {
        attributes: [
            'name', 'game', 'locale', 'country', 'features', 'logoImage', 'qrcodeLogoImage', 'mainImage', 'thumbnailImage',
            'plannedDonationRatio', 'description', 'presentation', 'websiteUrl', 'donationUrl',
            'image1Image', 'image2Image', 'image3Image', 'image4Image', 'image5Image', 'image6Image', 'image7Image', 'image8Image', 'image9Image',
        ],
        content: [
            ['row', [
                ['block', [
                    '$name!', '$game!', '$plannedDonationRatio', '$description', '$presentation', '$websiteUrl', '$donationUrl',
                ]],
                ['block', [
                    '$locale', '$country', '$features', '$logoImage', '$qrcodeLogoImage', '$mainImage', '$thumbnailImage',
                    '$image1Image', '$image2Image', '$image3Image', '$image4Image', '$image5Image', '$image6Image', '$image7Image', '$image8Image', '$image9Image',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'game', 'locale', 'country', 'features', 'logoImage', 'qrcodeLogoImage', 'mainImage', 'thumbnailImage',
            'plannedDonationRatio', 'description', 'presentation', 'websiteUrl', 'donationUrl',
            'image1Image', 'image2Image', 'image3Image', 'image4Image', 'image5Image', 'image6Image', 'image7Image', 'image8Image', 'image9Image',
        ],
        content: [
            ['row', [
                ['block', [
                    '$code:readonly!', '!$name!', '$game!', '$plannedDonationRatio',
                    '$description', '$presentation', '$websiteUrl', '$donationUrl',
                ]],
                ['block', [
                    '$locale', '$country', '$features', '$logoImage', '$qrcodeLogoImage', '$mainImage', '$thumbnailImage',
                    '$image1Image', '$image2Image', '$image3Image', '$image4Image', '$image5Image', '$image6Image', '$image7Image', '$image8Image', '$image9Image',
                ]]
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'code', 'name', 'gameCode', 'gameName', 'organizationCode', 'organizationName', 'locale', 'country'
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 0, width: 200} as any,
            {id: 'name', label: 'column_name_label'} as any,
            {id: 'game', label: 'column_game_label', flex: 1, width: 120, component: 'game'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;