const t = {
    icon: 'fa-users',
    menus: {
        main: {section: 'settings3', icon: 'fa-credit-card', priority: 68},
    },
    new: {
        attributes: [
            'tenant', 'organization', 'paymentProvider', 'paymentProviderCustomer', 'paymentProviderPaymentMethod', 'stripeCustomer', 'inlinePreset', 'presets',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$tenant!', '$organization', '$inlinePreset', '$presets',
                ]],
                ['block', [
                    '$paymentProvider', '$paymentProviderCustomer', '$paymentProviderPaymentMethod', '$stripeCustomer',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'tenant', 'organization', 'paymentProvider', 'paymentProviderCustomer', 'paymentProviderPaymentMethod', 'stripeCustomer',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$tenant!', '$organization',
                ]],
                ['block', [
                    '$paymentProvider', '$paymentProviderCustomer', '$paymentProviderPaymentMethod', '$stripeCustomer',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'organizationName', 'organizationCode', 'paymentProvider', 'paymentProviderCustomer', 'paymentProviderPaymentMethod', 'stripeCustomer',
        ],
    },
    list: {
        columns: [
            {id: 'organization', label: 'column_organization_label', component: 'organization'},
            {id: 'paymentprovider', label: 'column_paymentprovider_label', flex: 0, width: 200} as any,
            {id: 'paymentprovidercustomer', label: 'column_paymentprovidercustomer_label', flex: 0, width: 200} as any,
        ],
        actions: ['display', 'delete'],
    },
};

export default t;