export const CREATE_DTEMPLATE = (gql: any) => gql`
    mutation($data: CreateDtemplateInput!) {
        createDtemplate(data: $data) {
            id
        }
    }
`;
export const GET_DTEMPLATE = (gql: any) => gql`
    query($id: ID!) {
        getDtemplate(id: $id) {
            id
            tenant
            code
            name
            definition
            tags
        }
    }
`;
export const FIND_DTEMPLATES = (gql: any) => gql`
    query findDtemplates($offset: String, $limit: Int) {
        findDtemplates(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                definition
                tags
            }
        }
    }
`;