export const CREATE_BDATAITEM = (gql: any) => gql`
    mutation($data: CreateBdataitemInput!) {
        createBdataitem(data: $data) {
            id
        }
    }
`;
export const GET_BDATAITEM = (gql: any) => gql`
    query($id: ID!) {
        getBdataitem(id: $id) {
            id
            name
            category
            subcategory
            tenant
            tenantCode
            tenantName
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            partner
            partnerCode
            partnerName
            createdAt
            updatedAt
            country
            locale
            presets
        }
    }
`;
export const FIND_BDATAITEMS = (gql: any) => gql`
    query findBdataitems($offset: String, $limit: Int) {
        findBdataitems(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                category
                subcategory
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                createdAt
                updatedAt
                country
                locale
                presets
            }
        }
    }
`;