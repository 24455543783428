export const CREATE_PREPORT = (gql: any) => gql`
    mutation($data: CreatePreportInput!) {
        createPreport(data: $data) {
            id
        }
    }
`;
export const GET_PREPORT = (gql: any) => gql`
    query($id: ID!) {
        getPreport(id: $id) {
            id
            createdAt
            updatedAt
            startDate
            type
            typeParentPeriod
            period
            year
            semester
            month
            week
            monthWeek
            day
            hour
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
        }
    }
`;
export const FIND_PREPORTS = (gql: any) => gql`
    query findPreports($offset: String, $limit: Int) {
        findPreports(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                partner
                partnerCode
                partnerName
                paidSales
                paidTickets
                paymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_PREPORTS = (gql: any) => gql`
    query searchPreports($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchPreports(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                partner
                partnerCode
                partnerName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_ALL_PREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchAllPreports:searchPreports(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                partner
                partnerCode
                partnerName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_YEAR_PREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchYearPreports:searchPreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "YEAR", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                partner
                partnerCode
                partnerName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_MONTH_PREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchMonthPreports:searchPreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "MONTH", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                partner
                partnerCode
                partnerName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_WEEK_PREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchWeekPreports:searchPreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "WEEK", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                partner
                partnerCode
                partnerName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_DAY_PREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchDayPreports:searchPreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "DAY", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                partner
                partnerCode
                partnerName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_HOUR_PREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchHourPreports:searchPreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "HOUR", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                partner
                partnerCode
                partnerName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
