export const CREATE_BRAND = (gql: any) => gql`
    mutation($data: CreateBrandInput!) {
        createBrand(data: $data) {
            id
        }
    }
`;
export const GET_BRAND = (gql: any) => gql`
    query($id: ID!) {
        getBrand(id: $id) {
            id
            code
            name
            tenant
            tenantCode
            tenantName
            logoImage  {
                available
                size
                url
            }
            logoAltImage  {
                available
                size
                url
            }
            bannerImage  {
                available
                size
                url
            }
            mobileBannerImage  {
                available
                size
                url
            }
            tabletBannerImage  {
                available
                size
                url
            }
            banner2Image  {
                available
                size
                url
            }
            mainImage  {
                available
                size
                url
            }
            appleTouchImage  {
                available
                size
                url
            }
            faviconImage  {
                available
                size
                url
            }
            presentation
            description
            websiteUrl
            facebookUrl
            twitterUrl
            youtubeUrl
            instagramUrl
            privateToken
            publicToken
            token
        }
    }
`;
export const FIND_BRANDS = (gql: any) => gql`
    query findBrands($offset: String, $limit: Int) {
        findBrands(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                tenant
                tenantCode
                tenantName
                logoImage  {
                    available
                    size
                    url
                }
                logoAltImage  {
                    available
                    size
                    url
                }
                bannerImage  {
                    available
                    size
                    url
                }
                mobileBannerImage  {
                    available
                    size
                    url
                }
                tabletBannerImage  {
                    available
                    size
                    url
                }
                mainImage  {
                    available
                    size
                    url
                }
                websiteUrl
            }
        }
    }
`;