const t = {
    icon: 'fa-tag',
    menus: {
        main: {section: 'settings3', priority: 50},
    },
    new: {
        attributes: [
            'code', 'name', 'tenant', 'type',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$code!', '$name!', '$tenant!', '$type',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'code', 'name', 'tenant', 'type',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$code!', '$name!', '$tenant!', '$type',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'code', 'name', 'type',
        ],
    },
    list: {
        columns: [
            {id: 'name', label: 'column_name_label'},
            {id: 'code', label: 'column_code_label'},
            {id: 'type', label: 'column_type_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;