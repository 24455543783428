export const CREATE_INVOICE = (gql: any) => gql`
    mutation($data: CreateInvoiceInput!) {
        createInvoice(data: $data) {
            id
        }
    }
`;
export const DELETE_INVOICE = (gql: any) => gql`
    mutation($id: ID!) {
        deleteInvoice(id: $id) {
            id
        }
    }
`;
export const FIND_INVOICES = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findInvoices(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                adminCanceledAt
                archivedAt
                canceledAt
                code
                createdAt
                id
                inlinePreset
                notPaidAt
                paidAt
                presets
                privateToken
                publicToken
                status
                statusAt
                tenant
                tenantCode
                tenantName
                token
                updatedAt
            }
        }
    }
`;
export const GET_INVOICE = (gql: any) => gql`
    query($id: ID!) {
        getInvoice(id: $id) {
            adminCanceledAt
            archivedAt
            canceledAt
            code
            createdAt
            id
            inlinePreset
            notPaidAt
            paidAt
            presets
            privateToken
            publicToken
            status
            statusAt
            tenant
            tenantCode
            tenantName
            token
            updatedAt
        }
    }
`;
export const SEARCH_INVOICES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchInvoices(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                adminCanceledAt
                archivedAt
                canceledAt
                code
                createdAt
                id
                inlinePreset
                notPaidAt
                paidAt
                presets
                privateToken
                publicToken
                status
                statusAt
                tenant
                tenantCode
                tenantName
                token
                updatedAt
            }
        }
    }
`;
export const UPDATE_INVOICE = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateInvoiceInput!) {
        updateInvoice(id: $id, data: $data) {
            id
        }
    }
`;
