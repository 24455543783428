export const CREATE_RUN = (gql: any) => gql`
    mutation($data: CreateRunInput!) {
        createRun(data: $data) {
            id
        }
    }
`;
export const GET_RUN = (gql: any) => gql`
    query($id: ID!) {
        getRun(id: $id) {
            id
            status
            token
            privateToken
            publicToken
            tenant
            tenantCode
            tenantName
            createdAt
            updatedAt
            definition
            params
            details
            processedItems
            erroredItems
            succeededItems
            automation
            result
        }
    }
`;
export const FIND_RUNS = (gql: any) => gql`
    query findRuns($offset: String, $limit: Int) {
        findRuns(offset: $offset, limit: $limit) {
            cursor
            count
            items {
            	id
            	createdAt
                updatedAt
                status
                token
                privateToken
                publicToken
                tenant
                tenantCode
                definition
                params
                details
                processedItems
                erroredItems
                succeededItems
                automation
                result
            }
        }
    }
`;