const t =  {
    menus: {
        main: {section: 'settings3', priority: 47, icon: 'fa-envelope'},
    },
    new: {
        attributes: [
            'campaign', 'email', 'firstName', 'game', 'inlinePreset', 'lastName', 'maxAttempts', 'organization',
            'owner', 'params', 'partner', 'phone', 'preferredNotificationMethod', 'presets', 'tenant', 'type', 'user',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$campaign', '$game', '$organization', '$owner', '$partner', '$user', '$inlinePreset', '$invitationPresets',
                ]],
                ['block', [
                    '$type!', '$maxAttempts', '$email', '$firstName', '$lastName', '$phone', '$preferredNotificationMethod', '$params',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'campaign', 'email', 'firstName', 'game', 'lastName', 'maxAttempts', 'organization', 'owner', 'params',
            'partner', 'phone', 'preferredNotificationMethod', 'tenant', 'type', 'user',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$campaign', '$game', '$organization', '$owner', '$partner', '$user',
                ]],
                ['block', [
                    '$type', '$maxAttempts', '$email', '$firstName', '$lastName', '$phone', '$preferredNotificationMethod', '$params',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'attempts', 'campaign', 'campaignCode', 'campaignName', 'createdAt', 'email', 'firstName', 'game', 'gameCode', 'gameName',
            'lastName', 'maxAttempts', 'organization', 'organizationCode', 'organizationName', 'owner', 'ownerCode', 'ownerEmail', 'ownerPhone',
            'params', 'partner', 'partnerCode', 'partnerName', 'phone', 'preferredNotificationMethod', 'publicPageShortUrl', 'publicPageUrl',
            'secretCode', 'status', 'statusAt', 'tenant', 'tenantCode', 'tenantName', 'type', 'user', 'userCode', 'userEmail', 'userFirstName',
            'userLastName', 'userPhone',
        ],
    },
    list: {
        columns: [
            { id: 'createdAt', label: 'column_createdat_label', component: 'date', flex: 0, width: 150 },
            { id: 'type', label: 'column_type_label', flex: 1 } as any,
            { id: 'owner', label: 'column_email_label', component: 'owner' },
            { id: 'preferredNotificationMethod', label: 'column_preferrednotificationmethod_label' },
            { id: 'attempts', label: 'column_attempts_label', flex: 1 } as any,
            { id: 'maxAttempts', label: 'column_maxattempts_label', flex: 1 } as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;