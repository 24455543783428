export const CREATE_PAGE = (gql: any) => gql`
    mutation($data: CreatePageInput!) {
        createPage(data: $data) {
            id
        }
    }
`;
export const GET_PAGE = (gql: any) => gql`
    query($id: ID!) {
        getPage(id: $id) {
            id
            code
            tenant
            tenantCode
            game
            gameCode
            organization
            organizationCode
            campaign
            campaignCode
            partner
            partnerCode
            key
            mode
            content
        }
    }
`;
export const FIND_PAGES = (gql: any) => gql`
    query findPages($offset: String, $limit: Int) {
        findPages(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                game
                gameCode
                organization
                organizationCode
                campaign
                campaignCode
                partner
                partnerCode
                tenant
                tenantCode
                key
                mode
                content
            }
        }
    }
`;