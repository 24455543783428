export const CREATE_SELLER = (gql: any) => gql`
    mutation($data: CreateSellerInput!) {
        createSeller(data: $data) {
            id
            code
        }
    }
`;
export const GET_SELLER = (gql: any) => gql`
    query($id: ID!) {
        getSeller(id: $id) {
            id
            token
            code
            firstName
            lastName
            email
            phone
            sellergroup
            sellergroupCode
            sellergroupName
            partner
            partnerCode
            partnerName
            organization
            organizationCode
            organizationName
            game
            gameCode
            gameName
            tenant
            tenantCode
            tenantName
            country
            locale
            stripeCustomer
        }
    }
`;
export const FIND_SELLERS = (gql: any) => gql`
    query findSellers($offset: String, $limit: Int) {
        findSellers(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id 
                token
                code
                firstName
                lastName
                email
                phone
                sellergroup
                sellergroupCode
                sellergroupName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                tenant
                tenantCode
                tenantName
                country
                locale
            }
        }
    }
`;