const t = {
    icon: 'fa-building',
    menus: {
        main: {section: 'customers', priority: 25},
    },
    new: {
        attributes: [
            'code', 'name', 'domain', 'shortName', 'fullName', 'legalName', 'nin', 'overline', 'publicEmail',
            'publicPhone', 'websiteUrl', 'twitterUrl', 'facebookUrl', 'instagramUrl', 'linkedinUrl', 'youtubeUrl',
            'pinterestUrl', 'tumblrUrl', 'flickrUrl', 'redditUrl', 'tiktokUrl', 'vimeoUrl', 'snapchatUrl', 'logoImage',
            'faviconImage', 'appleTouchImage', 'logoAltImage', 'partner', 'campaign', 'tenant', 'externalReference',
            'comment', 'description', 'presentation', 'mission', 'country', 'locale', 'zipCode', 'street', 'complement',
            'city', 'state',
        ],
        content: [
            ['row', [
                ['tabs', [
                    ['edit_organization_form_presentation_title', [
                        '$name!',
                        '$overline',
                        '$presentation',
                        '$description',
                        '$street',
                        '$complement',
                        '$zipCode',
                        '$city',
                        '$state',
                        '$country',
                        '$locale',
                        '$publicEmail',
                        '$publicPhone',
                        '$comment',
                    ]],
                    ['edit_organization_form_images_title', [
                        '$logoImage',
                        '$logoAltImage',
                        '$faviconImage',
                        '$appleTouchImage',
                    ]],
                ]],
                ['tabs', [
                    ['edit_organization_form_parameters_title', [
                        '$nin',
                        '$fullName',
                        '$shortName',
                        '$legalName',
                        '$owner:readonly',
                        '$externalReference',
                        '$tenant!',
                        '$partner',
                        '$campaign',
                        '$domain',
                    ]],
                    ['edit_organization_form_socials_title', [
                        '$websiteUrl',
                        '$facebookUrl',
                        '$twitterUrl',
                        '$youtubeUrl',
                        '$instagramUrl',
                        '$linkedinUrl',
                        '$pinterestUrl',
                        '$flickrUrl',
                        '$vimeoUrl',
                        '$tumblrUrl',
                        '$tiktokUrl',
                        '$snapchatUrl',
                        '$redditUrl',
                    ]],
                ]],
            ]],
            '$submit',
        ]
    },
    edit: {
        attributes: [
            'name', 'domain', 'fullName', 'legalName', 'shortName', 'nin', 'status', 'overline', 'publicEmail',
            'publicPhone', 'websiteUrl', 'twitterUrl', 'facebookUrl', 'instagramUrl', 'linkedinUrl', 'youtubeUrl',
            'pinterestUrl', 'tumblrUrl', 'flickrUrl', 'redditUrl', 'tiktokUrl', 'vimeoUrl', 'snapchatUrl', 'logoImage',
            'faviconImage', 'appleTouchImage', 'logoAltImage', 'partner', 'campaign', 'locale', 'externalReference',
            'comment', 'description', 'presentation', 'mission', 'country', 'zipCode', 'street', 'complement', 'city',
            'state',
        ],
        content: [
            ['row', [
                ['tabs', [
                    ['edit_organization_form_presentation_title', [
                        '$name!',
                        '$overline',
                        '$presentation',
                        '$description',
                        '$street',
                        '$complement',
                        '$zipCode',
                        '$city',
                        '$state',
                        '$country',
                        '$locale',
                        '$publicEmail',
                        '$publicPhone',
                        '$comment',
                    ]],
                    ['edit_organization_form_images_title', [
                        '$logoImage',
                        '$logoAltImage',
                        '$faviconImage',
                        '$appleTouchImage',
                    ]],
                ]],
                ['tabs', [
                    ['edit_organization_form_parameters_title', [
                        '$nin',
                        '$fullName',
                        '$shortName',
                        '$legalName',
                        '$owner:readonly',
                        '$externalReference',
                        '$tenant!',
                        '$partner',
                        '$campaign',
                        '$domain',
                    ]],
                    ['edit_organization_form_socials_title', [
                        '$websiteUrl',
                        '$facebookUrl',
                        '$twitterUrl',
                        '$youtubeUrl',
                        '$instagramUrl',
                        '$linkedinUrl',
                        '$pinterestUrl',
                        '$flickrUrl',
                        '$vimeoUrl',
                        '$tumblrUrl',
                        '$tiktokUrl',
                        '$snapchatUrl',
                        '$redditUrl',
                    ]],
                ]],
            ]],
            '$submit',
        ]
    },
    display: {
        properties: [
            'id', 'code', 'name', 'websiteUrl', 'country', 'locale',
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 0, width: 100} as any,
            {id: 'name', label: 'column_name_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;