const t = {
    icon: 'fa-image',
    menus: {
        main: {section: 'medias', priority: 45},
    },
    new: {
        attributes: [
            'type', 'game', 'organization', 'partner', 'tenant', 'tags', 'title', 'description',
            'locale', 'country', 'variables', 'draw', 'sale', 'promise',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$type!', '$imageTags', '$variables', '$title', '$description', '$locale', '$country',
                ]],
                ['block', [
                    '$tenant!', '$game', '$organization', '$partner', '$draw', '$sale', '$promise',
                ]]
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'game', 'organization', 'partner', 'tenant', 'tags', 'title', 'description', 'job',
            'mainImage', 'locale', 'country', 'variables', 'generateFailedDetails', 'draw', 'sale', 'promise',
        ],
        content: [
            ['row', [
                ['block', [
                    '$status:readonly!', '!$type!', '$imageTags', '$variables', '$title', '$description', '$job', '$locale', '$country',
                ]],
                ['block', [
                    '$tenant!', '$game', '$organization', '$partner', '$draw', '$sale', '$promise',
                ]]
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        body: true,
        pollableStatus: ['GENERATING', 'QUEUED'],
        pollInterval: 2000,
        properties: [
            'type', 'gameName', 'organizationName', 'status', 'title', 'description', 'tags', 'variables',
        ],
    },
    list: {
        columns: [
            {id: 'mainImage', label: 'column_main_image_label', render: 'image', flex: 0, width: 200},
            {id: 'game', label: 'column_game_label', flex: 1, width: 120, component: 'game'} as any,
            {id: 'title', label: 'column_overline_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;