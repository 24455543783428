export const CREATE_TEMPLATE = (gql: any) => gql`
    mutation($data: CreateTemplateInput!) {
        createTemplate(data: $data) {
            id
        }
    }
`;
export const DELETE_TEMPLATE = (gql: any) => gql`
    mutation($id: ID!) {
        deleteTemplate(id: $id) {
            id
        }
    }
`;
export const FIND_TEMPLATES = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findTemplates(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                token
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                locale
                country
                type
                definition
                definitionFormat
                params
                subject
            }
        }
    }
`;
export const GET_TEMPLATE = (gql: any) => gql`
    query($id: ID!) {
        getTemplate(id: $id) {
            id
            code
            token
            tenant
            tenantCode
            tenantName
            createdAt
            updatedAt
            locale
            country
            type
            definition
            definitionFormat
            params
            subject
        }
    }
`;
export const SEARCH_TEMPLATES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchTemplates(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                token
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                locale
                country
                type
                definition
                definitionFormat
                params
                subject
            }
        }
    }
`;
export const UPDATE_TEMPLATE = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateTemplateInput!) {
        updateTemplate(id: $id, data: $data) {
            id
        }
    }
`;
