export const CREATE_DRAW = (gql: any) => gql`
    mutation($data: CreateDrawInput!) {
        createDraw(data: $data) {
            id
        }
    }
`;
export const GET_DRAW = (gql: any) => gql`
    query($id: ID!) {
        getDraw(id: $id) {
            id
            key
            status
            token
            createdAt
            updatedAt
            tenant
            tenantCode
            tenantName
            partner
            partnerCode
            partnerName
            organization
            organizationCode
            organizationName
            game
            gameCode
            gameName
            strategy
            draft
            country
            locale
            processedTickets
            processedPrizes
            processedBunches
            winnersCount
            drawMode
            winners(limit: 1000) {
                cursor
                count
                items {
                    id
                    code
                    rank
                    bunchRank
                    token
                    createdAt
                    updatedAt
                    bunch
                    bunchName
                    ticket
                    ticketCode
                    firstName
                    lastName
                    email
                    phone
                    sale
                }
            }
        }
    }
`;
export const FIND_DRAWS = (gql: any) => gql`
    query findDraws($offset: String, $limit: Int) {
        findDraws(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                status
                key
                token
                createdAt
                updatedAt
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                strategy
                draft
                country
                locale
                processedTickets
                processedPrizes
                processedBunches
                winnersCount
            }
        }
    }
`;