export const CREATE_OIMAGE = (gql: any) => gql`
    mutation($data: CreateOimageInput!) {
        createOimage(data: $data) {
            id
        }
    }
`;
export const GET_OIMAGE = (gql: any) => gql`
    query($id: ID!) {
        getOimage(id: $id) {
            id
            status
            tags
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            organization
            organizationCode
            organizationName
            mainImage {
                available
                url
            }
            desktopImage {
                available
                url
            }
            tabletImage {
                available
                url
            }
            mobileImage {
                available
                url
            }
            tvImage {
                available
                url
            }
            emailImage {
                available
                url
            }
        }
    }
`;
export const FIND_OIMAGES = (gql: any) => gql`
    query findOimages($offset: String, $limit: Int) {
        findOimages(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                organization
                organizationCode
                organizationName
                mainImage {
                    available
                    url
                }
                desktopImage {
                    available
                    url
                }
                tabletImage {
                    available
                    url
                }
                mobileImage {
                    available
                    url
                }
                tvImage {
                    available
                    url
                }
                emailImage {
                    available
                    url
                }
            }
        }
    }
`;