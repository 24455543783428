export const CREATE_GROUPING = (gql: any) => gql`
    mutation($data: CreateGroupingInput!) {
        createGrouping(data: $data) {
            id
        }
    }
`;
export const GET_GROUPING = (gql: any) => gql`
    query($id: ID!) {
        getGrouping(id: $id) {
            id
            token
            publicToken
            limitedPrivatePageUrl
            code
            customCode
            rank
            name
            email
            phone
            sellergroups {
                items {
                    fullName
                    email
                    code
                }
            }
            partner
            partnerCode
            partnerName
            organization
            organizationCode
            organizationName
            game
            gameCode
            gameName
            tenant
            tenantCode
            tenantName
            country
            type
            locale
            logoImage {
                url
                available
            }
            presentation
            overline
            description
            main
            grouping
        }
    }
`;
export const FIND_GROUPINGS = (gql: any) => gql`
    query findGroupings($offset: String, $limit: Int) {
        findGroupings(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id 
                token
                code
                name
                email
                phone
                sellergroups {
                    items {
                        fullName
                        email
                        code
                    }
                }
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                tenant
                tenantCode
                tenantName
                country
                locale
                type
                grouping
            }
        }
    }
`;