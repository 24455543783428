export const CREATE_PAUDIO = (gql: any) => gql`
    mutation($data: CreatePaudioInput!) {
        createPaudio(data: $data) {
            id
        }
    }
`;
export const GET_PAUDIO = (gql: any) => gql`
    query($id: ID!) {
        getPaudio(id: $id) {
            id
            status
            tags
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            mainAudioUrl
            desktopAudioUrl
            tabletAudioUrl
            mobileAudioUrl
            tvAudioUrl
            emailAudioUrl
        }
    }
`;
export const FIND_PAUDIOS = (gql: any) => gql`
    query findPaudios($offset: String, $limit: Int) {
        findPaudios(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                mainAudioUrl
                desktopAudioUrl
                tabletAudioUrl
                mobileAudioUrl
                tvAudioUrl
                emailAudioUrl
            }
        }
    }
`;