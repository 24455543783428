const t =  {
    menus: {
        main: {section: 'settings3', priority: 96, icon: 'fa-chart-column'},
    },
    new: {
        attributes: [
            'name', 'code', 'tenant', 'definition', 'params', 'index', 'typename',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$code!', '$name!', '$longDefinition', '$params', '$index!', '$typename!',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'code', 'tenant', 'definition', 'params', 'index', 'typename',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$code!', '$name!', '$longDefinition', '$params', '$index!', '$typename!',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        body: true,
        properties: ['name', 'code', 'tenantCode', 'definition', 'params', 'index', 'typename',],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 1, width: 100} as any,
            {id: 'name', label: 'column_name_label', flex: 1, width: 100} as any,
            {id: 'index', label: 'column_index_label', flex: 1, width: 100} as any,
            {id: 'typename', label: 'column_typename_label', flex: 1, width: 100} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;