export const CREATE_GOPERIOD = (gql: any) => gql`
    mutation($data: CreateGoperiodInput!) {
        createGoperiod(data: $data) {
            id
        }
    }
`;
export const GET_GOPERIOD = (gql: any) => gql`
    query($id: ID!) {
        getGoperiod(id: $id) {
            campaign
            campaignCode
            campaignName
            createdAt
            customerCollectedAmount
            customerCollectedAmountFlex
            endDate
            game
            gameCode
            gameName
            godfather
            id
            inlinePreset
            marketPlaceFeeAmount
            marketPlaceFeeAmountFlex
            notificationNotifiedAt
            notificationNotifiedBy
            notificationStatus
            notificationUnnotifiedAt
            notificationUnnotifiedBy
            organization
            organizationCode
            organizationName
            paidAmount
            paidAmountFlex
            paidDonationCount
            paidDonationCountFlex
            paidDonations
            paidDonationsAmount
            paidDonationsAmountFlex
            paidDonationsFlex
            paidSales
            paidSalesFlex
            paidTickets
            paidTicketsAmount
            paidTicketsAmountFlex
            paidTicketsFlex
            partner
            partnerCode
            partnerName
            paymentAmount
            paymentProviderFeeAmount
            paymentProviderFeeAmountFlex
            presets
            privateToken
            publicToken
            sale
            sales
            startDate
            tags
            tenant
            tenantCode
            tenantName
            token
            totalFeeAmount
            totalFeeAmountFlex
            transactionFeeAmount
            transactionFeeAmountFlex
            updatedAt
        }
    }
`;
export const FIND_GOPERIODS = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findGoperiods(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                campaign
                campaignCode
                campaignName
                createdAt
                customerCollectedAmount
                customerCollectedAmountFlex
                endDate
                game
                gameCode
                gameName
                godfather
                id
                marketPlaceFeeAmount
                marketPlaceFeeAmountFlex
                notificationNotifiedAt
                notificationNotifiedBy
                notificationStatus
                notificationUnnotifiedAt
                notificationUnnotifiedBy
                organization
                organizationCode
                organizationName
                paidAmount
                paidAmountFlex
                paidDonationCount
                paidDonationCountFlex
                paidDonations
                paidDonationsAmount
                paidDonationsAmountFlex
                paidDonationsFlex
                paidSales
                paidSalesFlex
                paidTickets
                paidTicketsAmount
                paidTicketsAmountFlex
                paidTicketsFlex
                partner
                partnerCode
                partnerName
                paymentAmount
                paymentProviderFeeAmount
                paymentProviderFeeAmountFlex
                sale
                sales
                startDate
                tags
                tenant
                tenantCode
                tenantName
                totalFeeAmount
                totalFeeAmountFlex
                transactionFeeAmount
                transactionFeeAmountFlex
            }
        }
    }
`;
export const SEARCH_GOPERIODS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchGoperiods(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                campaign
                campaignCode
                campaignName
                createdAt
                customerCollectedAmount
                customerCollectedAmountFlex
                endDate
                game
                gameCode
                gameName
                godfather
                id
                marketPlaceFeeAmount
                marketPlaceFeeAmountFlex
                notificationNotifiedAt
                notificationNotifiedBy
                notificationStatus
                notificationUnnotifiedAt
                notificationUnnotifiedBy
                organization
                organizationCode
                organizationName
                paidAmount
                paidAmountFlex
                paidDonationCount
                paidDonationCountFlex
                paidDonations
                paidDonationsAmount
                paidDonationsAmountFlex
                paidDonationsFlex
                paidSales
                paidSalesFlex
                paidTickets
                paidTicketsAmount
                paidTicketsAmountFlex
                paidTicketsFlex
                partner
                partnerCode
                partnerName
                paymentAmount
                paymentProviderFeeAmount
                paymentProviderFeeAmountFlex
                sale
                sales
                startDate
                tags
                tenant
                tenantCode
                tenantName
                totalFeeAmount
                totalFeeAmountFlex
                transactionFeeAmount
                transactionFeeAmountFlex
            }
        }
    }
`;
export const UPDATE_GOPERIOD = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateGoperiodInput!) {
        updateGoperiod(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_GOPERIOD = (gql: any) => gql`
    mutation($id: ID!) {
        deleteGoperiod(id: $id) {
            id
        }
    }
`;