export const CREATE_PLATFORM = (gql: any) => gql`
    mutation($data: CreatePlatformInput!) {
        createPlatform(data: $data) {
            id
        }
    }
`;
export const GET_PLATFORM = (gql: any) => gql`
    query($id: ID!) {
        getPlatform(id: $id) {
            id
            code
            name
            organizationName
            organizationCode
            organization
            status
            partnerName
            partnerCode
            partner
            email
            tenantName
            tenantCode
            tenant
        }
    }
`;
export const FIND_PLATFORMS = (gql: any) => gql`
    query findPlatforms($offset: String, $limit: Int) {
        findPlatforms(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                organizationName
                organizationCode
                organization
                status
                partnerName
                partnerCode
                partner
                email
                tenantName
                tenantCode
                tenant
            }
        }
    }
`;