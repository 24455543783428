const t = {
    icon: 'fa-book-open',
    menus: {
        main: {section: 'activity', priority: 45},
    },
    new: {
        attributes: [
            'name', 'code', 'type', 'description', 'partner', ['params', 'object'],
        ],
        content: [
            ['row', [
                ['block', [
                    '!$code!', '$name!', '$tenant!', '$type!',
                ]],
                ['block', [
                    '$description', '$partner', '$offerParams',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'code', 'type', 'description', 'partner', ['params', 'object'],
        ],
        content: [
            ['row', [
                ['block', [
                    '!$code!', '$name!', '$tenant!', '$type!',
                ]],
                ['block', [
                    '$description', '$partner', '$offerParams',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'code', 'name', 'slug', 'publicPageUrl', 'offerCode', 'partnerCode',
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 0, width: 200} as any,
            {id: 'name', label: 'column_name_label'},
            { id: 'status', label: 'column_status_label', flex: 0, width: 120, component: 'offer_status' },
            { id: 'type', label: 'column_type_label', flex: 0, width: 200, component: 'offer_type' },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;