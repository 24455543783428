export const CREATE_SALEITEM = (gql: any) => gql`
    mutation($data: CreateSaleitemInput!) {
        createSaleitem(data: $data) {
            id
        }
    }
`;
export const GET_SALEITEM = (gql: any) => gql`
    query($id: ID!) {
        getSaleitem(id: $id) {
            campaign
            campaignCode
            campaignName
            createdAt
            game
            gameCode
            gameName
            id
            inlinePreset
            organization
            organizationCode
            organizationName
            pack
            packCode
            packDonation
            packName
            packPrice
            packTickets
            partner
            partnerCode
            partnerName
            presets
            privateToken
            publicToken
            quantity
            sale
            seller
            sellerCode
            sellerEmail
            sellerFirstName
            sellerLastName
            sellerName
            sellerPhone
            sellergroup
            sellergroupCode
            sellergroupEmail
            sellergroupGameSellergroupType
            sellergroupName
            sellergroupPhone
            sellergroupType
            status
            tags
            tenant
            tenantCode
            tenantName
            token
            type
            updatedAt
        }
    }
`;
export const SEARCH_SALEITEMS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchSaleitems(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                campaign
                campaignCode
                campaignName
                createdAt
                game
                gameCode
                gameName
                id
                inlinePreset
                organization
                organizationCode
                organizationName
                pack
                packCode
                packDonation
                packName
                packPrice
                packTickets
                partner
                partnerCode
                partnerName
                presets
                privateToken
                publicToken
                quantity
                sale
                seller
                sellerCode
                sellerEmail
                sellerFirstName
                sellerLastName
                sellerName
                sellerPhone
                sellergroup
                sellergroupCode
                sellergroupEmail
                sellergroupGameSellergroupType
                sellergroupName
                sellergroupPhone
                sellergroupType
                status
                tags
                tenant
                tenantCode
                tenantName
                token
                type
                updatedAt
            }
        }
    }
`;
export const FIND_SALEITEMS = (gql: any) => gql`
    query ($offset: String, $limit: Int) {
        findSaleitems(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                campaign
                campaignCode
                campaignName
                createdAt
                game
                gameCode
                gameName
                id
                inlinePreset
                organization
                organizationCode
                organizationName
                pack
                packCode
                packDonation
                packName
                packPrice
                packTickets
                partner
                partnerCode
                partnerName
                presets
                privateToken
                publicToken
                quantity
                sale
                seller
                sellerCode
                sellerEmail
                sellerFirstName
                sellerLastName
                sellerName
                sellerPhone
                sellergroup
                sellergroupCode
                sellergroupEmail
                sellergroupGameSellergroupType
                sellergroupName
                sellergroupPhone
                sellergroupType
                status
                tags
                tenant
                tenantCode
                tenantName
                token
                type
                updatedAt
            }
        }
    }
`;
export const UPDATE_SALEITEM = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateSaleitemInput!) {
        updateSaleitem(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_SALEITEM = (gql: any) => gql`
    mutation($id: ID!) {
        deleteSaleitem(id: $id) {
            id
        }
    }
`;