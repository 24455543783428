const t = {
    icon: 'fa-building',
    menus: {
        main: {section: 'settings', priority: 85},
    },
    new: {
        attributes: [
            'organization', 'type', 'code', 'name', 'campaign', 'partner', 'locale', 'externalReference',
            'zipCode', 'complement', 'state', 'street', 'city', 'country', 'presentation', 'description',
            'logoImage', 'logoAltImage', 'mainImage', 'bannerImage', 'mobileBannerImage', 'tabletBannerImage', 'banner2Image', 'backgroundImage',
            'flyerImage', 'twitterShareImage', 'facebookShareImage',
            'websiteUrl', 'twitterUrl', 'facebookUrl', 'instagramUrl', 'linkedinUrl', 'youtubeUrl', 'pinterestUrl',
            'tumblrUrl', 'flickrUrl', 'redditUrl', 'tiktokUrl', 'vimeoUrl', 'snapchatUrl',
            'backgroundVideoUrl', 'bannerVideoUrl', 'banner2VideoUrl', 'flyerVideoUrl', 'mainVideoUrl',
            'mainShortVideoUrl',  'mainLongVideoUrl', 'faviconImage', 'appleTouchImage',
            'overline', 'publicEmail', 'publicPhone', 'nin', 'fullName', 'shortName', 'legalName', 'domain', 'mainTheme',
            'owner', 'tenant',
            'varColorNavPrimary', 'varColorNavSecondary', 'varColorPrimary', 'varColorSecondary', 'varColorPanelPrimary', 'varColorPanelSecondary',
            'varColorTextPrimary', 'varColorTextSecondary', 'varColorBorderPrimary', 'varColorBorderSecondary', 'varColorLogo', 'varColorTitle',
            'varColorChoicePrimary', 'varColorChoiceSecondary', 'varColorFooterPrimary', 'varColorFooterSecondary', 'varColorHeading',
        ],
        content: [
            ['row', [
                ['tabs', [
                    ['edit_organization_form_presentation_title', [
                        '$organization!',
                        '$code!',
                        '$type!',
                        '$name',
                        '$overline',
                        '$presentation',
                        '$description',
                        '$street',
                        '$complement',
                        '$zipCode',
                        '$city',
                        '$state',
                        '$country',
                        '$locale',
                        '$publicEmail',
                        '$publicPhone',
                    ]],
                    ['edit_organization_form_images_title', [
                        '$logoImage',
                        '$logoAltImage',
                        '$bannerImage',
                        '$faviconImage',
                        '$appleTouchImage',
                        '$banner2Image',
                        '$mainImage',
                        '$backgroundImage',
                        '$flyerImage',
                        '$twitterShareImage',
                        '$facebookShareImage',
                    ]],
                    ['edit_organization_form_videos_title', [
                        '$backgroundVideoUrl',
                        '$bannerVideoUrl',
                        '$banner2VideoUrl',
                        '$flyerVideoUrl',
                        '$mainVideoUrl',
                        '$mainShortVideoUrl',
                        '$mainLongVideoUrl',
                    ]],
                ]],
                ['tabs', [
                    ['edit_organization_form_parameters_title', [
                        '$nin',
                        '$fullName',
                        '$shortName',
                        '$legalName',
                        '$owner',
                        '$externalReference',
                        '$tenant',
                        '$partner',
                        '$campaign',
                        '$domain',
                        '$mainTheme',
                    ]],
                    ['edit_organization_form_socials_title', [
                        '$websiteUrl',
                        '$facebookUrl',
                        '$twitterUrl',
                        '$youtubeUrl',
                        '$instagramUrl',
                        '$linkedinUrl',
                        '$pinterestUrl',
                        '$flickrUrl',
                        '$vimeoUrl',
                        '$tumblrUrl',
                        '$tiktokUrl',
                        '$snapchatUrl',
                        '$redditUrl',
                    ]],
                    ['edit_organization_form_colors_title', [
                        '$varColorPrimary',
                        '$varColorSecondary',
                        '$varColorNavPrimary',
                        '$varColorNavSecondary',
                        '$varColorTextPrimary',
                        '$varColorTextSecondary',
                        '$varColorBorderPrimary',
                        '$varColorBorderSecondary',
                        '$varColorPanelPrimary',
                        '$varColorPanelSecondary',
                        '$varColorChoicePrimary',
                        '$varColorChoiceSecondary',
                        '$varColorFooterPrimary',
                        '$varColorFooterSecondary',
                        '$varColorTitle',
                        '$varColorHeading',
                        '$varColorLogo',
                    ]],
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'organization', 'type', 'code', 'name', 'campaign', 'partner', 'locale', 'externalReference',
            'zipCode', 'complement', 'state', 'street', 'city', 'country', 'presentation', 'description',
            'logoImage', 'logoAltImage', 'mainImage', 'bannerImage', 'banner2Image', 'backgroundImage',
            'flyerImage', 'twitterShareImage', 'facebookShareImage',
            'websiteUrl', 'twitterUrl', 'facebookUrl', 'instagramUrl', 'linkedinUrl', 'youtubeUrl', 'pinterestUrl',
            'tumblrUrl', 'flickrUrl', 'redditUrl', 'tiktokUrl', 'vimeoUrl', 'snapchatUrl',
            'backgroundVideoUrl', 'bannerVideoUrl', 'banner2VideoUrl', 'flyerVideoUrl', 'mainVideoUrl',
            'mainShortVideoUrl',  'mainLongVideoUrl', 'faviconImage', 'appleTouchImage',
            'overline', 'publicEmail', 'publicPhone', 'nin', 'fullName', 'shortName', 'legalName', 'domain', 'mainTheme',
            'varColorNavPrimary', 'varColorNavSecondary', 'varColorPrimary', 'varColorSecondary', 'varColorPanelPrimary', 'varColorPanelSecondary',
            'varColorTextPrimary', 'varColorTextSecondary', 'varColorBorderPrimary', 'varColorBorderSecondary', 'varColorLogo', 'varColorTitle',
            'varColorChoicePrimary', 'varColorChoiceSecondary', 'varColorFooterPrimary', 'varColorFooterSecondary', 'varColorHeading',
        ],
        content: [
                ['row', [
                    ['tabs', [
                        ['edit_organization_form_presentation_title', [
                            '!$name!',
                            '$organization:readonly!',
                            '$code:readonly!',
                            '$type:readonly!',
                            '$overline',
                            '$presentation',
                            '$description',
                            '$street',
                            '$complement',
                            '$zipCode',
                            '$city',
                            '$state',
                            '$country',
                            '$locale',
                            '$publicEmail',
                            '$publicPhone',
                        ]],
                        ['edit_organization_form_images_title', [
                            '$logoImage',
                            '$logoAltImage',
                            '$bannerImage',
                            '$faviconImage',
                            '$appleTouchImage',
                            '$banner2Image',
                            '$mainImage',
                            '$backgroundImage',
                            '$flyerImage',
                            '$twitterShareImage',
                            '$facebookShareImage',
                        ]],
                        ['edit_organization_form_videos_title', [
                            '$backgroundVideoUrl',
                            '$bannerVideoUrl',
                            '$banner2VideoUrl',
                            '$flyerVideoUrl',
                            '$mainVideoUrl',
                            '$mainShortVideoUrl',
                            '$mainLongVideoUrl',
                        ]],
                    ]],
                    ['tabs', [
                        ['edit_organization_form_parameters_title', [
                            '$nin',
                            '$fullName',
                            '$shortName',
                            '$legalName',
                            '$owner',
                            '$externalReference',
                            '$tenant',
                            '$partner',
                            '$campaign',
                            '$domain',
                            '$mainTheme',
                        ]],
                        ['edit_organization_form_socials_title', [
                            '$websiteUrl',
                            '$facebookUrl',
                            '$twitterUrl',
                            '$youtubeUrl',
                            '$instagramUrl',
                            '$linkedinUrl',
                            '$pinterestUrl',
                            '$flickrUrl',
                            '$vimeoUrl',
                            '$tumblrUrl',
                            '$tiktokUrl',
                            '$snapchatUrl',
                            '$redditUrl',
                        ]],
                        ['edit_organization_form_colors_title', [
                            '$varColorPrimary',
                            '$varColorSecondary',
                            '$varColorNavPrimary',
                            '$varColorNavSecondary',
                            '$varColorTextPrimary',
                            '$varColorTextSecondary',
                            '$varColorBorderPrimary',
                            '$varColorBorderSecondary',
                            '$varColorPanelPrimary',
                            '$varColorPanelSecondary',
                            '$varColorChoicePrimary',
                            '$varColorChoiceSecondary',
                            '$varColorFooterPrimary',
                            '$varColorFooterSecondary',
                            '$varColorTitle',
                            '$varColorHeading',
                            '$varColorLogo',
                        ]],
                    ]],
                ]],
            '$submit',
        ]
    },
    display: {
        properties: ['code', 'type', 'organization', 'organizationCode'],
    },
    list: {
        columns: [
            {id: 'createdAt', 'label': 'column_createdat_label', flex: 0, width: 150, component: 'date'},
            {id: 'code', label: 'column_code_label', flex: 0, width: 100} as any,
            {id: 'type', label: 'column_type_label', flex: 0, width: 100} as any,
            {id: 'organization', label: 'column_organization_label', flex: 1, component: 'organization'} as any,
            {id: 'owner', label: 'column_owner_label', component: 'owner'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'go_public_page', 'delete'],
    },
};

export default t;