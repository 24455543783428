export const CREATE_SEGMENT = (gql: any) => gql`
    mutation($data: CreateSegmentInput!) {
        createSegment(data: $data) {
            id
        }
    }
`;
export const GET_SEGMENT = (gql: any) => gql`
    query($id: ID!) {
        getSegment(id: $id) {
            id
            code
            token
            tenant
            tenantCode
            tenantName
            createdAt
            updatedAt
            name
            definition
            params
            index
            typename
        }
    }
`;
export const FIND_SEGMENTS = (gql: any) => gql`
    query findSegments($offset: String, $limit: Int) {
        findSegments(offset: $offset, limit: $limit) {
            cursor
            count
            items {
            	id
                code
                token
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                name
                definition
                params
                index
                typename
            }
        }
    }
`;