const t =  {
    menus: {
        main: {section: 'settings3', priority: 98, icon: 'fa-palette'},
    },
    new: {
        attributes: [
            'code', 'tenant', 'locale', 'country', 'type',
            'definition', 'definitionFormat', 'params', 'subject',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$code!', '$locale', '$country', '$fixtureType!',
                ]],
                ['block', [
                    '$textareaDefinition', '$definitionFormat!', '$params', '$subject',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'code', 'tenant', 'locale', 'country', 'type',
            'definition', 'definitionFormat', 'params', 'subject',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$code!', '$locale', '$country', '$fixtureType!',
                ]],
                ['block', [
                    '$textareaDefinition', '$definitionFormat!', '$params', '$subject',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'code', 'tenantCode', 'locale', 'country', 'type',
            'definition', 'definitionFormat', 'params', 'subject',
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 1, width: 100} as any,
            {id: 'tenant', label: 'column_tenant_label', flex: 1, width: 120, component: 'tenant'} as any,
            {id: 'type', label: 'column_type_label', flex: 1, width: 100} as any,
            {id: 'definitionFormat', label: 'column_format_label', flex: 1, width: 100} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;