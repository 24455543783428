const t = {
    icon: 'fa-ruler',
    menus: {
        main: {section: 'ads', priority: 40},
    },
    new: {
        attributes: [
            'blacklistedAds', 'blacklistedAdvertisers', 'blacklistedAffiliaters', 'blacklistedCountries', 'blacklistedTags', 'campaign', 'features', 'game', 'limit',
            'rank', 'tags', 'whitelistedAds', 'whitelistedAdvertisers', 'whitelistedAffiliaters', 'whitelistedCountries', 'whitelistedTags',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$game!', '$campaign', '$limit', '$blacklistedAds', '$blacklistedAdvertisers', '$blacklistedAffiliaters', '$blacklistedCountries', '$blacklistedTags',
                ]],
                ['block', [
                    '$rank', '$tags', '$features', '$whitelistedAds', '$whitelistedAdvertisers', '$whitelistedAffiliaters', '$whitelistedCountries', '$whitelistedTags',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'blacklistedAds', 'blacklistedAdvertisers', 'blacklistedAffiliaters', 'blacklistedCountries', 'blacklistedTags', 'campaign', 'features', 'game', 'limit',
            'rank', 'tags', 'whitelistedAds', 'whitelistedAdvertisers', 'whitelistedAffiliaters', 'whitelistedCountries', 'whitelistedTags',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$game!', '$campaign', '$limit', '$blacklistedAds', '$blacklistedAdvertisers', '$blacklistedAffiliaters', '$blacklistedCountries', '$blacklistedTags',
                ]],
                ['block', [
                    '$rank', '$tags', '$features', '$whitelistedAds', '$whitelistedAdvertisers', '$whitelistedAffiliaters', '$whitelistedCountries', '$whitelistedTags',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'blacklistedAds', 'blacklistedAdvertisers', 'blacklistedAffiliaters', 'blacklistedCountries', 'blacklistedTags', 'campaign', 'campaignCode', 'campaignName',
            'createdAt', 'features', 'game', 'gameCode', 'gameName', 'limit', 'organization', 'organizationCode', 'organizationName', 'partner', 'partnerCode', 'partnerName',
            'privateToken', 'publicToken', 'publishArchivedAt', 'publishArchivedBy', 'publishPublishedAt', 'publishPublishedBy', 'publishStatus', 'publishUnpublishedAt', 'publishUnpublishedBy',
            'rank', 'tags', 'tenant', 'tenantCode', 'tenantName', 'token', 'updatedAt', 'whitelistedAds', 'whitelistedAdvertisers', 'whitelistedAffiliaters', 'whitelistedCountries', 'whitelistedTags',
        ],
    },
    list: {
        columns: [
            {id: 'createdAt', label: 'column_createdat_label', component: 'date', flex: 0, width: 160},
            {id: 'status', label: 'column_status_label'},
            {id: 'gameCode', label: 'column_game_code_label', component: 'game'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;