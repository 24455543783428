export const CREATE_KEYWORD = (gql: any) => gql`
    mutation($data: CreateKeywordInput!) {
        createKeyword(data: $data) {
            id
        }
    }
`;
export const GET_KEYWORD = (gql: any) => gql`
    query($id: ID!) {
        getKeyword(id: $id) {
            id
            code
            name
            game
            organizationName
            gameName
            status
            features
        }
    }
`;
export const FIND_KEYWORDS = (gql: any) => gql`
    query findKeywords($offset: String, $limit: Int) {
        findKeywords(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                gameCode
                gameName
                organizationCode
                organizationName
                game
                name
                status
                features
            }
        }
    }
`;