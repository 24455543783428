export const CREATE_OAUDIO = (gql: any) => gql`
    mutation($data: CreateOaudioInput!) {
        createOaudio(data: $data) {
            id
        }
    }
`;
export const GET_OAUDIO = (gql: any) => gql`
    query($id: ID!) {
        getOaudio(id: $id) {
            id
            status
            tags
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            organization
            organizationCode
            organizationName
            mainAudioUrl
            desktopAudioUrl
            tabletAudioUrl
            mobileAudioUrl
            tvAudioUrl
            emailAudioUrl
        }
    }
`;
export const FIND_OAUDIOS = (gql: any) => gql`
    query findOaudios($offset: String, $limit: Int) {
        findOaudios(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                organization
                organizationCode
                organizationName
                mainAudioUrl
                desktopAudioUrl
                tabletAudioUrl
                mobileAudioUrl
                tvAudioUrl
                emailAudioUrl
            }
        }
    }
`;