export const CREATE_MODULE = (gql: any) => gql`
    mutation($data: CreateModuleInput!) {
        createModule(data: $data) {
            id
        }
    }
`;
export const GET_MODULE = (gql: any) => gql`
    query($id: ID!) {
        getModule(id: $id) {
            id
            name
            code
            params {
                name
            }
            definition
        }
    }
`;
export const FIND_MODULES = (gql: any) => gql`
    query findModules($offset: String, $limit: Int) {
        findModules(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                params {
                    name
                }
            }
        }
    }
`;