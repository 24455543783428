export const CREATE_DPRINTCOLOR = (gql: any) => gql`
    mutation($data: CreateDprintcolorInput!) {
        createDprintcolor(data: $data) {
            id
        }
    }
`;
export const GET_DPRINTCOLOR = (gql: any) => gql`
    query($id: ID!) {
        getDprintcolor(id: $id) {
            id
            tenant
            code
            name
            definition
            tags
        }
    }
`;
export const FIND_DPRINTCOLORS = (gql: any) => gql`
    query findDprintcolors($offset: String, $limit: Int) {
        findDprintcolors(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                definition
                tags
            }
        }
    }
`;