const t =  {
    menus: {
        main: {section: 'settings3', priority: 48, icon: 'fa-envelope'},
    },
    new: {
        attributes: [
            'campaign', 'code', 'email', 'firstName', 'game', 'grouping', 'lastName', 'notificationParams', 'organization',
            'partner', 'phone', 'seller', 'sellergroup', 'tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$campaign', '$game', '$organization', '$partner', '$seller', '$sellergroup', '$grouping',
                ]],
                ['block', [
                    '$code!', '$email', '$firstName', '$lastName', '$phone', '$notificationParams',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'campaign', 'code', 'email', 'firstName', 'game', 'grouping', 'lastName', 'notificationParams', 'organization',
            'partner', 'phone', 'seller', 'sellergroup', 'tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$campaign', '$game', '$organization', '$partner', '$seller', '$sellergroup', '$grouping',
                ]],
                ['block', [
                    '$code!', '$email', '$firstName', '$lastName', '$phone', '$notificationParams',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'code', 'phone', 'email', 'firstName', 'lastName', 'notificationParams', 'game', 'gameCode', 'gameName', 'grouping',
            'groupingCode', 'groupingEmail', 'organization', 'organizationCode', 'organizationName', 'seller', 'sellerCode', 'sellerEmail',
            'sellergroup', 'sellergroupCode', 'sellergroupName',
        ],
    },
    list: {
        columns: [
            { id: 'code', label: 'column_code_label', flex: 1 } as any,
            { id: 'email', label: 'column_email_label' },
            { id: 'phone', label: 'column_phone_label' },
            { id: 'game', label: 'column_game_code_label', flex: 0, width: 350, component: 'game' },
            { id: 'organization', label: 'column_organization_code_label', flex: 0, width: 350, component: 'organization' },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;