const t = {
    icon: 'fa-gear',
    menus: {
        main: {section: 'settings', priority: 90},
    },
    new: {
        attributes: [
            'tenant', 'code', 'name', 'type', 'definition',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$code!', '$type!', '!$name!'
                ]],
                ['block', [
                    '$codeDefinition',
                ]]
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'definition',
        ],
        content: [
            ['row', [
                ['block', [
                    ['row', ['$code:readonly!', '$type:readonly!', '$name:readonly!']],
                    '$codeDefinition',
                ]]
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: [
            'id', 'status', 'code', 'type', 'name', 'definition',
        ],
    },
    list: {
        columns: [
            {id: 'createdAt', label: 'column_createdat_label', flex: 0, width: 200, component: 'date'},
            {id: 'status', label: 'column_status_label', flex: 0, width: 200},
            {id: 'code', label: 'column_code_label'},
            {id: 'type', label: 'column_type_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;