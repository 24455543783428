const t = {
    icon: 'fa-palette',
    menus: {
        main: {section: 'settings2', priority: 60},
    },
    new: {
        attributes: [
            'tenant', 'code', 'name', 'organization', 'game', 'mainCss', 'mainJs', 'features',
            'varColorNavPrimary', 'varColorNavSecondary', 'varColorPrimary', 'varColorSecondary', 'varColorPanelPrimary', 'varColorPanelSecondary',
            'varColorTextPrimary', 'varColorTextSecondary', 'varColorBorderPrimary', 'varColorBorderSecondary', 'varColorLogo', 'varColorTitle',
            'varColorChoicePrimary', 'varColorChoiceSecondary', 'varColorFooterPrimary', 'varColorFooterSecondary', 'varColorHeading',
        ],
        content: [
            ['row', [
                ['block', [
                    '$code',
                    '$name',
                    '$tenant',
                    '$features',
                ]],
                ['tabs', [
                    ['edit_theme_form_colors_title', [
                        ['column', [
                            '$varColorPrimary',
                            '$varColorSecondary',
                            '$varColorNavPrimary',
                            '$varColorNavSecondary',
                            '$varColorTextPrimary',
                            '$varColorTextSecondary',
                            '$varColorBorderPrimary',
                            '$varColorBorderSecondary',
                            '$varColorPanelPrimary',
                            '$varColorPanelSecondary',
                            '$varColorChoicePrimary',
                            '$varColorChoiceSecondary',
                            '$varColorFooterPrimary',
                            '$varColorFooterSecondary',
                            '$varColorTitle',
                            '$varColorHeading',
                            '$varColorLogo',
                        ]]
                    ]],
                    ['edit_theme_form_advanced_title', [
                        '$mainCss',
                        '$mainJs',
                    ]],
                ]]
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'tenant', 'name', 'organization', 'game', 'mainCss', 'mainJs', 'features',
            'varColorNavPrimary', 'varColorNavSecondary', 'varColorPrimary', 'varColorSecondary', 'varColorPanelPrimary', 'varColorPanelSecondary',
            'varColorTextPrimary', 'varColorTextSecondary', 'varColorBorderPrimary', 'varColorBorderSecondary',
            'varColorLogo', 'varColorTitle', 'varColorChoicePrimary', 'varColorChoiceSecondary', 'varColorFooterPrimary', 'varColorFooterSecondary', 'varColorHeading',
        ],
        content: [
            ['row', [
                ['block', [
                    '$name',
                    '$code:readonly',
                    '$tenant',
                    '$features',
                ]],
                ['tabs', [
                    ['edit_theme_form_colors_title', [
                        ['column', [
                            '$varColorPrimary',
                            '$varColorSecondary',
                            '$varColorNavPrimary',
                            '$varColorNavSecondary',
                            '$varColorTextPrimary',
                            '$varColorTextSecondary',
                            '$varColorBorderPrimary',
                            '$varColorBorderSecondary',
                            '$varColorPanelPrimary',
                            '$varColorPanelSecondary',
                            '$varColorChoicePrimary',
                            '$varColorChoiceSecondary',
                            '$varColorFooterPrimary',
                            '$varColorFooterSecondary',
                            '$varColorTitle',
                            '$varColorHeading',
                            '$varColorLogo',
                        ]]
                    ]],
                    ['edit_theme_form_advanced_title', [
                        '$mainCss',
                        '$mainJs',
                    ]],
                ]]
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: [
            'id', 'code', 'name',
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 0, width: 150} as any,
            {id: 'name', label: 'column_name_label'} as any,
            {id: 'colors', label: 'column_colors_label', flex: 0, width: 250, render: 'colors', colorShape: 'circle', colorSize: 'sm', colors: ['varColorPrimary', 'varColorSecondary', 'varColorNavPrimary', 'varColorNavSecondary', 'varColorFooterPrimary']},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'go_public_page', 'delete']
    },
};

export default t;