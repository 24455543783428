export const CREATE_DESIGN = (gql: any) => gql`
    mutation($data: CreateDesignInput!) {
        createDesign(data: $data) {
            id
        }
    }
`;
export const GET_DESIGN = (gql: any) => gql`
    query($id: ID!) {
        getDesign(id: $id) {
            id
            data
            mainImage {
                available
                url
            }
            createdAt
            updatedAt
            tenant
            tenantCode
            tenantName
            token
            publicToken
            privateToken
        }
    }
`;
export const FIND_DESIGNS = (gql: any) => gql`
    query findDesigns($offset: String, $limit: Int) {
        findDesigns(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                data
                mainImage {
                    available
                    url
                }
                createdAt
                updatedAt
                tenant
                tenantCode
                tenantName
            }
        }
    }
`;