export const CREATE_TOKEN = (gql: any) => gql`
    mutation($data: CreateTokenInput!) {
        createToken(data: $data) {
            id
        }
    }
`;
export const GET_TOKEN = (gql: any) => gql`
    query($id: ID!) {
        getToken(id: $id) {
            id
            code
            name
            platformName
            platformCode
            platform
            platformEmail
            status
            partnerName
            partnerCode
            partner
            token
            secretKey
        }
    }
`;
export const FIND_TOKENS = (gql: any) => gql`
    query findTokens($offset: String, $limit: Int) {
        findTokens(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                platformName
                platformCode
                platform
                status
                partnerName
                partnerCode
                partner
            }
        }
    }
`;