const t = {
    new: {
        attributes: [
            'game', 'wdefinition', 'type', 'params', 'startAt', 'endAt', 'name', 'description',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$wdefinition!', '$game', '$type', '$params', '$startAt', '$endAt', '$name', '$description'
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'game', 'wdefinition', 'type', 'params', 'startAt', 'endAt', 'name', 'description'
        ],
        content: [
            ['row', [
                ['block', [
                    '!$wdefinition!', '$game', '$type', '$params', '$startAt', '$endAt', '$name', '$description'
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: [
            'key', 'game', 'name', 'description',
        ],
    },
    list: {
        columns: [
            {id: 'key', label: 'column_key_label'},
            {id: 'status', label: 'column_status_label'},
            {id: 'name', label: 'column_name_label'},
            {id: 'gameCode', label: 'column_game_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;