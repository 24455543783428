export const CREATE_DYNAUDIO = (gql: any) => gql`
    mutation($data: CreateDynaudioInput!) {
        createDynaudio(data: $data) {
            id
        }
    }
`;
export const GET_DYNAUDIO = (gql: any) => gql`
    query($id: ID!) {
        getDynaudio(id: $id) {
            id
            status
            tags
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            title
            description
            job
            type
            mainAudioFile {
                available
                key
                url
            }
            variables
            generateFailedDetails
            draw
            drawCode
            sale
            promise
            promiseCode
        }
    }
`;
export const FIND_DYNAUDIOS = (gql: any) => gql`
    query findDynaudios($offset: String, $limit: Int) {
        findDynaudios(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                title
                token
                job
                type
            }
        }
    }
`;