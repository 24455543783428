export const CREATE_EXTERNALEVENT = (gql: any) => gql`
    mutation($data: CreateExternaleventInput!) {
        createExternalevent(data: $data) {
            id
        }
    }
`;
export const GET_EXTERNALEVENT = (gql: any) => gql`
    query($id: ID!) {
        getExternalevent(id: $id) {
            createdAt
            id
            status
            tags
            tenant
            tenantCode
            tenantName
            organization
            organizationCode
            organizationName
            game
            gameCode
            gameName
            sale
            provider
            type
            updatedAt
        }
    }
`;
export const FIND_EXTERNALEVENTS = (gql: any) => gql`
    query findExternalevents($offset: String, $limit: Int) {
        findExternalevents(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                createdAt
                id
                status
                tags
                tenant
                tenantCode
                tenantName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                sale
                provider
                type
                updatedAt
            }
        }
    }
`;
export const SEARCH_EXTERNALEVENTS = (gql: any) => gql`
    query searchExternalevents($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchExternalevents(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                createdAt
                id
                status
                tags
                tenant
                tenantCode
                tenantName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                sale
                provider
                type
                updatedAt
            }
        }
    }
`;
export const SEARCH_HELLOASSOPAYMENT_EXTERNALEVENTS = (gql: any) => gql`
    query searchHelloassoPaymentExternalevents($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchHelloassoPaymentExternalevents: searchExternalevents(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "provider", type: "string", operator: "wildcard", value: "HELLOASSO"},
                {field: "type", type: "string", operator: "wildcard", value: "Payment"},
            ]
        }) {
            cursor
            count
            items {
                createdAt
                id
                status
                tags
                tenant
                tenantCode
                tenantName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                sale
                provider
                type
                updatedAt
            }
        }
    }
`;
export const SEARCH_ALL_EXTERNALEVENTS = (gql: any) => gql`
    query searchAllExternalevents($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAllExternalevents: searchExternalevents(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                createdAt
                id
                status
                tags
                tenant
                tenantCode
                tenantName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                sale
                provider
                type
                updatedAt
            }
        }
    }
`;
export const DELETE_EXTERNALEVENT = (gql: any) => gql`
    mutation deleteExternalevent($id: ID!) {
        deleteExternalevent(id: $id) {
            id
        }
    }
`;
export const UPDATE_EXTERNALEVENT = (gql: any) => gql`
    mutation updateExternalevent($id: ID!, $data: UpdateExternaleventInput!) {
        updateExternalevent(id: $id, data: $data) {
            id
        }
    }
`;

export const FIND_SALE_EXTERNALEVENTS = (gql: any) => gql`
    query($id: String!, $offset: String) {
        findSaleExternalevents(id: $id, offset: $offset) {
            cursor
            items {
                createdAt
                id
                status
                tags
                tenant
                tenantCode
                tenantName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                sale
                provider
                type
                updatedAt
            }
        }
    }
`;
