export const CREATE_THEME = (gql: any) => gql`
    mutation($data: CreateThemeInput!) {
        createTheme(data: $data) {
            id
            code
        }
    }
`;
export const GET_THEME = (gql: any) => gql`
    query($id: ID!) {
        getTheme(id: $id) {
            id
            code
            name
            tenant
            mainCss {
                available
                content
                url
            }
            mainJs {
                available
                content
                url
            }
            varColorNavPrimary
            varColorNavSecondary
            varColorPrimary
            varColorSecondary
            varColorTextPrimary
            varColorTextSecondary
            varColorBorderPrimary
            varColorBorderSecondary
            varColorPanelPrimary
            varColorPanelSecondary
            varColorLogo
            varColorTitle
            varColorChoicePrimary
            varColorChoiceSecondary
            varColorFooterPrimary
            varColorFooterSecondary
            varColorHeading
            publicCatalogStatus
        }
    }
`;
export const FIND_THEMES = (gql: any) => gql`
    query findThemes($offset: String, $limit: Int) {
        findThemes(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                mainCss {
                    available
                    url
                }
                mainJs {
                    available
                    url
                }
                varColorNavPrimary
                varColorNavSecondary
                varColorPrimary
                varColorSecondary
                varColorTextPrimary
                varColorTextSecondary
                varColorBorderPrimary
                varColorBorderSecondary
                varColorPanelPrimary
                varColorPanelSecondary
                varColorLogo
                varColorTitle
                varColorChoicePrimary
                varColorChoiceSecondary
                varColorFooterPrimary
                varColorFooterSecondary
                varColorHeading
            }
        }
    }
`;