export const CREATE_TAG = (gql: any) => gql`
    mutation($data: CreateTagInput!) {
        createTag(data: $data) {
            id
        }
    }
`;
export const GET_TAG = (gql: any) => gql`
    query($id: ID!) {
        getTag(id: $id) {
            id
            code
            name
            tenant
            tenantCode
            tenantName
            type
        }
    }
`;
export const FIND_TAGS = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findTags(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                tenant
                tenantCode
                tenantName
                type
            }
        }
    }
`;
export const FIND_TAGS_BY_TYPE = (gql: any) => gql`
    query($type: String, $offset: String, $limit: Int) {
        findTagsByType(type: $type, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                tenant
                tenantCode
                tenantName
                type
            }
        }
    }
`;
export const SEARCH_TAGS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchTags(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                tenant
                tenantCode
                tenantName
                type
            }
        }
    }
`;
export const UPDATE_TAG = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateTagInput!) {
        updateTag(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_TAG = (gql: any) => gql`
    mutation($id: ID!) {
        deleteTag(id: $id) {
            id
        }
    }
`;