export const CREATE_INVITATION = (gql: any) => gql`
    mutation($data: CreateInvitationInput!) {
        createInvitation(data: $data) {
            id
        }
    }
`;
export const DELETE_INVITATION = (gql: any) => gql`
    mutation($id: ID!) {
        deleteInvitation(id: $id) {
            id
        }
    }
`;
export const FIND_INVITATIONS = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findInvitations(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                attempts
                completedAt
                createdAt
                email
                expiredAt
                firstName
                game
                gameCode
                gameName
                id
                lastName
                maxAttempts
                organization
                organizationCode
                organizationName
                owner
                ownerCode
                ownerEmail
                ownerPhone
                phone
                preferredNotificationMethod
                secretCode
                status
                tenant
                tenantCode
                tenantName
                type
                user
                userCode
                userEmail
                userFirstName
                userLastName
                userPhone
            }
        }
    }
`;
export const GET_INVITATION = (gql: any) => gql`
    query($id: ID!) {
        getInvitation(id: $id) {
            abortedAt
            attempts
            campaign
            campaignCode
            campaignName
            completedAt
            createdAt
            email
            expiredAt
            firstName
            game
            gameCode
            gameName
            id
            inlinePreset
            lastName
            maxAttempts
            organization
            organizationCode
            organizationName
            owner
            ownerCode
            ownerEmail
            ownerPhone
            params {
                booleanValue
                floatValue
                intValue
                jsonValue
                name
                type
                value
            }
            partner
            partnerCode
            partnerName
            phone
            preferredNotificationMethod
            presets
            publicPageShortUrl
            publicPageUrl
            secretCode
            status
            statusAt
            tenant
            tenantCode
            tenantName
            token
            type
            updatedAt
            user
            userCode
            userEmail
            userFirstName
            userLastName
            userPhone
        }
    }
`;
export const SEARCH_INVITATIONS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchInvitations(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                attempts
                completedAt
                createdAt
                email
                expiredAt
                firstName
                game
                gameCode
                gameName
                id
                lastName
                maxAttempts
                organization
                organizationCode
                organizationName
                owner
                ownerCode
                ownerEmail
                ownerPhone
                phone
                preferredNotificationMethod
                secretCode
                status
                tenant
                tenantCode
                tenantName
                type
                user
                userCode
                userEmail
                userFirstName
                userLastName
                userPhone
            }
        }
    }
`;
export const UPDATE_INVITATION = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateInvitationInput!) {
        updateInvitation(id: $id, data: $data) {
            id
        }
    }
`;
