const t = {
    icon: 'fa-cart-arrow-down',
    menus: {
        main: {section: 'settings3', priority: 60},
    },
    new: {
        attributes: [
            'product', 'owner',
        ],
        content: [
            ['row', [
                ['block', [
                    '$owner!', '$product!'
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'product', 'owner', 'status', 'processorStatus',
        ],
        content: [
            ['row', [
                ['block', [
                    '$owner!', '$product!'
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: [
            'code', 'name', 'status', 'owner', 'amount', 'currency', 'processorStatus', 'orderitems',
        ],
    },
    list: {
        columns: [
            {id: 'id', label: 'column_id_label', flex: 0, width: 300},
            {id: 'status', label: 'column_status_label', flex: 0, width: 150},
            { id: 'gameCode', label: 'column_game_code_label', component: 'game' },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;