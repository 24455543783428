export const GET_PROMISE = (gql: any) => gql`
    query($id: ID!) {
        getPromise(id: $id) {
            id
            sale
            campaign
            campaignCode
            campaignName
            partner
            partnerCode
            partnerName
            organization
            organizationCode
            organizationName
            game
            gameCode
            gameName
            ownerGodfather
            ownerGodfatherCode
            ownerGodfatherEmail
            tickets
            donation
            createdAt
            updatedAt
            status
            publicPageShortUrl
        }
    }
`;
export const FIND_PROMISES = (gql: any) => gql`
    query findPromises($offset: String, $limit: Int) {
        findPromises(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                sale
                createdAt
                updatedAt
                status
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                tickets
                donation
                game
                gameCode
            }
        }
    }
`;

export const SEARCH_PROMISES = (gql: any) => gql`
    query searchPromises($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPromises(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                sale
                createdAt
                updatedAt
                status
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                tickets
                donation
                game
                gameCode
                gameName
            }
        }
    }
`;

export const UPDATE_PROMISE = (gql: any) => gql`
    mutation updatePromise($id: ID!, $data: UpdatePromiseInput!) {
        updatePromise(id: $id, data: $data) {
            id
        }
    }
`;

export const DELETE_PROMISE = (gql: any) => gql`
    mutation deletePromise($id: ID!) {
        deletePromise(id: $id) {
            id
        }
    }
`;
