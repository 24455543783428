export const CREATE_GVERSION = (gql: any) => gql`
    mutation($data: CreateGversionInput!) {
        createGversion(data: $data) {
            id
        }
    }
`;
export const GET_GVERSION = (gql: any) => gql`
    query($id: ID!) {
        getGversion(id: $id) {
            id
            name
            game
            gameCode
            gameName
            organization
            organizationName
            seoLang
            seoKeywords
            seoTitle
            seoDescription
            seoSubject
            seoAbstract
            seoAuthor
            fullName
            shortName
            legalName
            nin
            customPacks
            customBonuses
            customOwnerForm
            bookTickets
            ticketPriceAmount
            ticketPriceCurrency
            plannedTickets
            createdAt
            updatedAt
            type
            code
            locale
            country
            public
            private
            presentation
            description
            publicEmail
            publicPhone
            overline
            features
            plannedDrawnAt
            plannedStartedAt
            plannedClosedAt
            externalReference
            faviconImage {
                available
                url
            }
            appleTouchImage {
                available
                url
            }
            logoImage {
                available
                url
            }
            logoAltImage {
                available
                url
            }
            bannerImage {
                available
                url
            }
            mobileBannerImage {
                available
                url
            }
            tabletBannerImage {
                available
                url
            }
            mainImage {
                available
                url
            }
            banner2Image {
                available
                url
            }
            backgroundImage {
                available
                url
            }
            facebookShareImage {
                available
                url
            }
            twitterShareImage {
                available
                url
            }
            flyerImage {
                available
                url
            }
            termsPdfFile {
                available
                url
            }
            saleMandatoryOptInMessage
            websiteUrl
            twitterUrl
            facebookUrl
            instagramUrl
            linkedinUrl
            youtubeUrl
            pinterestUrl
            tumblrUrl
            flickrUrl
            redditUrl
            tiktokUrl
            vimeoUrl
            snapchatUrl
            backgroundVideoUrl
            bannerVideoUrl
            banner2VideoUrl
            flyerVideoUrl
            mainVideoUrl
            mainShortVideoUrl
            mainLongVideoUrl
            salePaidVideoUrl
            salePromisedVideoUrl
            winnerVideoUrl
            looserVideoUrl
            participantVideoUrl
            promiseReminderVideoUrl
            promiseLastReminderVideoUrl
            mainCss {
                available
                content
            }
            mainJs {
                available
                content
            }
            mainQuote
            mainQuoteImage {
                available
                url
            }
            mainQuoteTitle
            mainQuoteImageTitle
            mainQuoteImageSubtitle
            domain
            notifyEmail
            mainTheme
            godfatherImage {
                available
                url
            }
            godfatherVideoUrl
            godfatherTitle
            godfatherSubtitle
            godfather2Image {
                available
                url
            }
            godfather2VideoUrl
            godfather2Title
            godfather2Subtitle
            godfather3Image {
                available
                url
            }
            godfather3VideoUrl
            godfather3Title
            godfather3Subtitle
            agenda
            warning
            locationMainImage {
                available
                url
            }
            locationLogoImage {
                available
                url
            }
            locationMainVideoUrl
            locationMainAudioUrl
            locationLatitude
            locationLongitude
            locationName
            locationCountry
            locationZipCode
            locationStreet
            locationComplement
            locationCity
            locationState
            locationPhone
            locationEmail
            locationWebsiteUrl
            locationFacebookUrl
            locationTwitterUrl
            locationYoutubeUrl
            locationPresentation
            locationDescription
            infos
            varColorNavPrimary
            varColorNavSecondary
            varColorPrimary
            varColorSecondary
            varColorTextPrimary
            varColorTextSecondary
            varColorBorderPrimary
            varColorBorderSecondary
            varColorPanelPrimary
            varColorPanelSecondary
            varColorLogo
            varColorTitle
            varColorChoicePrimary
            varColorChoiceSecondary
            varColorFooterPrimary
            varColorFooterSecondary
            varColorHeading
            publicPageEffects
            publicPageModules
            publicPageModulesData
            publicPageModulesDiff
            publicPageModulesCustomRaw
            publicPageBackgroundStyle
            publicPageBackgroundCustom
            publicPageBackgroundColor
            publicPageBackgroundImage {
                available
                url
            }
            publicPageBackgroundTexture
            publicPageBackgroundVideoUrl
            bonusMessage
        }
    }
`;
export const FIND_GVERSIONS = (gql: any) => gql`
    query findGversions($offset: String, $limit: Int) {
        findGversions(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                type
                locale
                game
                gameName
                gameCode
                publicToken
                organization
                organizationCode
                organizationName
                bookTickets
                ticketPriceAmount
                ticketPriceCurrency
                plannedTickets
                createdAt
                updatedAt
                saleMandatoryOptInMessage
                mainCss {
                    available
                }
                mainJs {
                    available
                }
                domain
                bannerImage {
                    url
                    available
                }
                mobileBannerImage {
                    url
                    available
                }
                tabletBannerImage {
                    url
                    available
                }
            }
        }
    }
`;