export const CREATE_OVIDEO = (gql: any) => gql`
    mutation($data: CreateOvideoInput!) {
        createOvideo(data: $data) {
            id
        }
    }
`;
export const GET_OVIDEO = (gql: any) => gql`
    query($id: ID!) {
        getOvideo(id: $id) {
            id
            status
            tags
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            organization
            organizationCode
            organizationName
            mainVideoUrl
            desktopVideoUrl
            tabletVideoUrl
            mobileVideoUrl
            tvVideoUrl
            emailVideoUrl
        }
    }
`;
export const FIND_OVIDEOS = (gql: any) => gql`
    query findOvideos($offset: String, $limit: Int) {
        findOvideos(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                organization
                organizationCode
                organizationName
                mainVideoUrl
                desktopVideoUrl
                tabletVideoUrl
                mobileVideoUrl
                tvVideoUrl
                emailVideoUrl
            }
        }
    }
`;