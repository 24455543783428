export const CREATE_DPAPERSIZE = (gql: any) => gql`
    mutation($data: CreateDpapersizeInput!) {
        createDpapersize(data: $data) {
            id
        }
    }
`;
export const GET_DPAPERSIZE = (gql: any) => gql`
    query($id: ID!) {
        getDpapersize(id: $id) {
            id
            tenant
            code
            name
            value
            tags
        }
    }
`;
export const FIND_DPAPERSIZES = (gql: any) => gql`
    query findDpapersizes($offset: String, $limit: Int) {
        findDpapersizes(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                value
                tags
            }
        }
    }
`;