export const CREATE_CREPORT = (gql: any) => gql`
    mutation($data: CreateCreportInput!) {
        createCreport(data: $data) {
            id
        }
    }
`;
export const GET_CREPORT = (gql: any) => gql`
    query($id: ID!) {
        getCreport(id: $id) {
            id
            createdAt
            updatedAt
                startDate
            type
            typeParentPeriod
            period
            year
            semester
            month
            week
            monthWeek
            day
            hour
            campaign
            campaignCode
            campaignName
            tenant
            tenantCode
            tenantName
        }
    }
`;
export const FIND_CREPORTS = (gql: any) => gql`
    query findCreports($offset: String, $limit: Int) {
        findCreports(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                campaign
                campaignCode
                campaignName
                paidSales
                paidTickets
                paymentAmount
                promisedSales
            }
        }
    }
`;
export const FIND_ALL_CREPORTS = (gql: any) => gql`
    query findAllCreports($offset: String, $limit: Int) {
        findAllCreports: findCreports(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                campaign
                campaignCode
                campaignName
                paidSales
                paidTickets
                paymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_CREPORTS = (gql: any) => gql`
    query searchCreports($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchCreports(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                campaign
                campaignCode
                campaignName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_ALL_CREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchAllCreports:searchCreports(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                campaign
                campaignCode
                campaignName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_YEAR_CREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchYearCreports:searchCreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "YEAR", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                campaign
                campaignCode
                campaignName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_MONTH_CREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchMonthCreports:searchCreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "MONTH", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                campaign
                campaignCode
                campaignName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_WEEK_CREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchWeekCreports:searchCreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "WEEK", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                campaign
                campaignCode
                campaignName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_DAY_CREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchDayCreports:searchCreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "DAY", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                campaign
                campaignCode
                campaignName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_HOUR_CREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchHourCreports:searchCreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "HOUR", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                campaign
                campaignCode
                campaignName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
