const t = {
    icon: 'fa-print',
    menus: {
        main: {section: 'settings3', priority: 70},
    },
    new: {
        attributes: [
            'name', 'code', 'tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$code!', '$name!', '$tenant!',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'code', 'tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$code!', '$name!', '$tenant!',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'code', 'name',
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 0, width: 200} as any,
            {id: 'name', label: 'column_name_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;