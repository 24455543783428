export const CREATE_RECIPIENT = (gql: any) => gql`
    mutation($data: CreateRecipientInput!) {
        createRecipient(data: $data) {
            id
        }
    }
`;
export const DELETE_RECIPIENT = (gql: any) => gql`
    mutation($id: ID!) {
        deleteRecipient(id: $id) {
            id
        }
    }
`;
export const FIND_RECIPIENTS = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findRecipients(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                code
                createdAt
                email
                firstName
                game
                gameCode
                gameName
                grouping
                groupingCode
                groupingEmail
                id
                lastName
                notificationParams
                organization
                organizationCode
                organizationName
                phone
                seller
                sellerCode
                sellerEmail
                sellergroup
                sellergroupCode
                sellergroupName
                tenant
                tenantCode
                tenantName
            }
        }
    }
`;
export const GET_RECIPIENT = (gql: any) => gql`
    query($id: ID!) {
        getRecipient(id: $id) {
            campaign
            campaignCode
            campaignName
            code
            createdAt
            email
            firstName
            game
            gameCode
            gameName
            grouping
            groupingCode
            groupingEmail
            groupingName
            groupingPhone
            groupingType
            id
            lastName
            notificationParams
            organization
            organizationCode
            organizationName
            partner
            partnerCode
            partnerName
            phone
            privateToken
            publicToken
            seller
            sellerCode
            sellerEmail
            sellerFirstName
            sellerLastName
            sellerPhone
            sellergroup
            sellergroupCode
            sellergroupEmail
            sellergroupGameSellergroupType
            sellergroupName
            sellergroupPhone
            sellergroupType
            tenant
            tenantCode
            tenantName
            token
            updatedAt
        }
    }
`;
export const SEARCH_RECIPIENTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchRecipients(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                code
                createdAt
                email
                firstName
                game
                gameCode
                gameName
                grouping
                groupingCode
                groupingEmail
                id
                lastName
                notificationParams
                organization
                organizationCode
                organizationName
                phone
                seller
                sellerCode
                sellerEmail
                sellergroup
                sellergroupCode
                sellergroupName
                tenant
                tenantCode
                tenantName
            }
        }
    }
`;
export const UPDATE_RECIPIENT = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateRecipientInput!) {
        updateRecipient(id: $id, data: $data) {
            id
        }
    }
`;
