export const CREATE_SELLERGROUP = (gql: any) => gql`
    mutation($data: CreateSellergroupInput!) {
        createSellergroup(data: $data) {
            id
            code
        }
    }
`;
export const GET_SELLERGROUP = (gql: any) => gql`
    query($id: ID!) {
        getSellergroup(id: $id) {
            id
            token
            code
            customCode
            rank
            name
            email
            phone
            sellers {
                items {
                    firstName
                    lastName
                    email
                    code
                }
            }
            partner
            partnerCode
            partnerName
            organization
            organizationCode
            organizationName
            game
            gameCode
            gameName
            grouping
            groupingCode
            groupingName
            groupingEmail
            groupingPhone
            groupingType
            tenant
            tenantCode
            tenantName
            country
            type
            locale
            qrcodeLogoImage {
                url
                available
            }
            logoImage {
                url
                available
            }
            presentation
            overline
            description
            main
            stripeCustomer
        }
    }
`;
export const FIND_SELLERGROUPS = (gql: any) => gql`
    query findSellergroups($offset: String, $limit: Int) {
        findSellergroups(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id 
                token
                code
                name
                email
                phone
                sellers {
                    items {
                        firstName
                        lastName
                        email
                        code
                    }
                }
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                tenant
                tenantCode
                tenantName
                country
                locale
                type
            }
        }
    }
`;