const t =  {
    icon: 'fa-trophy',
    menus: {
        main: {section: 'settings', priority: 105},
    },
    new: {
        attributes: [
            'game', 'presets', 'type', 'code', 'value',
        ],
        content: [
            ['row', [
                ['block', [
                    '$game!', '$gachievementPresets',
                ]],
                ['block', [
                    '$type!', '$code!', '$value',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'game', 'type', 'code', 'value',
        ],
        content: [
            ['row', [
                ['block', [
                    '$game!',
                ]],
                ['block', [
                    '$type!', '$code', '$value',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [ 'gameName', 'gameCode', 'value', 'type', 'code'],
    },
    list: {
        columns: [
            {id: 'game', label: 'column_game_label', flex: 1, component: 'game'} as any,
            {id: 'type', label: 'column_type_label', flex: 1, width: 120} as any,
            {id: 'code', label: 'column_code_label', flex: 1, width: 120} as any,
            {id: 'value', label: 'column_value_label', flex: 1, width: 120} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;