export const CREATE_REGISTRATION = (gql: any) => gql`
    mutation($data: CreateRegistrationInput!) {
        createRegistration(data: $data) {
            id
        }
    }
`;
export const GET_REGISTRATION = (gql: any) => gql`
    query($id: ID!) {
        getRegistration(id: $id) {
            id
            type
            name
            organizationName
            organizationCode
            organization
            status
            partnerName
            partnerCode
            partner
            email
            phone
            lastName
            firstName
            campaignCode
            campaignName
            campaign
            utmSource
            utmCampaign
            utmTerm
            utmContent
            utmMedium
            lastStep
            registrationsteps {
                items {
                    id
                    code
                    createdAt
                }
            }
            requestedLogoImage {
                url
                available
            }
        }
    }
`;
export const FIND_COMPLETED_REGISTRATIONS = (gql: any) => gql`
    query findCompletedRegistrations($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        findCompletedRegistrations: findRegistrations(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                type
                name
                organizationName
                organizationCode
                organization
                gameName
                gameCode
                game
                privatePageUrl
                status
                partnerName
                partnerCode
                partner
                email
                phone
                lastName
                firstName
                campaignCode
                campaignName
                campaign
                utmSource
                utmCampaign
                utmTerm
                utmContent
                utmMedium
                
                requestedOrganizationDescription
                requestedOrganizationDomain
                requestedOrganizationFacebookUrl
                requestedOrganizationName
                requestedOrganizationOverline
                requestedOrganizationPresentation
                requestedOrganizationType
                requestedOrganizationWebsiteUrl
                requestedBunchList
                requestedGameDescription
                requestedGameName
                requestedGameOptions
                requestedGameOverline
                requestedGamePresentation
                requestedGameSubject
                requestedGameTheme
                requestedGimage1Image {
                    available
                    url
                }
                requestedGimage2Image {
                    available
                    url
                }
                requestedGimage3Image {
                    available
                    url
                }
                requestedGimage4Image {
                    available
                    url
                }
                requestedGimage5Image {
                    available
                    url
                }
                requestedGimage6Image {
                    available
                    url
                }
                requestedGimage7Image {
                    available
                    url
                }
                requestedGimage8Image {
                    available
                    url
                }
                requestedPlannedCustomTickets
                requestedPlannedDrawnAt
                requestedPlannedStartedAt
                requestedPlannedTickets
                requestedSaleData
                requestedTicketPriceAmount
                requestedTicketPriceCurrency
                requestedCity
                requestedCountry
                requestedLocale
                requestedZipCode
                requestedBannerImage {
                    available
                    url
                }
                requestedMobileBannerImage {
                    available
                    url
                }
                requestedTabletBannerImage {
                    available
                    url
                }
                requestedLogoImage {
                    available
                    url
                }
                requestedSupportedLocales
                requestedThemeCode
                lastStep
            }
        }
    }
`;
export const FIND_CANCELED_REGISTRATIONS = (gql: any) => gql`
    query findCanceledRegistrations($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        findCanceledRegistrations: findRegistrations(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                type
                name
                organizationName
                organizationCode
                organization
                gameName
                gameCode
                game
                privatePageUrl
                status
                partnerName
                partnerCode
                partner
                email
                phone
                lastName
                firstName
                campaignCode
                campaignName
                campaign
                utmSource
                utmCampaign
                utmTerm
                utmContent
                utmMedium
                
                requestedOrganizationDescription
                requestedOrganizationDomain
                requestedOrganizationFacebookUrl
                requestedOrganizationName
                requestedOrganizationOverline
                requestedOrganizationPresentation
                requestedOrganizationType
                requestedOrganizationWebsiteUrl
                requestedBunchList
                requestedGameDescription
                requestedGameName
                requestedGameOptions
                requestedGameOverline
                requestedGamePresentation
                requestedGameSubject
                requestedGameTheme
                requestedGimage1Image {
                    available
                    url
                }
                requestedGimage2Image {
                    available
                    url
                }
                requestedGimage3Image {
                    available
                    url
                }
                requestedGimage4Image {
                    available
                    url
                }
                requestedGimage5Image {
                    available
                    url
                }
                requestedGimage6Image {
                    available
                    url
                }
                requestedGimage7Image {
                    available
                    url
                }
                requestedGimage8Image {
                    available
                    url
                }
                requestedPlannedCustomTickets
                requestedPlannedDrawnAt
                requestedPlannedStartedAt
                requestedPlannedTickets
                requestedSaleData
                requestedTicketPriceAmount
                requestedTicketPriceCurrency
                requestedCity
                requestedCountry
                requestedLocale
                requestedZipCode
                requestedBannerImage {
                    available
                    url
                }
                requestedMobileBannerImage {
                    available
                    url
                }
                requestedTabletBannerImage {
                    available
                    url
                }
                requestedLogoImage {
                    available
                    url
                }
                requestedSupportedLocales
                requestedThemeCode
                failureDetails
                lastStep
            }
        }
    }
`;
export const FIND_NOTCOMPLETED_REGISTRATIONS = (gql: any) => gql`
    query findNotCompletedRegistrations($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        findNotCompletedRegistrations: findRegistrations(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                type
                name
                organizationName
                organizationCode
                organization
                gameName
                gameCode
                game
                privatePageUrl
                status
                partnerName
                partnerCode
                partner
                email
                phone
                lastName
                firstName
                campaignCode
                campaignName
                campaign
                utmSource
                utmCampaign
                utmTerm
                utmContent
                utmMedium
                
                requestedOrganizationDescription
                requestedOrganizationDomain
                requestedOrganizationFacebookUrl
                requestedOrganizationName
                requestedOrganizationOverline
                requestedOrganizationPresentation
                requestedOrganizationType
                requestedOrganizationWebsiteUrl
                requestedBunchList
                requestedGameDescription
                requestedGameName
                requestedGameOptions
                requestedGameOverline
                requestedGamePresentation
                requestedGameSubject
                requestedGameTheme
                requestedGimage1Image {
                    available
                    url
                }
                requestedGimage2Image {
                    available
                    url
                }
                requestedGimage3Image {
                    available
                    url
                }
                requestedGimage4Image {
                    available
                    url
                }
                requestedGimage5Image {
                    available
                    url
                }
                requestedGimage6Image {
                    available
                    url
                }
                requestedGimage7Image {
                    available
                    url
                }
                requestedGimage8Image {
                    available
                    url
                }
                requestedPlannedCustomTickets
                requestedPlannedDrawnAt
                requestedPlannedStartedAt
                requestedPlannedTickets
                requestedSaleData
                requestedTicketPriceAmount
                requestedTicketPriceCurrency
                requestedCity
                requestedCountry
                requestedLocale
                requestedZipCode
                requestedBannerImage {
                    available
                    url
                }
                requestedMobileBannerImage {
                    available
                    url
                }
                requestedTabletBannerImage {
                    available
                    url
                }
                requestedLogoImage {
                    available
                    url
                }
                requestedSupportedLocales
                requestedThemeCode
                lastStep
            }
        }
    }
`;
export const SEARCH_COMPLETED_REGISTRATIONS = (gql: any) => gql`
    query searchCompletedRegistrations($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchCompletedRegistrations: searchRegistrations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "COMPLETED"}
            ]
        }) {
            cursor
            count
            items {
                id
                type
                name
                organizationName
                organizationCode
                organization
                gameName
                gameCode
                game
                privatePageUrl
                status
                partnerName
                partnerCode
                partner
                email
                phone
                lastName
                firstName
                campaignCode
                campaignName
                campaign
                utmSource
                utmCampaign
                utmTerm
                utmContent
                utmMedium
                
                requestedOrganizationDescription
                requestedOrganizationDomain
                requestedOrganizationFacebookUrl
                requestedOrganizationName
                requestedOrganizationOverline
                requestedOrganizationPresentation
                requestedOrganizationType
                requestedOrganizationWebsiteUrl
                requestedBunchList
                requestedGameDescription
                requestedGameName
                requestedGameOptions
                requestedGameOverline
                requestedGamePresentation
                requestedGameSubject
                requestedGameTheme
                requestedGimage1Image {
                    available
                    url
                }
                requestedGimage2Image {
                    available
                    url
                }
                requestedGimage3Image {
                    available
                    url
                }
                requestedGimage4Image {
                    available
                    url
                }
                requestedGimage5Image {
                    available
                    url
                }
                requestedGimage6Image {
                    available
                    url
                }
                requestedGimage7Image {
                    available
                    url
                }
                requestedGimage8Image {
                    available
                    url
                }
                requestedPlannedCustomTickets
                requestedPlannedDrawnAt
                requestedPlannedStartedAt
                requestedPlannedTickets
                requestedSaleData
                requestedTicketPriceAmount
                requestedTicketPriceCurrency
                requestedCity
                requestedCountry
                requestedLocale
                requestedZipCode
                requestedBannerImage {
                    available
                    url
                }
                requestedMobileBannerImage {
                    available
                    url
                }
                requestedTabletBannerImage {
                    available
                    url
                }
                requestedLogoImage {
                    available
                    url
                }
                requestedSupportedLocales
                requestedThemeCode
                createdAt
            }
        }
    }
`;
export const SEARCH_CANCELED_REGISTRATIONS = (gql: any) => gql`
    query searchCanceledRegistrations($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchCanceledRegistrations: searchRegistrations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "CANCELED"}
            ]
        }) {
            cursor
            count
            items {
                id
                type
                name
                organizationName
                organizationCode
                organization
                gameName
                gameCode
                game
                privatePageUrl
                status
                partnerName
                partnerCode
                partner
                email
                phone
                lastName
                firstName
                campaignCode
                campaignName
                campaign
                utmSource
                utmCampaign
                utmTerm
                utmContent
                utmMedium
                
                requestedOrganizationDescription
                requestedOrganizationDomain
                requestedOrganizationFacebookUrl
                requestedOrganizationName
                requestedOrganizationOverline
                requestedOrganizationPresentation
                requestedOrganizationType
                requestedOrganizationWebsiteUrl
                requestedBunchList
                requestedGameDescription
                requestedGameName
                requestedGameOptions
                requestedGameOverline
                requestedGamePresentation
                requestedGameSubject
                requestedGameTheme
                requestedGimage1Image {
                    available
                    url
                }
                requestedGimage2Image {
                    available
                    url
                }
                requestedGimage3Image {
                    available
                    url
                }
                requestedGimage4Image {
                    available
                    url
                }
                requestedGimage5Image {
                    available
                    url
                }
                requestedGimage6Image {
                    available
                    url
                }
                requestedGimage7Image {
                    available
                    url
                }
                requestedGimage8Image {
                    available
                    url
                }
                requestedPlannedCustomTickets
                requestedPlannedDrawnAt
                requestedPlannedStartedAt
                requestedPlannedTickets
                requestedSaleData
                requestedTicketPriceAmount
                requestedTicketPriceCurrency
                requestedCity
                requestedCountry
                requestedLocale
                requestedZipCode
                requestedBannerImage {
                    available
                    url
                }
                requestedMobileBannerImage {
                    available
                    url
                }
                requestedTabletBannerImage {
                    available
                    url
                }
                requestedLogoImage {
                    available
                    url
                }
                requestedSupportedLocales
                requestedThemeCode
                createdAt
                failureDetails
                lastStep
            }
        }
    }
`;
export const SEARCH_ALL_REGISTRATIONS = (gql: any) => gql`
    query searchAllRegistrations($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAllRegistrations: searchRegistrations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
            ]
        }) {
            cursor
            count
            items {
                id
                type
                name
                organizationName
                organizationCode
                organization
                gameName
                gameCode
                game
                privatePageUrl
                status
                partnerName
                partnerCode
                partner
                email
                phone
                lastName
                firstName
                campaignCode
                campaignName
                campaign
                utmSource
                utmCampaign
                utmTerm
                utmContent
                utmMedium
                
                requestedOrganizationDescription
                requestedOrganizationDomain
                requestedOrganizationFacebookUrl
                requestedOrganizationName
                requestedOrganizationOverline
                requestedOrganizationPresentation
                requestedOrganizationType
                requestedOrganizationWebsiteUrl
                requestedBunchList
                requestedGameDescription
                requestedGameName
                requestedGameOptions
                requestedGameOverline
                requestedGamePresentation
                requestedGameSubject
                requestedGameTheme
                requestedGimage1Image {
                    available
                    url
                }
                requestedGimage2Image {
                    available
                    url
                }
                requestedGimage3Image {
                    available
                    url
                }
                requestedGimage4Image {
                    available
                    url
                }
                requestedGimage5Image {
                    available
                    url
                }
                requestedGimage6Image {
                    available
                    url
                }
                requestedGimage7Image {
                    available
                    url
                }
                requestedGimage8Image {
                    available
                    url
                }
                requestedPlannedCustomTickets
                requestedPlannedDrawnAt
                requestedPlannedStartedAt
                requestedPlannedTickets
                requestedSaleData
                requestedTicketPriceAmount
                requestedTicketPriceCurrency
                requestedCity
                requestedCountry
                requestedLocale
                requestedZipCode
                requestedBannerImage {
                    available
                    url
                }
                requestedMobileBannerImage {
                    available
                    url
                }
                requestedTabletBannerImage {
                    available
                    url
                }
                requestedLogoImage {
                    available
                    url
                }
                requestedSupportedLocales
                requestedThemeCode
                createdAt
                failureDetails
                lastStep
            }
        }
    }
`;
export const SEARCH_COMPLETEDGAME_REGISTRATIONS = (gql: any) => gql`
    query searchCompletedGameRegistrations($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchCompletedGameRegistrations: searchRegistrations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "COMPLETED"},
                {field: "type", type: "string", operator: "wildcard", value: "game"},
            ]
        }) {
            cursor
            count
            items {
                id
                type
                name
                organizationName
                organizationCode
                organization
                gameName
                gameCode
                game
                privatePageUrl
                status
                partnerName
                partnerCode
                partner
                email
                phone
                lastName
                firstName
                campaignCode
                campaignName
                campaign
                utmSource
                utmCampaign
                utmTerm
                utmContent
                utmMedium
                
                requestedOrganizationDescription
                requestedOrganizationDomain
                requestedOrganizationFacebookUrl
                requestedOrganizationName
                requestedOrganizationOverline
                requestedOrganizationPresentation
                requestedOrganizationType
                requestedOrganizationWebsiteUrl
                requestedBunchList
                requestedGameDescription
                requestedGameName
                requestedGameOptions
                requestedGameOverline
                requestedGamePresentation
                requestedGameSubject
                requestedGameTheme
                requestedGimage1Image {
                    available
                    url
                }
                requestedGimage2Image {
                    available
                    url
                }
                requestedGimage3Image {
                    available
                    url
                }
                requestedGimage4Image {
                    available
                    url
                }
                requestedGimage5Image {
                    available
                    url
                }
                requestedGimage6Image {
                    available
                    url
                }
                requestedGimage7Image {
                    available
                    url
                }
                requestedGimage8Image {
                    available
                    url
                }
                requestedPlannedCustomTickets
                requestedPlannedDrawnAt
                requestedPlannedStartedAt
                requestedPlannedTickets
                requestedSaleData
                requestedTicketPriceAmount
                requestedTicketPriceCurrency
                requestedCity
                requestedCountry
                requestedLocale
                requestedZipCode
                requestedBannerImage {
                    available
                    url
                }
                requestedMobileBannerImage {
                    available
                    url
                }
                requestedTabletBannerImage {
                    available
                    url
                }
                requestedLogoImage {
                    available
                    url
                }
                requestedSupportedLocales
                requestedThemeCode
                createdAt
                lastStep
            }
        }
    }
`;
export const SEARCH_COMPLETEDSPONSOR_REGISTRATIONS = (gql: any) => gql`
    query searchCompletedSponsorRegistrations($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchCompletedSponsorRegistrations: searchRegistrations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "COMPLETED"},
                {field: "type", type: "string", operator: "wildcard", value: "sponsor"},
            ]
        }) {
            cursor
            count
            items {
                id
                type
                name
                organizationName
                organizationCode
                organization
                gameName
                gameCode
                game
                privatePageUrl
                status
                partnerName
                partnerCode
                partner
                email
                phone
                lastName
                firstName
                campaignCode
                campaignName
                campaign
                utmSource
                utmCampaign
                utmTerm
                utmContent
                utmMedium
                
                requestedOrganizationDescription
                requestedOrganizationDomain
                requestedOrganizationFacebookUrl
                requestedOrganizationName
                requestedOrganizationOverline
                requestedOrganizationPresentation
                requestedOrganizationType
                requestedOrganizationWebsiteUrl
                requestedBunchList
                requestedGameDescription
                requestedGameName
                requestedGameOptions
                requestedGameOverline
                requestedGamePresentation
                requestedGameSubject
                requestedGameTheme
                requestedGimage1Image {
                    available
                    url
                }
                requestedGimage2Image {
                    available
                    url
                }
                requestedGimage3Image {
                    available
                    url
                }
                requestedGimage4Image {
                    available
                    url
                }
                requestedGimage5Image {
                    available
                    url
                }
                requestedGimage6Image {
                    available
                    url
                }
                requestedGimage7Image {
                    available
                    url
                }
                requestedGimage8Image {
                    available
                    url
                }
                requestedPlannedCustomTickets
                requestedPlannedDrawnAt
                requestedPlannedStartedAt
                requestedPlannedTickets
                requestedSaleData
                requestedTicketPriceAmount
                requestedTicketPriceCurrency
                requestedCity
                requestedCountry
                requestedLocale
                requestedZipCode
                requestedBannerImage {
                    available
                    url
                }
                requestedMobileBannerImage {
                    available
                    url
                }
                requestedTabletBannerImage {
                    available
                    url
                }
                requestedLogoImage {
                    available
                    url
                }
                requestedSupportedLocales
                requestedThemeCode
                createdAt
                lastStep
            }
        }
    }
`;
export const SEARCH_COMPLETEDDONEE_REGISTRATIONS = (gql: any) => gql`
    query searchCompletedDoneeRegistrations($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchCompletedDoneeRegistrations: searchRegistrations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "COMPLETED"},
                {field: "type", type: "string", operator: "eq", value: "donee"},
            ]
        }) {
            cursor
            count
            items {
                id
                type
                name
                organizationName
                organizationCode
                organization
                gameName
                gameCode
                game
                privatePageUrl
                status
                partnerName
                partnerCode
                partner
                email
                phone
                lastName
                firstName
                campaignCode
                campaignName
                campaign
                utmSource
                utmCampaign
                utmTerm
                utmContent
                utmMedium
                
                requestedOrganizationDescription
                requestedOrganizationDomain
                requestedOrganizationFacebookUrl
                requestedOrganizationName
                requestedOrganizationOverline
                requestedOrganizationPresentation
                requestedOrganizationType
                requestedOrganizationWebsiteUrl
                requestedBunchList
                requestedGameDescription
                requestedGameName
                requestedGameOptions
                requestedGameOverline
                requestedGamePresentation
                requestedGameSubject
                requestedGameTheme
                requestedGimage1Image {
                    available
                    url
                }
                requestedGimage2Image {
                    available
                    url
                }
                requestedGimage3Image {
                    available
                    url
                }
                requestedGimage4Image {
                    available
                    url
                }
                requestedGimage5Image {
                    available
                    url
                }
                requestedGimage6Image {
                    available
                    url
                }
                requestedGimage7Image {
                    available
                    url
                }
                requestedGimage8Image {
                    available
                    url
                }
                requestedPlannedCustomTickets
                requestedPlannedDrawnAt
                requestedPlannedStartedAt
                requestedPlannedTickets
                requestedSaleData
                requestedTicketPriceAmount
                requestedTicketPriceCurrency
                requestedCity
                requestedCountry
                requestedLocale
                requestedZipCode
                requestedBannerImage {
                    available
                    url
                }
                requestedMobileBannerImage {
                    available
                    url
                }
                requestedTabletBannerImage {
                    available
                    url
                }
                requestedLogoImage {
                    available
                    url
                }
                requestedSupportedLocales
                requestedThemeCode
                createdAt
                lastStep
            }
        }
    }
`;
export const SEARCH_NOTCOMPLETED_REGISTRATIONS = (gql: any) => gql`
    query searchNotCompletedRegistrations($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchNotCompletedRegistrations: searchRegistrations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "CREATED"}
            ]
        }) {
            cursor
            count
            items {
                id
                type
                name
                organizationName
                organizationCode
                organization
                gameName
                gameCode
                game
                privatePageUrl
                status
                partnerName
                partnerCode
                partner
                email
                phone
                lastName
                firstName
                campaignCode
                campaignName
                campaign
                utmSource
                utmCampaign
                utmTerm
                utmContent
                utmMedium
                
                requestedOrganizationDescription
                requestedOrganizationDomain
                requestedOrganizationFacebookUrl
                requestedOrganizationName
                requestedOrganizationOverline
                requestedOrganizationPresentation
                requestedOrganizationType
                requestedOrganizationWebsiteUrl
                requestedBunchList
                requestedGameDescription
                requestedGameName
                requestedGameOptions
                requestedGameOverline
                requestedGamePresentation
                requestedGameSubject
                requestedGameTheme
                requestedGimage1Image {
                    available
                    url
                }
                requestedGimage2Image {
                    available
                    url
                }
                requestedGimage3Image {
                    available
                    url
                }
                requestedGimage4Image {
                    available
                    url
                }
                requestedGimage5Image {
                    available
                    url
                }
                requestedGimage6Image {
                    available
                    url
                }
                requestedGimage7Image {
                    available
                    url
                }
                requestedGimage8Image {
                    available
                    url
                }
                requestedPlannedCustomTickets
                requestedPlannedDrawnAt
                requestedPlannedStartedAt
                requestedPlannedTickets
                requestedSaleData
                requestedTicketPriceAmount
                requestedTicketPriceCurrency
                requestedCity
                requestedCountry
                requestedLocale
                requestedZipCode
                requestedBannerImage {
                    available
                    url
                }
                requestedMobileBannerImage {
                    available
                    url
                }
                requestedTabletBannerImage {
                    available
                    url
                }
                requestedLogoImage {
                    available
                    url
                }
                requestedSupportedLocales
                requestedThemeCode
                createdAt
                lastStep
            }
        }
    }
`;
export const FIND_REGISTRATIONS = (gql: any) => gql`
    query findRegistrations($offset: String, $limit: Int) {
        findRegistrations(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                type
                name
                organizationName
                organizationCode
                organization
                status
                partnerName
                partnerCode
                partner
                email
                phone
                lastName
                firstName
                campaignCode
                campaignName
                campaign
                utmSource
                utmCampaign
                utmTerm
                utmContent
                utmMedium
                lastStep
            }
        }
    }
`;
export const SEARCH_REGISTRATIONS = (gql: any) => gql`
    query searchRegistrations($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchRegistrations(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                name
                organizationName
                organizationCode
                organization
                status
                partnerName
                partnerCode
                partner
                email
                phone
                lastName
                firstName
                campaignCode
                campaignName
                campaign
                lastStep
            }
        }
    }
`;
export const UPDATE_REGISTRATION = (gql: any) => gql`
    mutation updateRegistration($id: ID!, $data: UpdateRegistrationInput!) {
        updateRegistration(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_REGISTRATION = (gql: any) => gql`
    mutation deleteRegistration($id: ID!) {
        deleteRegistration(id: $id) {
            id
        }
    }
`;