const t = {
    icon: 'fa-clock',
    menus: {
        main: {section: 'settings4', priority: 35},
    },
    new: {
        attributes: [
            'partner', 'type', 'kind', 'treminder',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$partner!', '$treminder',
                ]],
                ['block', [
                    '$type!', '$kind',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'status',
        ],
        content: [
            ['row', [
                ['block', [
                    '$status:readonly!',
                    '!$partner!', '$treminder',
                ]],
                ['block', [
                    '$type!', '$kind',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: [
            'id', 'organizationCode', 'organizationName', 'gameCode', 'tenantName', 'promise', 'promiseCode', 'kind', 'gpreminder', 'opreminder', 'ppreminder', 'tpreminder',
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 0, width: 100} as any,
            {id: 'type', label: 'column_type_label'},
            {id: 'kind', label: 'column_kind_label'},
            {id: 'status', label: 'column_status_label'},
            {id: 'jobStatus', label: 'column_jobstatus_label'},
            {id: 'partner', label: 'column_partner_label', flex: 1, width: 120, component: 'partner'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;