export const CREATE_RIDP = (gql: any) => gql`
    mutation($data: CreateRidpInput!) {
        createRidp(data: $data) {
            id
        }
    }
`;
export const DELETE_RIDP = (gql: any) => gql`
    mutation($id: ID!) {
        deleteRidp(id: $id) {
            id
        }
    }
`;
export const FIND_RIDPS = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findRidps(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                createdAt
                id
                provider
                status
                tags
                tenant
                tenantCode
                tenantName
                token
                updatedAt
            }
        }
    }
`;
export const GET_RIDP = (gql: any) => gql`
    query($id: ID!) {
        getRidp(id: $id) {
            createdAt
                id
                provider
                status
                tags
                tenant
                tenantCode
                tenantName
                token
                updatedAt
        }
    }
`;
export const SEARCH_RIDPS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchRidps(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                createdAt
                id
                provider
                status
                tags
                tenant
                tenantCode
                tenantName
                token
                updatedAt
            }
        }
    }
`;
export const UPDATE_RIDP = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateRidpInput!) {
        updateRidp(id: $id, data: $data) {
            id
        }
    }
`;
