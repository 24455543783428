const t = {
    icon: 'fa-hand-holding-dollar',
    menus: {
        main: {section: 'settings3', priority: 40},
    },
    new: {
        attributes: [
            'name', 'code', 'tenant', 'status', 'presentation', 'description', 'websiteUrl',
            'logoImage', 'qrcodeLogoImage', 'bannerImage', 'mobileBannerImage', 'tabletBannerImage', 'logoAltImage', 'faviconImage', 'appleTouchImage', 'mainImage',
            'stripeCustomer',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$code!', '$name!', '$tenant!', '$presentation', '$description', '$websiteUrl', '$stripeCustomer',
                ]],
                ['block', [
                    '$logoImage', '$qrcodeLogoImage', '$bannerImage', '$mobileBannerImage', '$tabletBannerImage', '$logoAltImage', '$faviconImage', '$appleTouchImage', '$mainImage',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'code', 'tenant', 'status', 'presentation', 'description', 'websiteUrl',
            'logoImage', 'qrcodeLogoImage', 'bannerImage', 'mobileBannerImage', 'tabletBannerImage', 'logoAltImage', 'faviconImage', 'appleTouchImage', 'mainImage',
            'publicPageBackgroundImage', 'publicPageModules', 'publicPageModulesData', 'publicPageModulesCustomRaw',
            'publicPageModulesDiff', 'publicPageEffects', 'publicPageBackgroundStyle', 'publicPageBackgroundCustom',
            'publicPageBackgroundColor', 'publicPageBackgroundTexture', 'varColorPrimary', 'varColorSecondary', 'varColorNavPrimary',
            'varColorNavSecondary', 'varColorTextPrimary', 'varColorTextSecondary', 'varColorBorderPrimary', 'varColorBorderSecondary',
            'varColorPanelPrimary', 'varColorPanelSecondary', 'varColorChoicePrimary', 'varColorChoiceSecondary', 'varColorFooterPrimary',
            'varColorFooterSecondary', 'varColorTitle', 'varColorHeading', 'varColorLogo', 'mainTheme', 'mainDfontfamily', 'street', 'complement',
            'city', 'zipCode', 'state', 'locale', 'country', 'supportedLocales', 'scope', 'scopeLocale', 'campaign', 'smssender', 'emailsender',
            'notifyEmail', 'notifyPhone', 'backgroundImage', 'banner2Image', 'facebookShareImage', 'flyerImage', 'twitterShareImage',
            'mainCss', 'mainJs', 'mainQuote', 'mainQuoteImage', 'mainQuoteImageTitle', 'mainQuoteImageSubtitle', 'mainQuoteTitle', 'mainQuoteVideoUrl',
            'private', 'public', 'stripeCustomer',
        ],
        content: [
            ['tabs', [
                ['edit_partner_form_general_title', [
                    ['row', [
                        ['block', [
                            '$status:readonly!', '!$code!', '$name!', '$tenant!', '$campaign', '$presentation', '$description', '$websiteUrl', '$mainTheme', '$mainDfontfamily',
                            '$street', '$complement', '$city', '$zipCode', '$state', '$locale', '$country', '$supportedLocales', '$scope', '$scopeLocale',
                            '$smssender', '$emailsender', '$notifyEmail', '$notifyPhone', '$mainQuote', '$mainQuoteImageTitle', '$mainQuoteImageSubtitle',
                            '$mainQuoteTitle', '$mainQuoteVideoUrl', '$private', '$public', '$stripeCustomer',
                        ]],
                        ['block', [
                            '$logoImage', '$qrcodeLogoImage', '$bannerImage', '$mobileBannerImage', '$tabletBannerImage', '$logoAltImage', '$faviconImage', '$appleTouchImage', '$mainImage', '$publicPageBackgroundImage',
                            '$backgroundImage', '$banner2Image', '$facebookShareImage', '$flyerImage', '$twitterShareImage', '$mainQuoteImage',
                        ]]
                    ]]
                ]],
                ['edit_partner_form_design_title', [
                    ['row', [
                        ['column', [
                            '$varColorPrimary', '$varColorSecondary', '$varColorNavPrimary', '$varColorNavSecondary', '$varColorTextPrimary', '$varColorTextSecondary',
                            '$varColorBorderPrimary', '$varColorBorderSecondary', '$varColorPanelPrimary', '$varColorPanelSecondary', '$varColorChoicePrimary',
                            '$varColorChoiceSecondary', '$varColorFooterPrimary', '$varColorFooterSecondary', '$varColorTitle', '$varColorHeading', '$varColorLogo',
                        ]],
                        ['block', [
                            '$mainCss', '$mainJs', '$publicPageModules', '$publicPageModulesData', '$publicPageModulesCustomRaw', '$publicPageModulesDiff', '$publicPageEffects',
                            '$publicPageBackgroundStyle', '$publicPageBackgroundCustom', '$publicPageBackgroundColor', '$publicPageBackgroundTexture',
                        ]]
                    ]]
                ]]
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'code', 'name', 'status', 'websiteUrl', 'locale',
        ],
    },
    list: {
        columns: [
            {id: 'logoImage', label: 'column_logoimage_label', flex: 0, width: 110, render: 'image'} as any,
            {id: 'code', label: 'column_code_label', flex: 0, width: 200} as any,
            {id: 'name', label: 'column_name_label'},
            {id: 'status', label: 'column_status_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;