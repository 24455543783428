export const CREATE_GVIDEO = (gql: any) => gql`
    mutation($data: CreateGvideoInput!) {
        createGvideo(data: $data) {
            id
        }
    }
`;
export const GET_GVIDEO = (gql: any) => gql`
    query($id: ID!) {
        getGvideo(id: $id) {
            id
            status
            tags
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            title
            description
            rank
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            mainVideoUrl
            desktopVideoUrl
            tabletVideoUrl
            mobileVideoUrl
            tvVideoUrl
            emailVideoUrl
        }
    }
`;
export const FIND_GVIDEOS = (gql: any) => gql`
    query findGvideos($offset: String, $limit: Int) {
        findGvideos(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                mainVideoUrl
                desktopVideoUrl
                tabletVideoUrl
                mobileVideoUrl
                tvVideoUrl
                emailVideoUrl
            }
        }
    }
`;