const t = {
    new: {
        attributes: [
            'key', 'name', 'description',
        ],
        content: [
            ['row', [
                ['block', [
                    '$key', '$name', '$description',
                ]]
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'key', 'name', 'description',
        ],
        content: [
            ['row', [
                ['block', [
                    '$key!', '$name', '$description',
                ]]
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        body: true,
        properties: [
            'key', 'name', 'description',
        ],
    },
    list: {
        columns: [
            {id: 'key', label: 'column_key_label'},
            {id: 'status', label: 'column_status_label'},
            {id: 'name', label: 'column_name_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
    types: {
        wconfiguration: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    {id: 'key', label: 'column_key_label'},
                    {id: 'status', label: 'column_status_label'},
                    {id: 'name', label: 'column_name_label'},
                    {id: 'gameCode', label: 'column_game_label'},
                ],
                globalActions: ['add'],
                actions: ['display', 'edit', 'delete'],
            },
        },
    },
};

export default t;