export const CREATE_GADRULE = (gql: any) => gql`
    mutation($data: CreateGadruleInput!) {
        createGadrule(data: $data) {
            id
        }
    }
`;
export const GET_GADRULE = (gql: any) => gql`
    query($id: ID!) {
        getGadrule(id: $id) {
            blacklistedAds
            blacklistedAdvertisers
            blacklistedAffiliaters
            blacklistedCountries
            blacklistedTags
            campaign
            campaignCode
            campaignName
            createdAt
            features
            game
            gameCode
            gameName
            id
            limit
            organization
            organizationCode
            organizationName
            partner
            partnerCode
            partnerName
            privateToken
            publicToken
            publishArchivedAt
            publishArchivedBy
            publishPublishedAt
            publishPublishedBy
            publishStatus
            publishUnpublishedAt
            publishUnpublishedBy
            rank
            status
            tags
            tenant
            tenantCode
            tenantName
            token
            updatedAt
            whitelistedAds
            whitelistedAdvertisers
            whitelistedAffiliaters
            whitelistedCountries
            whitelistedTags
        }
    }
`;
export const FIND_GADRULES = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findGadrules(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                blacklistedAds
                blacklistedAdvertisers
                blacklistedAffiliaters
                blacklistedCountries
                blacklistedTags
                createdAt
                features
                game
                gameCode
                gameName
                id
                limit
                organization
                organizationCode
                organizationName
                publishStatus
                rank
                status
                tags
                whitelistedAds
                whitelistedAdvertisers
                whitelistedAffiliaters
                whitelistedCountries
                whitelistedTags
            }
        }
    }
`;
export const SEARCH_GADRULES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGadrules(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                blacklistedAds
                blacklistedAdvertisers
                blacklistedAffiliaters
                blacklistedCountries
                blacklistedTags
                createdAt
                features
                game
                gameCode
                gameName
                id
                limit
                organization
                organizationCode
                organizationName
                publishStatus
                rank
                status
                tags
                whitelistedAds
                whitelistedAdvertisers
                whitelistedAffiliaters
                whitelistedCountries
                whitelistedTags
            }
        }
    }
`;
export const UPDATE_GADRULE = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateGadruleInput!) {
        updateGadrule(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_GADRULE = (gql: any) => gql`
    mutation($id: ID!) {
        deleteGadrule(id: $id) {
            id
        }
    }
`;