const t = {
    icon: 'fa-bell',
    menus: {
        main: { section: 'settings3', priority: 45 },
    },
    new: {
        attributes: [
            'tenant', 'organization', 'game', 'draw', 'sale', 'locale', 'country', 'template', 'recipient', 'variables', 'sender', 'type',
            'promise', 'bodyFile', 'bodyTextFile', 'subject', 'attachments',
        ],
        content: [
            ['row', [
                ['block', [
                    '$notificationType!', '$template', '$sender', '$recipient!', '$tenant!', '$game', '$organization',
                    '$draw', '$sale', '$promise',
                ]],
                ['block', [
                    '$attachments', '$subject', '$bodyFile', '$bodyTextFile', '$variables', '$locale', '$country',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'status', 'tenant', 'organization', 'game', 'draw', 'sale', 'locale', 'country', 'template', 'recipient', 'variables', 'sender', 'type',
            'promise', 'bodyFile', 'bodyTextFile', 'subject', 'attachments',
        ],
        content: [
            ['row', [
                ['block', [
                    '$status:readonly!', '$notificationType!', '$template', '$sender', '$recipient!', '$tenant!', '$game', '$organization',
                    '$draw', '$sale', '$promise',
                ]],
                ['block', [
                    '$attachments', '$subject', '$bodyFile', '$bodyTextFile', '$variables', '$locale', '$country',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        body: true,
        properties: [
            'id', 'createdAt', 'status', 'gameCode', 'gameName', 'game', 'organization', 'organizationCode', 'organizationName', 'recipient', 'subject', 'type', 'template',
        ],
    },
    list: {
        columns: [
            { id: 'createdAt', label: 'column_createdat_label', component: 'date', flex: 0, width: 200 },
            { id: 'type', label: 'column_type_label', component: 'notification_type', flex: 0, width: 90 },
            { id: 'status', label: 'column_status_label', component: 'notification_status', flex: 0, width: 100 },
            { id: 'recipient', label: 'column_recipient_label', flex: 0, width: 200 },
            { id: 'gameCode', label: 'column_game_code_label', component: 'game', flex: 1 },
            { id: 'subject', label: 'column_subject_label' },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
        filters: {
            all: {},
            sendFailed: {},
            delivered: {},
            created: {},
        },
    },
};

export default t;