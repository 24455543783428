const t = {
    icon: 'fa-chart-column',
    menus: {
        main: {section: 'settings2', priority: 86},
    },
    new: {
        attributes: [
            'seller', 'year', 'period', 'typeParentPeriod', 'type',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$seller!', '$year!',
                ]],
                ['block', [
                    '$type!', '$period!', '$typeParentPeriod!',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'seller', 'year', 'period', 'typeParentPeriod', 'type',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$seller!', '$year!',
                ]],
                ['block', [
                    '$type!', '$period!', '$typeParentPeriod!',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'createdAt', 'updatedAt', 'type', 'period', 'gameCode', 'gameName', 'sellerCode', 'sellerName', 'paidSales',
        ],
    },
    list: {
        columns: [
            {id: 'period', label: 'column_period_label'},
            {id: 'game', label: 'column_game_label', component: 'game'},
            {id: 'sellerCode', label: 'column_seller_code_label'},
            {id: 'sellerName', label: 'column_seller_label'},
            {id: 'type', label: 'column_type_label'},
            {id: 'paidSales', type: 'number', label: 'column_paidsales_label'},
            {id: 'paidTickets', type: 'number', label: 'column_paidtickets_label'},
            {id: 'paymentAmount', type: 'number', label: 'column_paymentamount_label', format: 'priceFromCents'},
            {id: 'promisedSales', type: 'number', label: 'column_promisedsales_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
        filters: {
            all: {},
            year: {},
            month: {},
            week: {},
            day: {},
            hour: {},
        },
    },
};

export default t;