const t = {
    icon: 'fa-at',
    menus: {
        main: {section: 'settings2', priority: 95},
    },
    new: {
        attributes: [
            'tenant', 'email', 'name', 'identityArn', 'features',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$email!',
                    '$name!',
                    '$identityArn!',
                    '$tenant!',
                    '$features',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'tenant', 'email', 'name', 'identityArn', 'features',
        ],
        content: [
            ['row', [
                ['block', [
                    '$email!',
                    '$name!',
                    '$identityArn!',
                    '$tenant!',
                    '$features',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'email', 'name', 'identityArn', 'tenantCode',
        ],
    },
    list: {
        columns: [
            {id: 'email', label: 'column_email_label', flex: 0, width: 200} as any,
            {id: 'name', label: 'column_name_label', flex: 0, width: 200} as any,
            {id: 'identityArn', label: 'column_identityarn_label', flex: 0, width: 200} as any,
            {id: 'tenantCode', label: 'column_tenant_label'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete']
    },
};

export default t;