const t = {
    icon: 'fa-image',
    menus: {
        main: {section: 'designs', priority: 45},
    },
    new: {
        attributes: [
            'tenant', 'code', 'name', 'value', 'tags',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '!$code!', '$name!',
                ]],
                ['block', [
                    '$value!', '$objectTags!',
                ]]
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'code', 'name', 'value', 'tags',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant:readonly!', '!$code!', '$name!',
                ]],
                ['block', [
                    '$value!', '$objectTags!',
                ]]
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'code', 'name', 'value', 'tags',
        ],
    },
    list: {
        columns: [
            {id: 'name', label: 'column_name_label'},
            {id: 'code', label: 'column_code_label'},
            {id: 'value', label: 'column_value_label'},
            {id: 'tags', label: 'column_tags_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;