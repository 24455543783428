export const CREATE_GOREPORT = (gql: any) => gql`
    mutation($data: CreateGoreportInput!) {
        createGoreport(data: $data) {
            id
        }
    }
`;
export const GET_GOREPORT = (gql: any) => gql`
    query($id: ID!) {
        getGoreport(id: $id) {
            id
            createdAt
            updatedAt
            startDate
            type
            typeParentPeriod
            period
            year
            semester
            month
            week
            monthWeek
            day
            hour
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            game
            gameCode
            gameName
            sale
            godfather
            organization
            organizationCode
            organizationName
        }
    }
`;
export const FIND_GOREPORTS = (gql: any) => gql`
    query findGoreports($offset: String, $limit: Int) {
        findGoreports(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sale
                godfather
                paidSales
                paidTickets
                paymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_GOREPORTS = (gql: any) => gql`
    query searchGoreports($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchGoreports(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sale
                godfather
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_ALL_GOREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchAllGoreports:searchGoreports(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sale
                godfather
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_YEAR_GOREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchYearGoreports:searchGoreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "YEAR", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sale
                godfather
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_MONTH_GOREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchMonthGoreports:searchGoreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "MONTH", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sale
                godfather
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_WEEK_GOREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchWeekGoreports:searchGoreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "WEEK", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sale
                godfather
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_DAY_GOREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchDayGoreports:searchGoreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "DAY", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sale
                godfather
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_HOUR_GOREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchHourGoreports:searchGoreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "HOUR", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sale
                godfather
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const UPDATE_GOREPORT = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateGoreportInput!) {
        updateGoreport(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_GOREPORT = (gql: any) => gql`
    mutation($id: ID!) {
        deleteGoreport(id: $id) {
            id
        }
    }
`;