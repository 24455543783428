export const CREATE_SGREPORT = (gql: any) => gql`
    mutation($data: CreateSgreportInput!) {
        createSgreport(data: $data) {
            id
        }
    }
`;
export const GET_SGREPORT = (gql: any) => gql`
    query($id: ID!) {
        getSgreport(id: $id) {
            id
            createdAt
            updatedAt
            startDate
            type
            typeParentPeriod
            period
            year
            semester
            month
            week
            monthWeek
            day
            hour
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            game
            gameCode
            gameName
            sellergroup
            organization
            organizationCode
            organizationName
        }
    }
`;
export const FIND_SGREPORTS = (gql: any) => gql`
    query findSgreports($offset: String, $limit: Int) {
        findSgreports(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
                paidSales
                paidTickets
                paymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_SGREPORTS = (gql: any) => gql`
    query searchSgreports($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchSgreports(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_ALL_SGREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchAllSgreports:searchSgreports(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_YEAR_SGREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchYearSgreports:searchSgreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "YEAR", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_MONTH_SGREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchMonthSgreports:searchSgreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "MONTH", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_WEEK_SGREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchWeekSgreports:searchSgreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "WEEK", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_DAY_SGREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchDaySgreports:searchSgreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "DAY", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_HOUR_SGREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchHourSgreports:searchSgreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "HOUR", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
