export const CREATE_SVGOBJECT = (gql: any) => gql`
    mutation($data: CreateSvgobjectInput!) {
        createSvgobject(data: $data) {
            id
        }
    }
`;
export const GET_SVGOBJECT = (gql: any) => gql`
    query getSvgobject($id: ID!) {
        getSvgobject(id: $id) {
            id
            code
            name
            tenant
            tenantCode
            tenantName
            description
            tags
            createdAt
            updatedAt
            token
            svgFile {
                available
                url
            }
        }
    }
`;
export const FIND_SVGOBJECTS = (gql: any) => gql`
    query findSvgobjects($offset: String, $limit: Int) {
        findSvgobjects(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                tenant
                tenantCode
                tenantName
                description
                tags
                createdAt
                updatedAt
                token
                svgFile {
                    available
                    url
                }
            }
        }
    }
`;