const t = {
    icon: 'fa-user-tag',
    menus: {
        main: { section: 'activity', priority: 40 },
    },
    new: {
        attributes: [
            'sale', 'email', 'phone', 'godfather',
        ],
        content: [
            ['row', [
                ['block', [
                    '$sale!'
                ]],
                ['block', [
                    '$godfather', '$email', '$phone',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'sale', 'email', 'phone', 'godfather', 'status',
        ],
        content: [
            ['row', [
                ['block', [
                    '$sale!'
                ]],
                ['block', [
                    '$godfather', '$email', '$phone',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'code', 'email', 'gameName', 'gameCode', 'phone', 'status', 'organization'
        ],
    },
    list: {
        columns: [
            { id: 'code', label: 'column_code_label', flex: 0, width: 100 } as any,
            { id: 'email', label: 'column_email_label' },
            { id: 'phone', label: 'column_phone_label' },
            { id: 'game', label: 'column_game_label', component: 'game'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'go_public_page', 'delete'],
    },
};

export default t;
