export const GET_PREORDER = (gql: any) => gql`
    query($id: ID!) {
        getPreorder(id: $id) {
            id
            sale
            campaign
            campaignCode
            campaignName
            partner
            partnerCode
            partnerName
            organization
            organizationCode
            organizationName
            game
            gameCode
            gameName
            tickets
            donation
            createdAt
            updatedAt
            status
            publicPageShortUrl
        }
    }
`;
export const FIND_PREORDERS = (gql: any) => gql`
    query findPreorders($offset: String, $limit: Int) {
        findPreorders(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                sale
                createdAt
                updatedAt
                status
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                tickets
                donation
                game
                gameCode
            }
        }
    }
`;

export const SEARCH_PREORDERS = (gql: any) => gql`
    query searchPreorders($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPreorders(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                sale
                createdAt
                updatedAt
                status
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                tickets
                donation
                game
                gameCode
                gameName
            }
        }
    }
`;


export const UPDATE_PREORDER = (gql: any) => gql`
    mutation updatePreorder($id: ID!, $data: UpdatePreorderInput!) {
        updatePreorder(id: $id, data: $data) {
            id
        }
    }
`;

export const DELETE_PREORDER = (gql: any) => gql`
    mutation deletePreorder($id: ID!) {
        deletePreorder(id: $id) {
            id
        }
    }
`;