export const CREATE_TICKET = (gql: any) => gql`
    mutation($data: CreateTicketInput!) {
        createTicket(data: $data) {
            id
            code
        }
    }
`;
export const GET_TICKET = (gql: any) => gql`
    query($id: ID!) {
        getTicket(id: $id) {
            id
            code
            status
            createdAt
            updatedAt
            owner
            price
            currency
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            tenant
            tenantCode
            sale
            publicPageShortUrl
            lastName
            firstName
            email
            phone
            status
        }
    }
`
;
export const GET_TICKET_BY_CODE = (gql: any) => gql`
    query($code: String!) {
        getTicketByCode(code: $code) {
            id
            code
        }
    }
`;
export const FIND_TICKETS = (gql: any) => gql`
    query findTickets($offset: String, $limit: Int) {
        findTickets(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                status
                createdAt
                updatedAt
                owner
                price
                currency
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                tenant
                tenantCode
                sale
                publicPageShortUrl
                customCode
            }
        }
    }
`;