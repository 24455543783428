const t = {
    icon: 'fa-chart-simple',
    menus: {
        main: { section: 'settings', priority: 135 },
    },
    new: {
        attributes: [
            'campaign', 'country', 'description', 'features', 'game', 'inlinePreset', 'locale', 'logoImage', 'longDescription', 'mainImage',
            'name', 'overline', 'presentation', 'presets', 'public', 'rank', 'tags', 'thumbnailImage', 'type', 'value', 'websiteUrl',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name', '$value', '$websiteUrl', '$description', '$presentation', '$overline', '$longDescription',
                    '$features', '$inlinePreset', '$glabelsPresets', '$country', '$locale',
                ]],
                ['block', [
                    '$game!', '$campaign', '$mainImage', '$logoImage', '$thumbnailImage', '$rank',
                    '$tags', '$type', '$public',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'campaign', 'country', 'description', 'features', 'game', 'locale', 'logoImage', 'longDescription', 'mainImage',
            'name', 'overline', 'presentation', 'public', 'rank', 'tags', 'thumbnailImage', 'type', 'value', 'websiteUrl',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name', '$value', '$websiteUrl', '$description', '$presentation', '$overline', '$longDescription',
                    '$features', '$country', '$locale',
                ]],
                ['block', [
                    '$game!', '$campaign', '$mainImage', '$logoImage', '$thumbnailImage', '$rank',
                    '$tags', '$type', '$public',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'campaign', 'campaignCode', 'campaignName', 'country', 'createdAt', 'description', 'features', 'game', 'gameCode', 'gameName', 'id',
            'inlinePreset', 'locale', 'logoImage', 'longDescription', 'mainImage', 'name', 'organization', 'organizationCode', 'websiteUrl',
            'organizationName', 'overline', 'partner', 'partnerCode', 'partnerName', 'presentation', 'presets', 'privateToken', 'public',
            'publicToken', 'rank', 'status', 'tags', 'tenant', 'tenantCode', 'tenantName', 'thumbnailImage', 'type', 'updatedAt', 'value',
        ],
    },
    list: {
        columns: [
            { id: 'game', label: 'column_game_code_label', component: 'game', flex: 1 },
            { id: 'name', label: 'column_name_label' } as any,
            { id: 'value', type: 'number', label: 'column_value_label', flex: 0, width: 100 },
            { id: 'rank', type: 'number', label: 'column_rank_label', flex: 0, width: 100 },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;