const t = {
    icon: 'fa-stairs',
    menus: {
        main: {section: 'customers', priority: 51},
    },
    display: {
        properties: [
            'code', 'createdAt', 'registration', 'campaign', 'campaignCode', 'campaignName', 'lastUtmCampaign', 'lastUtmContent', 'lastUtmMedium',
            'lastUtmSource', 'lastUtmTerm', 'partner', 'partnerCode', 'partnerName', 'privateToken', 'publicToken', 'tenant', 'tenantCode', 'tenantName',
            'updatedAt', 'utmCampaign', 'utmContent', 'utmMedium', 'utmSource', 'utmTerm',
        ],
    },
    list: {
        columns: [
            {id: 'createdAt', label: 'column_createdat_label', component: 'date', flex: 1} as any,
            {id: 'status', label: 'column_status_label', flex: 1} as any,
            {id: 'code', label: 'column_code_label', flex: 1} as any,
            {id: 'registrationStatus', label: 'column_registrationstatus_label', flex: 1} as any,
            {id: 'registration', label: 'column_registration_label', flex: 1, component: 'registration'} as any,
        ],
        actions: ['display'],
    },
};

export default t;