const t = {
    icon: 'fa-handshake-simple',
    menus: {
        main: { section: 'activity', priority: 36 },
    },
    edit: {
        attributes: [
            'name',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$tickets',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        body: true,
        properties: [
            'status', 'game', 'gameCode', 'gameName', 'organization', 'organizationCode', 'organizationName', 'sale', 'tickets', 'donation',
        ],
    },
    list: {
        columns: [
            {id: 'createdAt', label: 'column_createdat_label', component: 'date', flex: 0, width: 200},
            { id: 'status', label: 'column_status_label', flex: 0, width: 120, component: 'game_promise_status' },
            { id: 'owner', label: 'column_owner_label', flex: 0, width: 120 },
            { id: 'tickets', type: 'number', label: 'column_tickets_label', flex: 0, width: 120 },
            { id: 'donation', label: 'column_donation_label', component: 'donation_amount', flex: 0, width: 120 },
            { id: 'gameCode', label: 'column_game_code_label', component: 'game', flex: 1 },
        ],
        globalActions: [],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;