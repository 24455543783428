export const CREATE_WINNER = (gql: any) => gql`
    mutation($data: CreateWinnerInput!) {
        createWinner(data: $data) {
            id
        }
    }
`;
export const GET_WINNER = (gql: any) => gql`
    query($id: ID!) {
        getWinner(id: $id) {
            id
            code
            rank
            bunchRank
            token
            createdAt
            updatedAt
            tenant
            tenantCode
            tenantName
            partner
            partnerCode
            partnerName
            organization
            organizationCode
            organizationName
            game
            gameCode
            gameName
            draw
            bunch
            bunchName
            ticket
            ticketCode
            country
            locale
            firstName
            lastName
            email
            phone
            sale
        }
    }
`;
export const FIND_WINNERS = (gql: any) => gql`
    query findWinners($offset: String, $limit: Int) {
        findWinners(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                rank
                bunchRank
                token
                createdAt
                updatedAt
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                game
                gameCode
                gameName
                draw
                bunch
                bunchName
                ticket
                ticketCode
                country
                locale
                firstName
                lastName
                email
                phone
                sale
            }
        }
    }
`;