const t = {
    icon: 'fa-hand-holding-dollar',
    menus: {
        main: {section: 'settings', priority: 35},
    },
    new: {
        attributes: [
            'name', 'game', 'locale', 'country', 'level', 'mainImage', 'thumbnailImage', 'logoImage', 'description', 'websiteUrl',
            'image1Image', 'image2Image', 'image3Image', 'image4Image', 'image5Image', 'image6Image', 'image7Image', 'image8Image', 'image9Image',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$game!', '$sponsoringLevel', '$description', '$websiteUrl',
                ]],
                ['block', [
                    '$mainImage', '$thumbnailImage', '$logoImage', '$locale', '$country', '$features',
                    '$image1Image', '$image2Image', '$image3Image', '$image4Image', '$image5Image', '$image6Image', '$image7Image', '$image8Image', '$image9Image',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'game', 'locale', 'country', 'level', 'mainImage', 'thumbnailImage', 'logoImage', 'description', 'websiteUrl',
            'image1Image', 'image2Image', 'image3Image', 'image4Image', 'image5Image', 'image6Image', 'image7Image', 'image8Image', 'image9Image',
        ],
        content: [
            ['row', [
                ['block', [
                    '$code:readonly!', '!$name!', '$game!', '$sponsoringLevel', '$description', '$websiteUrl',
                ]],
                ['block', [
                    '$mainImage', '$thumbnailImage', '$logoImage', '$locale', '$country', '$features',
                    '$image1Image', '$image2Image', '$image3Image', '$image4Image', '$image5Image', '$image6Image', '$image7Image', '$image8Image', '$image9Image',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'name', 'game', 'gameName','gameCode', 'organization','organizationCode', 'organizationName', 'level',
        ],
    },
    list: {
        columns: [
            {id: 'mainImage', label: 'column_main_image_label', render: 'image', flex: 0, width: 200},
            {id: 'code', label: 'column_code_label', flex: 0, width: 120} as any,
            {id: 'game', label: 'column_game_label', component: 'game', flex: 0, width: 300},
            {id: 'name', label: 'column_name_label', flex: 1} as any,
            {id: 'partner', label: 'column_partner_label', component: 'partner', flex: 0, width: 300},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;
