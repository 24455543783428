export const CREATE_REGISTRATIONSTEP = (gql: any) => gql`
    mutation($data: CreateRegistrationstepInput!) {
        createRegistrationstep(data: $data) {
            id
        }
    }
`;
export const GET_REGISTRATIONSTEP = (gql: any) => gql`
    query($id: ID!) {
        getRegistrationstep(id: $id) {
            campaign
            campaignCode
            campaignName
            code
            createdAt
            id
            lastUtmCampaign
            lastUtmContent
            lastUtmMedium
            lastUtmSource
            lastUtmTerm
            partner
            partnerCode
            partnerName
            privateToken
            publicToken
            registration
            status
            tenant
            tenantCode
            tenantName
            updatedAt
            utmCampaign
            utmContent
            utmMedium
            utmSource
            utmTerm
            registrationStatus
        }
    }
`;
export const FIND_REGISTRATIONSTEPS = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findRegistrationsteps(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                code
                createdAt
                id
                registration
                status
                registrationStatus
            }
        }
    }
`;
export const SEARCH_REGISTRATIONSTEPS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchRegistrationsteps(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                code
                createdAt
                id
                registration
                status
                registrationStatus
            }
        }
    }
`;
export const UPDATE_REGISTRATIONSTEP = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateRegistrationstepInput!) {
        updateRegistrationstep(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_REGISTRATIONSTEP = (gql: any) => gql`
    mutation($id: ID!) {
        deleteRegistrationstep(id: $id) {
            id
        }
    }
`;