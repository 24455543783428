export const CREATE_OKEYFIGURE = (gql: any) => gql`
    mutation($data: CreateOkeyfigureInput!) {
        createOkeyfigure(data: $data) {
            id
        }
    }
`;
export const GET_OKEYFIGURE = (gql: any) => gql`
    query($id: ID!) {
        getOkeyfigure(id: $id) {
            campaign
            campaignCode
            campaignName
            country
            createdAt
            description
            features
            id
            inlinePreset
            locale
            logoImage {
                url
                available
            }
            longDescription
            mainImage {
                url
                available
            }
            name
            organization
            organizationCode
            organizationName
            overline
            partner
            partnerCode
            partnerName
            presentation
            presets
            privateToken
            public
            publicToken
            rank
            status
            tags
            tenant
            tenantCode
            tenantName
            thumbnailImage {
                url
                available
            }
            type
            updatedAt
            value
            websiteUrl
        }
    }
`;
export const FIND_OKEYFIGURES = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findOkeyfigures(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                description
                id
                logoImage {
                    url
                    available
                }
                mainImage {
                    url
                    available
                }
                name
                organization
                organizationCode
                organizationName
                overline
                presentation
                privateToken
                public
                publicToken
                rank
                status
                tags
                tenant
                tenantCode
                tenantName
                type
                updatedAt
                value
                websiteUrl
            }
        }
    }
`;
export const SEARCH_OKEYFIGURES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOkeyfigures(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                description
                id
                logoImage {
                    url
                    available
                }
                mainImage {
                    url
                    available
                }
                name
                organization
                organizationCode
                organizationName
                overline
                presentation
                privateToken
                public
                publicToken
                rank
                status
                tags
                tenant
                tenantCode
                tenantName
                type
                updatedAt
                value
                websiteUrl
            }
        }
    }
`;
export const UPDATE_OKEYFIGURE = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateOkeyfigureInput!) {
        updateOkeyfigure(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_OKEYFIGURE = (gql: any) => gql`
    mutation($id: ID!) {
        deleteOkeyfigure(id: $id) {
            id
        }
    }
`;