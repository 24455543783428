export const CREATE_TREMINDER = (gql: any) => gql`
    mutation($data: CreateTreminderInput!) {
        createTreminder(data: $data) {
            id
        }
    }
`;
export const GET_TREMINDER = (gql: any) => gql`
    query($id: ID!) {
        getTreminder(id: $id) {
            id
            tenant
            tenantCode
            tenantName
            country
            locale
            status
            type
            kind
        }
    }
`;
export const FIND_TREMINDERS = (gql: any) => gql`
    query findTreminders($offset: String, $limit: Int) {
        findTreminders(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                tenant
                tenantCode
                tenantName
                status
                kind
                type
            }
        }
    }
`;