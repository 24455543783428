export const CREATE_DONEE = (gql: any) => gql`
    mutation($data: CreateDoneeInput!) {
        createDonee(data: $data) {
            id
        }
    }
`;
export const GET_DONEE = (gql: any) => gql`
    query($id: ID!) {
        getDonee(id: $id) {
            id
            code
            name
            type
            lastName
            firstName
            email
            phone
            game
            gameCode
            gameName
            tenant
            tenantCode
            tenantName
            logoImage  {
                available
                size
                url
            }
            logoAltImage  {
                available
                size
                url
            }
            bannerImage  {
                available
                size
                url
            }
            mobileBannerImage  {
                available
                size
                url
            }
            tabletBannerImage  {
                available
                size
                url
            }
            banner2Image  {
                available
                size
                url
            }
            mainImage  {
                available
                size
                url
            }
            appleTouchImage  {
                available
                size
                url
            }
            faviconImage  {
                available
                size
                url
            }
            presentation
            description
            websiteUrl
            facebookUrl
            twitterUrl
            youtubeUrl
            instagramUrl
            privateToken
            publicToken
            token
        }
    }
`;
export const FIND_DONEES = (gql: any) => gql`
    query findDonees($offset: String, $limit: Int) {
        findDonees(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                type
                lastName
                firstName
                email
                phone
                game
                gameCode
                gameName
                tenant
                tenantCode
                tenantName
                logoImage  {
                    available
                    size
                    url
                }
                logoAltImage  {
                    available
                    size
                    url
                }
                bannerImage  {
                    available
                    size
                    url
                }
                mainImage  {
                    available
                    size
                    url
                }
                websiteUrl
            }
        }
    }
`;
export const DELETE_DONEE = (gql: any) => gql`
    mutation deleteDonee($id: ID!) {
        deleteDonee(id: $id) {
            id
        }
    }
`;
export const SEARCH_DONEES = (gql: any) => gql`
    query searchDonees($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchDonees(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                type
                lastName
                firstName
                email
                game
                gameCode
                gameName
                phone
                tenant
                tenantCode
                tenantName
                logoImage  {
                    available
                    size
                    url
                }
                logoAltImage  {
                    available
                    size
                    url
                }
                bannerImage  {
                    available
                    size
                    url
                }
                mainImage  {
                    available
                    size
                    url
                }
                websiteUrl
            }
        }
    }
`;
export const UPDATE_DONEE = (gql: any) => gql`
    mutation updateDonee($id: ID!, $data: UpdateDoneeInput!) {
        updateDonee(id: $id, data: $data) {
            id
        }
    }
`;
