export const CREATE_INPUT = (gql: any) => gql`
    mutation($data: CreateInputInput!) {
        createInput(data: $data) {
            id
        }
    }
`;
export const GET_INPUT = (gql: any) => gql`
    query($id: ID!) {
        getInput(id: $id) {
            id
            code
            type
            defaultValue
            name
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            locale
            country
        }
    }
`;
export const FIND_INPUTS = (gql: any) => gql`
    query findInputs($offset: String, $limit: Int) {
        findInputs(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                type
                defaultValue
                name
                gameCode
                gameName
                organizationCode
                organizationName
            }
        }
    }
`;