export const CREATE_GODFATHER = (gql: any) => gql`
    mutation($data: CreateGodfatherInput!) {
        createGodfather(data: $data) {
            id
        }
    }
`;
export const GET_GODFATHER = (gql: any) => gql`
    query($id: ID!) {
        getGodfather(id: $id) {
            id
            code
            sale
            email
            phone
            gameName
            gameCode
        }
    }
`;
export const FIND_GODFATHERS = (gql: any) => gql`
    query findGodfathers($offset: String, $limit: Int) {
        findGodfathers(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                sale
                email
                phone
                gameName
                gameCode
                publicPageShortUrl
                status
            }
        }
    }
`;