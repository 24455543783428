const t = {
    icon: 'fa-box',
    menus: {
        main: {section: 'settings3', priority: 65},
    },
    new: {
        attributes: [
            'name', 'code', 'description', 'tenant', 'organization', 'game', 'amount', 'currency', 'processorType',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$code!', '$amount!',
                ]],
                ['block', [
                    '$description', '$currency', '$organization', '$game', '$tenant',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'code', 'description', 'tenant', 'organization', 'game', 'amount', 'currency', 'status', 'processorType',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$code!', '$amount!', '$processorType',
                ]],
                ['block', [
                    '$description', '$currency', '$organization', '$game', '$tenant',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'code', 'name', 'status', 'amount', 'currency', 'processorType',
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 0, width: 200} as any,
            {id: 'name', label: 'column_name_label'},
            {id: 'status', label: 'column_status_label'},
            {id: 'amount', type: 'number', label: 'column_amount_label', format: 'priceFromCents'},
            {id: 'currency', label: 'column_currency_label'},
            {id: 'processorType', label: 'column_processortype_label'},
            {id: 'game', label: 'column_game_label', flex: 1, width: 120, component: 'game'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;