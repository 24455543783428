const t = {
    icon: 'fa-file',
    menus: {
        main: {section: 'settings2', priority: 80},
    },
    new: {
        attributes: [
            'name', 'type', 'frequency', 'game', 'organization', 'partner', 'campaign', 'tenant', 'sellergroup', 'seller', 'user',
            'params', 'extractorParams', 'transformerParams', 'loaderParams', 'extractor', 'transformer', 'loader', 'notifiers',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name', '$exporterType!', '$frequency!', '$game', '$organization', '$partner', '$campaign', '$tenant!', '$sellergroup', '$seller', '$user',
                ]],
                ['block', [
                    '$extractor', '$transformer', '$loader', '$exportruleParams', '$extractorParams', '$transformerParams', '$loaderParams', '$notifiers',
                ]]
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'type', 'frequency', 'game', 'organization', 'partner', 'campaign', 'tenant', 'sellergroup', 'seller', 'user',
            'params', 'extractorParams', 'transformerParams', 'loaderParams', 'extractor', 'transformer', 'loader', 'notifiers',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name', '$exporterType!', '$frequency!', '$game', '$organization', '$partner', '$campaign', '$tenant!', '$sellergroup', '$seller', '$user',
                ]],
                ['block', [
                    '$extractor', '$transformer', '$loader', '$exportruleParams', '$extractorParams', '$transformerParams', '$loaderParams', '$notifiers',
                ]]
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: [
            'id', 'name', 'type', 'frequency', 'status', 'gameCode', 'gameName', 'organizationCode', 'organizationName', 'partnerCode', 'partnerName', 'campaignCode', 'campaignName', 'tenantCode', 'tenantName', 'sellergroupCode', 'sellergroupName', 'sellerCode', 'sellerName', 'userCode', 'userEmail',
        ],
    },
    list: {
        columns: [
            {id: 'type', label: 'column_type_label', width: 200},
            {id: 'frequency', label: 'column_type_label', width: 200},
            {id: 'status', label: 'column_status_label', width: 200},
            {id: 'name', label: 'column_name_label'},
            {id: 'organization', label: 'column_organization_label', width: 200, component: 'organization'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
        opensearchActions: ['display', 'edit', 'delete'],
    },
};

export default t;