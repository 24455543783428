export const CREATE_FIXTURE = (gql: any) => gql`
    mutation($data: CreateFixtureInput!) {
        createFixture(data: $data) {
            id
        }
    }
`;
export const DELETE_FIXTURE = (gql: any) => gql`
    mutation($id: ID!) {
        deleteFixture(id: $id) {
            id
        }
    }
`;
export const FIND_FIXTURES = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findFixtures(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                token
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                locale
                country
                type
                definition
                definitionFormat
                params
                subject
            }
        }
    }
`;
export const GET_FIXTURE = (gql: any) => gql`
    query($id: ID!) {
        getFixture(id: $id) {
            id
            code
            token
            tenant
            tenantCode
            tenantName
            createdAt
            updatedAt
            locale
            country
            type
            definition
            definitionFormat
            params
            subject
        }
    }
`;
export const SEARCH_FIXTURES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchFixtures(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                token
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                locale
                country
                type
                definition
                definitionFormat
                params
                subject
            }
        }
    }
`;
export const UPDATE_FIXTURE = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateFixtureInput!) {
        updateFixture(id: $id, data: $data) {
            id
        }
    }
`;
