export const CREATE_CUSTOMER = (gql: any) => gql`
    mutation($data: CreateCustomerInput!) {
        createCustomer(data: $data) {
            id
        }
    }
`;
export const GET_CUSTOMER = (gql: any) => gql`
    query($id: ID!) {
        getCustomer(id: $id) {
            id
            status
            email
            lastName
            firstName
            phone
            name
            legalName
            fullName
            websiteUrl
            vatNumber
            nin
            currency
            locale
            code
            street
            complement
            city
            zipCode
            state
            country
            stripeCustomer
            tenant
            tenantCode
            tenantName
            campaign
            campaignName
            campaignCode
            statPaymentAmount
            statPayments
        }
    }
`;
export const SEARCH_LATEST_CUSTOMERS = (gql: any) => gql`
    query searchLatestCustomers($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchLatestCustomers: searchCustomers(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                status
                email
                lastName
                firstName
                phone
                name
                legalName
                fullName
                websiteUrl
                vatNumber
                nin
                currency
                locale
                code
                street
                complement
                city
                zipCode
                state
                country
                stripeCustomer
                tenant
                tenantCode
                tenantName
                campaign
                campaignName
                campaignCode
                statPaymentAmount
                statPayments
            }
        }
    }
`;
export const SEARCH_TOP_CUSTOMERS = (gql: any) => gql`
    query searchTopCustomers($offset: String, $limit: Int, $sort: String = "statPaymentAmount:desc") {
        searchTopCustomers: searchCustomers(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "statPaymentAmount", type: "int", operator: "gt", intValue: 0}
            ]
        }) {
            cursor
            count
            items {
                id
                status
                email
                lastName
                firstName
                phone
                name
                legalName
                fullName
                websiteUrl
                vatNumber
                nin
                currency
                locale
                code
                street
                complement
                city
                zipCode
                state
                country
                stripeCustomer
                tenant
                tenantCode
                tenantName
                campaign
                campaignName
                campaignCode
                statPaymentAmount
                statPayments
            }
        }
    }
`;
export const SEARCH_ALL_CUSTOMERS = (gql: any) => gql`
    query searchAllCustomers($offset: String, $limit: Int) {
        searchAllCustomers: searchCustomers(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                status
                email
                lastName
                firstName
                phone
                name
                legalName
                fullName
                websiteUrl
                vatNumber
                nin
                currency
                locale
                code
                street
                complement
                city
                zipCode
                state
                country
                stripeCustomer
                tenant
                tenantCode
                tenantName
                campaign
                campaignName
                campaignCode
                statPaymentAmount
                statPayments
            }
        }
    }
`;
export const FIND_CUSTOMERS = (gql: any) => gql`
    query findCustomers($offset: String, $limit: Int) {
        findCustomers(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                status
                email
                lastName
                firstName
                phone
                name
                legalName
                fullName
                websiteUrl
                vatNumber
                nin
                currency
                locale
                code
                street
                complement
                city
                zipCode
                state
                country
                stripeCustomer
                tenant
                tenantCode
                tenantName
                campaign
                campaignName
                campaignCode
                statPaymentAmount
                statPayments
            }
        }
    }
`;
export const FIND_ALL_CUSTOMERS = (gql: any) => gql`
    query findAllCustomers($offset: String, $limit: Int) {
        findAllCustomers: findCustomers(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                status
                email
                lastName
                firstName
                phone
                name
                legalName
                fullName
                websiteUrl
                vatNumber
                nin
                currency
                locale
                code
                street
                complement
                city
                zipCode
                state
                country
                stripeCustomer
                tenant
                tenantCode
                tenantName
                campaign
                campaignName
                campaignCode
                statPaymentAmount
                statPayments
            }
        }
    }
`;