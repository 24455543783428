export const CREATE_GDATAITEM = (gql: any) => gql`
    mutation($data: CreateGdataitemInput!) {
        createGdataitem(data: $data) {
            id
        }
    }
`;
export const GET_GDATAITEM = (gql: any) => gql`
    query($id: ID!) {
        getGdataitem(id: $id) {
            id
            name
            category
            subcategory
            tenant
            tenantCode
            tenantName
            organization
            organizationCode
            organizationName
            partner
            partnerCode
            partnerName
            createdAt
            updatedAt
            country
            locale
            presets
        }
    }
`;
export const FIND_GDATAITEMS = (gql: any) => gql`
    query findGdataitems($offset: String, $limit: Int) {
        findGdataitems(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                category
                subcategory
                tenant
                tenantCode
                tenantName
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                createdAt
                updatedAt
                country
                locale
                presets
            }
        }
    }
`;