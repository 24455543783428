export const CREATE_AUTOMATION = (gql: any) => gql`
    mutation($data: CreateAutomationInput!) {
        createAutomation(data: $data) {
            id
        }
    }
`;
export const GET_AUTOMATION = (gql: any) => gql`
    query($id: ID!) {
        getAutomation(id: $id) {
            id
            code
            token
            tenant
            tenantCode
            tenantName
            createdAt
            updatedAt
            name
            definition
            params
            frequency
            status
            lastExecutionDate
            lastExecutionStatus
            lastExecutionDetails
            type
            plannedExecutionDate
            game
            gameCode
            gameName
        }
    }
`;
export const FIND_AUTOMATIONS = (gql: any) => gql`
    query findAutomations($offset: String, $limit: Int) {
        findAutomations(offset: $offset, limit: $limit) {
            cursor
            count
            items {
            	id
                code
                token
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                name
                definition
                params
                frequency
                status
                lastExecutionDate
                lastExecutionStatus
                lastExecutionDetails
                type
                plannedExecutionDate
                game
                gameCode
                gameName
            }
        }
    }
`;
export const SEARCH_ALL_AUTOMATIONS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAllAutomations: searchAutomations(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
            	id
                code
                token
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                name
                definition
                params
                frequency
                status
                lastExecutionDate
                lastExecutionStatus
                lastExecutionDetails
                type
                plannedExecutionDate
                game
                gameCode
                gameName
            }
        }
    }
`;

export const SEARCH_RUNNING_AUTOMATIONS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchRunningAutomations: searchAutomations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "RUNNING"}
            ]
        }) {
            cursor
            count
            items {
            	id
                code
                token
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                name
                definition
                params
                frequency
                status
                lastExecutionDate
                lastExecutionStatus
                lastExecutionDetails
                type
                plannedExecutionDate
                game
                gameCode
                gameName
            }
        }
    }
`;
export const SEARCH_PAUSED_AUTOMATIONS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPausedAutomations: searchAutomations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "PAUSED"}
            ]
        }) {
            cursor
            count
            items {
            	id
                code
                token
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                name
                definition
                params
                frequency
                status
                lastExecutionDate
                lastExecutionStatus
                lastExecutionDetails
                type
                plannedExecutionDate
                game
                gameCode
                gameName
            }
        }
    }
`;
export const SEARCH_CREATED_AUTOMATIONS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchCreatedAutomations: searchAutomations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "CREATED"}
            ]
        }) {
            cursor
            count
            items {
            	id
                code
                token
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                name
                definition
                params
                frequency
                status
                lastExecutionDate
                lastExecutionStatus
                lastExecutionDetails
                type
                plannedExecutionDate
                game
                gameCode
                gameName
            }
        }
    }
`;
export const SEARCH_ARCHIVED_AUTOMATIONS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchArchivedAutomations: searchAutomations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "ARCHIVED"}
            ]
        }) {
            cursor
            count
            items {
            	id
                code
                token
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                name
                definition
                params
                frequency
                status
                lastExecutionDate
                lastExecutionStatus
                lastExecutionDetails
                type
                plannedExecutionDate
                game
                gameCode
                gameName
            }
        }
    }
`;