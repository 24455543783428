const t = {
    plural: 'bunches',
    icon: 'fa-award',
    menus: {
        main: { section: 'settings', priority: 20 },
    },
    new: {
        attributes: [
            'name', 'game', 'locale', 'country', 'rank', 'quantity', 'mainImage', 'thumbnailImage',
            'priceAmount', 'priceCurrency', 'featured', 'overline', 'description', 'longDescription',
            'image1Image', 'image2Image', 'image3Image', 'image4Image', 'image5Image', 'image6Image', 'image7Image', 'image8Image', 'image9Image',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$quantity!', '$rank', '$game!', '$description', '$longDescription',
                    '$overline', '$priceAmount', '$priceCurrency',
                ]],
                ['block', [
                    '$mainImage', '$thumbnailImage', '$locale', '$country', '$features', '$bunchTags', '$featured',
                    '$image1Image', '$image2Image', '$image3Image', '$image4Image', '$image5Image', '$image6Image', '$image7Image', '$image8Image', '$image9Image',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'game', 'locale', 'country', 'rank', 'quantity', 'mainImage', 'thumbnailImage',
            'priceAmount', 'priceCurrency', 'featured', 'overline', 'description', 'sponsoring', 'longDescription',
            'image1Image', 'image2Image', 'image3Image', 'image4Image', 'image5Image', 'image6Image', 'image7Image', 'image8Image', 'image9Image',
        ],
        content: [
            ['row', [
                ['block', [
                    '$code:readonly!', '!$name!', '$quantity!', '$rank', '$game!', '$sponsoring', '$description', '$longDescription',
                    '$overline', '$priceAmount', '$priceCurrency',
                ]],
                ['block', [
                    '$mainImage', '$thumbnailImage', '$locale', '$country', '$features', '$bunchTags', '$featured',
                    '$image1Image', '$image2Image', '$image3Image', '$image4Image', '$image5Image', '$image6Image', '$image7Image', '$image8Image', '$image9Image',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'name', 'game', 'gameName', 'organization', 'organizationName', 'rank', 'quantity',
            'priceAmount', 'longDescription'
        ],
    },
    list: {
        columns: [
            { id: 'mainImage', label: 'column_main_image_label', render: 'image', flex: 0, width: 200 },
            { id: 'code', label: 'column_code_label', flex: 0, width: 100 },
            { id: 'game', label: 'column_game_code_label', component: 'game', flex: 1 },
            { id: 'name', label: 'column_name_label' } as any,
            { id: 'rank', type: 'number', label: 'column_rank_label', flex: 0, width: 100 },
            { id: 'quantity', type: 'number', label: 'column_quantity_label', flex: 0, width: 120 } as any,
            { id: 'priceAmount', label: 'column_price_label', component: 'bunch_price',flex: 0, width: 100  },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;