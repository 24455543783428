export const CREATE_ODATAITEM = (gql: any) => gql`
    mutation($data: CreateOdataitemInput!) {
        createOdataitem(data: $data) {
            id
        }
    }
`;
export const GET_ODATAITEM = (gql: any) => gql`
    query($id: ID!) {
        getOdataitem(id: $id) {
            id
            name
            domain
            subdomain
            sport
            tenant
            tenantCode
            tenantName
            createdAt
            updatedAt
            country
            locale
            presets
        }
    }
`;
export const FIND_ODATAITEMS = (gql: any) => gql`
    query findOdataitems($offset: String, $limit: Int) {
        findOdataitems(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                domain
                subdomain
                sport
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                country
                locale
                presets
            }
        }
    }
`;