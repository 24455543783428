export const CREATE_LEAD = (gql: any) => gql`
    mutation($data: CreateLeadInput!) {
        createLead(data: $data) {
            id
        }
    }
`;
export const GET_LEAD = (gql: any) => gql`
    query($id: ID!) {
        getLead(id: $id) {
            id
            name
            fullName
            shortName
            legalName
            nin
            createdAt
            updatedAt
            status
            campaign
            campaignCode
            campaignName
            partner
            partnerCode
            partnerName
            code
            locale
            country
            presentation
            description
            externalReference
            city
            street
            zipCode
            publicEmail
            publicPhone
            overline
            complement
            state
            tenant
            faviconImage {
                available
                url
            }
            logoImage {
                available
                url
            }
            logoAltImage {
                available
                url
            }
            websiteUrl
            twitterUrl
            facebookUrl
            instagramUrl
            linkedinUrl
            youtubeUrl
            pinterestUrl
            tumblrUrl
            flickrUrl
            redditUrl
            tiktokUrl
            vimeoUrl
            snapchatUrl
            domain
        }
    }
`;
export const FIND_LEADS = (gql: any) => gql`
    query findLeads($offset: String, $limit: Int) {
        findLeads(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                partner
                tenant
                tenantCode
                name
                createdAt
                updatedAt
                status
                domain
            }
        }
    }
`;