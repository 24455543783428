export const CREATE_DBACKGROUND = (gql: any) => gql`
    mutation($data: CreateDbackgroundInput!) {
        createDbackground(data: $data) {
            id
        }
    }
`;
export const GET_DBACKGROUND = (gql: any) => gql`
    query($id: ID!) {
        getDbackground(id: $id) {
            id
            tenant
            code
            name
            description
            type
            svgFile {
                available
                url
            }
            mainImage {
                available
                url
            }
            tags
        }
    }
`;
export const FIND_DBACKGROUNDS = (gql: any) => gql`
    query findDbackgrounds($offset: String, $limit: Int) {
        findDbackgrounds(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                description
                type
                svgFile {
                    available
                    url
                }
                mainImage {
                    available
                    url
                }
                tags
            }
        }
    }
`;