export const CREATE_SEREPORT = (gql: any) => gql`
    mutation($data: CreateSereportInput!) {
        createSereport(data: $data) {
            id
        }
    }
`;
export const GET_SEREPORT = (gql: any) => gql`
    query($id: ID!) {
        getSereport(id: $id) {
            id
            createdAt
            updatedAt
            startDate
            type
            typeParentPeriod
            period
            year
            semester
            month
            week
            monthWeek
            day
            hour
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            game
            gameCode
            gameName
            sellergroup
            seller
            organization
            organizationCode
            organizationName
        }
    }
`;
export const FIND_SEREPORTS = (gql: any) => gql`
    query findSereports($offset: String, $limit: Int) {
        findSereports(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
                seller
                paidSales
                paidTickets
                paymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_SEREPORTS = (gql: any) => gql`
    query searchSereports($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchSereports(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
                seller
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_ALL_SEREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchAllSereports:searchSereports(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
                seller
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_YEAR_SEREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchYearSereports:searchSereports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "YEAR", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
                seller
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_MONTH_SEREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchMonthSereports:searchSereports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "MONTH", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
                seller
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_WEEK_SEREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchWeekSereports:searchSereports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "WEEK", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
                seller
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_DAY_SEREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchDaySereports:searchSereports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "DAY", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
                seller
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
export const SEARCH_HOUR_SEREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchHourSereports:searchSereports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "HOUR", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                game
                gameCode
                gameName
                sellergroup
                seller
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
            }
        }
    }
`;
