export const CREATE_GAUDIO = (gql: any) => gql`
    mutation($data: CreateGaudioInput!) {
        createGaudio(data: $data) {
            id
        }
    }
`;
export const GET_GAUDIO = (gql: any) => gql`
    query($id: ID!) {
        getGaudio(id: $id) {
            id
            status
            tags
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            mainAudioUrl
            desktopAudioUrl
            tabletAudioUrl
            mobileAudioUrl
            tvAudioUrl
            emailAudioUrl
        }
    }
`;
export const FIND_GAUDIOS = (gql: any) => gql`
    query findGaudios($offset: String, $limit: Int) {
        findGaudios(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                status
                tags
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                mainAudioUrl
                desktopAudioUrl
                tabletAudioUrl
                mobileAudioUrl
                tvAudioUrl
                emailAudioUrl
            }
        }
    }
`;