const t = {
    icon: 'fa-users',
    menus: {
        main: {section: 'customers', priority: 40},
    },
    new: {
        attributes: [
            'email', 'lastName', 'firstName', 'tenant', 'stripeCustomer', 'phone', 'legalName', 'fullName', 'campaign', 'websiteUrl', 'vatNumber', 'nin',
            'name', 'currency', 'locale', 'code', 'street', 'complement', 'city', 'zipCode', 'state', 'country',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$email', '$lastName', '$firstName', '$phone', '$name', '$fullName', '$legalName', '$street', '$complement', '$city', '$zipCode', '$state', '$country',
                ]],
                ['block', [
                    '$tenant!', '$campaign', '$stripeCustomer', '$websiteUrl', '$vatNumber', '$nin', '$currency', '$locale',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'email', 'lastName', 'firstName', 'tenant', 'stripeCustomer', 'phone', 'legalName', 'fullName', 'campaign', 'websiteUrl', 'vatNumber', 'nin',
            'name', 'currency', 'locale', 'code', 'street', 'complement', 'city', 'zipCode', 'state', 'country',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$email', '$lastName', '$firstName', '$phone', '$name', '$fullName', '$legalName', '$street', '$complement', '$city', '$zipCode', '$state', '$country',
                ]],
                ['block', [
                    '$code:readonly!', '$tenant!', '$campaign', '$stripeCustomer', '$websiteUrl', '$vatNumber', '$nin', '$currency', '$locale',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'email', 'lastName', 'firstName', 'phone', 'name', 'legalName', 'fullName', 'websiteUrl', 'vatNumber', 'nin', 'currency', 'locale', 'code',
            'street', 'complement', 'city', 'zipCode', 'state', 'country', 'stripeCustomer', 'tenantName', 'tenantCode', 'campaignName', 'campaignCode',
            'statPaymentAmount', 'statPayments'
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 0, width: 200} as any,
            {id: 'email', label: 'column_email_label'},
            {id: 'statPaymentAmount', label: 'column_statpaymentamount_label'},
            {id: 'statPayments', label: 'column_statpayments_label'},
            {id: 'firstName', label: 'column_firstname_label'},
            {id: 'lastName', label: 'column_lastname_label'},
            {id: 'stripeCustomer', label: 'column_stripecustomer_label'},
            {id: 'status', label: 'column_status_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
        filters: {
            latest: {},
            top: {},
            all: {},
        }
    },
};

export default t;