const t = {
    icon: 'fa-table-columns',
    menus: {
        main: {section: 'settings2', priority: 80},
    },
    new: {
        attributes: [
            'tenant', 'partner', 'organization', 'game', 'campaign', 'seller', 'sellergroup', 'grouping',
            'name', 'rank', 'level', 'definition', 'inlinePreset', 'presets', 'shareMode',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$dashboardsLevel!', '$shareMode', '$rank', '$dashboardDefinition', '$dashboardsPresets', '$inlinePreset',
                ]],
                ['block', [
                    '$tenant!', '$game', '$organization', '$partner', '$campaign', '$seller', '$sellergroup', '$grouping',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'tenant', 'partner', 'organization', 'game', 'campaign', 'seller', 'sellergroup', 'grouping',
            'name', 'rank', 'level', 'definition', 'shareMode',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$dashboardsLevel!', '$shareMode', '$rank', '$dashboardDefinition',
                ]],
                ['block', [
                    '$tenant!', '$game', '$organization', '$partner', '$campaign', '$seller', '$sellergroup', '$grouping',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'name', 'rank', 'level', 'definition', 'shareMode', 'privatePageUrl',
            'tenantCode', 'partnerCode', 'organizationCode', 'gameCode', 'campaignCode', 'sellerCode', 'sellergroupCode', 'groupingCode',
        ],
    },
    list: {
        columns: [
            {id: 'name', label: 'column_name_label'},
            {id: 'level', label: 'column_level_label'},
            {id: 'shareMode', label: 'column_sharemode_label'},
            {id: 'rank', label: 'column_rank_label'},
            {id: 'game', label: 'column_game_label', component: 'game'},
            {id: 'organization', label: 'column_organization_label', component: 'organization'},
            {id: 'partner', label: 'column_partner_label', component: 'partner'},
            {id: 'grouping', label: 'column_grouping_label', component: 'grouping'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete', 'go_private_page'],
    },
};

export default t;