export const CREATE_TREPORT = (gql: any) => gql`
    mutation($data: CreateTreportInput!) {
        createTreport(data: $data) {
            id
        }
    }
`;
export const GET_TREPORT = (gql: any) => gql`
    query($id: ID!) {
        getTreport(id: $id) {
            id
            createdAt
            updatedAt
            startDate
            type
            typeParentPeriod
            period
            year
            semester
            month
            week
            monthWeek
            day
            hour
            tenant
            tenantCode
            tenantName
        }
    }
`;
export const FIND_TREPORTS = (gql: any) => gql`
    query findTreports($offset: String, $limit: Int) {
        findTreports(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                tenant
                tenantCode
                tenantName
                paidSales
                paidTickets
                paymentAmount
                promisedSales
                paymentAmountForPaidBookpayments
                gamesHavingSales
                organizationsHavingActivatedIntegrations
            }
        }
    }
`;
export const SEARCH_TREPORTS = (gql: any) => gql`
    query searchTreports($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchTreports(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                tenant
                tenantCode
                tenantName
                paidSales
                paidTickets
                paymentAmount
                promisedSales
                paymentAmountForPaidBookpayments
                gamesHavingSales
                organizationsHavingActivatedIntegrations
            }
        }
    }
`;
export const SEARCH_ALL_TREPORTS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchAllTreports: searchTreports(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                tenant
                tenantCode
                tenantName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
                paymentAmountForPaidBookpayments
                gamesHavingSales
                organizationsHavingActivatedIntegrations
            }
        }
    }
`;
export const SEARCH_YEAR_TREPORTS = (gql: any) => gql`
    query ($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchYearTreports: searchTreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "YEAR", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                tenant
                tenantCode
                tenantName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
                paymentAmountForPaidBookpayments
                paymentAmountForPaidSales
                gamesHavingSales
                organizationsHavingActivatedIntegrations
            }
        }
    }
`;
export const SEARCH_MONTH_TREPORTS = (gql: any) => gql`
    query ($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchMonthTreports: searchTreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "MONTH", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                tenant
                tenantCode
                tenantName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
                paymentAmountForPaidBookpayments
                gamesHavingSales
                organizationsHavingActivatedIntegrations
            }
        }
    }
`;
export const SEARCH_WEEK_TREPORTS = (gql: any) => gql`
    query ($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchWeekTreports: searchTreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "WEEK", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                tenant
                tenantCode
                tenantName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
                paymentAmountForPaidBookpayments
                gamesHavingSales
                organizationsHavingActivatedIntegrations
            }
        }
    }
`;
export const SEARCH_DAY_TREPORTS = (gql: any) => gql`
    query ($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchDayTreports: searchTreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "DAY", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                tenant
                tenantCode
                tenantName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
                paymentAmountForPaidBookpayments
                gamesHavingSales
                organizationsHavingActivatedIntegrations
            }
        }
    }
`;
export const SEARCH_HOUR_TREPORTS = (gql: any) => gql`
    query ($offset: String, $limit: Int, $sort: String = "startDate:desc") {
        searchHourTreports: searchTreports(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "type", value: "HOUR", operator: "wildcard", type: "string"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                startDate
                type
                typeParentPeriod
                period
                year
                semester
                month
                week
                monthWeek
                day
                hour
                tenant
                tenantCode
                tenantName
                paidSales
                paidTickets
                paymentAmount
                paidDonationsPaymentAmount
                paidTicketsPaymentAmount
                promisedSales
                paymentAmountForPaidBookpayments
                gamesHavingSales
                organizationsHavingActivatedIntegrations
            }
        }
    }
`;
