export const CREATE_BUNCH = (gql: any) => gql`
    mutation($data: CreateBunchInput!) {
        createBunch(data: $data) {
            id
        }
    }
`;
export const GET_BUNCH = (gql: any) => gql`
    query($id: ID!) {
        getBunch(id: $id) {
            id
            code
            rank
            quantity
            name
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            locale
            country
            mainImage {
                url
                available
            }
            image1Image {
                url
                available
            }
            image2Image {
                url
                available
            }
            image3Image {
                url
                available
            }
            image4Image {
                url
                available
            }
            image5Image {
                url
                available
            }
            image6Image {
                url
                available
            }
            image7Image {
                url
                available
            }
            image8Image {
                url
                available
            }
            image9Image {
                url
                available
            }
            thumbnailImage {
                url
                available
            }
            priceAmount
            priceCurrency
            featured
            overline
            description
            tags
            sponsoring
            sponsoringCode
            sponsoringName
            longDescription
        }
    }
`;
export const GET_BUNCH_BY_CODE = (gql: any) => gql`
    query($code: String!) {
        getBunchByCode(code: $code) {
            id
            code
            rank
            quantity
            name
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            priceAmount
            priceCurrency
            sponsoring
            sponsoringCode
            sponsoringName
        }
    }
`;
export const FIND_BUNCHES = (gql: any) => gql`
    query findBunches($offset: String, $limit: Int) {
        findBunches(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                rank
                quantity
                name
                gameCode
                gameName
                organizationCode
                organizationName
                priceAmount
                priceCurrency
                featured
                overline
                description
                tags
                token
                mainImage {
                    available
                    url
                }
                image1Image {
                    url
                    available
                }
                image2Image {
                    url
                    available
                }
                image3Image {
                    url
                    available
                }
                image4Image {
                    url
                    available
                }
                image5Image {
                    url
                    available
                }
                image6Image {
                    url
                    available
                }
                image7Image {
                    url
                    available
                }
                image8Image {
                    url
                    available
                }
                image9Image {
                    url
                    available
                }
                sponsoring
                sponsoringCode
                sponsoringName
            }
        }
    }
`;
export const SEARCH_BUNCHES = (gql: any) => gql`
    query searchBunches($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchBunches(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                rank
                quantity
                name
                gameCode
                gameName
                game
                organizationCode
                organizationName
                priceAmount
                priceCurrency
                featured
                overline
                description
                tags
                token
                mainImage {
                    available
                    url
                }
                image1Image {
                    url
                    available
                }
                image2Image {
                    url
                    available
                }
                image3Image {
                    url
                    available
                }
                image4Image {
                    url
                    available
                }
                image5Image {
                    url
                    available
                }
                image6Image {
                    url
                    available
                }
                image7Image {
                    url
                    available
                }
                image8Image {
                    url
                    available
                }
                image9Image {
                    url
                    available
                }
                sponsoring
                sponsoringCode
                sponsoringName
            }
        }
    }
`;
export const SEARCH_GAME_BUNCHES = (gql: any) => gql`
    query searchGameBunches($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchBunches(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                code
                rank
                quantity
                name
                priceAmount
                priceCurrency
                featured
                overline
                description
                tags
                token
                mainImage {
                    available
                    url
                }
                image1Image {
                    url
                    available
                }
                image2Image {
                    url
                    available
                }
                image3Image {
                    url
                    available
                }
                image4Image {
                    url
                    available
                }
                image5Image {
                    url
                    available
                }
                image6Image {
                    url
                    available
                }
                image7Image {
                    url
                    available
                }
                image8Image {
                    url
                    available
                }
                image9Image {
                    url
                    available
                }
                sponsoring
                sponsoringCode
                sponsoringName
            }
        }
    }
`;
export const FIND_GAME_BUNCHES = (gql: any) => gql`
    query findGameBunches($id: String!, $offset: String, $limit: Int) {
        findGameBunches(id: $id, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                rank
                quantity
                name
                priceAmount
                priceCurrency
                featured
                overline
                description
                tags
                token
                mainImage {
                    available
                    url
                }
                image1Image {
                    url
                    available
                }
                image2Image {
                    url
                    available
                }
                image3Image {
                    url
                    available
                }
                image4Image {
                    url
                    available
                }
                image5Image {
                    url
                    available
                }
                image6Image {
                    url
                    available
                }
                image7Image {
                    url
                    available
                }
                image8Image {
                    url
                    available
                }
                image9Image {
                    url
                    available
                }
                sponsoring
                sponsoringCode
                sponsoringName
            }
        }
    }
`;
