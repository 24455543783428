const t = {
    icon: 'fa-file-invoice',
    menus: {
        main: {section: 'settings3', priority: 67},
    },
    new: {
        attributes: [
            'campaign', 'email', 'firstName', 'fullName', 'game', 'inlinePreset', 'lastName', 'legalName', 'nin', 'organization',
            'partner', 'phone', 'presets', 'publicEmail', 'publicPhone', 'seller', 'sellergroup', 'shortName', 'tenant', 'user',
            'websiteUrl',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$campaign', '$game', '$organization', '$partner', '$seller', '$sellergroup', '$tenant', '$user',
                    '$invoicePresets', '$inlinePreset',

                ]],
                ['block', [
                    '$email', '$phone', '$publicEmail', '$publicPhone', '$lastName', '$firstName', '$fullName', '$shortName', '$legalName',
                    '$nin', '$websiteUrl',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'campaign', 'email', 'firstName', 'fullName', 'game', 'lastName', 'legalName', 'nin', 'organization', 'partner',
            'phone', 'publicEmail', 'publicPhone', 'seller', 'sellergroup', 'shortName', 'tenant', 'user', 'websiteUrl',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$campaign', '$game', '$organization', '$partner', '$seller', '$sellergroup', '$tenant', '$user',

                ]],
                ['block', [
                    '$email', '$phone', '$publicEmail', '$publicPhone', '$lastName', '$firstName', '$fullName', '$shortName', '$legalName',
                    '$nin', '$websiteUrl',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'code', 'createdAt', 'status', 'websiteUrl', 'email', 'phone', 'lastName', 'firstName', 'campaign', 'campaignCode',
            'campaignName', 'game', 'gameCode', 'gameName', 'organization', 'organizationCode', 'organizationName', 'partner',
            'partnerCode', 'partnerName', 'seller', 'sellerCode', 'sellergroup', 'sellergroupCode', 'sellergroupName', 'tenant',
            'tenantCode', 'tenantName', 'user', 'userCode', 'userEmail', 'userFirstName', 'userLastName', 'userPhone',
        ],
    },
    list: {
        columns: [
            {id: 'createdAt', label: 'column_createdat_label', component: 'date', flex: 0, width: 160},
            {id: 'code', label: 'column_code_label', flex: 0, width: 240},
            {id: 'email', label: 'column_email_label', flex: 0, width: 240},
            {id: 'phone', label: 'column_phone_label', flex: 0, width: 240},
            { id: 'gameCode', label: 'column_game_code_label', component: 'game', flex: 0, width: 300 },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;