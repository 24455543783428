export const CREATE_JOB = (gql: any) => gql`
    mutation($data: CreateJobInput!) {
        createJob(data: $data) {
            id
        }
    }
`;
export const GET_JOB = (gql: any) => gql`
    query($id: ID!) {
        getJob(id: $id) {
            id
            token
            statusToken
            progressToken
            type
            status
            data
            report
            startCallbackUrl
            completeCallbackUrl
            errorCallbackUrl
            abortCallbackUrl
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            progress
            progressDetails
            itemsCount
            toCreateItemsCount
            toUpdateItemsCount
            toDeleteItemsCount
            toNochangeItemsCount
            toIgnoreItemsCount
            processedItemsCount
            processedToCreateItemsCount
            processedToUpdateItemsCount
            processedToDeleteItemsCount
            processedToNochangeItemsCount
            processedToIgnoreItemsCount
            toCreateItemsRatio
            toUpdateItemsRatio
            toDeleteItemsRatio
            toNochangeItemsRatio
            toIgnoreItemsRatio
            processedItemsProgress
            processedToCreateItemsProgress
            processedToUpdateItemsProgress
            processedToDeleteItemsProgress
            processedToNochangeItemsProgress
            processedToIgnoreItemsProgress
        }
    }
`;
export const FIND_JOBS = (gql: any) => gql`
    query findJobs($offset: String, $limit: Int) {
        findJobs(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                token
                statusToken
                progressToken
                type
                status
                data
                report
                startCallbackUrl
                completeCallbackUrl
                errorCallbackUrl
                abortCallbackUrl
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                progress
                itemsCount
                processedItemsCount
                processedItemsProgress
            }
        }
    }
`;