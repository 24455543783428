const t = {
    icon: 'fa-video',
    menus: {
        main: {section: 'medias', priority: 65},
    },
    new: {
        attributes: [
            'partner', 'tags', 'mainVideoUrl', 'desktopVideoUrl', 'tabletVideoUrl', 'mobileVideoUrl', 'tvVideoUrl', 'emailVideoUrl',
            'title', 'description',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$title!', '$description', '$partner!', '$videoTags',
                ]],
                ['block', [
                    '$mainVideoUrl',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'partner', 'tags', 'mainVideoUrl', 'desktopVideoUrl', 'tabletVideoUrl', 'mobileVideoUrl', 'tvVideoUrl', 'emailVideoUrl',
            'title', 'description',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$title!', '$description', '$partner!', '$videoTags',
                ]],
                ['block', [
                    '$mainVideoUrl',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'partnerName', 'status', 'title', 'description', 'tags',
        ],
    },
    list: {
        columns: [
            {id: 'status', label: 'column_status_label'},
            {id: 'partner', label: 'column_partner_label', flex: 1, width: 120, component: 'partner'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;