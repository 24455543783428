import {red} from '@material-ui/core/colors';
import buildMenus from "@genstackio/react-admin-core/lib/utils/buildMenus";
import crudRoutes from "@genstackio/react-admin-core/lib/utils/crudRoutes";
import buildBreadcrumbs from "@genstackio/react-admin-core/lib/utils/buildBreadcrumbs";
import buildUploader from "@genstackio/react-admin-core/lib/utils/buildUploader";
import buildLists from "@genstackio/react-admin-core/lib/utils/buildLists";
import logoPng from '@gotombola/logos/images/logo.png';
import logoFullPng from '@gotombola/logos/images/logo-full.png';
import * as types from './types';
import defaultCleanData from "@genstackio/react-admin-core/lib/utils/cleanData";
import cleanContentKeyValue from "@genstackio/react-admin-core/lib/utils/cleanContentKeyValue";
import * as cellComponents from './cells';
import * as commonCellComponents from '@gotombola/react-as/lib/cells';
import appTranslations from './translations';
import commonTranslations from '@gotombola/react-as/lib/configs/translations';

export const translations = [commonTranslations, appTranslations];

export const app = {
    prefix: 'gotombola_admin',
    endpoint: process.env.REACT_APP_API_ENDPOINT,
    defaultLocale: 'autodetect',
    fallbackLng: 'fr-FR',
    locales: ['fr-FR', 'de-DE', 'en-US', 'es-ES', 'it-IT'],
    requiredRoles: ['admin'],
};
export const apiOptions = {
    timeout: 15000,
};
export const logos = {
    logo: {url: logoPng, alt: 'GoTombola'},
    logoFull: {url: logoFullPng, alt: 'GoTombola'},
};
export const uploader = buildUploader(process.env.REACT_APP_UPLOADS_ENDPOINT);
export const theme = {
    tailwind: {},
    mui: {
        palette: {
            primary: {
                main: '#FBBD2A',
            },
            secondary: {
                main: '#2663E9',
            },
            error: {
                main: red.A400,
            },
            background: {
                default: '#FFFFFF',
            },
        },
    },
    theme: 'theme-color1',
};
export const ambiance = {
    sectionHeaderCorner: 'rounded-xxsmall',
    menuItemCorner: 'rounded-xxsmall',
    menuFoldCorner: 'rounded-xxsmall',
    buttonCorner: 'rounded-xxsmall',
};
export const routes = [
    {path: "/user/forgot-password", screen: 'forgot_password', secured: false},
    {path: '/', exact: false, screen: 'app', routes: [
            {path: '/', screen: 'home', exact: true},
            {path: '/query/execute', screen: 'query', exact: false},
            {path: '/', exact: false, screen: 'crud_app', routes: [
                    ...crudRoutes(types),
                    {path: "/user/profile/edit", screen: 'user/edit_current_user'},
                    {path: "/user/profile", screen: 'user/user_profile'},
                    {screen: 'not_found', exact: false, secured: false},
                ]},
            {screen: 'not_found', exact: false, secured: false},
        ]},
    {screen: 'not_found', exact: false, secured: false},
];
export const lists = buildLists(types);
export const breadcrumbs = buildBreadcrumbs(types, ({t}: any) => ({
    'user-profile': [
        {label: t('screen_home_label'), target: '/'},
        {label: t('screen_user_profile_label'), target: '/user/profile'},
    ],
    default: [
        {label: t('screen_home_label'), target: '/'},
    ],
}));
export const menus = buildMenus(types, {
    main: {
        activity: {},
        customers: {},
        ads: {},
        settings: {},
        settings2: {},
        settings3: {},
        settings4: {},
        medias: {},
        designs: {},
    },
    user: {
        '-': {
            items: ({t}: {t: Function}) => [
                {label: t('user_menu_profile_label'), target: '/user/profile'}
            ],
        }
    }
});
export const table = {cellComponents: {...commonCellComponents, ...cellComponents.default}};

export * as queries from './queries';
export {default as fields} from './fields';
export {default as icons} from './icons';
export {default as importers} from './importers';
export const cleanData = (data: any, keptKeys: any, createMode: boolean) => {
    const v = defaultCleanData(data, keptKeys, createMode);
    (!v || !v['definition'] || ('string' !== typeof v['definition'])) && cleanContentKeyValue(v, 'definition');
    cleanContentKeyValue(v, 'publicPageModulesCustomRaw');

    return v;
}
