export const CREATE_PACK = (gql: any) => gql`
    mutation($data: CreatePackInput!) {
        createPack(data: $data) {
            id
        }
    }
`;
export const GET_PACK = (gql: any) => gql`
    query($id: ID!) {
        getPack(id: $id) {
            id
            code
            tickets
            ticketBooks
            donation
            currency
            name
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            locale
            country
            overline
            price
        }
    }
`;
export const FIND_PACKS = (gql: any) => gql`
    query findPacks($offset: String, $limit: Int) {
        findPacks(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                tickets
                ticketBooks
                donation
                currency
                name
                gameCode
                gameName
                organizationCode
                organizationName
                overline
            }
        }
    }
`;