export const CREATE_ORGANIZATION = (gql: any) => gql`
    mutation($data: CreateOrganizationInput!) {
        createOrganization(data: $data) {
            id
        }
    }
`;
export const GET_ORGANIZATION = (gql: any) => gql`
    query($id: ID!) {
        getOrganization(id: $id) {
            id
            name
            fullName
            shortName
            legalName
            owner
            nin
            createdAt
            updatedAt
            status
            games {
                count
                cursor
                items {
                    id
                    status
                    code
                    name
                    plannedTickets
                    statPaidTickets
                    statPaidSales
                    statPaidAmount
                    ticketPriceAmount
                    ticketPriceCurrency
                }
            }
            tickets {
                count
                cursor
                items {
                    id
                }
            }
            integrations {
                items {
                    id
                    name
                    code
                    game
                    gameCode
                    status
                }
            }
            campaign
            campaignCode
            campaignName
            partner
            partnerCode
            partnerName
            code
            locale
            country
            presentation
            description
            externalReference
            publicPageShortUrl
            city
            street
            zipCode
            publicEmail
            publicPhone
            overline
            complement
            state
            owner
            ownerEmail
            ownerFirstName
            ownerLastName
            tenant
            faviconImage {
                available
                url
            }
            appleTouchImage {
                available
                url
            }
            qrcodeLogoImage {
                available
                url
            }
            logoImage {
                available
                url
            }
            logoAltImage {
                available
                url
            }
            bannerImage {
                available
                url
            }
            mobileBannerImage {
                available
                url
            }
            tabletBannerImage {
                available
                url
            }
            mainImage {
                available
                url
            }
            banner2Image {
                available
                url
            }
            backgroundImage {
                available
                url
            }
            facebookShareImage {
                available
                url
            }
            twitterShareImage {
                available
                url
            }
            flyerImage {
                available
                url
            }
            websiteUrl
            twitterUrl
            facebookUrl
            instagramUrl
            linkedinUrl
            youtubeUrl
            pinterestUrl
            tumblrUrl
            flickrUrl
            redditUrl
            tiktokUrl
            vimeoUrl
            snapchatUrl
            backgroundVideoUrl
            bannerVideoUrl
            banner2VideoUrl
            flyerVideoUrl
            mainVideoUrl
            mainShortVideoUrl
            mainLongVideoUrl
            domain
            mainTheme
            mainDfontfamily
            presets
            varColorNavPrimary
            varColorNavSecondary
            varColorPrimary
            varColorSecondary
            varColorTextPrimary
            varColorTextSecondary
            varColorBorderPrimary
            varColorBorderSecondary
            varColorPanelPrimary
            varColorPanelSecondary
            varColorLogo
            varColorTitle
            varColorChoicePrimary
            varColorChoiceSecondary
            varColorFooterPrimary
            varColorFooterSecondary
            varColorHeading
            oversions {
                count
                cursor
                items {
                    id
                    code
                    type
                }
            }
            bridgeAccountUrl
            stripeCustomer
        }
    }
`;
export const GET_ORGANIZATION_BY_CODE = (gql: any) => gql`
    query($code: String!) {
        getOrganizationByCode(code: $code) {
            id
            code
            name
        }
    }
`;
export const FIND_ORGANIZATIONS = (gql: any) => gql`
    query findOrganizations($offset: String, $limit: Int) {
        findOrganizations(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                partner
                tenant
                tenantCode
                name
                createdAt
                updatedAt
                status
                publicPageShortUrl
                domain
                ownerEmail
                logoImage {
                    url
                    available
                }
            }
        }
    }
`;