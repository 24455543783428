const t = {
    icon: 'fa-clock',
    menus: {
        main: {section: 'settings4', priority: 25},
    },
    new: {
        attributes: [
            'game', 'type', 'kind', 'oreminder', 'preminder', 'treminder',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$game!', '$treminder', '$preminder', '$oreminder',
                ]],
                ['block', [
                    '$type!', '$kind',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'status',
        ],
        content: [
            ['row', [
                ['block', [
                    '$status:readonly!', '!$game!', '$treminder', '$preminder', '$oreminder',
                ]],
                ['block', [
                    '$type!', '$kind',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: [
            'id', 'status', 'organizationCode', 'organizationName', 'gameCode', 'tenantName', 'kind', 'job', 'jobStatus', 'ogreminder', 'pgreminder', 'tgreminder',
        ],
    },
    list: {
        columns: [
            {id: 'id', label: 'column_id_label', flex: 0, width: 100} as any,
            {id: 'type', label: 'column_type_label'},
            {id: 'kind', label: 'column_kind_label'},
            {id: 'status', label: 'column_status_label'},
            {id: 'jobStatus', label: 'column_jobstatus_label'},
            {id: 'game', label: 'column_game_label', flex: 1, width: 120, component: 'game'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;