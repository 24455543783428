const t = {
    icon: 'fa-file',
    menus: {
        main: {section: 'settings2', priority: 65},
    },
    new: {
        attributes: [
            'type', 'game', 'organization', 'partner', 'tenant', 'tags', 'publicFile', 'title', 'description',
            'locale', 'country', 'variables', 'draw', 'sale', 'promise',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$type!', '$game', '$organization', '$partner', '$tenant!', '$draw', '$sale', '$promise',
                ]],
                ['block', [
                    '$publicFile', '$tags', '$title', '$description', '$locale', '$country', '$variables',
                ]]
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'game', 'organization', 'partner', 'tenant', 'tags', 'publicFile', 'title', 'description',
            'job', 'status', 'locale', 'country', 'variables', 'generatedFailedDetails', 'draw', 'sale', 'promise',
        ],
        content: [
            ['row', [
                ['block', [
                    '$type:readonly!', '!$game', '$organization', '$partner', '$tenant!', '$draw', '$sale', '$promise',
                ]],
                ['block', [
                    '$publicFile', '$tags', '$title', '$description', '$locale', '$country', '$variables',
                ]]
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'type', 'status', 'gameCode', 'gameName', 'title', 'description', 'job', 'jobStatus', 'generetadFailedDetails',
        ],
    },
    list: {
        columns: [
            {id: 'status', label: 'column_status_label'},
            {id: 'type', label: 'column_type_label'},
            {id: 'game', label: 'column_game_label', component: 'game'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete', 'download'],
        opensearchActions: ['display', 'edit', 'delete'],
    },
};

export default t;