export const CREATE_BOOKSET = (gql: any) => gql`
    mutation($data: CreateBooksetInput!) {
        createBookset(data: $data) {
            id
        }
    }
`;
export const GET_BOOKSET = (gql: any) => gql`
    query($id: ID!) {
        getBookset(id: $id) {
            bookList
            campaign
            campaignCode
            campaignName
            country
            createdAt
            game
            gameCode
            gameName
            id
            inlinePreset
            locale
            organization
            organizationCode
            organizationName
            partner
            partnerCode
            partnerName
            presets
            privatePageUrl
            privateToken
            publicPageShortUrl
            publicPageUrl
            publicToken
            status
            tenant
            tenantCode
            tenantName
            token
            updatedAt
        }
    }
`;
export const SEARCH_BOOKSETS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchBooksets(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                bookList
                campaign
                campaignCode
                campaignName
                country
                createdAt
                game
                gameCode
                gameName
                id
                inlinePreset
                locale
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                presets
                privatePageUrl
                publicPageShortUrl
                publicPageUrl
                status
                tenant
                tenantCode
                tenantName
            }
        }
    }
`;
export const FIND_BOOKSETS = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findBooksets(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                bookList
                campaign
                campaignCode
                campaignName
                country
                createdAt
                game
                gameCode
                gameName
                id
                inlinePreset
                locale
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                presets
                privatePageUrl
                publicPageShortUrl
                publicPageUrl
                status
                tenant
                tenantCode
                tenantName
            }
        }
    }
`;
export const DELETE_BOOKSET = (gql: any) => gql`
    mutation($id: ID!) {
        deleteBookset(id: $id) {
            id
        }
    }
`;
export const UPDATE_BOOKSET = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateBooksetInput!) {
        updateBookset(id: $id, data: $data) {
            id
        }
    }
`;