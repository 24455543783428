const t = {
    icon: 'fa-file',
    menus: {
        main: {section: 'settings2', priority: 82},
    },
    new: {
        attributes: [
            'type', 'game', 'organization', 'partner', 'campaign', 'tenant', 'sellergroup', 'seller', 'user',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$type!', '$game', '$organization', '$partner', '$campaign', '$tenant!', '$sellergroup', '$seller', '$user',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'type', 'game', 'organization', 'partner', 'campaign', 'tenant', 'sellergroup', 'seller', 'user',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$type!', '$game', '$organization', '$partner', '$campaign', '$tenant!', '$sellergroup', '$seller', '$user',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: [
            'id', 'type', 'status', 'gameCode', 'gameName', 'organizationCode', 'organizationName', 'partnerCode', 'partnerName', 'campaignCode', 'campaignName', 'tenantCode', 'tenantName', 'sellergroupCode', 'sellergroupName', 'sellerCode', 'sellerName', 'userCode', 'userEmail',
        ],
    },
    list: {
        columns: [
            {id: 'status', label: 'column_status_label'},
            {id: 'type', label: 'column_type_label'},
            {id: 'game', label: 'column_game_label', component: 'game'},
            {id: 'partner', label: 'column_partner_label', component: 'partner'},
            {id: 'tenant', label: 'column_tenant_label', component: 'tenant'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
        opensearchActions: ['display', 'edit', 'delete'],
    },
};

export default t;