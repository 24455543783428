export const CREATE_TENANT = (gql: any) => gql`
    mutation($data: CreateTenantInput!) {
        createTenant(data: $data) {
            id
            code
        }
    }
`;
export const GET_TENANT = (gql: any) => gql`
    query($id: ID!) {
        getTenant(id: $id) {
            id
            code
            name
            mainTheme
            mainDfontfamily
            lastMonthStats {
                sales {
                    days {
                        labels
                        values
                    }
                }
                tickets {
                    days {
                        labels
                        values
                    }
                }
            }
            lastYearStats {
                sales {
                    days {
                        labels
                        values
                    }
                }
            }
            currentMonthStats {
                sales {
                    days {
                        labels
                        values
                    }
                }
            }
            stripeCustomer
            tlocales {
                items {
                    code
                    createdAt
                    id
                    mainImage {
                        url
                        available
                    }
                    name
                    privateToken
                    publicToken
                    status
                    rank
                }
            }
            tcountries {
                items {
                    code
                    createdAt
                    id
                    mainImage {
                        url
                        available
                    }
                    name
                    privateToken
                    publicToken
                    status
                    rank
                }
            }
        }
    }
`;
export const FIND_TENANTS = (gql: any) => gql`
    query findTenants($offset: String, $limit: Int) {
        findTenants(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                publicPageShortUrl
            }
        }
    }
`;
export const MOVE_TENANT_TLOCALE = (gql: any) => gql`
    mutation($parentId: String!, $id: ID!, $direction: String) {
        moveTenantTlocale(parentId: $parentId, id: $id, direction: $direction) {
            id
        }
    }
`;
export const MOVE_TENANT_TCOUNTRY = (gql: any) => gql`
    mutation($parentId: String!, $id: ID!, $direction: String) {
        moveTenantTcountry(parentId: $parentId, id: $id, direction: $direction) {
            id
        }
    }
`;