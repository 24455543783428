const t = {
    icon: 'fa-ticket',
    menus: {
        main: { section: 'activity', priority: 30 },
    },
    new: {
        attributes: [
            'name', 'owner', 'sale', 'price', 'currency',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$customCode', '$sale', '$owner!',
                ]],
                ['block', [
                    '$price', '$currency',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'owner', 'sale', 'price', 'currency',
        ],
        content: [
            ['row', [
                ['block', [
                    '$code:readonly!', '!$customCode', '$sale', '$owner!',
                ]],
                ['block', [
                    '$price', '$currency',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        body: true,
        properties: [
            'id', 'code', 'sale', 'owner', 'price', 'currency', 'email', 'lastName', 'firstName', 'phone', 'customCode'
        ],
    },
    list: {
        columns: [
            { id: 'code', label: 'column_code_label', flex: 0, width: 100 } as any,
            { id: 'gameCode', label: 'column_game_code_label', component: 'game', flex: 1 },
            { id: 'price', type: 'number', label: 'column_paymentamount_label', flex: 0, width: 120, component: 'ticket_price_amount' },
            { id: 'owner', label: 'column_owner_label', flex: 1 },
            { id: 'customCode', label: 'column_customcode_label', flex: 1 },
        ],
        globalActions: ['add'],
        actions: ['display', 'go_public_page'],
    },
};

export default t;