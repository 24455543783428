import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {BaseCell} from "@gotombola/react-as/lib/types";

export function AffiliaterCell({row: { affiliater, affiliaterCode, affiliaterName }}: AffiliaterCellProps): ReactElement {
    const history = useHistory();
    const affiliaterUri = `/affiliaters/${affiliater}`;

    const onAffiliaterClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(affiliaterUri);
        },
        [history, affiliaterUri],
    );

    return (
        <>
            <a href={affiliaterUri} onClick={onAffiliaterClick} className={'hover:underline'}>
                <b>{affiliaterCode}</b> - {affiliaterName}
            </a>
        </>
    );
}

export type AffiliaterCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default AffiliaterCell;