export const CREATE_PROJECT = (gql: any) => gql`
    mutation($data: CreateProjectInput!) {
        createProject(data: $data) {
            id
        }
    }
`;
export const GET_PROJECT = (gql: any) => gql`
    query($id: ID!) {
        getProject(id: $id) {
            id
            code
            name
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            locale
            country
        }
    }
`;
export const FIND_PROJECTS = (gql: any) => gql`
    query findProjects($offset: String, $limit: Int) {
        findProjects(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                gameCode
                gameName
                organizationCode
                organizationName
            }
        }
    }
`;