const t = {
    icon: 'fa-dice',
    menus: {
        main: {section: 'settings', priority: 25},
    },
    new: {
        attributes: [
            'game', 'key', 'draft', 'strategy', 'drawMode'
        ],
        content: [
            ['row', [
                ['block', [
                    '$game!', '$key!', '$drawMode',
                ]],
                ['block', [
                    '$strategy', '$draft',
                ]]
            ]]
        ],
    },
    edit: {
        attributes: [
            'status', 'drawMode'
        ],
        content: [
            ['row', [
                ['block', [
                    '!$status', '$game:readonly!', '$key:readonly!', '$drawMode'
                ]],
                ['block', [
                    '$strategy:readonly', '$draft:readonly',
                ]]
            ]]
        ],
    },
    display: {
        properties: [
            'id', 'status', 'key', 'gameCode', 'gameName', 'strategy', 'draft', 'processedTickets', 'processedPrizes', 'processedBunches', 'winnersCount', 'failureDetails',
        ],
        tabs: [
            {name: 'infos'},
            {name: 'winners'},
        ],
    },
    list: {
        columns: [
            {id: 'createdAt', label: 'column_createdat_label', flex: 0, width: 200, component: 'date'},
            {id: 'game', label: 'column_game_code_label', component: 'game'},
            {id: 'processedTickets', type: 'number', label: 'column_tickets_label', flex: 0, width: 120},
            {id: 'processedPrizes', type: 'number', label: 'column_prizes_label', flex: 0, width: 120},
            {id: 'processedBunches', type: 'number', label: 'column_bunches_label', flex: 0, width: 120},
            {id: 'winnersCount', type: 'number', label: 'column_winners_label', flex: 0, width: 120},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;