const t = {
    icon: 'fa-file-audio',
    menus: {
        main: {section: 'medias', priority: 75},
    },
    new: {
        attributes: [
            'partner', 'tags', 'mainAudioUrl', 'backgroundAudioUrl',
            'title', 'description',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$title!', '$description', '$partner', '$audioTags',
                ]],
                ['block', [
                    '$mainAudioUrl',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'partner', 'tags', 'mainAudioUrl', 'backgroundAudioUrl',
            'title', 'description',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$title!', '$description', '$partner', '$audioTags',
                ]],
                ['block', [
                    '$mainAudioUrl',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'partnerName', 'status', 'title', 'description', 'tags',
        ],
    },
};

export default t;