export const CREATE_OPT = (gql: any) => gql`
    mutation($data: CreateOptInput!) {
        createOpt(data: $data) {
            id
        }
    }
`;
export const GET_OPT = (gql: any) => gql`
    query($id: ID!) {
        getOpt(id: $id) {
            id
            status
            type
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            partner
            partnerCode
            partnerName
            campaign
            campaignCode
            campaignName
            tenant
            tenantCode
            tenantName
            sellergroup
            sellergroupCode
            sellergroupName
            seller
            sellerCode
            user
            userCode
            userEmail
            privateToken
            publicToken
        }
    }
`;
export const FIND_OPTS = (gql: any) => gql`
    query findOpts($offset: String, $limit: Int) {
        findOpts(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                status
                type
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                campaign
                campaignCode
                campaignName
                tenant
                tenantCode
                tenantName
                sellergroup
                sellergroupCode
                sellergroupName
                seller
                sellerCode
                user
                userCode
                userEmail
                privateToken
                publicToken
            }
        }
    }
`;
export const DELETE_OPT = (gql: any) => gql`
    mutation deleteOpt($id: ID!) {
        deleteOpt(id: $id) {
            id
        }
    }
`;
export const SEARCH_OPTS = (gql: any) => gql`
    query searchOpts($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOpts(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                status
                type
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                campaign
                campaignCode
                campaignName
                tenant
                tenantCode
                tenantName
                sellergroup
                sellergroupCode
                sellergroupName
                seller
                sellerCode
                user
                userCode
                userEmail
                privateToken
                publicToken
            }
        }
    }
`;
export const UPDATE_OPT = (gql: any) => gql`
    mutation updateOpt($id: ID!, $data: UpdateOptInput!) {
        updateOpt(id: $id, data: $data) {
            id
        }
    }
`;
