export const CREATE_CHUNKCOUNTER = (gql: any) => gql`
    mutation($data: CreateChunkcounterInput!) {
        createChunkcounter(data: $data) {
            id
        }
    }
`;
export const GET_CHUNKCOUNTER = (gql: any) => gql`
    query($id: ID!) {
        getChunkcounter(id: $id) {
            id
            startedAt
            endedAt
            type
            intDiff
            floatDiff
            hasIntDiff
            hasFloatDiff
            app
            view
            tenant
            tenantName
            tenantCode
            game
            gameName
            gameCode
            organization
            organizationName
            organizationCode
            seller
            sellerCode
            sellergroup
            sellergroupName
            sellergroupCode
            grouping
            groupingName
            groupingCode
            campaign
            campaignName
            campaignCode
            partner
            partnerName
            partnerCode
        }
    }
`;
export const FIND_CHUNKCOUNTERS = (gql: any) => gql`
    query findChunkcounters($offset: String, $limit: Int) {
        findChunkcounters(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                startedAt
                endedAt
                type
                intDiff
                floatDiff
                hasIntDiff
                hasFloatDiff
                app
                view
                tenant
                tenantName
                tenantCode
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                seller
                sellerCode
                sellergroup
                sellergroupName
                sellergroupCode
                grouping
                groupingName
                groupingCode
                campaign
                campaignName
                campaignCode
                partner
                partnerName
                partnerCode
            }
        }
    }
`;
export const FIND_ALL_CHUNKCOUNTERS = (gql: any) => gql`
    query findAllChunkcounters($offset: String, $limit: Int) {
        findAllChunkcounters:findChunkcounters(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                startedAt
                endedAt
                type
                intDiff
                floatDiff
                hasIntDiff
                hasFloatDiff
                app
                view
                tenant
                tenantName
                tenantCode
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                seller
                sellerCode
                sellergroup
                sellergroupName
                sellergroupCode
                grouping
                groupingName
                groupingCode
                campaign
                campaignName
                campaignCode
                partner
                partnerName
                partnerCode
            }
        }
    }
`;
export const FIND_PROCESSABLE_CHUNKCOUNTERS = (gql: any) => gql`
    query findProcessableChunkcounters($offset: String, $limit: Int) {
        findProcessableChunkcounters(offset: $offset, limit: $limit, sort: "descending") {
            cursor
            count
            items {
                id
                startedAt
                endedAt
                type
                intDiff
                floatDiff
                hasIntDiff
                hasFloatDiff
                app
                view
                tenant
                tenantName
                tenantCode
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                seller
                sellerCode
                sellergroup
                sellergroupName
                sellergroupCode
                grouping
                groupingName
                groupingCode
                campaign
                campaignName
                campaignCode
                partner
                partnerName
                partnerCode
            }
        }
    }
`;
export const FIND_PROCESSABLEENDED_CHUNKCOUNTERS = (gql: any) => gql`
    query findProcessableEndedChunkcounters($offset: String, $limit: Int) {
        findProcessableEndedChunkcounters:findEndedAtProcessableChunkcounters(offset: $offset, limit: $limit, sort: "descending") {
            cursor
            count
            items {
                id
                startedAt
                endedAt
                type
                intDiff
                floatDiff
                hasIntDiff
                hasFloatDiff
                app
                view
                tenant
                tenantName
                tenantCode
                game
                gameName
                gameCode
                organization
                organizationName
                organizationCode
                seller
                sellerCode
                sellergroup
                sellergroupName
                sellergroupCode
                grouping
                groupingName
                groupingCode
                campaign
                campaignName
                campaignCode
                partner
                partnerName
                partnerCode
            }
        }
    }
`;