export const CREATE_IDENTITY = (gql: any) => gql`
    mutation($data: CreateIdentityInput!) {
        createIdentity(data: $data) {
            id
        }
    }
`;
export const DELETE_IDENTITY = (gql: any) => gql`
    mutation($id: ID!) {
        deleteIdentity(id: $id) {
            id
        }
    }
`;
export const FIND_IDENTITIES = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findIdentities(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                createdAt
                externalId
                id
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                provider
                status
                tenant
                tenantCode
                tenantName
                user
                userCode
                userEmail
                userFirstName
                userLastName
                userPhone
            }
        }
    }
`;
export const GET_IDENTITY = (gql: any) => gql`
    query($id: ID!) {
        getIdentity(id: $id) {
            createdAt
            externalId
            id
            organization
            organizationCode
            organizationName
            partner
            partnerCode
            partnerName
            privateToken
            provider
            publicToken
            status
            tags
            tenant
            tenantCode
            tenantName
            token
            updatedAt
            user
            userCode
            userEmail
            userFirstName
            userLastName
            userPhone
        }
    }
`;
export const SEARCH_IDENTITIES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchIdentities(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                createdAt
                externalId
                id
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                provider
                status
                tenant
                tenantCode
                tenantName
                user
                userCode
                userEmail
                userFirstName
                userLastName
                userPhone
            }
        }
    }
`;
export const UPDATE_IDENTITY = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateIdentityInput!) {
        updateIdentity(id: $id, data: $data) {
            id
        }
    }
`;
