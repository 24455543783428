export const CREATE_ASSET = (gql: any) => gql`
    mutation($data: CreateAssetInput!) {
        createAsset(data: $data) {
            id
        }
    }
`;
export const GET_ASSET = (gql: any) => gql`
    query($id: ID!) {
        getAsset(id: $id) {
            createdAt
            description
            id
            inlinePreset
            name
            code
            presets
            privateToken
            publicToken
            status
            tags
            tenant
            tenantCode
            tenantName
            token
            type
            updatedAt
            mainImage {
                available
                url
            }
        }
    }
`;
export const FIND_ASSETS = (gql: any) => gql`
    query findAssets($offset: String, $limit: Int) {
        findAssets(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                createdAt 
                description 
                id 
                inlinePreset 
                mainImage {
                    available
                    url
                }
                name 
                code
                presets
                privateToken 
                publicToken 
                status 
                tags
                tenant 
                tenantCode 
                tenantName 
                token 
                type 
                updatedAt 
            }
        }
    }
`;
export const SEARCH_ASSETS = (gql: any) => gql`
    query searchAssets($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAssets(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                createdAt 
                description 
                id 
                inlinePreset 
                name
                code
                presets
                privateToken 
                publicToken 
                status 
                tags
                tenant 
                tenantCode 
                tenantName 
                token 
                type 
                updatedAt 
                mainImage {
                    available
                    url
                }   
            }
        }
    }
`;
export const DELETE_ASSET = (gql: any) => gql`
    mutation deleteAsset($id: ID!) {
        deleteAsset(id: $id) {
            id
        }
    }
`;
export const UPDATE_ASSET = (gql: any) => gql`
    mutation updateAsset($id: ID!, $data: UpdateAssetInput!) {
        updateAsset(id: $id, data: $data) {
            id
        }
    }
`;
