const t =  {
    menus: {
        main: {section: 'settings', priority: 41, icon: 'fa-copyright'},
    },
    new: {
        attributes: [
            'name', 'brand', 'tenant', 'presentation', 'description',
            'logoImage', 'logoAltImage', 'bannerImage', 'mobileBannerImage', 'tabletBannerImage', 'banner2Image',
            'mainImage', 'faviconImage', 'appleTouchImage',
            'websiteUrl', 'twitterUrl', 'facebookUrl', 'youtubeUrl', 'instagramUrl',
            'email', 'lastName', 'firstName', 'phone', 'type', 'game',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$name!', '$type!', '$brand', '$game', '$email!', '$phone',
                    '$lastName!', '$firstName!', '$presentation', '$description',
                    '$websiteUrl', '$twitterUrl', '$facebookUrl', '$youtubeUrl', '$instagramUrl',
                ]],
                ['block', [
                    '$logoImage', '$logoAltImage', '$bannerImage', '$mobileBannerImage', '$tabletBannerImage', '$banner2Image',
                    '$mainImage', '$faviconImage', '$appleTouchImage',
                ]]
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'presentation', 'description',
            'logoImage', 'logoAltImage', 'bannerImage', 'mobileBannerImage', 'tabletBannerImage', 'banner2Image',
            'mainImage', 'faviconImage', 'appleTouchImage',
            'websiteUrl', 'twitterUrl', 'facebookUrl', 'youtubeUrl', 'instagramUrl',
            'email', 'lastName', 'firstName', 'phone', 'type',
        ],
        content: [
            ['row', [
                ['block', [
                    '$name!', '$type!', '$email!', '$phone',
                    '$lastName!', '$firstName!', '$presentation', '$description',
                    '$websiteUrl', '$twitterUrl', '$facebookUrl', '$youtubeUrl', '$instagramUrl',
                ]],
                ['block', [
                    '$logoImage', '$logoAltImage', '$bannerImage', '$mobileBannerImage', '$tabletBannerImage', '$banner2Image',
                    '$mainImage', '$faviconImage', '$appleTouchImage',
                ]]
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'gameCode', 'gameName', 'email', 'lastName', 'firstName', 'name', 'type',
        ],
    },
    list: {
        columns: [
            {id: 'game', label: 'column_game_label', component: 'game'},
            {id: 'email', label: 'column_email_label'},
            {id: 'lastName', label: 'column_lastname_label'},
            {id: 'firstName', label: 'column_firstname_label'},
            {id: 'name', label: 'column_name_label'},
            {id: 'type', label: 'column_type_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;