export const GET_BOOKPAYMENT = (gql: any) => gql`
    query($id: ID!) {
        getBookpayment(id: $id) {
            id
            campaign
            campaignCode
            campaignName
            partner
            partnerCode
            partnerName
            organization
            organizationCode
            organizationName
            game
            gameCode
            gameName
            tickets
            donation
            paymentAmount
            createdAt
            updatedAt
            status
            publicPageUrl
            paymentProviderFeeAmount
            marketPlaceFeeAmount
            transactionFeeAmount
            totalFeeAmount
            customerCollectedAmount
            book
            bookCode
            bookCustomCode
        }
    }
`;
export const FIND_BOOKPAYMENTS = (gql: any) => gql`
    query findBookpayments($offset: String, $limit: Int) {
        findBookpayments(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                status
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                paymentAmount
                paymentCurrency
                tickets
                game
                gameCode
                gameName
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
                book
                bookCode
                bookCustomCode
            }
        }
    }
`;