const t =  {
    menus: {
        main: {section: 'settings3', priority: 97, icon: 'fa-palette'},
    },
    new: {
        attributes: [
            'code', 'tenant', 'partner', 'organization', 'game', 'sellergroup', 'seller', 'locale', 'text', 'type', 'domain', 'main', 'lock',
        ],
        content: [
            ['row', [
                ['block', [
                    '$code!', '$locale!', '$translationText!', '$type!', '$domain', '$main', '$lock'
                ]],
                ['block', [
                    '$tenant!', '$partner', '$organization', '$game', '$sellergroup', '$seller'
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'code', 'tenant', 'partner', 'organization', 'game', 'sellergroup', 'seller', 'locale', 'text', 'type', 'domain', 'main', 'lock',
        ],
        content: [
            ['row', [
                ['block', [
                    '$code!', '$locale!', '$translationText!', '$type!', '$domain', '$main', '$lock'
                ]],
                ['block', [
                    '$tenant!', '$partner', '$organization', '$game', '$sellergroup', '$seller'
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'code', 'tenantCode', 'partnerCode', 'organizationCode', 'gameCode', 'sellergroupCode', 'sellerCode', 'locale', 'text', 'type', 'domain', 'main', 'lock',
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 0, width: 100} as any,
            {id: 'locale', label: 'column_locale_label', flex: 0, width: 100} as any,
            {id: 'tenant', label: 'column_tenant_label', component: 'tenant', flex: 0, width: 120} as any,
            {id: 'game', label: 'column_game_label', component: 'game', flex: 0, width: 320} as any,
            {id: 'text', label: 'column_text_label', flex: 1, width: 100} as any,
            {id: 'type', label: 'column_type_label', flex: 0, width: 100} as any,
            {id: 'domain', label: 'column_domain_label', flex: 0, width: 100} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;