export const CREATE_FEATURE = (gql: any) => gql`
    mutation($data: CreateFeatureInput!) {
        createFeature(data: $data) {
            id
        }
    }
`;
export const GET_FEATURE = (gql: any) => gql`
    query($id: ID!) {
        getFeature(id: $id) {
            id
            code
            name
            tenant
            tenantCode
            tenantName
        }
    }
`;
export const FIND_FEATURES = (gql: any) => gql`
    query findFeatures($offset: String, $limit: Int) {
        findFeatures(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                tenant
                tenantCode
                tenantName
            }
        }
    }
`;