const t = {
    icon: 'fa-building',
    menus: {
        main: {section: 'customers', priority: 45},
    },
    new: {
        attributes: [
            'code', 'email', 'name', 'organization', 'partner', 'tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '$code!', '$email', '$name',
                ]],
                ['block', [
                    '$tenant!', '$organization', '$partner',
                ]],
            ]],
            '$submit',
        ]
    },
    edit: {
        attributes: [
            'code', 'email', 'name', 'organization', 'partner', 'tenant', 'status',
        ],
        content: [
            ['row', [
                ['block', [
                    '$code', '$email', '$name',
                ]],
                ['block', [
                    '$status', '$organization', '$partner', '$tenant',
                ]],
            ]],
            '$submit',
        ]
    },
    display: {
        properties: [
            'id', 'code', 'name', 'email', 'organizationName', 'partnerName', 'tenantName',
        ],
    },
    list: {
        columns: [
            {id: 'code', label: 'column_code_label', flex: 1} as any,
            {id: 'status', label: 'column_status_label', flex: 1} as any,
            {id: 'name', label: 'column_name_label', flex: 1} as any,
            {id: 'email', label: 'column_email_label', flex: 1} as any,
            {id: 'organizationName', label: 'column_organization_label', flex: 1} as any,
            {id: 'partner', label: 'column_partner_label', flex: 1, component: 'partner'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;