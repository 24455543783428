export const CREATE_PRINTER = (gql: any) => gql`
    mutation($data: CreatePrinterInput!) {
        createPrinter(data: $data) {
            id
        }
    }
`;
export const GET_PRINTER = (gql: any) => gql`
    query($id: ID!) {
        getPrinter(id: $id) {
            id
            code
            name
            tenant
        }
    }
`;
export const FIND_PRINTERS = (gql: any) => gql`
    query findPrinters($offset: String, $limit: Int) {
        findPrinters(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                tenant
            }
        }
    }
`;