export const CREATE_OREMINDER = (gql: any) => gql`
    mutation($data: CreateOreminderInput!) {
        createOreminder(data: $data) {
            id
        }
    }
`;
export const GET_OREMINDER = (gql: any) => gql`
    query($id: ID!) {
        getOreminder(id: $id) {
            id
            tenant
            tenantCode
            tenantName
            partner
            partnerCode
            partnerName
            organization
            organizationCode
            organizationName
            country
            locale
            status
            type
            kind
            preminder
            treminder
        }
    }
`;
export const FIND_OREMINDERS = (gql: any) => gql`
    query findOreminders($offset: String, $limit: Int) {
        findOreminders(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                tenant
                tenantCode
                tenantName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                status
                kind
                type
                preminder
                treminder
            }
        }
    }
`;