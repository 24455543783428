export const CREATE_EXPORTRULE = (gql: any) => gql`
    mutation($data: CreateExportruleInput!) {
        createExportrule(data: $data) {
            id
        }
    }
`;
export const GET_EXPORTRULE = (gql: any) => gql`
    query($id: ID!) {
        getExportrule(id: $id) {
            id
            name
            status
            type
            frequency
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            partner
            partnerCode
            partnerName
            campaign
            campaignCode
            campaignName
            tenant
            tenantCode
            tenantName
            sellergroup
            sellergroupCode
            sellergroupName
            seller
            sellerCode
            user
            userCode
            userEmail
            privateToken
            publicToken
            token
            params
            extractorParams
            transformerParams
            loaderParams
            notifiers
            extractor
            transformer
            loader
        }
    }
`;
export const FIND_EXPORTRULES = (gql: any) => gql`
    query findExportrules($offset: String, $limit: Int) {
        findExportrules(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                name
                status
                type
                frequency
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                campaign
                campaignCode
                campaignName
                tenant
                tenantCode
                tenantName
                sellergroup
                sellergroupCode
                sellergroupName
                seller
                sellerCode
                user
                userCode
                userEmail
                privateToken
                publicToken
                token
                params
                extractorParams
                transformerParams
                loaderParams
                notifiers
                extractor
                transformer
                loader
            }
        }
    }
`;
export const DELETE_EXPORTRULE = (gql: any) => gql`
    mutation deleteExportrule($id: ID!) {
        deleteExportrule(id: $id) {
            id
        }
    }
`;
export const SEARCH_EXPORTRULES = (gql: any) => gql`
    query searchExportrules($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchExportrules(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                name
                status
                type
                frequency
                game
                gameCode
                gameName
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                campaign
                campaignCode
                campaignName
                tenant
                tenantCode
                tenantName
                sellergroup
                sellergroupCode
                sellergroupName
                seller
                sellerCode
                user
                userCode
                userEmail
                privateToken
                publicToken
                token
                params
                extractorParams
                transformerParams
                loaderParams
                notifiers
                extractor
                transformer
                loader
            }
        }
    }
`;
export const UPDATE_EXPORTRULE = (gql: any) => gql`
    mutation updateExportrule($id: ID!, $data: UpdateExportruleInput!) {
        updateExportrule(id: $id, data: $data) {
            id
        }
    }
`;
