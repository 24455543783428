const t = {
    icon: 'fa-toolbox',
    menus: {
        main: {section: 'settings3', priority: 55},
    },
    new: {
        attributes: [
            'code', 'name', 'tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$code', '$name'
                ]]
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'code', 'name', 'tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$code', '$name'
                ]]
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'code', 'name',
        ],
    },
    list: {
        columns: [
            {id: 'name', label: 'column_name_label'},
            {id: 'code', label: 'column_code_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;