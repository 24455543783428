export const GET_SALE = (gql: any) => gql`
    query($id: ID!) {
        getSale(id: $id) {
            id
            campaign
            email
            campaignCode
            campaignName
            partner
            partnerCode
            partnerName
            organization
            organizationCode
            organizationName
            game
            gameCode
            gameName
            ownerGodfather
            ownerGodfatherCode
            ownerGodfatherEmail
            godfather
            godfatherCode
            godfatherEmail
            godfatherPhone
            tickets
            donation
            books
            pack
            packCode
            packName
            beneficiary
            beneficiaryCode
            beneficiaryName
            project
            projectCode
            projectName
            paymentAmount
            createdAt
            updatedAt
            status
            publicPageUrl
            publicPageShortUrl
            privatePageUrl
            paymentProviderFeeAmount
            marketPlaceFeeAmount
            transactionFeeAmount
            totalFeeAmount
            customerCollectedAmount
            billingRule
            planCode
            planRemainingTickets
            remainingTickets
            planRemainingSales
            remainingSales
            planRemainingPaymentAmount
            remainingPaymentAmount
        }
    }
`;
export const SEARCH_SALES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchSales(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                email
                createdAt
                updatedAt
                status
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                godfather
                godfatherCode
                godfatherEmail
                godfatherPhone
                books
                pack
                packCode
                packName
                beneficiary
                beneficiaryCode
                beneficiaryName
                project
                projectCode
                projectName
                paymentAmount
                paymentCurrency
                tickets
                donation
                game
                gameCode
                gameName
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
            }
        }
    }
`;
export const SEARCH_CREATED_SALES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchCreatedSales: searchSales(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "CREATED"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                status
                email
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                godfather
                godfatherCode
                godfatherEmail
                godfatherPhone
                books
                pack
                packCode
                packName
                beneficiary
                beneficiaryCode
                beneficiaryName
                project
                projectCode
                projectName
                paymentAmount
                paymentCurrency
                tickets
                donation
                game
                gameCode
                gameName
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
            }
        }
    }
`;
export const SEARCH_PROMISED_SALES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPromisedSales: searchSales(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "PROMISED"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                email
                status
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                godfather
                godfatherCode
                godfatherEmail
                godfatherPhone
                books
                pack
                packCode
                packName
                beneficiary
                beneficiaryCode
                beneficiaryName
                project
                projectCode
                projectName
                paymentAmount
                paymentCurrency
                tickets
                donation
                game
                gameCode
                gameName
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
            }
        }
    }
`;
export const SEARCH_CANCELED_SALES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchCanceledSales: searchSales(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "CANCELED"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                status
                email
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                godfather
                godfatherCode
                godfatherEmail
                godfatherPhone
                books
                pack
                packCode
                packName
                beneficiary
                beneficiaryCode
                beneficiaryName
                project
                projectCode
                projectName
                paymentAmount
                paymentCurrency
                tickets
                donation
                game
                gameCode
                gameName
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
            }
        }
    }
`;
export const SEARCH_FAILED_SALES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchFailedSales: searchSales(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "FAILED"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                status
                email
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                godfather
                godfatherCode
                godfatherEmail
                godfatherPhone
                books
                pack
                packCode
                packName
                beneficiary
                beneficiaryCode
                beneficiaryName
                project
                projectCode
                projectName
                paymentAmount
                paymentCurrency
                tickets
                donation
                game
                gameCode
                gameName
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
            }
        }
    }
`;
export const SEARCH_PAID_SALES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "paidAt:desc") {
        searchPaidSales: searchSales(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "PAID"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                status
                email
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                godfather
                godfatherCode
                godfatherEmail
                godfatherPhone
                books
                pack
                packCode
                packName
                beneficiary
                beneficiaryCode
                beneficiaryName
                project
                projectCode
                projectName
                paymentAmount
                paymentCurrency
                tickets
                donation
                game
                gameCode
                gameName
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
            }
        }
    }
`;
export const SEARCH_PAIDHELLOASSO_SALES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "paidAt:desc") {
        searchPaidHelloassoSales: searchSales(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "PAID"},
                {field: "paymentProvider", type: "string", operator: "eq", value: "helloasso"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                status
                email
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                godfather
                godfatherCode
                godfatherEmail
                godfatherPhone
                books
                pack
                packCode
                packName
                beneficiary
                beneficiaryCode
                beneficiaryName
                project
                projectCode
                projectName
                paymentAmount
                paymentCurrency
                paymentProvider
                tickets
                donation
                game
                gameCode
                gameName
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
            }
        }
    }
`;
export const SEARCH_PAIDPAYPAL_SALES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "paidAt:desc") {
        searchPaidPaypalSales: searchSales(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "PAID"},
                {field: "paymentProvider", type: "string", operator: "eq", value: "paypal"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                status
                email
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                godfather
                godfatherCode
                godfatherEmail
                godfatherPhone
                books
                pack
                packCode
                packName
                beneficiary
                beneficiaryCode
                beneficiaryName
                project
                projectCode
                projectName
                paymentAmount
                paymentCurrency
                paymentProvider
                tickets
                donation
                game
                gameCode
                gameName
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
            }
        }
    }
`;
export const SEARCH_PAIDSTRIPE_SALES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "paidAt:desc") {
        searchPaidStripeSales: searchSales(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "status", type: "string", operator: "wildcard", value: "PAID"},
                {field: "paymentProvider", type: "string", operator: "eq", value: "stripe"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                status
                email
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                godfather
                godfatherCode
                godfatherEmail
                godfatherPhone
                books
                pack
                packCode
                packName
                beneficiary
                beneficiaryCode
                beneficiaryName
                project
                projectCode
                projectName
                paymentAmount
                paymentCurrency
                paymentProvider
                tickets
                donation
                game
                gameCode
                gameName
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
            }
        }
    }
`;
export const SEARCH_FLEXNOTBILLED_SALES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "paidAt:desc") {
        searchFlexNotBilledSales: searchSales(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "billingStatus", type: "string", operator: "wildcard", value: "NOT_BILLED"},
                {field: "billingRule", type: "string", operator: "wildcard", value: "FLEX"}
                {field: "paidAt", type: "int", operator: "gt", intValue: 0}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                status
                email
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                godfather
                godfatherCode
                godfatherEmail
                godfatherPhone
                books
                pack
                packCode
                packName
                beneficiary
                beneficiaryCode
                beneficiaryName
                project
                projectCode
                projectName
                paymentAmount
                paymentCurrency
                tickets
                donation
                game
                gameCode
                gameName
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
            }
        }
    }
`;
export const SEARCH_FLEX_SALES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "paidAt:desc") {
        searchFlexSales: searchSales(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "billingRule", type: "string", operator: "wildcard", value: "FLEX"}
            ]
        }) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                status
                email
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                godfather
                godfatherCode
                godfatherEmail
                godfatherPhone
                books
                pack
                packCode
                packName
                beneficiary
                beneficiaryCode
                beneficiaryName
                project
                projectCode
                projectName
                paymentAmount
                paymentCurrency
                tickets
                donation
                game
                gameCode
                gameName
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
            }
        }
    }
`;
export const SEARCH_ALL_SALES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAllSales: searchSales(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                status
                email
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                godfather
                godfatherCode
                godfatherEmail
                godfatherPhone
                books
                pack
                packCode
                packName
                beneficiary
                beneficiaryCode
                beneficiaryName
                project
                projectCode
                projectName
                paymentAmount
                paymentCurrency
                tickets
                donation
                game
                gameCode
                gameName
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
            }
        }
    }
`;
export const FIND_SALES = (gql: any) => gql`
    query findSales($offset: String, $limit: Int) {
        findSales(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                status
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                organization
                organizationCode
                organizationName
                ownerGodfather
                ownerGodfatherCode
                ownerGodfatherEmail
                godfather
                godfatherCode
                godfatherEmail
                godfatherPhone
                books
                pack
                packCode
                packName
                beneficiary
                beneficiaryCode
                beneficiaryName
                project
                projectCode
                projectName
                paymentAmount
                paymentCurrency
                tickets
                donation
                game
                gameCode
                gameName
                publicPageShortUrl
                paymentProviderFeeAmount
                marketPlaceFeeAmount
                transactionFeeAmount
                totalFeeAmount
                customerCollectedAmount
            }
        }
    }
`;