const t = {
    icon: 'fa-image',
    menus: {
        main: {section: 'designs', priority: 35},
    },
    new: {
        attributes: [
            'tenant', 'code', 'name', 'description', 'type', 'svgFile', 'mainImage', 'tags',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '!$code!', '$name!', '$description', '$type',
                ]],
                ['block', [
                    '$svgFile', '$mainImage', '$objectTags!',
                ]]
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'code', 'name', 'description', 'type', 'svgFile', 'mainImage', 'tags',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant:readonly!', '!$code!', '$name!', '$description', '$type',
                ]],
                ['block', [
                    '$svgFile', '$mainImage', '$objectTags!',
                ]]
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'code', 'name', 'description', 'type', 'svgFile', 'mainImage', 'tags',
        ],
    },
    list: {
        columns: [
            {id: 'mainImage', label: 'column_main_image_label', render: 'image', flex: 0, width: 200},
            {id: 'svgFile', label: 'column_svgfile_label', render: 'image'},
            {id: 'code', label: 'column_code_label'},
            {id: 'name', label: 'column_name_label'},
            {id: 'type', label: 'column_type_label'},
            {id: 'tags', label: 'column_tags_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;