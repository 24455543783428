export const CREATE_ACHIEVABLE = (gql: any) => gql`
    mutation($data: CreateAchievableInput!) {
        createAchievable(data: $data) {
            id
        }
    }
`;
export const GET_ACHIEVABLE = (gql: any) => gql`
    query($id: ID!) {
        getAchievable(id: $id) {
            id
            achievableset
            achievablesetCode
            achievablesetName
            event
            type
            scope
            code
            name
            description
            tenant
            tenantCode
            campaign
            campaignCode
            partner
            partnerCode
            organization
            organizationCode
            game
            gameCode
            seller
            sellerCode
            sellergroup
            sellergroupCode
            godfather
            godfatherCode
            project
            projectCode
            pack
            packCode
            beneficiary
            beneficiaryCode
            sale
            rule
        }
    }
`;
export const FIND_ACHIEVABLES = (gql: any) => gql`
    query findAchievables($offset: String, $limit: Int) {
        findAchievables(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                achievableset
                achievablesetCode
                achievablesetName
                event
                type
                scope
                code
                name
                description
                tenant
                tenantCode
                campaign
                campaignCode
                partner
                partnerCode
                organization
                organizationCode
                game
                gameCode
                seller
                sellerCode
                sellergroup
                sellergroupCode
                godfather
                godfatherCode
                project
                projectCode
                pack
                packCode
                beneficiary
                beneficiaryCode
                sale
                rule
            }
        }
    }
`;
export const SEARCH_ACHIEVABLES = (gql: any) => gql`
    query searchAchievables($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAchievables(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                event
                type
                scope
                code
                name
                description
                tenant
                tenantCode
                campaign
                campaignCode
                partner
                partnerCode
                organization
                organizationCode
                game
                gameCode
                seller
                sellerCode
                sellergroup
                sellergroupCode
                godfather
                godfatherCode
                project
                projectCode
                pack
                packCode
                beneficiary
                beneficiaryCode
                sale
                rule
            }
        }
    }
`;
export const UPDATE_ACHIEVABLE = (gql: any) => gql`
    mutation updateAchievable($id: ID!, $data: UpdateAchievableInput!) {
        updateAchievable(id: $id, data: $data) {
            id
        }
    }
`;

export const DELETE_ACHIEVABLE = (gql: any) => gql`
    mutation deleteAchievable($id: ID!) {
        deleteAchievable(id: $id) {
            id
        }
    }
`;
