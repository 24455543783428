export const CREATE_DSHAPE = (gql: any) => gql`
    mutation($data: CreateDshapeInput!) {
        createDshape(data: $data) {
            id
        }
    }
`;
export const GET_DSHAPE = (gql: any) => gql`
    query($id: ID!) {
        getDshape(id: $id) {
            id
            tenant
            code
            name
            definition
            tags
        }
    }
`;
export const FIND_DSHAPES = (gql: any) => gql`
    query findDshapes($offset: String, $limit: Int) {
        findDshapes(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                definition
                tags
            }
        }
    }
`;