const t = {
    icon: 'fa-trophy',
    menus: {
        main: {section: 'settings', priority: 32},
    },
    new: {
        attributes: [
            'draw',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$draw!',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'status',
        ],
        content: [
            ['row', [
                ['block', [
                    '$status:readonly!', '!$draw!',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        body: true,
        properties: [
            'id', 'gameCode', 'gameName', 'email', 'phone',
        ],
    },
    list: {
        columns: [
            {id: 'createdAt', label: 'column_createdat_label', component: 'date', flex: 0, width: 160},
            {id: 'game', label: 'column_game_code_label', component: 'game'},
            {id: 'email', label: 'column_email_label'},
            {id: 'phone', label: 'column_phone_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;