export const CREATE_DOCUMENT = (gql: any) => gql`
    mutation($data: CreateDocumentInput!) {
        createDocument(data: $data) {
            id
        }
    }
`;
export const GET_DOCUMENT = (gql: any) => gql`
    query($id: ID!) {
        getDocument(id: $id) {
            id
            type
            token
            status
            tags
            publicFile {
                available
                url
            }
            game
            gameCode
            gameName
            organization
            organizationName
            organizationCode
            partner
            partnerCode
            partnerName
            tenant
            tenantName
            tenantCode
            locale
            country
            title
            description
            variables
            generateFailedDetails
            job
            jobStatus
            publicToken
            privateToken
            draw
            drawCode
            sale
            promise
            promiseCode
        }
    }
`;
export const FIND_DOCUMENTS = (gql: any) => gql`
    query findDocuments($offset: String, $limit: Int) {
        findDocuments(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                type
                token
                status
                game
                gameCode
                gameName
                organization
                organizationName
                organizationCode
                partner
                partnerCode
                partnerName
                tenant
                tenantName
                tenantCode
                locale
                country
                title
                description
                job
                jobStatus
                draw
                drawCode
                sale
                promise
                promiseCode
                publicToken
                publicFile {
                    available
                    url
                }
            }
        }
    }
`;