const t =  {
    icon: 'fa-database',
    menus: {
        main: {section: 'settings', priority: 96},
    },
    new: {
        attributes: [
            'name', 'category', 'subcategory', 'country', 'locale', 'tenant', 'presets', 'game', 'partner', 'organization',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$game!', '$partner', '$organization',
                ]],
                ['block', [
                    '$name!', '$category!', '$subcategory', '$odataitemPresets',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'category', 'subcategory', 'country', 'locale', 'presets', 'game', 'partner', 'organization',
        ],
        content: [
            ['row', [
                ['block', [
                    '$game!', '$partner', '$organization',
                ]],
                ['block', [
                    '$name!', '$category!', '$subcategory', '$odataitemPresets',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [ 'name', 'category', 'subcategory', 'country', 'locale', 'tenant', 'presets', 'gameCode', 'partnerCode', 'organizationCode',],
    },
    list: {
        columns: [
            {id: 'name', label: 'column_name_label', flex: 1, width: 120} as any,
            {id: 'category', label: 'column_category_label', flex: 1, width: 120} as any,
            {id: 'subcategory', label: 'column_subcategory_label', flex: 1, width: 120} as any,
            {id: 'game', label: 'column_game_label', flex: 1, width: 120, component: 'game'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;