const t = {
    icon: 'fa-trophy',
    menus: {
        main: {section: 'settings', priority: 30},
    },
    new: {
        attributes: [
            'draw', 'rank', 'bunch', 'bunchRank', 'ticket',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$draw!', '$bunch!', '$ticket!',
                ]],
                ['block', [
                    '$bunchRank!', '$rank',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'status',
        ],
        content: [
            ['row', [
                ['block', [
                    '$status:readonly!', '!$draw!', '$bunch!', '$ticket!',
                ]],
                ['block', [
                    '$bunchRank!', '$rank',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'code', 'rank', 'bunch', 'ticketCode', 'gameCode', 'gameName', 'email', 'phone',
        ],
    },
    list: {
        columns: [
            {id: 'createdAt', label: 'column_createdAt_label', component: 'date', flex: 0, width: 160},
            {id: 'code', label: 'column_code_label', flex: 0, width: 120},
            {id: 'game', label: 'column_game_code_label', component: 'game'},
            {id: 'rank', type: 'number', label: 'column_rank_label', flex: 0, width: 100},
            {id: 'bunch', label: 'column_bunch_label', flex: 0, width: 200, component: 'bunch'} as any,
            {id: 'email', label: 'column_email_label', flex: 0, width: 180},
            {id: 'phone', label: 'column_phone_label', flex: 0, width: 120},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;