const t = {
    icon: 'fa-book',
    menus: {
        main: { section: 'settings', priority: 47 },
    },
    new: {
        attributes: ['bookList', 'campaign', 'country', 'game', 'inlinePreset', 'locale', 'presets'],
        content: [
            ['row', [
                ['block', [
                    '!$game!', '$campaign', '$inlinePreset', '$booksetsPresets',
                ]],
                ['block', [
                    '$bookList', '$locale', '$country'
                ]]
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: ['bookList', 'campaign', 'country', 'game', 'locale', 'status'],
        content: [
            ['row', [
                ['block', [
                    '!$game!', '$campaign',
                ]],
                ['block', [
                    '$bookList', '$locale', '$country'
                ]]
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'bookList', 'createdAt', 'game', 'gameCode', 'gameName', 'locale', 'country', 'organization', 'organizationCode', 'organizationName',
            'partner', 'partnerCode', 'partnerName', 'campaign', 'campaignCode', 'campaignName', 'privatePageUrl', 'publicPageShortUrl', 'tenant',
            'tenantCode', 'tenantName', 'inlinePreset', 'presets', 'updatedAt',
        ],
    },
    list: {
        columns: [
            { id: 'gameCode', label: 'column_game_code_label', component: 'game', flex: 1 },
            { id: 'organizationName', label: 'column_organization_label', component: 'organization', flex: 1 },
            { id: 'bookList', label: 'column_booklist_label', flex: 1 },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    }
};

export default t;