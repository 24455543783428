export const CREATE_GKEYFIGURE = (gql: any) => gql`
    mutation($data: CreateGkeyfigureInput!) {
        createGkeyfigure(data: $data) {
            id
        }
    }
`;
export const GET_GKEYFIGURE = (gql: any) => gql`
    query($id: ID!) {
        getGkeyfigure(id: $id) {
            campaign
            campaignCode
            campaignName
            country
            createdAt
            description
            features
            game
            gameCode
            gameName
            id
            inlinePreset
            locale
            logoImage {
                url
                available
            }
            longDescription
            mainImage {
                url
                available
            }
            name
            organization
            organizationCode
            organizationName
            overline
            partner
            partnerCode
            partnerName
            presentation
            presets
            privateToken
            public
            publicToken
            rank
            status
            tags
            tenant
            tenantCode
            tenantName
            thumbnailImage {
                url
                available
            }
            type
            updatedAt
            value
            websiteUrl
        }
    }
`;
export const FIND_GKEYFIGURES = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findGkeyfigures(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                description
                game
                gameCode
                gameName
                id
                logoImage {
                    url
                    available
                }
                mainImage {
                    url
                    available
                }
                name
                organization
                organizationCode
                organizationName
                overline
                presentation
                privateToken
                public
                publicToken
                rank
                status
                tags
                tenant
                tenantCode
                tenantName
                type
                updatedAt
                value
                websiteUrl
            }
        }
    }
`;
export const SEARCH_GKEYFIGURES = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGkeyfigures(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                description
                game
                gameCode
                gameName
                id
                logoImage {
                    url
                    available
                }
                mainImage {
                    url
                    available
                }
                name
                organization
                organizationCode
                organizationName
                overline
                presentation
                privateToken
                public
                publicToken
                rank
                status
                tags
                tenant
                tenantCode
                tenantName
                type
                updatedAt
                value
                websiteUrl
            }
        }
    }
`;
export const UPDATE_GKEYFIGURE = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateGkeyfigureInput!) {
        updateGkeyfigure(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_GKEYFIGURE = (gql: any) => gql`
    mutation($id: ID!) {
        deleteGkeyfigure(id: $id) {
            id
        }
    }
`;