export const CREATE_BENEFICIARY = (gql: any) => gql`
    mutation($data: CreateBeneficiaryInput!) {
        createBeneficiary(data: $data) {
            id
        }
    }
`;
export const GET_BENEFICIARY = (gql: any) => gql`
    query($id: ID!) {
        getBeneficiary(id: $id) {
            id
            code
            name
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            locale
            country
            features
            qrcodeLogoImage {
                available
                size
                url
            }
            logoImage {
                available
                size
                url
            }
            mainImage {
                available
                size
                url
            }
            thumbnailImage {
                available
                size
                url
            }
            image1Image {
                url
                available
            }
            image2Image {
                url
                available
            }
            image3Image {
                url
                available
            }
            image4Image {
                url
                available
            }
            image5Image {
                url
                available
            }
            image6Image {
                url
                available
            }
            image7Image {
                url
                available
            }
            image8Image {
                url
                available
            }
            image9Image {
                url
                available
            }
            plannedDonationRatio
            description
            presentation
            websiteUrl
            donationUrl
        }
    }
`;
export const FIND_BENEFICIARIES = (gql: any) => gql`
    query findBeneficiaries($offset: String, $limit: Int) {
        findBeneficiaries(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                gameCode
                gameName
                organizationCode
                organizationName
                logoImage {
                    available
                    size
                    url
                }
                mainImage {
                    available
                    size
                    url
                }
                thumbnailImage {
                    available
                    size
                    url
                }
                image1Image {
                    url
                    available
                }
                image2Image {
                    url
                    available
                }
                image3Image {
                    url
                    available
                }
                image4Image {
                    url
                    available
                }
                image5Image {
                    url
                    available
                }
                image6Image {
                    url
                    available
                }
                image7Image {
                    url
                    available
                }
                image8Image {
                    url
                    available
                }
                image9Image {
                    url
                    available
                }
                plannedDonationRatio
                description
                presentation
                websiteUrl
                donationUrl
            }
        }
    }
`;
export const UPDATE_BENEFICIARY = (gql: any) => gql`
    mutation updateBeneficiary($id: ID!, $data: UpdateBeneficiaryInput!) {
        updateBeneficiary(id: $id, data: $data) {
            id
        }
    }
`;
export const MOVE_GAME_BENEFICIARY = (gql: any) => gql`
    mutation moveGameBeneficiary($parentId: String!, $id: ID!, $direction: String) {
        moveGameBeneficiary(parentId: $parentId, id: $id, direction: $direction) {
            id
        }
    }
`;
export const DELETE_BENEFICIARY = (gql: any) => gql`
    mutation deleteBeneficiary($id: ID!) {
        deleteBeneficiary(id: $id) {
            id
        }
    }
`;
