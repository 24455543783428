export const CREATE_DIMAGE = (gql: any) => gql`
    mutation($data: CreateDimageInput!) {
        createDimage(data: $data) {
            id
        }
    }
`;
export const GET_DIMAGE = (gql: any) => gql`
    query($id: ID!) {
        getDimage(id: $id) {
            id
            tenant
            code
            name
            description
            type
            svgFile {
                available
                url
            }
            mainImage {
                available
                url
            }
            tags
        }
    }
`;
export const FIND_DIMAGES = (gql: any) => gql`
    query findDimages($offset: String, $limit: Int) {
        findDimages(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                description
                type
                svgFile {
                    available
                    url
                }
                mainImage {
                    available
                    url
                }
                tags
            }
        }
    }
`;