const t = {
    icon: 'fa-file-audio',
    menus: {
        main: {section: 'medias', priority: 65},
    },
    new: {
        attributes: [
            'game', 'tags', 'mainAudioUrl', 'backgroundAudioUrl',
            'title', 'description',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$title!', '$description', '$game!', '$audioTags',
                ]],
                ['block', [
                    '$mainAudioUrl',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'game', 'tags', 'mainAudioUrl', 'backgroundAudioUrl',
            'title', 'description',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$title!', '$description', '$game!', '$audioTags',
                ]],
                ['block', [
                    '$mainAudioUrl',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'gameName', 'organizationName', 'status', 'title', 'description', 'tags',
        ],
    },
    list: {
        columns: [
            {id: 'status', label: 'column_status_label'},
            {id: 'game', label: 'column_game_label', flex: 1, width: 120, component: 'game'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;