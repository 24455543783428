const t = {
    icon: 'fa-laptop',
    menus: {
        main: {section: 'customers', priority: 34},
    },
    new: {
        attributes: [
            'country', 'externalReference', 'game', 'locale', 'objectId', 'organization', 'partner', 'seller',
            'sellergroup', 'tags', 'tenant', 'type', 'user',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$type', '$country', '$locale', '$externalReference', '$objectId', '$tags',
                ]],
                ['block', [
                    '$tenant!', '$game', '$organization', '$partner', '$seller', '$sellergroup', '$user',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'country', 'externalReference', 'game', 'locale', 'objectId', 'organization', 'partner', 'seller',
            'sellergroup', 'tags', 'tenant', 'type', 'user',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$type', '$country', '$locale', '$externalReference', '$objectId', '$tags',
                ]],
                ['block', [
                    '$tenant!', '$game', '$organization', '$partner', '$seller', '$sellergroup', '$user',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'activeGroup', 'closedAt', 'closedBy', 'country', 'createdAt', 'externalReference', 'game', 'gameCode', 'gameName', 'id', 'inlinePreset', 'locale',
            'notOpenedAt', 'notOpenedBy', 'objectId', 'openedAt', 'openedBy', 'organization', 'organizationCode', 'organizationName', 'partner', 'partnerCode',
            'partnerName', 'presets', 'privateToken', 'publicToken', 'seller', 'sellerCode', 'sellerEmail', 'sellerFirstName', 'sellerLastName', 'sellerPhone',
            'sellergroup', 'sellergroupCode', 'sellergroupEmail', 'sellergroupGameSellergroupType', 'sellergroupName', 'sellergroupPhone', 'sellergroupType',
            'status', 'tags', 'tenant', 'tenantCode', 'tenantName', 'type', 'updatedAt', 'user', 'userCode', 'userEmail', 'userFirstName', 'userLastName', 'userPhone',
        ],
    },
    list: {
        columns: [
            { id: 'createdAt', label: 'column_createdat_label', flex: 1, width: 100, component: 'date' } as any,
            { id: 'game', label: 'column_game_label', flex: 1, width: 100, component: 'game' } as any,
            { id: 'type', label: 'column_type_label', flex: 1, width: 100 } as any,
            { id: 'externalReference', label: 'column_externalreference_label', flex: 1, width: 100 } as any,
            { id: 'objectId', label: 'column_objectid_label', flex: 1, width: 100 } as any,
            { id: 'closedAt', label: 'column_closedat_label', flex: 1, width: 100, component: 'date' } as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;