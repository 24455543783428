export const CREATE_GLABEL = (gql: any) => gql`
    mutation($data: CreateGlabelInput!) {
        createGlabel(data: $data) {
            id
        }
    }
`;
export const GET_GLABEL = (gql: any) => gql`
    query($id: ID!) {
        getGlabel(id: $id) {
            campaign
            campaignCode
            campaignName
            country
            createdAt
            description
            features
            game
            gameCode
            gameName
            id
            inlinePreset
            locale
            logoAltImage {
                url
                available
            }
            logoImage {
                url
                available
            }
            longDescription
            mainImage {
                url
                available
            }
            name
            organization
            organizationCode
            organizationName
            overline
            partner
            partnerCode
            partnerName
            presentation
            presets
            privateToken
            public
            publicToken
            rank
            status
            tags
            tenant
            tenantCode
            tenantName
            thumbnailImage {
                url
                available
            }
            type
            updatedAt
            websiteUrl
        }
    }
`;
export const FIND_GLABELS = (gql: any) => gql`
    query($offset: String, $limit: Int) {
        findGlabels(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                description
                game
                gameCode
                gameName
                id
                logoImage {
                    url
                    available
                }
                mainImage {
                    url
                    available
                }
                name
                organization
                organizationCode
                organizationName
                overline
                presentation
                privateToken
                public
                publicToken
                rank
                status
                tags
                tenant
                tenantCode
                tenantName
                type
                updatedAt
                websiteUrl
            }
        }
    }
`;
export const SEARCH_GLABELS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGlabels(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                description
                game
                gameCode
                gameName
                id
                logoImage {
                    url
                    available
                }
                mainImage {
                    url
                    available
                }
                name
                organization
                organizationCode
                organizationName
                overline
                presentation
                privateToken
                public
                publicToken
                rank
                status
                tags
                tenant
                tenantCode
                tenantName
                type
                updatedAt
                websiteUrl
            }
        }
    }
`;
export const UPDATE_GLABEL = (gql: any) => gql`
    mutation($id: ID!, $data: UpdateGlabelInput!) {
        updateGlabel(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_GLABEL = (gql: any) => gql`
    mutation($id: ID!) {
        deleteGlabel(id: $id) {
            id
        }
    }
`;