const t =  {
    plural: 'identities',
    menus: {
        main: {section: 'customers', priority: 31, icon: 'fa-users'},
    },
    new: {
        attributes: [
            'provider', 'externalId', 'partner', 'tenant', 'organization',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '$partner', '$organization',
                ]],
                ['block', [
                    '$provider!', '$externalId!',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'provider', 'externalId', 'partner', 'tenant', 'organization',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant', '$partner', '$organization',
                ]],
                ['block', [
                    '$provider', '$externalId',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'createdAt', 'externalId', 'id', 'organization', 'organizationCode', 'organizationName', 'partner', 'partnerCode', 'partnerName', 'privateToken', 'provider',
            'publicToken', 'status', 'tags', 'tenant', 'tenantCode', 'tenantName', 'token', 'updatedAt', 'user', 'userCode', 'userEmail', 'userFirstName', 'userLastName', 'userPhone',
        ],
    },
    list: {
        columns: [
            {id: 'id', label: 'column_id_label', flex: 1, width: 100} as any,
            {id: 'provider', label: 'column_provider_label', flex: 1, width: 100} as any,
            {id: 'externalId', label: 'column_externalId_label', flex: 1, width: 100} as any,
            { id: 'organizationCode', label: 'column_organization_code_label', flex: 1, width: 100, component: 'organization' } as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;