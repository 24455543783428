export const CREATE_PARTNER = (gql: any) => gql`
    mutation($data: CreatePartnerInput!) {
        createPartner(data: $data) {
            id
        }
    }
`;
export const GET_PARTNER = (gql: any) => gql`
    query($id: ID!) {
        getPartner(id: $id) {
            id
            code
            name
            tenant
            status
            presentation
            description
            websiteUrl
            mainTheme
            mainDfontfamily
            faviconImage {
                available
                url
            }
            appleTouchImage {
                available
                url
            }
            qrcodeLogoImage {
                available
                url
            }
            logoImage {
                available
                url
            }
            logoAltImage {
                available
                url
            }
            bannerImage {
                available
                url
            }
            mobileBannerImage {
                available
                url
            }
            tabletBannerImage {
                available
                url
            }
            mainImage {
                available
                url
            }
            campaign
            campaignCode
            campaignName
            country
            emailsender
            emailsenderEmail
            emailsenderIdentityArn
            emailsenderName
            locale
            notifyEmail
            notifyPhone
            scopeLocale
            smssender
            smssenderName
            smssenderPhone
            smssenderProvider
            varColorBorderPrimary
            varColorBorderSecondary
            varColorChoicePrimary
            varColorChoiceSecondary
            varColorFooterPrimary
            varColorFooterSecondary
            varColorHeading
            varColorLogo
            varColorNavPrimary
            varColorNavSecondary
            varColorPanelPrimary
            varColorPanelSecondary
            varColorPrimary
            varColorSecondary
            varColorTextPrimary
            varColorTextSecondary
            varColorTitle
            backgroundImage {
                available
                url
            }
            banner2Image {
                available
                url
            }
            facebookShareImage {
                available
                url
            }
            flyerImage {
                available
                url
            }
            mainCss {
                available
                content
                url
            }
            mainJs {
                available
                content
                url
            }
            mainQuote
            mainQuoteImage {
                available
                url
            }
            mainQuoteImageSubtitle
            mainQuoteImageTitle
            mainQuoteTitle
            mainQuoteVideoUrl
            private
            public
            publicPageBackgroundImage {
                available
                url
            }
            scope
            publicPageModulesCustomRaw
            publicPageModulesData
            publicPageModulesDiff
            supportedLocales
            twitterShareImage {
                available
                url
            }
            stripeCustomer
        }
    }
`;
export const FIND_PARTNERS = (gql: any) => gql`
    query findPartners($offset: String, $limit: Int) {
        findPartners(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                tenant
                status
                logoImage {
                    available
                    url
                }
            }
        }
    }
`;
export const SEARCH_PARTNERS = (gql: any) => gql`
    query searchPartners($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchPartners(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                code
                name
                tenant
                status
                logoImage {
                    available
                    url
                }
            }
        }
    }
`;
