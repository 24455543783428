const t = {
    icon: 'fa-image',
    menus: {
        main: { section: 'medias', priority: 30 },
    },
    new: {
        attributes: [
            'name', 'code', 'description', 'mainImage', 'type', 'tags', 'tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$code!', '$description', '$assetType!', '$assetTags', '$tenant!',
                ]],
                ['block', [
                    '$mainImage',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'code', 'description', 'mainImage', 'type', 'tags', 'tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$code!', '$description', '$assetType!', '$assetTags', '$tenant!',
                ]],
                ['block', [
                    '$mainImage',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'name', 'code', 'description', 'mainImage', 'type', 'tags', 'tenant',
        ],
    },
    list: {
        columns: [
            { id: 'mainImage', label: 'column_main_image_label', render: 'image', flex: 0, width: 200 },
            { id: 'code', label: 'column_code_label' },
            { id: 'name', label: 'column_name_label' },
            { id: 'description', label: 'column_description_label' },
            { id: 'tags', label: 'column_tags_label' },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;